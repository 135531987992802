import styled from "styled-components/macro";

export const ScrollTextBoxHolder = styled.div`
  padding: 5vh;
  height: max-content;
  width: 100px;
  text-align: left;
  font-size: 0.7rem;
  flex-basis: 50%;
  /* border: black solid 2px; */

  h5 {
    margin: 0;
    padding: 0;
    font-family: Tungsten Extra;
    font-size: 1.5rem;
    letter-spacing: 0.3vw;
    margin-bottom: 0;
    color: ${({
      theme: {
        palette: {
          fg: { lightPink },
        },
      },
    }) => lightPink};
    /* margin: 0px; */
  }

  p {
    bottom: 0;
    margin-top: 0;
    line-height: 2vh;
    font-family: Akkurat-Pro-Light;
    color: white;
    font-size: 1.6vh;
    letter-spacing: 1px;
    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
  }
`;

export const ScrollTextWrapper = styled.div`
  /* background-color: hotpink; */

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* flex-basis: 100%; */
  flex: 1;
  width: 100%;

  /* height: 900px; */
`;

/***
 * SCROLL
 */

export const GridWrapperA = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(100px, auto);
  padding: 6vh;
  padding-top: 8vh;
  padding-left: 0;
  height: 100%;
  width: 100%;

  .title {
    transform: translate(-18%, -20%);
    display: flex;
    text-align: center;
    justify-content: flex-end;
    font-family: Tungsten;
    z-index: 1;
    font-size: 12rem;
    margin: 0;
    grid-column: 2 / 7;
    grid-row: 1/4;

    #sup {
      top: 0%;
      padding: 0;
    }

    #sub {
      margin-left: 33%;
    }
  }
  .headshot {
    transform: translate(-20%, 0);

    margin: 0;
    padding: 0;
    grid-column: 2 / 4;
    grid-row: 1 / 6;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("${process.env
      .PUBLIC_URL}/assets/images/about/petew.jpg");
  }

  .text {
    /* border: white 2px solid; */
    transform: translate(0, -5%);
    padding: 1rem;
    padding-right: 0%;
    padding-left: 2rem;
    display: grid;
    grid-column: 5/8;
    grid-row: 1/6;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    /* grid-auto-rows: minmax(100px, auto); */

    gap: 1rem;
    margin: 1.5rem;

    text-align: left;

    h5 {
      margin: 0;
      padding: 0;
      font-family: Tungsten Extra;
      font-size: 1.5 rem;
      letter-spacing: 0.3vw;
      margin-bottom: 0;
      color: ${({
        theme: {
          palette: {
            fg: { lightPink },
          },
        },
      }) => lightPink};
      /* margin: 0px; */
    }

    p {
      bottom: 0;
      margin-top: 0;
      line-height: 1rem;
      font-family: Akkurat-Pro-Light;
      color: white;
      font-size: 0.7rem;
      letter-spacing: 1px;
      color: ${({
        theme: {
          palette: {
            fg: { darkGreen },
          },
        },
      }) => darkGreen};
    }

    .subtitle {
      grid-column: 2;
      grid-row: 1;
      font-family: Tungsten;
      color: ${({
        theme: {
          palette: {
            fg: { darkGreen },
          },
        },
      }) => darkGreen};
      /* width: 400px; */
      font-size: 2rem;
      text-align: left;
      letter-spacing: 1vh;
      grid-column: 2;
      grid-row: 1;
    }

    .t-0 {
      transform: translate(0, 15%);
      margin-top: 20px;
      grid-column: 1;
      grid-row: 2;
    }

    .t-1 {
      margin-top: 20px;

      grid-column: 2;
      grid-row: 2;
    }

    .t-2 {
      grid-column: 1;
      grid-row: 3;
    }
    .t-3 {
      grid-column: 2;
      grid-row: 3;
    }
  }

  .six {
    transform: translate(-25%, -20%);
    p {
      color: ${({
        theme: {
          palette: {
            fg: { darkGreen },
          },
        },
      }) => darkGreen};
      font-family: Akkurat-Pro-Regular;
      font-size: 1rem;
      letter-spacing: 0.6px;
      line-height: 1.3rem;

      text-align: left;
    }
    margin-right: 20%;

    grid-column: 2/4;
    grid-row: 5;
  }
`;
