import config from "./config";
import HttpService from "services/HttpService";
import SalesInfoService from "services/SalesInfoService";

const httpService = new HttpService();
const salesInfoService = new SalesInfoService(config, httpService);

const dependencies = {
  config,
  httpService,
  salesInfoService,
};

export default dependencies;
