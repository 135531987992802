import styled from "styled-components/macro";
import { MdMenu } from "react-icons/md";

import { MenuIconCust } from "../Icons/MenuIcon";

export const StyledMenuButton = styled(MenuIconCust)``;

export const Root = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ theme: { spacing } }) => spacing(6)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;

  margin-right: ${({ theme: { spacing } }) => spacing(1)}px;
`;
