import React from "react";

const Arrow = ({ size = 75, angle }) => (
  <svg width={size} height={size} viewBox="-2 -2 28 28" version="1.1">
    <g transform={`translate(12,12) rotate(${angle}) translate(-12,-12)`}>
      <path
        d="M12.05756,0.1792485 L23.88121,11.99715 L12.05756,23.8208"
        stroke="#e83458"
        stroke-width="2"
        fill-opacity="0"
      />
      <path
        d="M0.1188358,0.1792485 L11.93673,11.99715 L0.1188358,23.8208"
        stroke="#e83458"
        stroke-width="2"
        fill-opacity="0"
      />
    </g>
  </svg>
);

export const ArrowLeft = ({ size }) => <Arrow size={size} angle={180} />;
export const ArrowRight = ({ size }) => <Arrow size={size} angle={0} />;
