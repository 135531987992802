import { useEffect, useState, useCallback, memo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Route } from "react-router";
import { favouritesSelector } from "state/selectors";
import { initialise } from "state/favourites";
import Screen from "components/Screen";
import { copyUrlToClipboard } from "util/helpers";
import { BLOCK_TO_BUILDING } from "state/apartments";

import Pattern from "components/Icons/Pattern";
import {
  CopyButton,
  FavouriteCard,
  FavouriteHeader,
  FavouritesCardsWrapper,
  FavouritesPageWrapper,
  FavouriteTitle,
  Info,
  Share,
  ShareButton,
  ShareUIWrapper,
} from "./Favourites.style";
import ApartmentCard from "../ApartmentFinder/BuildingExplorer/ApartmentCard";
import useStore from "state/zustandStore";
import { FIELD_NAME_MAPPER } from "App";

const Favourites = ({ match: { url, path } }) => {
  const favourites = useSelector(favouritesSelector);

  const dispatch = useDispatch();
  const [shareOpen, setShareOpen] = useState(false);

  const [openCard, setOpenCard] = useState(false);
  const [currentCard, setCurrentCard] = useState();

  const handleClick = (v) => {
    console.log(v);
    setCurrentCard(v);
    setOpenCard(!openCard);
  };

  const toggleShare = useCallback(() => {
    setShareOpen((open) => !open);
  }, []);
  const history = useHistory();

  const handleCloseApartmentModal = useCallback(() => {
    setOpenCard(false);
    history.replace(url);
  }, [url, history]);

  const Floors = {
    "-1": "L GROUND",
    0: "GROUND",
    1: "FIRST",
    2: "SECOND",
    3: "THIRD",
    4: "FOURTH",
    5: "FITH",
    6: "SITH",
    7: "SEVENTH",
    8: "EIGHTH",
    9: "NINTH",
    10: "TENTH",
    11: "ELEVENTH",
    12: "TWELFTH",
    13: "THIRTEENTH",
    14: "FOURTEENTH",
    15: "FITHTEENTH",
    16: "SIXTEENTH",
    17: "SEVENTEENTH",
    18: "EIGHTEENTH",
    19: "NINETEENTH",
  };

  const apartments = useStore((s) => s.apartments);
  const ReturnFloorplan = memo(
    ({ favourite }) => {
      let lowercase = favourite.flatType;
      const thisApt = apartments.find(
        (ap) => ap[FIELD_NAME_MAPPER.plot] === favourite.id
      );
      if (favourite === undefined) {
        return <div>--</div>;
      } else {
        return (
          <object
            style={{
              pointerEvents: "none",
              userSelect: "none",
            }}
            width="100%"
            height="90%"
            data={`${process.env.PUBLIC_URL}/assets/svg/cards/${
              thisApt[FIELD_NAME_MAPPER.type]
            }.svg`}
            type="image/svg+xml"
          >
            <img alt="floorplan" />
          </object>
        );
      }
    },
    [favourites]
  );

  const hasFav = favourites.length === 0 ? true : false;

  return (
    <Screen>
      {hasFav && (
        <Screen>
          <div
            style={{
              zIndex: 101,
              backgroundColor: "#004C42",
              color: "#004A3F",
              fontSize: "1.5rem",
              padding: "20px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              justifyItems: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          ></div>
          <div
            style={{
              zIndex: 104,
              backgroundColor: "#FFE9CE",
              color: "#004A3F",
              fontSize: "1.5rem",
              padding: "20px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              justifyItems: "center",
              width: "600px",
              height: "65px",
              position: "absolute",
            }}
          >
            YOUR FAVOURITES ARE WAITING TO BE PICKED
          </div>
          {/* <Pattern
            viewBox="0 0 600 600"
            style={{
              zIndex: 102,
              backgroundColor: "#004A3F",
              position: "absolute",
              transform: "translate(0, 0)",
            }}
            fillOpacity={0.4}
          /> */}
        </Screen>
      )}

      {true && (
        <ApartmentCard
          onClose={handleCloseApartmentModal}
          currentApt={currentCard}
        ></ApartmentCard>
      )}

      <FavouritesPageWrapper>
        <ShareUIWrapper>
          <FavouriteTitle>
            You have {favourites.length} favourites:
          </FavouriteTitle>
          <ShareButton onClick={toggleShare}>share</ShareButton>
          {shareOpen && (
            <Share>
              <CopyButton onClick={() => copyUrlToClipboard("copyable-url")}>
                <div>
                  COPY <br /> LINK
                </div>
              </CopyButton>
              <input
                style={{
                  flex: 3,
                  display: "flex",
                  justifyContent: "space-around",
                }}
                id="copyable-url"
                value={`${getBaseLocation(url)}/${encode(favourites)}`}
              />
            </Share>
          )}
        </ShareUIWrapper>
        <FavouritesCardsWrapper>
          {/* <Pattern
            viewBox="0 0 600 600"
            style={{
              position: "absolute",
              transform: "translate(0, 0)",
            }}
            fillOpacity={0.4}
          /> */}
          {favourites.map((favourite, i) => (
            <FavouriteCard
              onClick={() =>
                handleClick(
                  apartments.find(
                    (ap) => ap[FIELD_NAME_MAPPER.plot] === favourite.id
                  )
                )
              }
            >
              <FavouriteHeader>
                {console.log(favourite.building)}
                {/* <Info>{favourite.flatType}</Info> */}
                <Info>
                  {BLOCK_TO_BUILDING[favourite.id.split("-")[0]]}-
                  {favourite.id.split("-")[1]}
                </Info>
                <Info>{favourite.type} BED</Info>
                <Info>{Floors[favourite.floor]} FLOOR</Info>
              </FavouriteHeader>
              <ReturnFloorplan favourite={favourite} />
            </FavouriteCard>
          ))}
        </FavouritesCardsWrapper>
      </FavouritesPageWrapper>
      <Route
        path={`${path}/:favourites`}
        component={SharedFavouritesReceiver}
      />
    </Screen>
  );
};

const getBaseLocation = (url) => {
  return `${window.location.href.split(url)[0]}${url}`;
};

const SharedFavouritesReceiver = ({
  match: {
    params: { favourites },
  },
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const decoded = decode(favourites);
    if (decoded) {
      dispatch(initialise({ apartmentIds: decoded }));
    }
  }, [favourites, dispatch]);
  return null;
};

const encode = (favourites) => {
  return btoa(favourites.map(({ id }) => id).join(","));
};

const decode = (encoded) => {
  return atob(encoded).split(",");
};

export default Favourites;
