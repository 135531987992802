import { useState, useCallback, useEffect, useRef } from "react";
import { Root, Tab, Content } from "./SlideDrawerTemp.style";
import { useTranslation } from "react-i18next";

export const useSlideDrawerTempControls = (initialIsOpen) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    onClose: handleClose,
    onOpen: handleOpen,
    isOpen,
  };
};

const SlideDrawerTemp = ({ isOpen, tab, onTabClick, children, width }) => {
  const contentRef = useRef();
  const [contentWidth, setContentWidth] = useState(width);

  useEffect(() => {
    // const { width: contentWidth } = contentRef.current.getBoundingClientRect();
    const contentWidth = contentRef.current.getBoundingClientRect();
    setContentWidth(contentWidth);
  }, []);
  console.log("contentWidth", contentWidth);
  console.log("width", width);

  return (
    <Root>
      <Tab onClick={onTabClick}>{tab}</Tab>
      <Content
        onClick={onTabClick}
        // right={width}
        ref={contentRef}
        isOpen={isOpen}
        // contentWidth={contentWidth}
      >
        {children}
      </Content>
    </Root>
  );
};

export default SlideDrawerTemp;
