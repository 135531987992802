import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDoubleRight } from "react-icons/bs";
import CoverImage from "components/CoverImage";
import SlideDrawer, { useSlideDrawerControls } from "components/SlideDrawer";
import {
  Root,
  Tab,
  PoiRoot,
  ClosePois,
  Pois,
  Poi,
  PoiNumber,
  PoiTitle,
  PoiText,
  Left,
  Right,
  POICircle,
  Time,
} from "./Local.style";
import { useEffect } from "react";

const mapBackgroundUrl = `${process.env.PUBLIC_URL}/assets/images/location/local/map_background.png`;

const POIs = {
  1: {
    left: 40.5,
    top: 68,
  },
  2: {
    left: 44.75,
    top: 67,
  },
  3: {
    left: 67.5,
    top: 92,
  },
  4: {
    left: 52,
    top: 98,
  },
  5: {
    left: 49,
    top: 66.5,
  },
  6: {
    left: 69.5,
    top: 81,
  },
  7: {
    left: 54,
    top: 51.5,
  },
  8: {
    left: 58,
    top: 59,
  },
  9: {
    left: 75,
    top: 69,
  },
  10: {
    left: 51,
    top: 90,
  },
  11: {
    left: 54.75,
    top: 62.5,
  },
  12: {
    left: 53,
    top: 62,
  },
  13: {
    left: 51.5,
    top: 95,
  },
  14: {
    left: 42,
    top: 68,
  },
};

const Local = () => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useSlideDrawerControls();
  const [highlightedPOI, setHighlightedPOI] = useState();

  useEffect(() => {
    if (highlightedPOI) {
      onOpen();
    }
  }, [highlightedPOI, onOpen]);

  return (
    <Root>
      <CoverImage imgUrl={mapBackgroundUrl}>
        {Object.keys(POIs).map((key) => (
          <POICircle
            key={key}
            position={POIs[key]}
            isHighlighted={key === highlightedPOI}
            onClick={() => {
              setHighlightedPOI(key);
            }}
          >
            {pad(`${key}`, 2)}
          </POICircle>
        ))}
      </CoverImage>
      <SlideDrawer
        width="400"
        isOpen={isOpen}
        onTabClick={onOpen}
        tab={<Tab>{t("location.local.highlights")}</Tab>}
      >
        <PointsOfInterest
          onClose={onClose}
          // highlight={highlightedPOI}
          onSelect={setHighlightedPOI}
        />
      </SlideDrawer>
    </Root>
  );
};

const pad = (s, n) => (s.length < n ? pad(`0${s}`) : s);

const POI_COUNT = 14;

const PointsOfInterest = ({ highlight, onSelect, onClose }) => (
  <PoiRoot>
    <ClosePois onClick={onClose}>
      <BsChevronDoubleRight style={{ fontSize: "4vh" }} />
    </ClosePois>
    <Pois>
      {Array.from({ length: POI_COUNT }).map((_, index) => (
        <PoiCard
          index={index + 1}
          isHighlighted={(index + 1).toString() === highlight}
          onSelect={onSelect}
        />
      ))}
    </Pois>
  </PoiRoot>
);

const PoiCard = ({ index, isHighlighted, onSelect }) => {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => ref.current.scrollIntoView(), 300);
    }
  }, [isHighlighted]);

  return (
    <Poi
      ref={ref}
      key={`poi-${index}`}
      isHighlighted={isHighlighted}
      onClick={() => onSelect(`${index}`)}
    >
      <Left>
        <PoiNumber>{index}</PoiNumber>
        <Time>{t(`location.local.pois.${index}.time`)} </Time>
      </Left>

      <Right>
        <PoiTitle>{t(`location.local.pois.${index}.title`)}</PoiTitle>
        <PoiText>{t(`location.local.pois.${index}.text`)}</PoiText>
      </Right>
    </Poi>
  );
};

export default Local;
