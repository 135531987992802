import React from "react";

export default function ToggleInner(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      fill={"#FFC1BF"}
      opacity={0.9}
      stroke={"#fff"}
      strokeWeight={40}
      {...props}
    >
      <g strokeWidth={4} data-name="Layer 1">
        <circle strokeWidth={7} cx={200} cy={200} r="179.5" />
        <g fillOpacity={0}>
          <path d="M262,143.33l20.24-.58a66.23,66.23,0,0,1,21.4,6.56l8.48-18.52-6.36-2.31.77-2.31-12.72-4L282.67,121V106.69h-16v5.4h-4.05l-.19,8.1-4.24,1Z" />
          <path d="M262,143.33l20.24-.58a66.23,66.23,0,0,1,21.4,6.56l8.48-18.52-6.36-2.31.77-2.31-12.72-4L282.67,121V106.69h-16v5.4h-4.05l-.19,8.1-4.24,1Z" />
          <polygon points="308.11 203.3 326.43 201.76 329.51 221.43 324.88 221.62 325.08 224.32 306.38 225.48 306.38 222.39 302.91 222.39 302.72 224.9 289.8 223.94 289.8 220.47 285.56 220.08 289.22 201.57 308.11 203.3" />
          <polygon points="308.11 203.3 326.43 201.76 329.51 221.43 324.88 221.62 325.08 224.32 306.38 225.48 306.38 222.39 302.91 222.39 302.72 224.9 289.8 223.94 289.8 220.47 285.56 220.08 289.22 201.57 308.11 203.3" />
          <path d="M337.43,186.53l19.05-6.35-3.27-7.51,1.73-.77-11.55-19.05L329.72,140l-1.92,1.54-5-4-11,16.94a64.66,64.66,0,0,1,15.79,13.86C333.82,176,337.43,186.53,337.43,186.53Z" />
          <path d="M337.43,186.53l19.05-6.35-3.27-7.51,1.73-.77-11.55-19.05L329.72,140l-1.92,1.54-5-4-11,16.94a64.66,64.66,0,0,1,15.79,13.86C333.82,176,337.43,186.53,337.43,186.53Z" />
          <polygon points="249.63 182.35 237.09 196.44 241.03 199.25 238.78 201.92 254.91 211.79 256.26 209.28 259.54 210.82 258.19 213.53 271.1 218.34 272.26 216.03 276.12 217.38 281.71 199.25 264.74 192.31 249.63 182.35" />
          <polygon points="249.63 182.35 237.09 196.44 241.03 199.25 238.78 201.92 254.91 211.79 256.26 209.28 259.54 210.82 258.19 213.53 271.1 218.34 272.26 216.03 276.12 217.38 281.71 199.25 264.74 192.31 249.63 182.35" />
          <polygon points="137.26 256.83 138.93 261.26 135.21 263 133.15 258.63 119.13 265.05 102.82 272.51 104.62 277.08 99.48 279.2 89.74 256.36 80.72 235.23 85.09 233.17 86.63 236.26 102.35 229.18 112.33 224.69 111.05 221.86 115.16 220.06 116.77 222.89 125.44 219.42 133.73 203.1 141.38 188.18 139.06 186.38 140.6 183.03 167.59 196.41 177.61 176.61 136.23 155.78 133.41 159.9 130.33 158.35 127.5 164.01 130.33 165.81 125.44 175.32 126.98 176.35 125.44 179.44 124.16 178.92 116.35 194.07 111.05 204.38 112.33 208.75 106.68 210.81 104.88 206.18 94.2 210.99 78.66 218 80.72 222.89 75.58 225.2 72.24 218 66.26 220.58 67.42 223.27 53.73 229.06 67.61 261.71 69.67 260.94 71.12 264.46 72.75 268.4 71.21 269.17 85.09 301.57 100 295.4 101.02 297.71 106.94 295.2 104.88 290 109.5 287.68 110.6 290.19 127.11 282.66 141.38 276.37 140.67 273.99 143.95 272.51 144.97 274.83 155.83 269.98 147.75 252.09 137.26 256.83" />
          <polygon points="137.26 256.83 138.93 261.26 135.21 263 133.15 258.63 119.13 265.05 102.82 272.51 104.62 277.08 99.48 279.2 89.74 256.36 80.72 235.23 85.09 233.17 86.63 236.26 102.35 229.18 112.33 224.69 111.05 221.86 115.16 220.06 116.77 222.89 125.44 219.42 133.73 203.1 141.38 188.18 139.06 186.38 140.6 183.03 167.59 196.41 177.61 176.61 136.23 155.78 133.41 159.9 130.33 158.35 127.5 164.01 130.33 165.81 125.44 175.32 126.98 176.35 125.44 179.44 124.16 178.92 116.35 194.07 111.05 204.38 112.33 208.75 106.68 210.81 104.88 206.18 94.2 210.99 78.66 218 80.72 222.89 75.58 225.2 72.24 218 66.26 220.58 67.42 223.27 53.73 229.06 67.61 261.71 69.67 260.94 71.12 264.46 72.75 268.4 71.21 269.17 85.09 301.57 100 295.4 101.02 297.71 106.94 295.2 104.88 290 109.5 287.68 110.6 290.19 127.11 282.66 141.38 276.37 140.67 273.99 143.95 272.51 144.97 274.83 155.83 269.98 147.75 252.09 137.26 256.83" />
          <polygon points="166.55 231.87 158.59 247.31 162.96 249.63 161.16 253.23 153.2 249.63 147.75 252.09 155.83 269.98 161.31 267.53 173.76 261.97 173.76 259.91 171.44 258.63 173.25 255.03 176.59 256.06 184.16 240.58 166.55 231.87" />
          <polygon points="166.55 231.87 158.59 247.31 162.96 249.63 161.16 253.23 153.2 249.63 147.75 252.09 155.83 269.98 161.31 267.53 173.76 261.97 173.76 259.91 171.44 258.63 173.25 255.03 176.59 256.06 184.16 240.58 166.55 231.87" />
          <polygon points="184.13 240.65 188.63 231.44 186.57 230.16 188.37 226.3 191.2 227.59 196.08 217.04 194.03 216.02 197.88 208.05 201.22 209.59 210.73 190.56 198.65 184.65 195.82 190.05 193.51 189.02 196.08 182.85 184.52 176.94 175.01 195.71 177.58 196.99 173.47 205.48 171.15 204.45 165.75 214.99 176.55 220.39 174.75 223.99 171.41 222.44 166.52 231.94 184.13 240.65" />
          <polygon points="184.13 240.65 188.63 231.44 186.57 230.16 188.37 226.3 191.2 227.59 196.08 217.04 194.03 216.02 197.88 208.05 201.22 209.59 210.73 190.56 198.65 184.65 195.82 190.05 193.51 189.02 196.08 182.85 184.52 176.94 175.01 195.71 177.58 196.99 173.47 205.48 171.15 204.45 165.75 214.99 176.55 220.39 174.75 223.99 171.41 222.44 166.52 231.94 184.13 240.65" />
        </g>
      </g>
    </svg>
  );
}
