import styled from "styled-components/macro";

import { showOutlines } from "../showOutlines";

export const BuildingModelContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;

  outline: ${showOutlines ? "#ccc dotted 1px" : ""};
`;
