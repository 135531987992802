import { useCallback, useState } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import CarouselIcon from "components/Icons/CarouselIcon";
import { ArrowLeft, ArrowRight } from "components/Arrow";
import data from "./data";
import ModalImage from "./ModalImage";
import GridGalleryImage from "./GridGalleryImage";
import { Top, Bottom, LayoutToggle, RightNav, LeftNav } from "./Gallery.style";
import { GridRoot, SpotsRoot, Spot } from "./Grid.style";

const PAGE_SIZE = 6;

const Grid = ({
  history,
  match: {
    url,
    path,
    params: { page: p },
  },
}) => {
  const page = Number(p);
  const pages = Math.ceil(data.length / PAGE_SIZE);
  const first = (page - 1) * PAGE_SIZE;
  const gridPage = data.slice(first, first + PAGE_SIZE);

  const [selected, setSelected] = useState({});

  const handleGoToPage = useCallback(
    (page) => {
      history.push(generatePath(path, { page }));
    },
    [history, path]
  );

  const prevPage =
    page > 1 ? generatePath(path, { page: page - 1 }) : undefined;
  const nextPage =
    page < pages ? generatePath(path, { page: page + 1 }) : undefined;

  const handleCloseSelected = useCallback(() => {
    setSelected({});
  }, []);

  return (
    <>
      <Top>
        <LayoutToggle
          onClick={() => {
            history.replace(
              `/gallery/carousel/${calcPageOnLayoutSwitch(page)}`
            );
          }}
        >
          <CarouselIcon />
        </LayoutToggle>
      </Top>
      <GridRoot>
        {gridPage.map(({ label, text, thumb, large }) => (
          <GridGalleryImage
            url={getGalleryImgUrl(thumb)}
            label={label}
            text={text}
            onClick={() => setSelected({ label, text, filename: large })}
          />
        ))}
        {prevPage && (
          <LeftNav>
            <Link to={prevPage}>
              <ArrowLeft />
            </Link>
          </LeftNav>
        )}
        {nextPage && (
          <RightNav>
            <Link to={nextPage}>
              <ArrowRight />
            </Link>
          </RightNav>
        )}
      </GridRoot>
      <Bottom>
        <Spots
          pages={Math.ceil(data.length / 6)}
          currentPage={page}
          onGoToPage={handleGoToPage}
        />
      </Bottom>
      <ModalImage
        url={getGalleryImgUrl(selected.filename)}
        label={selected.label}
        text={selected.text}
        onClose={handleCloseSelected}
      />
    </>
  );
};

const calcPageOnLayoutSwitch = (page = 1) => (page - 1) * 6 + 1;

const getGalleryImgUrl = (filename) =>
  filename && `${process.env.PUBLIC_URL}/assets/images/gallery/${filename}`;

const Spots = ({ pages, currentPage, onGoToPage }) => (
  <SpotsRoot>
    {Array.from({ length: pages }).map((_, index) => {
      // console.log(currentPage, index);
      return (
        <Spot
          isActive={currentPage === index + 1}
          onClick={() => onGoToPage(index + 1)}
        />
      );
    })}
  </SpotsRoot>
);

export default Grid;
