import styled from "styled-components/macro";

export const Root = styled.div`
  flex: 1;
  display: flex;
`;

export const Time = styled.div`
  /* position: absolute; */
  /* position: relative; */
  transform-origin: center center;
  transform: rotate(90deg) translate(50%, 0%);
  min-width: max-content;
  font-size: 1.45rem;
  color: ${({
    theme: {
      palette: {
        fg: { green },
      },
    },
  }) => green};
`;

export const Tab = styled.div`
  user-select: none;
  z-index: 2;
  cursor: pointer;
  font-size: 2em;
  text-transform: uppercase;
  background-color: ${({
    theme: {
      palette: {
        bg: { petiteOrchid },
      },
    },
  }) => petiteOrchid};
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  padding: 0 20px;
`;

export const PoiRoot = styled.div`
  z-index: 100;
  right: 0;
  /* position: absolute; */
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background-color: ${({
    theme: {
      palette: {
        bg: { smokeyWhite },
      },
    },
  }) => smokeyWhite};
`;

export const ClosePois = styled.div`
  flex: 1;
  /* border: black solid 2px; */
  flex-shrink: 1;
  flex-basis: 4vh;
  max-height: 4.5vh;
  display: flex;
  cursor: pointer;
  padding: ${({ theme: { spacing } }) => spacing(1)}px;
  display: flex;
  /* justify-content: flex-end; */
  padding-left: 1vh;
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
`;

export const Pois = styled.div`
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const Poi = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: ${({ theme: { spacing } }) => spacing(3)}px;

  transition: background-color 0.5s ease;

  background-color: ${({ isHighlighted }) =>
    isHighlighted ? `rgba(231, 51, 87, 0.25)` : `rgba(0, 0, 0, 0.0)`};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Left = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-basis: 1;
  flex-direction: column;
  max-width: 10%;
  /* border: red solid 2px; */
  padding-right: ${({ theme: { spacing } }) => spacing(2)}px;
  justify-content: flex-start;
  align-content: center;
  justify-items: center;
  align-items: center;
`;

export const Right = styled.div`
  border-left: 2px solid
    ${({
      theme: {
        palette: {
          fg: { darkPink },
        },
      },
    }) => darkPink};
  padding-left: ${({ theme: { spacing } }) => spacing(2)}px;
  display: flex;
  flex-direction: column;
`;

export const PoiNumber = styled.div`
  border: 2px solid
    ${({
      theme: {
        palette: {
          fg: { darkPink },
        },
      },
    }) => darkPink};
  padding: 2px 0 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme: { spacing } }) => spacing(1.5)}px;
  width: ${({ theme: { spacing } }) => spacing(3)}px;
  height: ${({ theme: { spacing } }) => spacing(3)}px;
`;

export const PoiTitle = styled.h4`
  font-size: 1.3rem;
  margin-top: 0;
`;

export const PoiText = styled.div`
  font-family: Akkurat-Pro-Regular;
  font-size: 0.9rem;
  letter-spacing: 0.6px;
  line-height: 1rem;
  padding: 0;
  margin: 0;
  max-width: 220px;
`;

export const POICircle = styled.div`
  cursor: pointer;

  width: 36px;
  height: 36px;

  position: absolute;
  left: ${({ position: { left } }) => left}%;
  top: ${({ position: { top } }) => top}%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  padding-top: 3px;
  font-size: 1.25em;

  transform: translate(-50%, -50%)
    scale(${({ isHighlighted }) => (isHighlighted ? 2 : 1)});

  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  color: white;

  border-radius: 18px;

  transition: transform 0.5s ease;

  &:hover {
    transform: translate(-50%, -50%) scale(2);
  }
`;
