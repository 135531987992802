import React from "react";

export default function Pattern(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 985.4 413.93"
      // width="985.4"
      // height="413.93"
      strokeOpacity={0}
      stroke="#fff"
      fill="#f39b93"
      {...props}
    >
      <path d="M311.07,202.55a13.46,13.46,0,0,0,1.26-.88c4.06-33.82,24.61-56.22,11.92-40.22C321.77,164.58,313.33,182.14,311.07,202.55Z" />
      <path d="M306,186.41c-1.3,4.1-1.65,7.32-4.08,23.22,1.69-1.43,1.27-.69,1.67-3.35,1.91-12.59,2.32-15.63,3.4-19.18C308.43,182.39,312,177.26,306,186.41Z" />
      <path d="M349.3,125.43c-.79,1.42-.86,1.33.63-.26A.54.54,0,0,0,349.3,125.43Z" />
      <path d="M326.55,192.42c.73-.44,2-15.44,10.86-38.77,6.49-17.05,9.35-19,4.71-13.68-1.18,1.36-11.89,25.09-16.66,53.1Z" />
      <path d="M468.5,6.32c0-.81.22,0-1.74-6.17h-1.21C469.09,11.33,468.45,10.88,468.5,6.32Z" />
      <path d="M466.89,31.65c.1.46,0,1.16.44-3.32-.71-3.15-6.48-26.28-6.85-25.45C458.91,6.33,460.2.7,466.89,31.65Z" />
      <path d="M475.11,24.89c-.15-.63-.31-1.26-.45-1.83-1.43,5.22-3.47,7.24-3.56,8.22-.07.64-.2,1.88-.2,1.9C473.13,30.28,475.33,25.8,475.11,24.89Z" />
      <path d="M448.87,24.08c-.57.88-.76,1.15-.87,1.32,11.59,48.72,8.18,57.69,10.43,50.17A499.15,499.15,0,0,0,448.87,24.08Z" />
      <path d="M460.45,82.57c7.13-2.66,12.9-7.73,18.1-13.32,3-3.19,2.59-2.56,2.46-4.17-.09-1.12-8.16,11.21-20.09,16.06C460.92,81.17,460.54,82.28,460.45,82.57Z" />
      <path d="M515.39,97.9l.65-1.12c.38-.67-1.74-4.29-2.8-5.7-6.63-8.91-17.67-11.69-25-19.38-3.65-3.82-3-5.33-3-1.74C493.63,81.34,511.66,84,515.39,97.9Z" />
      <path d="M441.28,34.69c-2.09,2.68,8.51,50.84,8.62,63.35,1.43-3,1.66-.87-1.7-21.66C440.89,31,441.53,34.37,441.28,34.69Z" />
      <path d="M452.21,102.69c-.42.87-.44.89-.51,1A55.48,55.48,0,0,0,480,91.61c0-.43.07-.69.16-1.65C466.13,102,452.58,101.94,452.21,102.69Z" />
      <path d="M432.94,44.8c-.06-.33,0-.32-1,.8,2.19,11,4.34,22.09,5.64,33.14,4.53,38.45-4.85,49,.7,40.36C442.69,94.76,437.8,69.16,432.94,44.8Z" />
      <path d="M435.47,130.5l-.7.88C435.26,131.37,435.32,131,435.47,130.5Z" />
      <path d="M427.2,111.9c-2.44,27.06-10.45,32.52-5,27.2,1-1,6.53-13.7,7.1-44.46.42-22.28-2.63-43.23-3-42.78l-.83.91A251.31,251.31,0,0,1,427.2,111.9Z" />
      <path d="M418,60.91c-.38.41-.6,33.63-3.52,59.82C411.15,150.09,404.63,153,409,150c.7-.47,2.66-7.47,2.82-8.05,6.36-23.35,7.45-82.41,7-81.89Z" />
      <path d="M389.65,161.24l1.29-.63c.74-.37,10-24,14.51-50.73,3.22-19.08,3.84-39.56,3.23-38.9C406,73.9,410.51,110.07,389.65,161.24Z" />
      <path d="M398.49,81.94c-4.37,4.67,7,21.11-23,86.24,1.67-.81,1.24.76,7.27-13.64C392.4,131.5,399.76,107,398.49,81.94Z" />
      <path d="M357.35,181.09c-3,1.35-1.62.22-6.55,5.49-22,23.44-51.91,56.28-42.37,105.5C318.85,316.63,293.19,271,323,222.91,337.18,199.94,359.32,180.2,357.35,181.09Z" />
      <path d="M363.81,173.7c.59-.27,21.21-35.18,24.76-81.37-3.17,3.22-1.25,37.63-26.25,82.06Z" />
      <path d="M377.12,103.66c-2.28,2.21-.68-.81-4.67,15.13a428.14,428.14,0,0,1-19.82,60c1.64-.75,1.13.75,5.6-11A427,427,0,0,0,377.12,103.66Z" />
      <path d="M367.53,113c-2.63,2.61.74-5.75-25.31,70.85C344.32,182.75,341,190.91,367.53,113Z" />
      <path d="M294.25,188.28c7.28-10.25,17.4-17.12,18.52-18.62s1.75-2.33,2.17-2.87a139.32,139.32,0,0,0-13.18,11.09c-9.58,9.33-14.05,18.64-14.12,20.4C287.32,205.82,286.64,199,294.25,188.28Z" />
      <path d="M288.33,184c0,.45-.1,1.65-.1,1.67,7.28-9.64,32.26-26,33.49-27.51,2.92-3.63,3.71-3.83-4.07,1.68C313.68,162.62,288.53,181,288.33,184Z" />
      <path d="M289.7,171.41c-.47,3-1.51,1.14,13.76-8.72,23.11-14.91,22.6-9.94,25.66-13.64C318.19,150.29,289.92,169.92,289.7,171.41Z" />
      <path d="M291.69,161.09c-1,4.11-.88-.23,15.05-9.1,16.46-9.15,28.17-9.86,28.89-10.71l1-1.16C310.64,144.76,291.89,160.2,291.69,161.09Z" />
      <path d="M324.64,135.08c-17.93,4.91-29,11.58-29.24,12.36-1.17,3.91-1-.67,18-7.59,15-5.49,30.13-7.62,30.77-8.33l1.09-1.21C338.35,131.74,331.44,133.22,324.64,135.08Z" />
      <path d="M350.37,124.69l.87-.92a104.89,104.89,0,0,0-52,11.2c-.8,2.5-1.47,1.64,6.51-1.78C330.26,122.71,349.75,125.36,350.37,124.69Z" />
      <path d="M356.75,117c1.3,0,.83.22,1.91-.86-.83-1.58-47.08,1.64-55.76,8.1-.26.19-.15-.06-.74,1.74,1.59.55-2.67-2.15,20.41-6.12A197.44,197.44,0,0,1,356.75,117Z" />
      <path d="M364.79,110.08l.84-.83a128,128,0,0,0-58.77,3.85c-.93,2.25-1.42,1.27,8-1C343.68,105.21,364.11,110.75,364.79,110.08Z" />
      <path d="M373,102c1.49-1.46,3.28-1.27-12.48-1.5-10.08-.15-29.22-.09-39.22,1.55-3.55.58-10.44,2.27-10.83,3.08C308.25,109.68,308.11,99.41,373,102Z" />
      <path d="M380.06,95.09l.85-.83a153.9,153.9,0,0,0-67.23,4.41c-1.26,2.5-1.73,1.08,11.71-1.78C357.33,90.11,379.32,95.83,380.06,95.09Z" />
      <path d="M319.32,87.73l-.63,1.2a139.74,139.74,0,0,1,69.75-3.31c1,.22.65.4,1.8-.79C351.59,76.29,319.56,87.28,319.32,87.73Z" />
      <path d="M326,75.41c-1.24,2.24-1.71,1.1,9.72-.57a156.23,156.23,0,0,1,45.29.08c18.19,2.71,14.69,4.25,16.6,2.22A156.48,156.48,0,0,0,326,75.41Z" />
      <path d="M320.65,76.62c-.4.1-.57.31-.53.64S320.17,77.47,320.65,76.62Z" />
      <path d="M332.18,64.43c-1.85,3.07-.84-.85,24.43-.85a164.89,164.89,0,0,1,46,6.52c1.49.44,1,.63,2.1-.58C367.81,58.36,332.66,63.65,332.18,64.43Z" />
      <path d="M353.81,52.3c11.21,0,22,2.51,40.71,6.33,19.56,4,16.66,3.82,17.89,2.47-37-7.55-54.82-12.66-72-8.45C337.51,56.29,339.43,52.3,353.81,52.3Z" />
      <path d="M346.82,45.55c-2.32,2.36-2.44.16,18.5.16a214.66,214.66,0,0,1,53.76,6.82c1.14.3.72.44,1.78-.72C382.29,41.55,347.37,45,346.82,45.55Z" />
      <path d="M359,34.7c-3,2.46-2.68.82,10.51.46a136.34,136.34,0,0,1,42.74,5.67c16.23,4.87,13,6,14.74,4.13A136.82,136.82,0,0,0,359,34.7Z" />
      <path d="M376.57,23.22c-3,0-2.33-.21-4.48,1.29,29.05-1.82,59.31,15.48,60,14.67l.65-.75C394.83,21,374.32,23.22,376.57,23.22Z" />
      <path d="M391.43,13.26c-2.82,1.48-2.16-.68,17.49,6.79,18.81,7.17,27.92,13.5,28.3,13l.61-.76C415.13,19.57,392.07,12.93,391.43,13.26Z" />
      <path d="M418.39,13.78c27.9,10.55,23.65,13.15,25.3,10.79-9.73-6.7-21.16-10.46-39.81-17.5l-1.26.54C402.23,7.78,400.3,6.94,418.39,13.78Z" />
      <path d="M433.5,11.63c17.6,5,14.28,6.72,15.57,4.52-5.79-3-11.82-4.95-38.06-11.79C407.62,5.51,407.09,4.08,433.5,11.63Z" />
      <path d="M446.42,6.12c7.7,2.77,6.42,3.26,7.23,1.65C442.36,2.82,429.05-.17,427.19.18,419.67,1.58,427.61-.65,446.42,6.12Z" />
      <path d="M457.23.23c0-.12.46-.08-3.8-.08C454.33.18,455.24.22,457.23.23Z" />
      <path d="M295.87,239.52c3.09-13.59,9.7-26.38,20.34-35.59-5.67,3.94-6.57,4.41-10.37,9.79-8.11,11.51-12.6,26.49-13,40C295.36,261.78,292.42,254.65,295.87,239.52Z" />
      <path d="M319.82,265.49c5.66-23.66,12.32-41.14,50.18-78.54,27.28-26.95,28.63-25.65,22.08-22.29-.35.18-53.07,45.77-66.39,78.17-9,21.83-14.4,51.63-7.75,74.1.53.68.35,1,.7-1.8C314.31,299,315.9,281.86,319.82,265.49Z" />
      <path d="M350.77,222.79C331.45,242.48,324.52,270,321,297.25c-1.85,14.34-1,6.61,1.43-2.09C329.56,242.86,346.17,232.19,350.77,222.79Z" />
      <path d="M404.61,174.09c-3.34.68-5.33,2.12-18.4,9.87-4.91,4.89-6.37,5.12,10.29-4.75C405.7,173.75,404.47,176.25,404.61,174.09Z" />
      <path d="M408.41,183.23c17.87,5.74,27.77,8.06,34.9,15-3.5-7-14.71-9.72-34.89-16.2C408.42,182,408.41,183.08,408.41,183.23Z" />
      <path d="M378.47,195.37c5.63-3.13,11.32-5.5,25.85-11.91,0-1.63,1.29-1.82-7.76,2.16-9.43,4.16-17.28,7.41-24,12C365.42,205.54,369.53,200.34,378.47,195.37Z" />
      <path d="M362.64,210.3c14.49-4.58,26.58-16.53,41.85-17.43,0-.27,0-1.13,0-1.15-9.24.53-17.61,5.13-25.63,9.62C360.84,211.43,365,206.88,362.64,210.3Z" />
      <path d="M408.58,192.16c0,.73,15.17,2.53,27.9,9.62,12.35,6.87,13.85,13.48,11.64,7.54-.47-1.28-7.55-7.77-19.16-12.33a139.32,139.32,0,0,0-20.4-5.82C408.56,191.23,408.58,192,408.58,192.16Z" />
      <path d="M348.06,239.73c-2.07,5.72-.72-.78,19.76-14.66C389.13,210.62,405,207.32,405,206.53c0-.25,0-.54,0-1C372.08,216.36,348.57,238.31,348.06,239.73Z" />
      <path d="M409.21,217c0-.06,0,0,0,.2A.44.44,0,0,0,409.21,217Z" />
      <path d="M342.71,256.3c-2.43,8.49,0-3.84,19.86-17.48,19.42-13.33,42.7-19,42.68-20,0,0,0-.9,0-1-19.29,6.89-38.53,14.27-53.66,28.34C349.36,248.16,343.24,254.49,342.71,256.3Z" />
      <path d="M336.52,294.5c0,.45,0,1.77,0,1.79,28.95-43.26,68.61-54.57,68.64-55.67,0-.43,0-1,0-1C360,257.34,336.55,293.47,336.52,294.5Z" />
      <path d="M336.45,305.38c.1,6.89-2.44-2.67,20.23-21.29,43.66-35.85,47.52-26.55,47.84-31.47-23.47,7.87-53.33,33.73-60.41,41.52C340.63,298,336.42,304,336.45,305.38Z" />
      <path d="M336.93,317.36c.11,1.73,19.07-35,66.57-52.19,0-.33.07-.73.1-1.06s-39.75,11.69-66.77,51.67C336.85,316.08,336.84,316,336.93,317.36Z" />
      <path d="M402.07,276.49s.09-.58.18-1.21c0-.29-51.36,27-64.3,53.61C339,338.57,331,316.9,402.07,276.49Z" />
      <path d="M404.49,286.37c-.17.89-.05.29-.22,1.13a44.71,44.71,0,0,1,27.74,21c4.14,7.16,2.51,8.05,4,5.66A45.86,45.86,0,0,0,404.49,286.37Z" />
      <path d="M341.27,351.07c10.37-32.95,56-50.15,58.45-61.71-13.51,16.27-48.16,29.79-58.87,59.36C341,349.42,341,349.54,341.27,351.07Z" />
      <path d="M350.3,349.08c12.83-18.59,44.61-40.28,46-45.34,1.23-4.59,3-2.32-20.27,17.56-12.35,10.56-26.24,22.43-33,38.59C344,364.54,342.31,360.65,350.3,349.08Z" />
      <path d="M361.18,347.65c9.45-8.4,20.65-15.29,29.37-24.82,1.53-4.65,1.64-2.94-4.31,2.76-11.5,11-25.58,18.58-35.58,31.37-3,3.89-6.21,9.42-6,10.29C345.92,372.73,342.69,364.08,361.18,347.65Z" />
      <path d="M363.11,360.1c2.92-2.51,19.81-15.61,21.2-19.41,2.49-6.81,1.89-1.62-9.7,8.45-9.12,7.91-22,16.1-27.36,28.63C348.66,383.21,345.13,375.52,363.11,360.1Z" />
      <path d="M410.2,355.62l.67-.94c.46-.65-5.69-14.58-20.71-18.51,0,.07-.32.89-.33.9C389.6,337.71,404.35,339.89,410.2,355.62Z" />
      <path d="M362.59,372.41c6.05-5,10.27-8.59,15.6-15.26,3.3-8.82,1.89-.58-15.48,13.67a84.32,84.32,0,0,0-8.6,7.73c-1.45,1.56-5,6.12-4.77,7C350.45,389.44,347.21,385.06,362.59,372.41Z" />
      <path d="M404.22,364l.64-.88c.81-1.13-10.49-16.94-18.05-17.75-.85,2.32-.64-.06,3.62,2.68A40.28,40.28,0,0,1,404.22,364Z" />
      <path d="M371.63,386.62c-1,3.05,1.38.52,4.74,11.49.58,1.87.2,1.83,1.32.37C376.41,394.17,374.7,389.9,371.63,386.62Z" />
      <path d="M365.19,388.42c-4.09,3.93-8.42,7.4-11.38,12,.64,2.15,0,1.8,2-.86,3-4,7.26-7.38,11-11.08C368.12,384.73,368.4,385.33,365.19,388.42Z" />
      <path d="M357.27,411.7c-.29.8-.32.24.35,2.92a28.11,28.11,0,0,1,5.09-10.36c.52-3,1-3.45-1.46.07A31.26,31.26,0,0,0,357.27,411.7Z" />
      <path d="M409.57,171c-.86-.18-.68-.43-.78,1,23.41,4.4,34,24.42,29.74,17.6-1.18-1.91-2.25-3.51-3.34-5.07A51.51,51.51,0,0,0,409.57,171Z" />
      <path d="M374.92,212.67c10.69-4.36,29.82-12.49,29.8-12.94,0-.24,0-.85,0-1.1,0,0-18.44,8.6-47,19.65C356.57,220.3,354,221.22,374.92,212.67Z" />
      <path d="M427.48,208.71c9.84,6,18.87,11.58,26.24,20.47-.47-2.22.06-2.11-4.06-6-6-5.7-25.51-20.15-40.9-23.76C408.84,202.65,410.5,198.32,427.48,208.71Z" />
      <path d="M455.5,244.05c0-.76,0-1.7,0-1.72-12-16.86-28.55-27.76-46.43-32,0,.18,0,1.17,0,1.19C442.12,219.55,455.51,245.76,455.5,244.05Z" />
      <path d="M339.38,269.65c-1.24,5.93-.26-2.18,20.71-17.89,24.76-18.55,45.24-21.21,45.24-22.14v-1C365.48,239,339.74,267.89,339.38,269.65Z" />
      <path d="M454.12,259.91c-13-29.82-44.69-33-44.69-32.06,0,.47,0,1,0,1a52.73,52.73,0,0,1,39.13,23.31C454.76,261.4,453.42,264.56,454.12,259.91Z" />
      <path d="M452.08,270.93c-6.28-15.44-14.5-25.86-30.48-28.51-4.31-.71-12.39-1.2-12.41-.79,0,.26,0,.9,0,1,18.23.48,29.05,3.67,38.27,21C452.5,273.12,451.24,274.76,452.08,270.93Z" />
      <path d="M447.13,288.81c3-9.06-9-37.34-37.53-41.94-.81-.13-.63-.35-.7,1A45.12,45.12,0,0,1,447.13,288.81Z" />
      <path d="M444.49,296.2c-1.38-4.45-6.8-14.1-9.15-17.81-12.41-19.55-27.23-18.95-27.27-18.45s-.08.95-.08,1c15.34.09,24.47,13.8,31.63,27.37,1.65,3.12,3.41,6.32,4.17,9.73Z" />
      <path d="M439.85,307.34a42.58,42.58,0,0,0-7.94-18.46c-3.21-4.27-10.5-11.26-15.92-14.37-3.91-2.24-9-3.59-9.1-3.07-.1.8-.05.35-.12,1,6.3,1,11,4.19,15.77,8.4C444,299.88,436.61,314.53,439.85,307.34Z" />
      <path d="M402.38,296.29l-.23.95c22.76,8,26.46,29.53,26.91,28.72l.66-1.19C423.28,303.23,402.55,295.53,402.38,296.29Z" />
      <path d="M398.29,311.83c-.52,1.77-.66,1,1.93,1,9.4,0,16.56,7.31,20.94,15.72,3.45,6.62,2.43,7.11,3.62,5.05-4-9.52-9.53-18.34-19.51-21.19A17.9,17.9,0,0,0,398.29,311.83Z" />
      <path d="M416.23,347,417,346c.58-.83-4.77-18.13-22.89-20.85,0,.06-.23.67-.31.92-.24.67,4.85,0,11.81,4.92A27,27,0,0,1,416.23,347Z" />
      <path d="M387.13,383.53c1.11,2.24.61,2.4,1.73,1-3.8-8.47-10.41-14.62-11-14.62-.16,0-.09-.09-.47.93A41.19,41.19,0,0,1,387.13,383.53Z" />
      <path d="M381.17,391.8c.45,2.31,0,2.27,1.13.9.4-.48-1.24-9.09-6.95-16.4l-.47,1.28A35,35,0,0,1,381.17,391.8Z" />
      <path d="M370.1,401.39a23.07,23.07,0,0,0-1.77-3.93c-.54,2.1-.94.18,2.91,10C372.33,405.85,372.27,407.17,370.1,401.39Z" />
      <path d="M529.54,85.34c-.25-.77.29.1-4.15,5.86-2.76,3.58-4,4.33-4.62,5.52-.54,1-1.09,1.94-1.64,2.85a55.61,55.61,0,0,0,9.52-10.94C530.16,86.33,530,86.83,529.54,85.34Z" />
      <path d="M532.22,94.6c-.24-.93.93.37-6,5.15-15.67,10.89-12.85,8.44-15.46,12.15C534.14,95.67,532.84,97,532.22,94.6Z" />
      <path d="M526.45,109.74c-4.7,2.16-10,2.87-14.92,4.25-3.31.91-2.63.57-4.11,2.59,8.63-3.54,20.85-3.2,27.09-11.66C533.79,101,535,105.82,526.45,109.74Z" />
      <path d="M514.58,122.18C499,126,499.31,127.52,498.26,129s-.14-1.66,16.71-5.76c5.66-1.37,11.53-2.83,16.74-6,.63-.38,4.24-2.77,4.16-3.44C535.3,109.12,539.45,116.15,514.58,122.18Z" />
      <path d="M536.9,159.25s.06-1,.07-1.19c-29.22,18.94-49.13,8.64-62.87,17.72-1.47,4.34-.94.09,8.07-2.18,11-2.78,23,.81,43.83-8.32C527.68,164.54,536.85,160.1,536.9,159.25Z" />
      <path d="M535.85,172.43c.51-5.06-.25,4.83-22.44,7.4-13.55,1.56-30.31-.29-42.87,8.09-.94,3.77-.33-.34,10.46-3.48,14.9-4.33,30.69-.86,45.36-6.4C531,176.29,535.77,173.21,535.85,172.43Z" />
      <path d="M468.16,199.29c-1.16,6.72-.57-5.44,20.71-4.41,9.52.46,18.72,2.71,32.57-1.77,5.65-1.83,12.46-5.36,12.57-6.07,0,0,.16-1.08.18-1.26C505.3,204.5,484.16,185.23,468.16,199.29Z" />
      <path d="M466.54,211.18c-.4,4-.13-2.65,13.32-2.65,9.33,0,29.83,6.93,52-8.51.23-1.23.11-.56.3-1.63-21.84,15.94-42.22,9-52.28,9C471.87,207.37,466.62,210.4,466.54,211.18Z" />
      <path d="M465.68,225c-.14,4.95-.34-5.11,24.49-6.3,11.8-.57,27.61,1.32,39.27-6,.68-3.62.67.07-9.4,2.74-13.95,3.7-28.76.13-43,3.89C471.44,220.86,465.7,224.15,465.68,225Z" />
      <path d="M465.63,233.6c0,.37,0,.73,0,1.1,12.3-7.09,30.76,6.38,61.69-10.73.06-.31.18-.91.23-1.22.22-1.16-13.11,9.38-36.28,9.38-6.56,0-10.5-.9-15.35-.91C470.6,231.21,465.62,232.88,465.63,233.6Z" />
      <path d="M488.61,241.51c9,0,17.94,1.44,26.92-.81,4.45-1.12,9.38-3.67,9.54-4.57,0,0,.24-1.26.27-1.43-16.08,13.37-42.86-1.81-59.26,12.25C466.32,252.06,466.36,241.51,488.61,241.51Z" />
      <path d="M493.71,260.45c8.76,0,18.85.89,27.49-2.82.55-3.1.71,0-9.38,1.27-6.09.78-12.33.4-18.19.4-19.67,0-25.83,7.21-25.76,7.71C468.53,271.65,467.6,260.45,493.71,260.45Z" />
      <path d="M497.61,268.87c3.93-.45,8-.85,11.95-1.52,3-.5,10.32-2.17,10.46-2.94.49-2.86,2.4.54-20.08,3-9.59,1.07-22,2.42-30.25,10.3C470.69,282.91,467.89,272.28,497.61,268.87Z" />
      <path d="M472.76,290.84c.26.9,5.55-7.54,24.16-13.17a165.94,165.94,0,0,1,21.58-4.57c0-.16,0,.21.17-1,0,0-30.2,2.35-46.21,17.69C472.47,289.81,472.6,290.27,472.76,290.84Z" />
      <path d="M498.25,287.22c1-.32,18.75-5.55,19-6.71.41-2.28,2.32-1-14.92,4.26-9.76,3-20.06,6.2-27.1,13.86C476.57,302.74,473.78,295,498.25,287.22Z" />
      <path d="M519.65,290a.57.57,0,0,0,.14-.83C519.61,290.22,519.67,290,519.65,290Z" />
      <path d="M515,292.72c.45-2.43,2-.87-12.38,3.67-7,2.22-18.28,5.65-22.9,14.64.48,1.22.48,1.24.57,1.46,3.89-8.86,14.47-12.4,22.63-15C503.94,297.19,514.83,293.77,515,292.72Z" />
      <path d="M513.34,301.89c.67-3.66,1.57-.15-10.84,4.87-6.51,2.63-15,5.17-19.88,11.51,1.05,2.63-.47.75,5.06-3.26,6.7-4.85,15.07-6.3,22.22-10.51C510.37,304.23,513.23,302.52,513.34,301.89Z" />
      <path d="M512.32,307.55c-7.34,7.66-19.56,8.86-27.57,16,1.12,2.73-1.56.32,11.33-5.37,5.51-2.44,11.37-4.59,15.9-8.68C512,309.44,512.24,308,512.32,307.55Z" />
      <path d="M509.13,327.64c.48-3.27,1.18-.41-11.81,6.23-2.28,1.16-4.93,2.17-6.58,4,0,.07.26.61.51,1.22,2.08-3,6.56-3.18,16.38-10.14C509.3,327.78,509.08,327.94,509.13,327.64Z" />
      <path d="M520.62,343.9l.59-.92c.31-.48-3.2-5.75-7.37-9.44-1.83-1.63-1.48-1.59-1.74,0A35,35,0,0,1,520.62,343.9Z" />
      <path d="M507.94,335.38c.53-3.22,1.2-1.24-5.66,1.86-3.37,1.51-7,2.95-9.45,5.8l.49,1.23C496.69,339.76,503.49,338.79,507.94,335.38Z" />
      <path d="M496.17,348.86c-.89,1-.8.66-.23,2.18,2.48-3.62,6.62-5.82,10.61-7.6,0-.1.05-.33.23-1.37C503.18,343.63,499.06,345.61,496.17,348.86Z" />
      <path d="M529.53,354.36c6.37.22,15.72,1.88,21.8-3.58,1.06-5.41.46,2.64-12.22,2.85-6.56.11-13.59-1.59-20,1C517,358.68,518,354,529.53,354.36Z" />
      <path d="M513.91,355.86a36.8,36.8,0,0,0-4.26-5.2c-.32,2.85-.32.29,3.68,6.39C513.39,356.94,513.44,356.82,513.91,355.86Z" />
      <path d="M498.22,357.14c.82,2.22-.72,1.19,6.89-4.1,0,0,.06-.47.2-1.53A49.94,49.94,0,0,0,498.22,357.14Z" />
      <path d="M500,362c.9,2.51-.41,1,4.53-3l.13-1.56A24.58,24.58,0,0,0,500,362Z" />
      <path d="M483.22,133.58c-2.17-1.58-4.52-3.28-5.61-5.7-.23.94-.17.7-.41,1.64a19.73,19.73,0,0,0,5.29,5c6.05,4.37,4.19,5,5.23,3.31C488.11,137.15,483.83,134,483.22,133.58Z" />
      <path d="M440.68,142.45c.07,3.36-.9-.92,11.95-4.79,6.64-2,14.18-3.17,20.24-7.45.72-2.89,1-1.7-2.88.45-7.71,4.3-16.9,4.6-24.92,8.71C444.42,139.71,440.66,141.78,440.68,142.45Z" />
      <path d="M540.56,119.6s.09,1,.1,1.21c7,1.84,12.58,6.5,17.6,11.55,8.19,8.24,7.74,9.23,5.94,4.81C564,136.65,552.54,122.51,540.56,119.6Z" />
      <path d="M530.65,126.09c-9.87,6.22-22.08,5-33,8.95-5.37,1.94-5,2.41-6.21,4.38,13.44-9.2,34.14-3.59,45.2-17C536.41,118.55,537.46,121.8,530.65,126.09Z" />
      <path d="M537.43,99.06c.66,2.73-.66.39,6.86,6.21,7.92,6.13,8.94,8.84,5.44,3A97.8,97.8,0,0,0,537.43,99.06Z" />
      <path d="M566.47,41.58l-1.28-.51c-1.26-.51,2,.13,5.79,23.82,1.3,8.11,2.64,16.37,6.18,24.17.46,1,2.93,6,3.84,6.36,4.85,1.82-1.85.62-6.32-17.25C571.65,66.08,571.43,53.23,566.47,41.58Z" />
      <path d="M570.89,143.38c1.39,3.84-.23,2.72,2.81-7.27,1.6-5.24,3.24-10.71,1.75-16.1-4.35-4.51.64-.72-.89,8.26C573.66,133.6,570.32,141.81,570.89,143.38Z" />
      <path d="M582.59,52.48c-.58-1.18-3-5.6-4.2-6.07-4.4-1.78-1-1.23,2.64,5.56,6.86,12.65,4.44,27.68,9.47,41.15.53,1.42,2.91,6.92,4,7.3l1.63.61C585.59,87.45,590.23,67.87,582.59,52.48Z" />
      <path d="M544,99.23a55.51,55.51,0,0,0-7.92-5.33c.74,2.76-.58.36,5.74,5.09C546.69,102.62,547.85,105,544,99.23Z" />
      <path d="M551.79,100.43c-.35-4.3-.1-3.48-1.77-5,.68,3.24.51,6.35,1,7.12C552.82,105.42,552.19,105.48,551.79,100.43Z" />
      <path d="M556.56,105.62c.11-4.9-.25-4.4-1.55-5.61.7,3.31.28,6.6.29,9.69,1.66,2.89,1.23,2.63,1.17.84S556.52,107.24,556.56,105.62Z" />
      <path d="M561.8,99.07c-4.71-2.11-6.1-4-1,.8,13.48,6.25,28.42,4.85,42.22,9.65,3.37,1.17,3.89,1.74,2.62.79C596.49,103.48,576.93,105.86,561.8,99.07Z" />
      <path d="M568.44,127.58c-1.37,7.5-1.1,6.62,0,9.44,0-7.28,3.09-14.55,2.06-22-2.77-2.79-.24-1.34-1.19,7C569.12,123.83,568.78,125.74,568.44,127.58Z" />
      <path d="M567.81,94.67c-2-.82.4,0-10.46-2.94-8.82-2.34-7.86-2.22-7.37-1.79,2,1.77-.38,1,15.48,5.12C578.3,98.38,576,98,567.81,94.67Z" />
      <path d="M608.71,112.8c-3-2.58-20.72-.61-24.71-.53-11.67.24-14.44-4-10.48,0,10.14,2.89,20.4-.66,30.63.59C610.42,113.63,612.37,115.92,608.71,112.8Z" />
      <path d="M617.66,121.76c-.35-.41-.18-.4-1.85-1.19-10.24-4.86-22-3.51-32.3-4.33-7.09-.56-7.48-1.45-6.79-.75,4.24,4.3,23.51-.2,37.34,5.56C619.31,123.24,621.61,126.46,617.66,121.76Z" />
      <path d="M539.31,108.7c.43,2.8-.46-.09,8.17,6,10.94,7.65,13.86,15.36,9.73,7.18C556.33,120.08,548.3,112.59,539.31,108.7Z" />
      <path d="M541.48,142.34v1.22a89.72,89.72,0,0,1,26.73,15.26c5.28,4.37,4.8,5.13,3.93,1.88A90.86,90.86,0,0,0,541.48,142.34Z" />
      <path d="M537.36,145.09c0-5.11.19,4.69-19.3,9.12-12.62,2.88-27.45,2-39.06,9.1-1.9,4.32-1.19.35,9.45-2.88,13.56-4.12,28.27-2.32,41.05-9.11C532.71,149.62,537.35,146.07,537.36,145.09Z" />
      <path d="M540.92,161.07c-.14,2.2-1,.1,8.29,5.21a83.91,83.91,0,0,1,28.91,26.31c-.07-.65-.15-1.4-.27-2.4A84.85,84.85,0,0,0,540.92,161.07Z" />
      <path d="M578.88,204.57c0-.66,0-1.21,0-1.75A91.4,91.4,0,0,0,540,171.72c0,.11-.12,1.14-.13,1.2C568.68,185.17,578.93,206.47,578.88,204.57Z" />
      <path d="M578.74,217.65c0-.69,0-1.07.07-1.42a133.56,133.56,0,0,0-40.21-32.7l-.17,1.21C566.66,199.83,578.67,219,578.74,217.65Z" />
      <path d="M577.58,230.51c.07-.53.17-1.37.18-1.39a111.84,111.84,0,0,0-40.92-34.47c-.15.9-.08.46-.2,1.18C566.33,210.59,577.38,232,577.58,230.51Z" />
      <path d="M574.84,245.37c.12-.51.3-1.29.32-1.36a98.76,98.76,0,0,0-40.42-37.45c-.4,2.22-1.12-.11,9,6.47C568.05,228.86,574.4,247.23,574.84,245.37Z" />
      <path d="M566.28,250.5a148.12,148.12,0,0,0-33.77-31.93c-.36,2-1.09.21,7,6.53a147.18,147.18,0,0,1,31.43,33.8C571.38,257.52,572.25,258.24,566.28,250.5Z" />
      <path d="M566.84,269.3c.17-.37.46-1.06.47-1.08A103.34,103.34,0,0,0,530,232.15c-.2,1.07-.18,1-.22,1.2C557,248.85,566.4,270.31,566.84,269.3Z" />
      <path d="M526,230.85s-.07.7.1-.21A.68.68,0,0,0,526,230.85Z" />
      <path d="M562.12,279.09l.51-1a102.93,102.93,0,0,0-35.24-32.44c-.39,2-1,.17,7.34,5.9C555.19,265.62,561.57,280.14,562.12,279.09Z" />
      <path d="M487.7,251.94c9.07.23,18.08,1.88,27.16-.24,3.19-.75,7.85-2.61,8-3.35,0-.28.16-.91.23-1.28-16.39,10.73-39.76-2.61-56.5,8.47C466.93,259.77,467.21,251.43,487.7,251.94Z" />
      <path d="M557.19,288.06l.54-.95a100,100,0,0,0-32.17-31.8s-.07.38-.22,1.22C548.53,270.92,556.72,288.9,557.19,288.06Z" />
      <path d="M523.92,264.5c-.14.78-.2,1.15-.22,1.28a110.84,110.84,0,0,1,24,24.91c4.92,7,3.81,7.11,4.86,5.34A112,112,0,0,0,523.92,264.5Z" />
      <path d="M542.17,312.65l.57-.87c.3-.45-8.68-13.69-22.62-24.4-.06.3-.14.8-.19,1.06C519.81,289.12,531.3,296.3,542.17,312.65Z" />
      <path d="M535.6,322.3c.29-.41.47-.67.64-.93.32-.47-5.4-14-17.77-24.83,0,0,0-.12-.2,1.11C518.14,298.39,528.7,305.78,535.6,322.3Z" />
      <path d="M510.64,362.9a12.14,12.14,0,0,0-1.94-2.72c0,.05-.12,1.3-.15,1.59C510.56,364.35,509.62,365.25,510.64,362.9Z" />
      <path d="M657.37,145.33l-1-.68c-.84-.58,1.29.22-2.47,20.32-.21,1.14-3.23,16.88-2.11,19.63,2.43,6-.26,2.48,1.79-11.14C655,164.17,657.59,154.74,657.37,145.33Z" />
      <path d="M652.69,197.45c0-.06-.8-1.94-.81-2-.4-1-3.6-2.17-4.79-2.52-4.4-1.3-9.14-1.19-13.4-2.83-1.21-.46-1.32-.92.32,1.34C640.29,193.59,648,192.52,652.69,197.45Z" />
      <path d="M621.45,181l-.48-.69C620.93,180.59,621.18,180.75,621.45,181Z" />
      <path d="M585.45,225.44c1.2-4.95,1.4-3.4.46-5.86-3.27,10-3.65,15.92-2.74,23.86C583.88,249.65,582.23,238.75,585.45,225.44Z" />
      <path d="M683,135.92c-.33,0-.41-.11.52.38A.59.59,0,0,0,683,135.92Z" />
      <path d="M587.3,231.32c1.5-5.22,1.6-3.43.81-5.71-.32-.93-5.33,16-4.4,21.79C585.74,259.88,582.83,246.87,587.3,231.32Z" />
      <path d="M590.29,239.07c1.55-6.29,1.79-4.43.7-6.61a87.05,87.05,0,0,0-4.32,28.47C589.77,273,585.45,258.82,590.29,239.07Z" />
      <path d="M621.21,197.35c3.67-13.42,2.38-13.34,1.59-14.45s.74.72-1.68,10.38c-1.85,7.38-4.87,15.51-3.07,23.33C621.68,217.37,615.57,218,621.21,197.35Z" />
      <path d="M628.93,205.06c2.52-12,1.56-11.58.25-13.33.93,8.85-3.7,17.44-2.73,26.36C629.17,218.49,625.82,219.85,628.93,205.06Z" />
      <path d="M613.84,196.68c2.41-5.39,5.49-13.05,5.28-19.05-2.51-3.61-.56-1.95-1.37,3.54-1.87,12.77-10.48,21.59-9.46,32.94C612,215.26,605.63,215.12,613.84,196.68Z" />
      <path d="M635.81,185.09c5.06.48,10,1,13.67,4.53-1-2.48-.88-2.22-1-2.32-3.28-2.22-7.29-2.82-11.17-3.22-10.67-1.1-9.65-1.55-9.3-1.05C628.92,184.35,627.89,184.35,635.81,185.09Z" />
      <path d="M609.86,219c.62,0,0-.15-.49-.28A.58.58,0,0,0,609.86,219Z" />
      <path d="M674.22,145.07c-5.79,4.15-7.06,2.36-6.14,3.06l1.08.82a16,16,0,0,0,5.78-3c8.43-6,11-3.88,7.93-5.49A30.28,30.28,0,0,0,674.22,145.07Z" />
      <path d="M665.84,108.87C660.49,89,660.61,90.13,657,87.68c8.7,12.11,4.63,28,20.68,45.66C687.1,138,672.13,132.3,665.84,108.87Z" />
      <path d="M589.51,217.38c.33.93,3.38,2,4.52,2.19,3.22.69,6.43.06,9.65-.58,5.14-1,5.11-.43,2.38-1.31-1.08-.35.18-.27-5,.63C589.27,220.36,587.69,212.29,589.51,217.38Z" />
      <path d="M712.48,93.38c-13.31-11.24-32.43-7-47.29-7-3.64,0-3.61-.49-1.27,1.14,15.08.23,38.71-5.16,50.89,9.9C713,93.45,713.27,94.05,712.48,93.38Z" />
      <path d="M676.9,137.47c-1-.5-6.89,5.06-11.08,6.73-2.42,1-2.78.18-1,1.52,5.13-1.42,8.55-5.65,13.13-7.71Z" />
      <path d="M591.56,223.18c.25.73,3.92,1,4.94,1,6.56,0,12.6-3.67,19-3.67,1.59,0,2.38.27.33-.19-3.42-.76-2.53-1.31-10,.88-3.72,1.09-7.47,2.19-11.32,1.71S590.82,221,591.56,223.18Z" />
      <path d="M660.05,171.61a87.12,87.12,0,0,0,3.09-14.25c.17-1.71.38-7.61-.36-8.16-3.24-2.39,1.93-.39-2.52,17.15-1.83,7.23-4.4,14.33-5.32,26C657.18,197.93,654.07,193.3,660.05,171.61Z" />
      <path d="M594.78,231.07c11.3,1.61,21.35-6,31.52-8.86-2.8-.4-1.87-1-10.73,2.9C593.46,234.84,592.59,226.7,594.78,231.07Z" />
      <path d="M624.51,178.08c.89,1.27,10.94.54,15.71,2.15,7.14,2.41,8.56,7.69,6.48,2.58-.42-1-3.63-2.67-4.76-3.15a24.1,24.1,0,0,0-9-1.69C622.34,177.51,622.89,175.76,624.51,178.08Z" />
      <path d="M678.28,110.7c-2.16-10.34-2.65-9.59-4.31-10.85-1-.77.12-.19,1.47,4.05,1.22,3.84,1.78,7.91,2.78,11.83,2.87,11.18,9.36,22.77,10.82,23.59C695.77,143.08,683.85,137.34,678.28,110.7Z" />
      <path d="M674.26,95c2.31,1.74.31.85,6.87,1.36A73.47,73.47,0,0,1,721,112c-.76-2-.21-2-6.06-5.7A74.71,74.71,0,0,0,674.26,95Z" />
      <path d="M635,208.74c2.11-8.16,1.41-7.62.26-9.08.35,6.45-3.66,12.55-2.55,19.32C635.54,219.41,632.05,220.21,635,208.74Z" />
      <path d="M657.21,208.06c.56.33.57.66-.52-1.56-4.25-1.92-9-1.2-13.17-2.83.38.44,1.07,1.23,1.46,1.66C649.11,206.36,653.57,205.93,657.21,208.06Z" />
      <path d="M593.63,245.58c2.06-6.53,2.35-4.74,1.17-6.52a72.33,72.33,0,0,0-5.57,30.58,43,43,0,0,0,1.5,4.88A71.43,71.43,0,0,1,593.63,245.58Z" />
      <path d="M668.93,153.9c-3.12-2.46,2.23-.82-5.43,27.93-.53,2-5,19.07-4.1,21,1.89,4-.74,2.22,4.1-16.48C666.26,175.69,669.71,164.83,668.93,153.9Z" />
      <path d="M641,212.55c1.21-5.45.87-4.66-.25-6,0,4.44-2.32,8.81-1.58,13.56C641.64,220.58,639.13,221.16,641,212.55Z" />
      <path d="M645.39,200.6c2.75-.08,5.45-.17,8,.93,1.65.72,1.61,1.42.6-1-3.57-1.59-6.88-1-10.59-1s-3.82-.63-3.37-.06C641,200.64,640.2,200.76,645.39,200.6Z" />
      <path d="M688.7,143.71c-1.26-.73-10.13,5-14.57,6.41-3,.93-3.41,0-1.5,1.55,6.38-1.21,11.24-6,17.24-7.27Z" />
      <path d="M598.81,237.78c.39.55,5.07-.3,5.83-.46,6.18-1.32,11.91-4.38,17.12-7.18,13.84-7.42,15.43-6.29,14-6.56-.8-.15-2-.35-2-.35-11.81,4.13-22.37,13.6-35.06,13.6C598,236.83,598,236.6,598.81,237.78Z" />
      <path d="M599.59,245.72l-.82-1.1a83.14,83.14,0,0,0-4.58,38.83C599.43,295.59,589.29,274.28,599.59,245.72Z" />
      <path d="M602.23,260.58c2-10.14,2.8-7.86,1.29-9.76a98.34,98.34,0,0,0-3.66,40.57c.6,5,.53,4.3,2.36,7.44A97.33,97.33,0,0,1,602.23,260.58Z" />
      <path d="M607.23,266.75c2.08-10.26,2.8-8.05,1.28-9.84a109.59,109.59,0,0,0-4.13,41.42c.54,5.81.53,5.11,2.2,8A108.47,108.47,0,0,1,607.23,266.75Z" />
      <path d="M615,264.26l-.89-1a117.47,117.47,0,0,0-5.19,47C613.55,318.14,604.15,298.5,615,264.26Z" />
      <path d="M622.81,272.68l-.89-.95a116.6,116.6,0,0,0-6.12,50.15C620.69,330.07,610.29,308.65,622.81,272.68Z" />
      <path d="M624,302.63c1.5-7.55,3.68-15,5.91-22.33l-.75-.82s-11,28.5-7.47,51.55C626.06,337.51,618.83,328.56,624,302.63Z" />
      <path d="M711.26,194.08l-1-1a176.61,176.61,0,0,0-4.27,56c1.92,1.21,1,1.86.64-8.78A175.24,175.24,0,0,1,711.26,194.08Z" />
      <path d="M631.84,297c2.56-13.15,3.67-10.56,1.95-12.46a118.52,118.52,0,0,0-4.25,50.47c1.24,9.33,1.57,8.67,3.06,10.45A117.14,117.14,0,0,1,631.84,297Z" />
      <path d="M637.6,282.59l.84.91c.65.69,8.75-1.22,10.3-1.66,15.65-4.39,28.68-14.53,42-22.55s14.06-6.24,11.87-7.67C680.94,261.43,662,281.15,637.6,282.59Z" />
      <path d="M727.5,189c-13.7,4.23-14.77.75-12.22,3.43,8.57.38,16.35-4.9,24.49-4.65C738.69,186.72,738.75,185.48,727.5,189Z" />
      <path d="M639.53,304.68c2.62-12.83,3.6-10.41,1.9-12.08a126.59,126.59,0,0,0-4.93,49.93c1,9.82,1.34,9,2.67,10.39A125.47,125.47,0,0,1,639.53,304.68Z" />
      <path d="M718.85,225.12c-2.48,9.9-5.69,19.6-4.78,29.43l1.26.91c-2-16.24,8.15-32.65,7.1-49.33C719.47,202.79,723.71,205.65,718.85,225.12Z" />
      <path d="M719,258c1.91,1.3.53,1.33,1.92-6.08,2-10.62,6.71-20.42,7.58-31.49.59-7.62-.43-7.76-1.61-9.18C729.94,227,719.82,242.16,719,258Z" />
      <path d="M645.1,290.56c.62.63,8.58-1.48,13.23-3.36,15.33-6.22,27.64-17.43,42.94-23.38,12.73-4.94,15.59-2,12.35-4.35-25.46,2.92-44,27.27-68.85,30.17C644.13,289.71,644.08,289.51,645.1,290.56Z" />
      <path d="M748.91,200.38c-6.52-1.92-11.49,2.92-20,2.81-4.57-.05-4.86-1.28-2.91.95,9.26,1.17,13.46-2.85,19.41-3C750,201,750.46,203.38,748.91,200.38Z" />
      <path d="M645.66,311.7c3.23-13.54,4.53-11,2.66-12.66a105.9,105.9,0,0,0-5.92,49.46c1.22,10.23,1.74,9.58,3.07,10.87A104.6,104.6,0,0,1,645.66,311.7Z" />
      <path d="M682.77,324.11l-.83-.54c-10.65,30.76-7.33,59.12-6.81,59.45,2.82,1.77-.77,1.13.5-21.5C677,337.17,683.49,324.59,682.77,324.11Z" />
      <path d="M687.51,341c2.66-13.06,3.7-10.89,1.92-12.22-9.52,31.17-4.13,60.24-3.55,60.55l1.09.59A129.83,129.83,0,0,1,687.51,341Z" />
      <path d="M702.07,377c-1.15-22.05,2.32-35.17,1.78-35.71-.29-.3-.66-.67-.83-.83-5.16,30.64,0,57.34.53,57.56C706.29,399.23,703.25,399.44,702.07,377Z" />
      <path d="M763.23,336.2a8.66,8.66,0,0,1-.14-1.14c0-.07-14.27-9.5-44.82-.35-3.94,1.18-7.83,2.51-11.53,3.82l.86.92C726,333,745.63,327.54,763.23,336.2Z" />
      <path d="M709.91,381.48c-.85-20.32,2.44-30.73,1.84-31.46l-.81-.95a149.23,149.23,0,0,0-.05,52.1C713.78,402.36,710.77,402,709.91,381.48Z" />
      <path d="M767.47,350.16c-.35-1.5.13-2.12-5.31-4.78-14.48-7.06-32.8-2.92-48,1.34l.84,1C732.46,342.81,753.33,338.55,767.47,350.16Z" />
      <path d="M720.21,369.81c1-9.41,1.68-7.36.19-9.2a130.09,130.09,0,0,0-1.47,36.49c.89,8.73,1,7.67,2.26,8.16A129.19,129.19,0,0,1,720.21,369.81Z" />
      <path d="M727.2,375.68c.43-7.1,1-5.46-.5-7.28a124.86,124.86,0,0,0,1.62,39.54l1.28.47A123.63,123.63,0,0,1,727.2,375.68Z" />
      <path d="M771.48,372.69c-.21-1.4-.21-1.39-.3-2.06-10.52-10.85-26.42-9.25-40.77-4.36,0,.06.74.92.77,1C745.57,362.38,761.7,361,771.48,372.69Z" />
      <path d="M734.4,385.09c.11-8.11,1-6-.65-8a83.37,83.37,0,0,0,3.32,34.11l1.38.51A82.4,82.4,0,0,1,734.4,385.09Z" />
      <path d="M774,390.46c0-.1-.15-1.09-.2-1.49-.14-1.05-13.17-11.88-29.66-5.83l.76.95A28.21,28.21,0,0,1,774,390.46Z" />
      <path d="M741.1,390.24c0-4.77.5-3.49-1-5.34-.86,14.86,2.77,28.44,3.36,28.66l1.12.41A85,85,0,0,1,741.1,390.24Z" />
      <path d="M747.74,394.55c.55.29.16-.07-1.14-1.67a72,72,0,0,0,3.21,22.91H751A70.81,70.81,0,0,1,747.74,394.55Z" />
      <path d="M648.45,219.3c.11-5.35-.46-4.92-2.23-6.73,1.59,3.27.95,7.09,1,9.49C648.77,222.52,648.38,223,648.45,219.3Z" />
      <path d="M604.38,245.17c9.74,1.34,18.12-4.35,25.84-9.56,16.1-10.85,20.79-8,18.52-8.74-.81-.26-1.9-.6-2-.63-14.65,3-24.61,18-39.6,18C602.92,244.21,602.7,243,604.38,245.17Z" />
      <path d="M629.85,246.65c10.09-4.61,19.14-11.32,29.21-16l-1.44-.59c-15.42,7.32-30.87,20.84-49,20.47l.78,1C609.81,252.06,618.4,251.86,629.85,246.65Z" />
      <path d="M627.22,256.1c-10.86,4.49-13.16.93-10.66,3.71,20.49-1,33.16-23,53.11-24.57-1.73-.76-2.09-1.64-8.78.84C648.27,240.77,639.28,251.11,627.22,256.1Z" />
      <path d="M624.43,268.44c.59.65,7.78-1.41,11.35-2.87,13.87-5.69,24.72-16.45,38.5-21.45,9.45-3.43,11.84-1.35,7.83-3.25-21.81,3.4-36.88,24.52-58.43,26.69C623.61,267.57,623.5,267.43,624.43,268.44Z" />
      <path d="M651.86,296.94c.72.63,10-2.5,12-3.25,16-6,29.73-16.06,46.11-20.87,14.7-4.32,17.09-.75,14.48-3.57-26.76.7-47.87,22.32-73.51,26.89Z" />
      <path d="M674.37,313.53c.94.65,9.2-1.67,11-2.25,16.84-5.4,33.07-17.19,61.13-13.25,0-.08-.17-.29-.64-1s-8.9-.85-10.14-.85c-14.63,0-24.24,3.36-37.46,8.93C672.89,315.75,671,311.2,674.37,313.53Z" />
      <path d="M743.23,305.33c8.46,0,8.55,2.06,7.17-.5-18.51-3.39-36.46,7.75-55.76,12.57-12.19,3.05-13.34.8-10.17,3C704.74,318.54,724.54,305.33,743.23,305.33Z" />
      <path d="M755.29,305.34c.26.5,10.62-8.19,24.37-1.91,3,1.38,5.62,3.73,0-1.24a25.88,25.88,0,0,0-24.84,2.29Z" />
      <path d="M732.66,314.73c7.23-2,15.79-4.6,22.17-1.09l-.65-1.38c-.45-.93-4.88-1.51-6.94-1.51-5.62,0-6.59.61-56.23,14.18C692.37,325.92,687.42,327.46,732.66,314.73Z" />
      <path d="M695.37,347.8c2.31-13.87,3.57-11.26,1.7-12.9-8.66,30.82-1.83,59.23-1.21,59.53L697,395A119.76,119.76,0,0,1,695.37,347.8Z" />
      <path d="M701.29,333.19c11.81-2.3,22.84-7.07,34.43-9.22,21.45-4,24.95,3.58,23.28-.64-16.15-4.82-33.59,2.07-47.35,6.08C699.28,333,699.47,331.55,701.29,333.19Z" />
      <path d="M769.31,358.94s-.15-.77-.24-1.28c0-.18-10.39-9.32-36.56-3.3-3.32.76-6.61,1.65-9.74,2.54,0,.05.79,1,.8,1C739.34,353.44,755.67,349.89,769.31,358.94Z" />
      <path d="M769.93,362.48c0-.15,0-.29-.07-.44A.6.6,0,0,0,769.93,362.48Z" />
      <path d="M774.57,354.29c-.58.71-1.14,1.45-1.66,2.2.49,2.48-.17,1.91,2.35-1.22Z" />
      <path d="M747.76,375.51a42.26,42.26,0,0,1,25.3,8.36c-.21-1.46.45-1.52-4.19-4.09a43.48,43.48,0,0,0-31-4.25C739.76,377.81,737.14,375.51,747.76,375.51Z" />
      <path d="M764.26,392.67c11.19.66,10.83,6.56,10.25,2.08-5.2-4.59-15.93-3.39-22.73-2.07l.68.84C752.87,394,757.27,392.26,764.26,392.67Z" />
      <path d="M754.82,403c-.2-.24-1.19-1.46-1.27-1.57a144,144,0,0,0,1.74,14.38C757.53,415.79,756,417.65,754.82,403Z" />
      <path d="M775.43,405.79c0-.71-2.52-2.25-3-2.54-4.2-2.37-9.08-2.58-13.88-2.2l.73.9c.44.55,9.76-1.45,15.65,4.66C775.6,407.34,775.47,407.7,775.43,405.79Z" />
      <path d="M765.55,409.79l.8,1c.39.49,3.61.05,8.23,2.56,1,.56.81.8.85-.84A21.06,21.06,0,0,0,765.55,409.79Z" />
      <path d="M761,410.52c.19,1.77.44,3.53.74,5.27h1.17C762.11,411.48,762.81,412.8,761,410.52Z" />
      <path d="M765.75,212.13l-1-1a41.62,41.62,0,0,0-.17,11.31C768.39,229.92,764.26,223.51,765.75,212.13Z" />
      <path d="M778,247.41c2.72,4.85,1,2.83,1-3.93,0-6,1.22-11.89,2.49-17.66l-1-.77C778.78,232.47,777.23,239.85,778,247.41Z" />
      <path d="M786.86,229.8l-1-.71A70.73,70.73,0,0,0,784,257.93C788.64,265.9,780.73,252.48,786.86,229.8Z" />
      <path d="M791.37,232.91l-1-.69a83.65,83.65,0,0,0-1,34.7c3.37,5.16.68,2.4-.07-10.09A82,82,0,0,1,791.37,232.91Z" />
      <path d="M794.93,274.38c3.42,4,.61,1.85-.72-11.1a84.71,84.71,0,0,1,1.56-27.11c.14-.64-.95-1-1.12-.29A86.13,86.13,0,0,0,794.93,274.38Z" />
      <path d="M799.41,272.4c1.34,9.19,1.43,8.25,2.93,9.77a145.43,145.43,0,0,1-2.72-44l-1.09-.68A143.79,143.79,0,0,0,799.41,272.4Z" />
      <path d="M805,280.69c.11.75.77,5,1.38,5.58,3.63,3.61-.66,1.46-.92-15.29-.16-10.07.87-20.54-1.78-30.21-2.84-1.75-.79-1.59.22,7.43C805.14,258.88,803.49,270,805,280.69Z" />
      <path d="M809,276a103.61,103.61,0,0,0,3.28,16c4.41,4.11-2.18-2.42-2.82-26-.19-7.09.18-14.26.61-21.34l-.93-.57C808.72,243.83,807.5,263.3,809,276Z" />
      <path d="M814.31,299.19c.11.41.16.84.66.84S815.26,300,814.31,299.19Z" />
      <path d="M819.77,298.59c3.07,2.59,1.94,2.6-.86-5.67a107.28,107.28,0,0,1-5-46s.13.05-1.07-.67A108.25,108.25,0,0,0,819.77,298.59Z" />
      <path d="M824.8,241.14c-7.7,4-9.3,2-6.36,3.73,15.06-4,24.74-19.33,39.58-23.05-2.27-.48-2.18-.88-6.26,1.06C841.84,227.59,834.55,236.08,824.8,241.14Z" />
      <path d="M825.5,303.31c2.77,2.23,1.81,2.5-.71-5.82a116.15,116.15,0,0,1-4.26-46.65c-.26-.15-1.07-.62-1.08-.64A117.21,117.21,0,0,0,825.5,303.31Z" />
      <path d="M832.56,308.65c3.57,2.45,1.67,2.16-1.89-7.21a79.27,79.27,0,0,1-3-46.5l-1-.6A80.43,80.43,0,0,0,832.56,308.65Z" />
      <path d="M880.15,229.28c-.63-.3-15.43,2.13-29.66,11.63-8.23,5.49-11.68,10.46-18.06,12l1.17.66c.74.42,4.61-1.89,5.8-2.72,4.67-3.23,17.82-17.09,42.23-20.84Z" />
      <path d="M840.23,313.33c2.94,1.6,1.63,2.1-1.86-7.68a99.78,99.78,0,0,1-4.85-47.41l-1.08-.6A100.7,100.7,0,0,0,840.23,313.33Z" />
      <path d="M846.88,316.65c2.72,1.24,1.41,1.63-1.55-7.52a92.78,92.78,0,0,1-2.85-46l-1.06-.58A93.86,93.86,0,0,0,846.88,316.65Z" />
      <path d="M852.26,311.54c2.69,9.55,2.74,8.47,4.15,9a123.91,123.91,0,0,1-7.74-43c.11-12.15,1.69-10.08-.37-11.2C846.32,280.78,848.25,297.32,852.26,311.54Z" />
      <path d="M861.79,322.4c2.56.88,1.41,1.53-1.57-7.32a92.76,92.76,0,0,1-3.71-44.19l-1.07-.6A93.85,93.85,0,0,0,861.79,322.4Z" />
      <path d="M909.21,259.26c6.94,0,7.49,2.18,5.47-.61-10.45-2.32-33,2.77-49.9,12.39l1.15.68C881.53,263,899.1,259.26,909.21,259.26Z" />
      <path d="M868.12,324.56c2.31.79,1.34,1.31-1.34-7.25a105.62,105.62,0,0,1-4.23-42.89l-1.08-.65A106.48,106.48,0,0,0,868.12,324.56Z" />
      <path d="M873.21,326.3c2.09.71,1.29,1.28-.55-6.89a131.7,131.7,0,0,1-2.74-40.52l-1.1-.68A133.29,133.29,0,0,0,873.21,326.3Z" />
      <path d="M872.85,275.94c.62.39,10.79-7,28.64-8.05,12.38-.74,20.56,2.15,20.28,1.68l-.69-1.16a68.81,68.81,0,0,0-49.17,6.95Z" />
      <path d="M926.64,278.31c-.69-1.3.22-1.5-8.07-2.43a95.49,95.49,0,0,0-39.64,3.94l1.25.82A94.15,94.15,0,0,1,926.64,278.31Z" />
      <path d="M877.28,321.28c1.94,8.06,2,7.07,3.29,7.51a112.62,112.62,0,0,1-5.28-36.15c.19-11.55,1.46-9.41-.47-10.64A113.89,113.89,0,0,0,877.28,321.28Z" />
      <path d="M923.05,282.66c7.61,1.93,8.45,5.18,6.49,1.26-11-5.91-24.32-2.91-35-.86-9.33,1.79-8.92,1.21-8.39,1.58C889.9,287.21,907.54,278.72,923.05,282.66Z" />
      <path d="M887.28,331.06l1.11.38c.74.25-3.26-8.81-4.67-26.11-1.6-19.86,1.33-16.54-1.32-18.33A129.68,129.68,0,0,0,887.28,331.06Z" />
      <path d="M893.46,333.16l1.13.38c1.06.36-6.47-15.35-4-40.84l-1.08-.77A105.54,105.54,0,0,0,893.46,333.16Z" />
      <path d="M908.94,342.73c.23,0,.16,0-.47-.22A.59.59,0,0,0,908.94,342.73Z" />
      <path d="M904.32,303.11l-.91-.72a97.2,97.2,0,0,0,3.52,35.37l1.34.46C902.12,317.73,905,303.63,904.32,303.11Z" />
      <path d="M912.44,339.66l1.3.45a109.8,109.8,0,0,1-3.28-26.6c0-6.15.64-4.85-1-6.19A111.1,111.1,0,0,0,912.44,339.66Z" />
      <path d="M918.72,338.93c.78,3.7.46,3.06,2,3.61-4.33-17-2.5-27.64-3.11-28.19l-.93-.82A98.67,98.67,0,0,0,918.72,338.93Z" />
      <path d="M925.31,321.43l-1-1a67.38,67.38,0,0,0,1.34,23.81c2.28.8.91,1.35-.17-7.42A66.25,66.25,0,0,1,925.31,321.43Z" />
      <path d="M930,326.05l-1-1a45.75,45.75,0,0,0,1.27,20.89c2.41.9,1,1.29-.18-5.89A44.41,44.41,0,0,1,930,326.05Z" />
      <path d="M938.67,335l-1.07-1.16a45.42,45.42,0,0,0,1.4,15.71c2.23,1,1.28,1.49.28-4A44,44,0,0,1,938.67,335Z" />
      <path d="M943.2,340.08c-.24,5.55.67,11.94,1.19,12.22,2.74,1.48-.28.79-.08-10.94Z" />
      <path d="M949.53,347.55l-1.07-1.29a34.27,34.27,0,0,0,.36,8.58C951.59,356.53,949.11,356.11,949.53,347.55Z" />
      <path d="M800.45,220.27c6.19-1.86,5.52-1.47.35-1.31-17.46,5.38-22-.37-17,3.46C789.44,223,795,221.91,800.45,220.27Z" />
      <path d="M792.39,228.07c9.08,0,16-6.23,23.57-9.57-3.36.06-2.19-.39-7.54,2.68-5,2.86-11.83,6.86-18.85,5.48C791.84,228.29,791.35,228.07,792.39,228.07Z" />
      <path d="M826.9,227.47c14.34-9.12,17.31-7.84,15.36-8-9.49-.91-22.16,15.56-37.57,15.56-3.35,0-4.27-1-1,1.07.36.22,6.17.48,13.77-3.16A93.29,93.29,0,0,0,826.9,227.47Z" />
      <path d="M863,226.08c-11.39,3.59-20.38,11.63-30.12,17.65-9.18,5.67-9.75,3.9-7.38,5.26,15.62-6.91,28.42-22.33,45.91-23.34C869.23,224.85,869,224.19,863,226.08Z" />
      <path d="M890.77,235.3c-1.23-.82-7.63.47-9.89,1.14C869,240,860,248.37,849,253.39c-9.85,4.49-12,2.1-8.5,4,10.55-2.09,19.49-9.09,28.13-14.12C888.44,231.74,895.93,238.71,890.77,235.3Z" />
      <path d="M880.22,245.45c-10.08,2.63-17.8,7.48-32.62,15.92,1.6.88-.12,1.4,9.83-4.27,11-6.27,21.72-12.44,38-12.44,8.34,0,8.7,1.45,6.49-.6C901.21,243.41,890.6,242.74,880.22,245.45Z" />
      <path d="M902.51,250.69c-24,0-34.63,12.14-46.26,15.44l1.16.66c1.31.74,14.17-6.89,17.3-8.44a62.71,62.71,0,0,1,35-6.09C908.71,251.07,909.22,250.69,902.51,250.69Z" />
      <path d="M895.15,291c6,0,10.78-2.49,19.17-2.49,11.82,0,19.72,4.76,19.47,4.23l-.62-1.35c-10.34-4.65-20.09-4.78-30-2.68C892.72,290.93,892.08,288.77,895.15,291Z" />
      <path d="M900.52,335.57l1.35.46a100.56,100.56,0,0,1-4.62-30.71c0-8.63,1-7-.77-8.28A101.72,101.72,0,0,0,900.52,335.57Z" />
      <path d="M916.63,296.35c20.49-1.27,22.18,7.65,19.7,2-9.41-5.41-21.4-2.57-30.75-2.57-5,0-5.15-.85-2.67,1.08A113.61,113.61,0,0,0,916.63,296.35Z" />
      <path d="M941.44,310.52c-.23-.59-.44-1.1-.68-1.69-.48-1.18-14.21-10.12-31-6.5l1.16.94A38.82,38.82,0,0,1,941.44,310.52Z" />
      <path d="M940.31,316.51c5.49,3.11,5.22,4.28,3.94,1.09a51.17,51.17,0,0,0-26.68-8.7l1.4,1.23A50,50,0,0,1,940.31,316.51Z" />
      <path d="M948.17,327c-.74-1.69.09-1.74-7.62-4.88a82.38,82.38,0,0,0-12.9-4.05l1.38,1.31C929.79,320.11,937.15,321,948.17,327Z" />
      <path d="M934.47,330.57l-1.08-1.12A55.64,55.64,0,0,0,935,347.83c2.29,1,1.11,1.52,0-5.74A54.71,54.71,0,0,1,934.47,330.57Z" />
      <path d="M950.77,332.84A41.91,41.91,0,0,0,936.65,327c2.43,2.51.2.78,5.76,2.9C952.06,333.55,952.82,337.31,950.77,332.84Z" />
      <path d="M955.58,342.86c-.78-1.55-.07-2-8.27-5.31-1.95-.79-1.83-1.05.22,1.34A66.86,66.86,0,0,1,955.58,342.86Z" />
      <path d="M960.09,352.27c-1.06-2.26-.21-1.41-4.53-3.65C957.74,351.4,956.62,350.47,960.09,352.27Z" />
      <path d="M640,145.07c.32-3.81.67-7.83-.22-11.61-3-2-.33-1.32-.6,6.88-.13,3.89-.79,7.72-.65,11.69C641.6,159.47,639.08,156.16,640,145.07Z" />
      <path d="M651.48,126.22c-.7-.29-6,2.77-11.89,2.12l1.53,1c1.09.75,6.63-.75,9.22-1.7C653.37,126.57,653.09,126.87,651.48,126.22Z" />
      <path d="M649.7,155.64c-.94,6.13-2.22,12.31-2.21,18.31,1.82,4.49,1.22,3.75,1.16.65-.16-8.81,2.63-17.9,3.08-26.94,0-.82.19-6-.36-6.39C648.75,139.51,652.11,139.8,649.7,155.64Z" />
      <path d="M617,167.32c3.53,5.12,19.36,3.78,28.13,11.75-1.06-2.59-.74-2.15-1.9-2.9C635.23,171,625.4,171.11,617,167.32Z" />
      <path d="M603.91,194.79c3.84-7.8,9.11-16,9.5-25.45-2.07-3-.43-1.92-1.68,4.26-2.23,11-9.63,19.4-12.6,30.07-.34,1.23-1.44,6-.66,6.41C601.25,211.68,596.37,210.13,603.91,194.79Z" />
      <path d="M587.72,212.37c.29.83,5.2,2.4,9.55,1.68-2.32-1.22-1.32-1-2.59-1C587.49,213.1,586.46,208.84,587.72,212.37Z" />
      <path d="M675.58,159.35c-2.6-2.21-.08-1.3-1.44,10.32-1.69,14.28-11.46,40.25-6.46,47.61C665.59,197.87,677.06,178.92,675.58,159.35Z" />
      <path d="M680.14,153c-4.35,2-4.38,1.18-2.89,2.44,6.41-2.35,12.52-6.78,19.42-6.86C694.33,147.08,694.83,146.32,680.14,153Z" />
      <path d="M681.7,164.74c-2.94-2.67.3-.71-1.44,11.69-1.89,13.5-7.81,25.61-9,39.42-.59,6.66,0,6.33,1.1,7.78C670.69,203.81,683.88,184.69,681.7,164.74Z" />
      <path d="M687.76,181.83c.63-4.64.66-10.76.06-11.34l-1.11-1.07c2.87,20.18-11.19,39.4-9.44,60,3.36,3.48-2.78.39,6-28.79A122.72,122.72,0,0,0,687.76,181.83Z" />
      <path d="M698.9,193.56c.26-1.95,1.16-11,.34-11.83-2.89-2.91,1.84-1.5-3.9,24.4-2.48,11.21-5.42,23.48-2.59,35.17,2.79,1.66.55,1.33.07-7.58C692.09,220,697.07,207.07,698.9,193.56Z" />
      <path d="M706.34,189c-2.24-2.31,1-3.05-4.08,22.69-2.63,13.35-3.5,18.81-3.65,23.76-.09,3.28.41,9.46,1.08,9.85,3.56,2.08-2.19.22,1.2-19.91C702.5,215.75,707.87,190.54,706.34,189Z" />
      <path d="M722.27,156.2c-2.13-9.3-1.86-8.83-6-14.89,4.4,8.18,5.6,21.82,7.34,23.53C726.64,167.82,724.93,167.78,722.27,156.2Z" />
      <path d="M695.46,247.52l-1.43-.75c-.91-.47-7,1.58-8.66,2.25C671,254.71,662.1,265,645,271.39c-14,5.26-15.43,2.6-13.29,4.9C663.36,270.36,673.23,250.37,695.46,247.52Z" />
      <path d="M712.57,136.19c-2.42-3.16-2.2-2.82-2.32-2.62C709.85,134.27,710.67,134.27,712.57,136.19Z" />
      <path d="M724.88,171.82c-1.43-1.47-13.45,6.59-22.7,6.59-.65,0-.78-.28.62,1.14,7.47-.26,15.27-4.76,19.42-6.11C726,172.21,726.31,173.3,724.88,171.82Z" />
      <path d="M727.05,263c2.17,1.25.48,1.4,1.48-7.28C730,243.45,737,224.12,733,218.9,734.17,233.5,726.52,248.17,727.05,263Z" />
      <path d="M733.75,266.81c2.52,1.39.58,1.3.62-6.56,0-9.51,4-17.32,5.56-26.19,1-5.48.46-5.15-.6-6.62C739.63,240.42,731.05,250.74,733.75,266.81Z" />
      <path d="M758.48,219.42c-.33-.63-4.69.76-5.63,1.1-3,1.09-6,2.58-9.19,3-2.55.35-2.51-.39-1.46,1.05.43.59,4.28-.46,5.39-.83,3.13-1,6.1-2.6,9.34-3.19C759.3,220.13,759.22,220.84,758.48,219.42Z" />
      <path d="M764,229.72c-4.77-1.34-9.14,1.51-13.58,2.4-2.94.58-2.93-.28-1.86,1.21.76,1.07,9-2.8,12.85-2.8C765.06,230.53,765.48,232.58,764,229.72Z" />
      <path d="M742.44,248c-1.22,5.33-2.7,10.74-3.24,16.25-.05.54-.45,5.33,0,5.6l1,.53c-.76-11.24,4.73-22.73,5.24-34.32C743.15,232.75,745.67,233.87,742.44,248Z" />
      <path d="M765.21,272.65c1.84-6.58,1.48-5.89.6-7.18-.42-.62.28-.36-1.43,5.87-1.06,3.85-2.31,7.87-1.94,11.89l1.27.86C763.09,280.23,764.22,276.18,765.21,272.65Z" />
      <path d="M772.38,262c-4.43,0-4.06-.24-3.82.12.82,1.21.24,1.05,3.93,1,3.3,0,7.27-.08,10.45,2.07-.22-.53-.48-1.12-.78-1.76C779.05,262,775.81,262,772.38,262Z" />
      <path d="M769,277.19c2.26-4.73,1.83-4.3,1.06-5.45-.42-.61.39-.27-2,4.68-1.33,2.82-2.81,5.9-2.43,9.05l1.38,1C766.12,283.41,767.71,280,769,277.19Z" />
      <path d="M778.72,284.61c-.19-.29-.18-.29-.75-1.16-2,4.51-2.08,10.07-1.44,10.64l1.13,1A19,19,0,0,1,778.72,284.61Z" />
      <path d="M636.91,120.51c-1.09-.41-4,.72-9.92-.8,2.25,1.54,2.16,2,6.54,2a26.83,26.83,0,0,0,5.17-.5Z" />
      <path d="M644.2,123.32c-.63-.25-2.88,1.39-8.16,1.93-1.09.11-1.27-.22.62,1.08a28.44,28.44,0,0,0,8.79-2.52Z" />
      <path d="M644.7,167.08c.59,1.44-.46.56,1-11.13.63-4.89,1.25-9.84.61-14.79-.55-4.27-.89-3.86-2.31-4.83C648.06,147.37,642.09,160.66,644.7,167.08Z" />
      <path d="M661.6,130.45c-1.61-.69-8.09,3.71-12.73,3.92-.57,0-.77-.23,1.11,1,4.73-.64,8.63-3.95,13.31-4.23Z" />
      <path d="M708.25,169.84c-12.08,4.89-13.53,1.64-11.25,3.9.71.7,6.36-.85,8.63-1.66,4.37-1.55,7.15-3.45,13.52-3.45,3.12,0,3.13.55,1.39-1.12A25.31,25.31,0,0,0,708.25,169.84Z" />
      <path d="M687.19,212.67c2.46-12.17,6.81-24.26,6.36-36.6-2.47-2.42-.21-1.78-1.62,9.28-1.63,12.78-6.8,25.64-7.44,38.87-.18,3.68.17,11.11.93,12C689,240.3,682.89,234,687.19,212.67Z" />
      <path d="M728.49,179.86c3.72-.78,3.91.26,2.38-1.5-8.92.72-13.88,6.8-22,6.8-.72,0-.83-.29.55,1.14C716.83,186,721.47,181.34,728.49,179.86Z" />
      <path d="M712.85,218.86c-2.11,10.67-4.75,21.4-3.92,32.13,2.36,1.56.48,1.71,1.14-8.34.73-11.13,4.13-22.23,5.63-33.66.12-.85,1.08-9,.5-9.66C713.87,196.83,717.06,197.62,712.85,218.86Z" />
      <path d="M745.2,194.16c-8.4-1.14-16.22,3.6-24.14,3.6-1,0-1.09-.35.27,1.15,7.39-.11,14.25-3.88,21.57-3.78C746.44,195.18,746.65,196.24,745.2,194.16Z" />
      <path d="M752.63,207.8c-.33-.67-5.28.17-6,.3C741.55,209,736.23,211,731,210c1.28,1.54.79,1.4,3,1.4,6.68,0,13.52-3.33,19.16-2.6Z" />
      <path d="M755.6,213.75c-.24-.48-2.77,0-3.3.09-5,1.12-9.58,5-14.77,4.41,1,1.38.88,1.23,1,1.23,6.37,0,11.46-5.41,17.53-4.73C756.12,214.76,756.17,214.88,755.6,213.75Z" />
      <path d="M761.49,225.11c-4.64-1.06-8.67,1.69-12.59,3s-4.06.06-2.76,1.87c5.18-.68,9.7-4.78,14.69-3.88C762.49,226.45,762.61,227.21,761.49,225.11Z" />
      <path d="M743.74,272.26c3.13,1.7-1,1.09,2.92-11.4,1.47-4.62,3.4-9.18,4.15-14,.47-3,.4-2.58-.87-4.41C750.28,252.4,742.9,262,743.74,272.26Z" />
      <path d="M771.14,217.29a38.81,38.81,0,0,0-1.61,8.12c-.59,6.62.16,6.85,1.44,9.24-1.59-10.4,1.46-16.2.95-16.65Z" />
      <path d="M767,235.39c-5.45-.83-10,2.57-15.06,2.76.69,1,.17,1.71,5.41.08,3.29-1,6.87-2.44,10.36-1.54C767.4,236.12,767.57,236.45,767,235.39Z" />
      <path d="M765.87,239c-3.55-.85-6.21,1.53-9,2.52-2.46.86-2.62-.11-1.45,1.56,3.38-.5,6-3.11,9-3.11,2.25,0,4.2,1.62,6.49,2.59-1.1-2-.9-1.71-1.13-1.83A16.5,16.5,0,0,0,765.87,239Z" />
      <path d="M776.23,252.33c-.83-1.52-6-1.61-6-1.61a41,41,0,0,0-5.91.61c-3.31.48-3,.2-2.81.52,1.07,1.54-.35.8,6.67.12C777.22,251.09,779,257.38,776.23,252.33Z" />
      <path d="M761,258.43c-.59-.86.44.13-1.39,7.05-1.22,4.59-3,9.33-3,14l1.15.71C757.52,272,763.5,262.08,761,258.43Z" />
      <path d="M770.58,255a15.21,15.21,0,0,0-6,1.3c.8,1.16.61,1,.92.9,4-1.39,7.32-2.2,15,2.83-.31-.58-.62-1.16-.94-1.74C779.08,257.5,774.56,255.14,770.58,255Z" />
      <path d="M777.16,268.64c7.16-.2,7.6,1.79,7.34.93-.14-.48-.26-.86-.39-1.25-3.2-1.37-6.58-.67-9.81-.83-2.69-.14-2.79-1-1.41,1A24.3,24.3,0,0,0,777.16,268.64Z" />
      <path d="M773.8,281.42c1.45-2.86,1.3-2.25.53-3.39-.23-.34.17-.53-1.56,2.88-1.18,2.32-2.31,4.66-2.39,7.29,0,1-.19.59,1.33,1.81C771,287.06,772.52,283.94,773.8,281.42Z" />
      <path d="M785.5,275.64c1,.37.89.72.39-1.08-4.68-1.76-9.71-1.14-9.45-.75l.59.89A17.51,17.51,0,0,1,785.5,275.64Z" />
      <path d="M787.66,281c-.08-.32.72,0-7.24-2.51-1.33-.43-1.08-.31-.93-.07,1,1.5.41,1.11,2.74,1.86C788.74,282.38,788.07,282.53,787.66,281Z" />
      <path d="M784,285.6c5.39.71,6,4.16,5,.57a12.64,12.64,0,0,0-5.78-1.8C783.49,284.74,784,285.58,784,285.6Z" />
      <path d="M783.46,292.33c-1.2-2.07-.94-1.89-1.32-.6-.33.31-1.29,6.28-.69,6.84,2.28,2.12-.15.72,1.67-6.08Z" />
      <path d="M790.58,291.89c-.19-.71-2.66-1.19-3.52-1.26,1.4,2.38,1.19.64,3.88,2.62A10.26,10.26,0,0,0,790.58,291.89Z" />
      <path d="M561.58,114.77c.33-9.21-.36-8.91-1.65-10.15-.92-.87.61.18.59,6.83,0,9.24-.87,6.15,1.13,10.24A49.87,49.87,0,0,1,561.58,114.77Z" />
      <path d="M564.47,120.47c-1.14,6.17-.81,5.37.24,7.72.71,1.56-.54.21.84-7.3.63-3.43,1.28-6.88.4-10.27-.07-.3.24,0-2.21-2.34C566,112,565.16,116.68,564.47,120.47Z" />
      <path d="M595.06,133.58c5,.52,10.07,0,15,0,17.21,0,20,9.07,18.67,6-1.16-2.58-.87-2.28-2.52-3.37-6.28-4.11-13.63-4-21.05-3.73-13.91.43-14.45-1.44-13.72-.53C592.52,133.36,592,133.26,595.06,133.58Z" />
      <path d="M584.3,134.05c.07-.63.35-3.91-.11-4.45-2.72-3.17,1.08-.53-2.71,12.36-1.74,5.9-4.44,12-5.2,18.56,1.51,5.64.7,4.13,1.12.28C578.41,151.71,583.35,143.53,584.3,134.05Z" />
      <path d="M631.68,127.91c-3.48-2.38-1.81-1.82,0,2.91,2.14,5.49,2.22,10.15,2.77,11.41.45,1,.84,1.92,1.24,2.87C635.23,139.12,634.35,133.3,631.68,127.91Z" />
      <path d="M587.84,134c-2.91-3.56-1.05-2-.83,3,.45,10.21-5.49,19.08-8.32,28.74-.75,2.55-.67,1.69,0,5C580.72,158.22,590.16,147.09,587.84,134Z" />
      <path d="M541.34,152.13c-.07,2.21-.91.18,7.82,4.11a65.9,65.9,0,0,1,26.94,22.29c-.54-3-.21-2.38-2.14-4.73A66.79,66.79,0,0,0,541.34,152.13Z" />
      <path d="M581.56,186.38c.32,1.56-.24-.19,1.5-4.82,4.14-11,14-17.88,14.16-31.81,0-.75,0-3.66-.48-4.32-3.85-5.22,2.34,2.15-2.75,14.41-3.41,8.22-10.46,14.89-12.88,24C581.2,184.46,581.37,185.46,581.56,186.38Z" />
      <path d="M598.13,140.6c10.11,2.19,22-4.46,34.09,7.06-1.18-2.79-1-2.4-1.22-2.57-7.93-6.16-15.46-5.89-22-5.39C596.19,140.67,595.86,137.59,598.13,140.6Z" />
      <path d="M579.91,176.61c.36,2-1-.91,6-15.73,3.22-6.82,6.4-13.57,6.43-21.24l-1.2-1.53C592.1,153.19,578.25,167.57,579.91,176.61Z" />
      <path d="M602.55,153.53c-2.48-3.54-.28-1.74-1.63,4.84-2.47,12-12.73,20.32-15.23,32.47-.28,1.38-.94,6-.2,7.2a22.09,22.09,0,0,0,1.33,1.91C582.67,183,602.8,171,602.55,153.53Z" />
      <path d="M637.8,161.11c-.56-1.34-5-3.47-6.78-4.12-8-3-17.21-2.2-25-5.57.26.36.75,1.05,1.2,1.71,9.68,3.46,21,1.76,29.17,8.06C639.08,163.27,639.35,164.86,637.8,161.11Z" />
      <path d="M653.63,224.29l1.22.47a20.58,20.58,0,0,0-1.47-5.6C648.6,215.22,652.65,217.48,653.63,224.29Z" />
      <path d="M541.33,133.73c0,.6,0,.45,0,1s5.71,1.84,13.52,5.74c14.52,7.25,15.76,14.58,13.73,8.56-.37-1.13-4.49-4.27-5.63-5.08C556.47,139.32,548.79,136.4,541.33,133.73Z" />
      <path d="M585.1,124.5c8.27,2.18,17,1.57,24.54,2.47,14.77,1.77,17.27,8.2,13.92,2.87-12.23-6.78-26.68-2.8-39.88-7Z" />
      <path d="M574,152.66c.43,1.37-.65-.41,3.2-9.36,2.06-4.8,4.3-9.62,4.24-14.9,0-2.45-.06-2-1.68-3.8C582.83,135.25,572,146.23,574,152.66Z" />
      <path d="M601.48,145.11c1.11,1.52-1.38.72,15.09,3.37,6.65,1.07,13.38,2.38,18.73,6.57,0,0-.56-1.37-.7-1.69C632.75,148.87,601.32,144.89,601.48,145.11Z" />
      <path d="M591.15,204.64c3.06,2.87-2.77-.29,8.79-21.87,3-5.54,5.94-11.11,7.18-17.34.13-.63.78-4.12.38-4.72-2.59-3.77,1.55.51-4.89,14.31C598.35,184.14,591.4,193.67,591.15,204.64Z" />
      <path d="M640.92,168.69c-7.77-5.05-17.59-4.54-26.15-7.68-3.44-1.26-3.61-2.2-1.7.57,5.38,2.28,11.42,2.89,16.6,4C642.45,168.5,642.87,173.46,640.92,168.69Z" />
      <path d="M661.23,215.29c.81.53.92,1.06-.7-1.69a18.4,18.4,0,0,0-4.91-1.14c-5.07-.7-5.49-1.71-4.55-.81.55.53,1.28,1.21,1.56,1.47C655.53,213.76,658.71,213.65,661.23,215.29Z" />
      <path d="M663.77,218.75l-.23-.35A.57.57,0,0,0,663.77,218.75Z" />
      <path d="M445.8,305c.26-2.33.63-2.5-1.33,2-2.2,28,12.23,53.55,24.76,75.72,20.33,36,15.24,33.06,18.13,33.06C472.84,380,441.28,345.71,445.8,305Z" />
      <path d="M448.18,297.89c-.59,1.58-.85,2.21-1,2.51,3.12,38.21,18.28,61.2,30.44,83.48,17.93,32.85,11.79,31.91,15.47,31.91C481.46,377.31,451.81,353.51,448.18,297.89Z" />
      <path d="M481.23,376.1c-15.08-28.34-19.93-29.31-27-71.83-3.23-19.47-2-18.18-3.17-14.6-.24.72,4.15,36.84,12.35,55.71,9,20.72,23.5,36,31.44,70.41h1.18A160.67,160.67,0,0,0,481.23,376.1Z" />
      <path d="M455.71,276.78c-.07-4.88.4-5-1.13.93,1.5,71.16,40.88,96.22,44.64,138.08h1.16C496.63,373.38,456.8,348.17,455.71,276.78Z" />
      <path d="M498.89,383.86c-11.43-32.06-28.37-45.53-36.15-90.78-5.18-30.17-3.12-39.24-4.8-30.61-.16.81,2.2,34,10.41,58.17,9.6,28.22,29.49,51.88,34,81C502.56,397.59,503.16,395.84,498.89,383.86Z" />
      <path d="M501.57,379.44a18.29,18.29,0,0,0-1.16-4.29C501.43,379.11,502.13,384.12,501.57,379.44Z" />
      <path d="M494.41,358.7c-4.16-11.37-3.07-9-9.94-21-21.18-36.89-18.73-42.25-22.14-84.19C461.28,316.18,477,320.92,494.41,358.7Z" />
      <path d="M434.6,160.75c-6-23.24,2.19-31.63-2.28-26.43-.61.7-4.17,16.8,5.62,39.53a168.68,168.68,0,0,0,11.37,21.21C446.83,186.42,439,177.77,434.6,160.75Z" />
      <path d="M462.37,191c0-.39.07-1.28.07-1.3-2.44,2.23-6.18,2.91-9,4.67l.34,1.14C456.13,193.93,462.31,192,462.37,191Z" />
      <path d="M462,198.12c0-.3.06-1.23.06-1.25a14.92,14.92,0,0,1-3,1.82c-4.43,2.27-3.7,2.42-3.34,3.72C457.59,200.15,462,199,462,198.12Z" />
      <path d="M428.36,138.7s-.8.83-1,1c-1.54,1.57,1.88,28.1,13.27,45.7a121.16,121.16,0,0,1,7.33,13c4.73,7.66,4.15,6.83,3.87,5.82C449.43,195.32,427.46,169.87,428.36,138.7Z" />
      <path d="M423.15,143.81c-.73,7.1,1.05,12.92,3.31,19,2.91,7.73,2.51,7.09,3.15,7.85,3.22,3.8,2.55,4.37-1.29-6.15-1.94-5.33-3.6-9.19-4.07-15C423.67,142.11,425.71,141.46,423.15,143.81Z" />
      <path d="M418.07,148.18c-.8,4,1.1,7.47,3.15,11,3.85,6.63.95,3.14,4.19,6.69-1.12-5.77-6.31-10.1-6.37-15.76C419,146.8,420.92,145.89,418.07,148.18Z" />
      <path d="M516.86,397.6c-11.82-14.27-10.7-13.92-10.65-13.06.17,3.33.3,1.68,16.15,21.6,8.84,11.11,6.13,9.65,8.54,9.65A220.67,220.67,0,0,0,516.86,397.6Z" />
      <path d="M514.15,413.12c-8.51-11.57-7.68-14.15-7.74-12.38-.08,2.06-1,2.38,6.83,13.07,1.74,2.38,1.07,2,2.86,2C515.44,414.86,514.91,414.15,514.15,413.12Z" />
      <path d="M506.34,389.44c.11,4.5-.93,3.55,11.12,18.77,6.66,8.42,4.12,7.58,6.66,7.58C517.51,404.77,509.68,399.09,506.34,389.44Z" />
      <path d="M505.67,409.48c-.34,2.64-.81,1.79,1.53,6.31C509.82,415.79,508,416.56,505.67,409.48Z" />
      <path d="M582.33,260.36c-.41-1.6-.26-1-.77-2.6-1.9,5.11,2.65,22.84,6.7,32.64,14.09,34.09,42.84,58.89,57.77,125.39h1.18C628.48,331.93,584.08,308.2,582.33,260.36Z" />
      <path d="M582.81,283.11c5.64,31.5,36.77,57.11,48.55,114,4.31,20.81,1.65,18.73,4.11,18.73C628.26,349.23,595.47,323.92,582.81,283.11Z" />
      <path d="M602.78,336.84c-3.33-5.31-2.79-4.63-2.29-3.3,4.82,12.78,14.7,19.29,18.32,48.17,1.37,10.93,1.84,22.14,2.56,33.05.09,1.3-.23,1,1.23,1C619.74,370.66,619.49,363.49,602.78,336.84Z" />
      <path d="M589.61,316.9c-.66-1.63-7.24-7.44-11.36-12.47-9-11-5.74-16.68-7.47-11.12,2.76,10.05,11.81,17.05,18,24.11C591.24,320.22,591.28,321.07,589.61,316.9Z" />
      <path d="M573.07,286c0,.06-.16.51-.41,1.33S578.19,299,586.46,309C583.56,301.79,579.9,300.17,573.07,286Z" />
      <path d="M648.21,370.35c2.64,6.64.4,1.83,2.63,5.86,6.68,12.06,5.53,26.19,5.69,39.58h1.15C657.51,401.23,659.07,383.14,648.21,370.35Z" />
      <path d="M655.82,376.79c-1-1.83-3.08-5.25-4.69-6.66-5.21-4.57-4.59-4.17-4.25-3.23,13,12.49,15.82,31.77,18.78,48.89h1.17C664.55,402.69,662.61,389,655.82,376.79Z" />
      <path d="M655.88,334.52a241.45,241.45,0,0,1,5.18-25.22l-.84-.62s-11.26,34-4,60.46C661.28,373.44,651.71,365.1,655.88,334.52Z" />
      <path d="M650.29,316.44c3.2-14,4.82-11.68,2.84-13.3a97.52,97.52,0,0,0-5,51.44c1.76,10.14,2.36,9.58,3.74,10.81A96.36,96.36,0,0,1,650.29,316.44Z" />
      <path d="M664.52,326.6c2.91-14.72,4.12-12,2.15-13.32a135.18,135.18,0,0,0-4.09,61c2.33,1.79,1,2.15,0-9.09A134.8,134.8,0,0,1,664.52,326.6Z" />
      <path d="M671.67,393.38c-.81-2.5-3.8-10.25-5.25-11.27-.73-.5-1.6-1.12-2.07-1.46,7,10.36,8.95,22.76,10.76,35.14h1.17C675.17,408.24,674,400.65,671.67,393.38Z" />
      <path d="M664.55,306.7c.31.21.24.19.42.19,17.87,0,30.06-17.83,64.63-17.83,12.28,0,12.41,1.92,10.85-.29-.42-.6-15.27-2.18-31,1.42-21.31,4.87-30.61,15.57-44.45,15.57C662.76,305.76,662.47,305.23,664.55,306.7Z" />
      <path d="M658,301.94c.84.64,10.9-2.46,12.12-2.87,20.27-6.75,40.71-20.89,62.8-19.16,1.14.09,1.19.43-.12-1.16-21.63-1.59-41.43,11.54-60.8,18.45C657.05,302.53,655.52,300.06,658,301.94Z" />
      <path d="M675,318.89l-.83-.56c-10.62,31.33-6.18,59.94-5.69,60.29,2.93,2-.81.8-.13-21.5C669.07,332.8,675.69,319.37,675,318.89Z" />
      <path d="M674.66,387.53c-5-3.11.39-3.7,8.06,28.26h1.19C681.68,406.52,679.7,396.18,674.66,387.53Z" />
      <path d="M682.82,392.27c4.39,7.51,7.76,15.51,11.57,23.52h1.27C685.05,393.57,686.13,394.08,682.82,392.27Z" />
      <path d="M693.83,398.46a16.71,16.71,0,0,0-1.39-1A67.22,67.22,0,0,0,699,415.79h1.31A65.87,65.87,0,0,1,693.83,398.46Z" />
      <path d="M581.46,295.18c-.48-1.65-4.47-6.65-6.19-16.2-.64,2-1.23,2,1,8a45.27,45.27,0,0,0,6.12,11.26C582.16,297.49,582,296.85,581.46,295.18Z" />
      <path d="M594.24,328.6c-1-2.54-11.07-10.49-15.21-14.46-12.24-11.72-8.74-19.27-10.34-14-.21.69.94,3.24,1.24,3.89,3.12,6.65,8.55,11.34,14.05,16C596.52,330.84,596.72,334.94,594.24,328.6Z" />
      <path d="M585.7,329.17c-4.8-4.29-9.7-8.69-13.57-13.89-6.42-8.62-4.52-11.57-5.71-7.56,7.14,17.19,27,25.41,35.38,41.38C600.12,344.11,600.9,342.73,585.7,329.17Z" />
      <path d="M603.42,354.07c-.41-1.3-7.53-8.49-17.39-16.16-5.37-4.18-11-8.55-15.3-14.13-7.27-9.42-4.92-14-6.33-9.08,5.86,17.13,23.66,23,38.22,39.92C604.53,356.83,604.56,357.7,603.42,354.07Z" />
      <path d="M601.9,358.46c-13.37-12.25-36.27-13.73-39.17-37.77,0,0-.42,1.54-.61,2.27-.38,1.42,1.87,6.87,2.86,8.78,9.68,18.58,34,18.32,42.09,35.33C606.15,363.33,606.31,362.51,601.9,358.46Z" />
      <path d="M609.23,377.26c-.19-1.07-6-6-7.85-7.52-14.78-11.51-31.82-12.67-38.1-27.7-4-9.66-1.57-17.78-3.58-9.81,3.55,30,29,22.92,49.85,46.82C609.55,379,609.35,377.92,609.23,377.26Z" />
      <path d="M611.44,393.12c0-.2-.13-1.43-.24-2.4-.64-6-24.35-17-36.67-27.66-20.41-17.74-13.82-35.26-16.2-25.21-.47,2,2,8.29,3.08,10.53C573.2,372.38,604.6,378.68,611.44,393.12Z" />
      <path d="M615.83,409.15a.47.47,0,0,0,0-.35C615.84,408.92,615.84,409,615.83,409.15Z" />
      <path d="M612,402.59c-13.34-14.87-39.33-18.26-51-40.4-6.3-12-3.9-19.59-5.43-11.86,4.34,21.19,18.95,29.79,33.93,38.09C614.31,402.16,612.08,406.8,612,402.59Z" />
      <path d="M570.08,385.71c-20-17-14.67-35.73-16.39-24.64-.27,1.77,1.83,7.21,3,9.55,12.84,26.36,48.16,31.87,54.74,42.86.05-.37.13-1.07.2-1.75C605.6,403.84,585.28,398.65,570.08,385.71Z" />
      <path d="M534.26,377.43c-6.27,1.18-14.41,2.67-18.84,9l.66.74c.48.52,1.59-4,10.3-6.77,5.49-1.74,11.36-2.06,16.85-4,.71-.25,4.69-1.75,4.75-2.41C548.26,371,549.88,374.49,534.26,377.43Z" />
      <path d="M552.08,374c-.31,3.29-.65,3.49,1.49,8.9,8,20.29,24.58,23.41,42.44,29.49,11.49,3.9,6.45,3.39,11.36,3.39C588.42,405.66,559.65,408.19,552.08,374Z" />
      <path d="M565.52,408.18c-16.59-10.82-14.23-26.78-14.41-16.27,0,1.24,4.06,15.21,25.78,23,2.94,1.06,1.88.84,6.42.84A64.93,64.93,0,0,1,565.52,408.18Z" />
      <path d="M551.19,402.62c3.68,5.67,9.28,9.95,15.34,13.06.28.15-.09.11,2.85.11C550,406.75,551,395.4,551.19,402.62Z" />
      <path d="M551.74,412c.19,2.18-.17,1.68,2.14,3.75C556.9,415.79,555.75,416.54,551.74,412Z" />
      <path d="M544,415.79c2.11,0,1.75.35,3.83-1.75C547.57,411.27,548.42,412.6,544,415.79Z" />
      <path d="M570.71,279.63c1.46-4.75,1-.75-6.47,4l-1.07,2C565.8,284.17,570.34,280.82,570.71,279.63Z" />
      <path d="M567.78,289.09c1.44-4.63.54.56-8.93,4.3-.08.14-.89,1.54-.91,1.56C562.78,293.29,567.51,290,567.78,289.09Z" />
      <path d="M547.55,304.29l.66-1.06a122,122,0,0,0-26.14-27.48c0,.06-.17,1-.17,1.07C521.77,277.58,534.73,285.58,547.55,304.29Z" />
      <path d="M564.25,300.62c1.63-5.39.12,1.24-10.52,4.43-2.7.81-1.86,0-3,1.88A28.26,28.26,0,0,0,564.25,300.62Z" />
      <path d="M552.52,313.29c9.67-3,9.24-4.22,9.65-5.65.36-1.24-.16,1.09-6.44,3.39-3.26,1.19-6.7,1.94-9.88,3.48C543.38,318.28,543.53,316.08,552.52,313.29Z" />
      <path d="M558.44,320.9c1.44-5.33,1,.45-7.84,2.57-4.14,1-8.49,1.12-12.54,2.61-2.23,3.21-1.78,1.13,4.86-.1C548.1,325,554.13,324.79,558.44,320.9Z" />
      <path d="M531.39,328.41l.6-.87c.29-.41-5.87-10.47-15.78-18.45,0,.11-.19,1.06-.19,1.08C515.9,310.83,524.1,316,531.39,328.41Z" />
      <path d="M487.4,330c1.07,2.55-.84.17,8.9-3.7,3.79-1.51,7.74-2.67,11.48-4.37,2.8-1.28,2.33-1,2.59-2.65C503.18,323.54,493.88,324.44,487.4,330Z" />
      <path d="M556.74,327.33c-9.26,8.29-18,5.24-24.16,6.54-1.83,2.56-1.82.72,5.73.72,4.93,0,9.07-.18,14.34-3C556.82,329.37,556.3,329,556.74,327.33Z" />
      <path d="M518.79,325c9,10.15,6.72,11.76,8.22,9.66.29-.41-5-8.85-12.52-15.63C514.11,321.28,513.66,319.21,518.79,325Z" />
      <path d="M554.78,335.18c-7.27,5.59-17.81,2-26.53,4.7-2.39,3.33-1.4.39,7.41,0,5-.22,10.2.23,15.08-1.37C554.9,337.17,554.43,336.65,554.78,335.18Z" />
      <path d="M517.6,348.93c-2.59-4-6.62-8.08-6.7-7.53-.12.82-.16,1.12-.17,1.15C518.26,349.5,516.14,351.5,517.6,348.93Z" />
      <path d="M552.83,343.63c1.21-5.5.83,2.68-11.14,2.68-6,0-12.2-1.68-18.1.53-1.65,2.64-1.34,1.55,1.38.75,8-2.37,16.28,1.91,24.35-1.52C550.13,345.73,552.64,344.48,552.83,343.63Z" />
      <path d="M537.27,360.54c-6.52.08-15.71-1.31-22.4,2.84-2.33,5.31-1.14-1.84,13.84-1.85,4.74,0,10.59.64,16.09-.63,1-.24,5-1.43,5.12-2.19C550.48,355.21,550.52,360.36,537.27,360.54Z" />
      <path d="M514,374.49c-.9.82-4.69,4.66-4.41,5.29a3.43,3.43,0,0,0,.57.87,32.13,32.13,0,0,1,24.72-13.76c4.43-.19,9.71.43,14.15-1.93,0-.08.1-.71.2-1.45C541.18,368.69,528.73,361,514,374.49Z" />
      <path d="M547.7,377.42c-5.85,8-17.62,4.68-26.15,15.81.06.06.72.79.78.87,7.83-10.43,18.37-7.65,25.23-14.75C547.56,379.32,547.68,377.69,547.7,377.42Z" />
      <path d="M525.35,397.45c1.74,1.94-.74.26,7.74-4.09,3.58-1.84,7.38-3.26,10.73-5.55,4.1-2.82,3.45-3.27,3.53-4.9C542.19,390,531.51,390.79,525.35,397.45Z" />
      <path d="M542.07,397.89c5.75-2.78,5-2.84,5-4.5-3,3.2-8.16,3.54-16,10.32C532.88,405.76,530.38,403.54,542.07,397.89Z" />
      <path d="M547.2,403.56c-.09-2.68.77-1.67-4.28,2.14-4.26,3.22-6.68,3.75-6.23,4.24.87.95.27.84,2.15-.21A40.17,40.17,0,0,0,547.2,403.56Z" />
      <path d="M404.38,370.66c-2.83,3.87-9.94,23.79-8.28,45.13h1.15C394.42,379.33,416,354.83,404.38,370.66Z" />
      <path d="M412.45,359.58a113.89,113.89,0,0,0-11.3,56.21c2,0,.71,1.44,1-9.47C403,376.48,416.13,356.09,412.45,359.58Z" />
      <path d="M399.41,377.36c-3.58,4.76-2.87,3.54-3.68,5.6a104.36,104.36,0,0,0-7.14,32.83c2.8,0-.51,1.18,3.42-16.7A101.93,101.93,0,0,1,399.41,377.36Z" />
      <path d="M398.62,371.66l.66-.89c.38-.52-4.7-11.1-16.37-14.85l-.34.9C382.33,357.47,393.36,359.37,398.62,371.66Z" />
      <path d="M392.92,379.24l.65-.84c.63-.83-7.63-12.8-14-13.59,0,.1-.4,1.09-.42,1.12C383.65,366.17,390.4,374,392.92,379.24Z" />
      <path d="M392.14,386.84c-3.88,5-3.16,3.79-3.86,5.44a83.54,83.54,0,0,0-6.19,23.51c2.16,0,.32,1.38,2.72-9.11A81.59,81.59,0,0,1,392.14,386.84Z" />
      <path d="M373,370.78c.22-.23,0,.24,1.12-2.78a230.78,230.78,0,0,0-22.31,25.93l.42,1.37A229.52,229.52,0,0,1,373,370.78Z" />
      <path d="M380.1,363.46c-.26.7-.24.61-.16.53A.5.5,0,0,0,380.1,363.46Z" />
      <path d="M383.6,397.5c-2.65,3.33-5.28,5.88-9,18.29h1.21C380.15,401.5,386.79,393.5,383.6,397.5Z" />
      <path d="M423.85,345.19c-2.32,3.64-1.55,1.25-3.36,5.58,7.37-5.88,8.28-17.74,16.37-22.14,0-.16.05-1,.07-1.33C429.89,330.64,427.45,339.54,423.85,345.19Z" />
      <path d="M425.84,349.34c12.63-15.94,11-13.12,10.94-15.31,0-.66-11.15,13.92-14.5,17.86-4.55,5.35-3.15,2.21-4.3,5.67C419.38,357.4,425,350.43,425.84,349.34Z" />
      <path d="M438.08,350c-.05-.38-.14-1-.17-1.23-6.74,3.83-8.66,11.65-17.64,18.56-6.66,5.12-5.87,2.51-6.51,5.36C429,365.36,430.44,354.75,438.08,350Z" />
      <path d="M410.12,398.27c-.31,4.87-1.82-.56,13.14-9.11,6.18-3.53,14.31-7.52,18.61-14.54-.59-3.47.54-1.46-3.95,3.19-6.28,6.52-15.09,9.43-22.28,14.87C414.43,393.59,410.2,397.12,410.12,398.27Z" />
      <path d="M421,397.54c7.22-4.28,17.18-7.21,22.26-15.06-.3-1.58,0-.2-.32-1.72-7,12.88-27.69,12.59-33.2,27.23,0,1.47,0,2.61,0,3.93C410.87,411.37,409.23,404.54,421,397.54Z" />
      <path d="M445.12,391.1c-.75-3.18.74-1-5.87,4.3-8.69,6.94-21.67,10.41-28,20.39h1.38C420.33,404.56,436.85,402.12,445.12,391.1Z" />
      <path d="M447.18,399.29c-.75-2.79.49-1.4-4.58,3.57-5.41,5.29-12.3,8.87-18.44,12.93,2.72,0,1.37.44,5.63-2.2C436.18,409.64,442.82,405.45,447.18,399.29Z" />
      <path d="M453.08,397.08c-2.91-1-2.6-1.56-2.08.42a38.3,38.3,0,0,0,18.11,2.79c-.26-.4-.42-.65-.69-1.1A37.28,37.28,0,0,1,453.08,397.08Z" />
      <path d="M449.4,407.09c-1-3.37,1.48.67-11.59,8.7H440C445.85,412,449.6,407.74,449.4,407.09Z" />
      <path d="M467,410.1a13.14,13.14,0,0,0,8.09-.49c-2.28-3.55,2.42,3.6-18.29-3.66-4.38-1.53-4-2-3.45-.05A96.7,96.7,0,0,0,467,410.1Z" />
      <path d="M482.26,413.54c.3.48.34.57.28.35A.64.64,0,0,0,482.26,413.54Z" />
      <path d="M449.16,415.79c1.81,0,1.37.31,2.57-1.19C451.11,412.67,451.71,413.08,449.16,415.79Z" />
      <path d="M478.41,414.85c-.33-.51-8.53,3-19.49-1.22-4.46-1.71-4.16-2.79-3.35-.23,5.9,3,5.18,2.39,23,2.39C479.06,415.67,479.07,415.88,478.41,414.85Z" />
      <path d="M415.1,367.24c.85-.08,7.31-6,10.68-9.83a79.65,79.65,0,0,0,8.33-11.73c2.81-4.24,3.17-2.1,3-4.58-5,2.81-5.08,9.07-16.27,19.86C414.85,366.71,415.86,364.34,415.1,367.24Z" />
      <path d="M445.82,352.14c-.32-1-1.88-2.09-3.78-5.89.22,2.28-.3,3.13,3.84,7.36C446.76,354.51,446.65,354.69,445.82,352.14Z" />
      <path d="M412.21,380.56c-.69,4-1.55,1.92,8.64-6.06,6.61-5.18,8.2-9,12.95-13.27,5.45-4.91,5.57-2.26,5.21-4.74-6.39,2.59-10.18,8.75-14.58,13.31C421,373.35,417.1,375.49,412.21,380.56Z" />
      <path d="M450.1,363.89c-.26-.65-3.59-2-6.77-6.39,0-.06-.08-.35.26,2.19a23.35,23.35,0,0,0,7.08,5.61C450.37,364.57,450.6,365.14,450.1,363.89Z" />
      <path d="M410.87,389.92c-.59,5.21-1.66-.28,12.39-9.46,5.88-3.85,12.83-7.76,17.28-13.92-.46-2.88.46-1.77-3.36,2.22-6.06,6.31-14.23,9.77-21.05,15.44C415.17,385,411,388.7,410.87,389.92Z" />
      <path d="M454,372.82c-.32-.71-4.46-1.22-9.34-6,.06.35.25,1.5.25,1.52.26,1.58,10,6.47,9.54,5.41Z" />
      <path d="M453.89,382.85c-7.41-2.46-7.55-6-6.78-2.21a23.32,23.32,0,0,0,13.06,4.47C458.86,382.7,460.1,384.91,453.89,382.85Z" />
      <path d="M464.25,392.34c-.36-.62-5.88.57-13.61-3.4-2.41-1.24-2.14-1.74-1.64.42a30.24,30.24,0,0,0,15.82,3.93S464.42,392.61,464.25,392.34Z" />
      <path d="M753.65,131l1,1.35a56.78,56.78,0,0,0-1.42-21.57l-1.32-.45A55.72,55.72,0,0,1,753.65,131Z" />
      <path d="M758.29,112.65c-3.7-1.42.88-.92-.28,10.23-.42,4.06-1.33,8.12-1.06,12.25,0,.5-.16.14,1.47,2.31C756.78,129.46,761.37,120.63,758.29,112.65Z" />
      <path d="M767.28,133.17c-1,16.12,1.36,17.3,3.75,19.87-6.12-10.74.16-24.78-3.67-36.59C764.17,115,768.37,115.12,767.28,133.17Z" />
      <path d="M795.41,155.05c-.17-8.39.33-18.56-3.68-27.08-3.11-1.33-1.11-1.41.74,5.38,2.78,10.24.94,21,2.5,31.63.22,1.5,1.31,7.25,2.11,7.67C800.62,174.52,795.8,173.88,795.41,155.05Z" />
      <path d="M797.93,130.57c-3-1.22-.68-1.36.82,7.67,1.89,11.34-.17,23.73,5.53,34.37,2.89,5.4,4.07,5.2,6.28,6C796.88,167.48,803.8,146,797.93,130.57Z" />
      <path d="M832.3,183.89c3,.42,1.84,1.22-2.88-5.69a65.48,65.48,0,0,1-11.37-39.7l-1.13-.43A66.51,66.51,0,0,0,832.3,183.89Z" />
      <path d="M863.57,185.16c4.22-.09-.63.84-9.76-11.36-10.47-14-10.39-26.11-11.14-26.37l-1-.34C844.78,171.2,862.69,185.18,863.57,185.16Z" />
      <path d="M881,184.06c.93-.09,1.8-.19,1.85-.2-9.81-6.13-19-13.49-23.17-24.37-2.9-7.64-.39-6.57-2.92-7.38C858,166.23,869,176.44,881,184.06Z" />
      <path d="M888.42,183.15c3.93-.57,2.49,0-1.51-1.92-10.77-5.26-16.77-15.66-21.59-26.4l-1.45-.45C869.19,166.43,876,178.22,888.42,183.15Z" />
      <path d="M899,181.28c-8-9-21.61-12.73-26-24.1l-1.36-.41c3.24,10,13.86,14.89,21.14,20.43C899.09,182,896.61,181.78,899,181.28Z" />
      <path d="M911.28,141.09c-.56-.49-6.36-.73-12.43,1.63-9.69,3.77-15,9.33-22.68,11.14,2.36.67,1.87,1.73,13.65-5.32C909,137.06,915.48,144.78,911.28,141.09Z" />
      <path d="M904.81,180c3.54-.85,1.51.6-8.73-6.63a76.55,76.55,0,0,1-14.94-13.86c-1.38-.36-1.81-.49-1.88-.51A77.73,77.73,0,0,0,904.81,180Z" />
      <path d="M912.7,177.92l1.29-.37c-7.86-7.48-18.06-7.88-22.14-15.56l-1.43-.3C894.21,170.41,904.86,170.8,912.7,177.92Z" />
      <path d="M921.77,175.25l1.23-.37c-5-5.42-13.26-4.52-18.4-9-2.2-1.93-.56-1.52-3.08-2C905.67,171.41,916.23,169.86,921.77,175.25Z" />
      <path d="M926.45,172.26c3.1.9,2.14.89,4.23.24.44-.14.49-.06-.28-.28-7.26-2.12-10.72-2.89-13.28-5.27-.82-.76-.11-.52-2.23-.83C917.44,169.88,922,171,926.45,172.26Z" />
      <path d="M740.25,113a22.6,22.6,0,0,0-.63-6.6c-.23-1,.06-.63-1.59-1.35,1.44,4.35.81,6.21,1.29,6.81Z" />
      <path d="M747.38,95.5c-2.36.56-1.84.17-2.9,1.72s-2.06,3.2-3.69,4.13c-.43.24-.68.05.94.76C744.31,100.43,745.22,97.47,747.38,95.5Z" />
      <path d="M745.41,115.42a28.19,28.19,0,0,0-.94-7.73c-2.77-1.06-.6-1.44-.27,6,.08,1.61.08,3.1.2,4.76C746.39,121.13,745.59,121.31,745.41,115.42Z" />
      <path d="M752.91,94.07c-1.6.44-3.19,6.53-6.08,9.1-1,.87-1.39.52.4,1.16,3.75-3,4.06-8.08,7.62-10.79Z" />
      <path d="M750.76,121c.15-3.86.28-7.89-1.3-11.44-3.37-1.18.14-.91.24,7.73,0,2.74-.24,5.42-.15,8.22C751.55,128.2,750.51,128,750.76,121Z" />
      <path d="M761.58,91.7c-3.07.84-5.61,9.84-9.32,13.25-1.12,1-1.48.63.29,1.28,5.06-4.17,5.61-12.2,11.41-14.89a.5.5,0,0,0,.28-.37Z" />
      <path d="M765.2,96.67c-7.82,13.13-11.2,10.08-8.15,11.26.84.33,4.37-3.41,6.06-5.79,1.47-2.08-1,1.48,7.33-11.45,1.32-2.07,1.88-1.9-.57-1.24C767.33,93.3,767.94,92.07,765.2,96.67Z" />
      <path d="M762.22,125.29c-1.3,9.23.22,17.46.92,18.36,4.32,5.57-2.08-1.56.15-17.72a37.49,37.49,0,0,0,.65-7.92c-.3-3.81-.89-3.45-2.18-4C763.45,117.51,762.76,121.5,762.22,125.29Z" />
      <path d="M776.26,87.74c-1.43.38-3.84,6.89-4.31,8.31-1.35,4.11-2.4,8.53-5,12.06s-3.65,2.31-1.49,3.23c7.51-6.2,6.07-17.48,12.52-24Z" />
      <path d="M784.9,85.65c-2,.42-4.81,7.22-5.53,9.14-6.82,18.34-12.44,17.18-9.4,18.54C779.18,103.11,779.68,95,783,89.51,785.89,84.81,789.27,84.73,784.9,85.65Z" />
      <path d="M794.41,84c-.86.11-7.38,7.68-12.6,19.08-6.57,14.32-9.66,11.18-6.77,12.53,3.4-2.91,5.51-7.06,7.39-11.13C792.2,83.26,799.08,83.45,794.41,84Z" />
      <path d="M780.11,161.49c.9.72,1.36,1.07,1.74,1.36-5.47-12.81,4.18-28-2.85-40.71-4.08-2-.75-1.33.84,5.48C782.46,138.8,776.5,150.12,780.11,161.49Z" />
      <path d="M797,87.32a82.76,82.76,0,0,0-8.33,13.38c-10,18.6-12.41,15.53-9.85,16.77,9.95-9.91,13-25.5,23.81-34.18C801,83.38,800.71,82.82,797,87.32Z" />
      <path d="M787.82,153.1c-.1-14.13-3.29-28.32-3.88-28.6-2.83-1.31.2-1.23,2.11,17.33,1.46,14.18-.64,19.68,2.64,25.87,4.74,3.09.91,1.28-.32-4.26C787.62,160.07,787.85,156.52,787.82,153.1Z" />
      <path d="M819.59,84.23c-1.13-.19-5.23,2.31-6.27,3-15.47,10.51-15.4,28.76-28.06,33.4l1.36.67c7.81-3.49,11-12,14.81-18.66C813.68,81.28,827.19,85.5,819.59,84.23Z" />
      <path d="M807.24,86.23c-4.08,4.23-7.35,9.23-10.52,14.06-14.22,21.71-17.61,17.28-14.34,18.92,13-9.75,19.08-28.84,30-35.85C810.64,83.23,810.62,82.73,807.24,86.23Z" />
      <path d="M816.68,98.93c-7.8,8.41-14.12,19.75-24.85,24.84l1.15.5c.78.33,5.69-3.4,7.29-4.81,9.1-8,14.67-19.37,24.52-26.18,9.06-6.26,14.11-3.61,9.37-5.25C833.06,87.64,825.1,89.84,816.68,98.93Z" />
      <path d="M816.09,180.41c4.13,1.23,1.25,1.21-2.64-6-9.34-17.43-4.35-39.33-6.86-40.32l-1-.41C807,142.51,803.88,165.57,816.09,180.41Z" />
      <path d="M853,96.3c-.86-.45-6.18,1.43-7.24,1.85C833.5,103,824.61,113,815.9,121.38c-9.81,9.43-10.43,7.86-8.21,8.74,14.66-11.32,27.13-30.58,46.7-33.06Z" />
      <path d="M862,101.42c-1-.61-6.12.78-7.92,1.43-9.88,3.56-19.07,12.61-38.08,25.9-5.1,3.56-6.08,2.25-3.33,3.32,2.55-1.17,3-1.52,21.73-15.45,9.11-6.76,18.39-14.59,29.13-14.25Z" />
      <path d="M868.05,105.29c-1.51-1-13.79,1.93-29.08,13-7.13,5.18-13.78,10.87-21.1,15.77,4.19,1.61,21.81-18.8,41.38-25.86C869.26,104.63,871.29,107.45,868.05,105.29Z" />
      <path d="M874.88,110.77c1.12-.06,1.33.26-.56-1.12-27.13,1.84-33.83,21.73-49.2,27.21l1.34.51c.9.34,5.8-2.88,7.13-3.84C844,125.93,852.7,112,874.88,110.77Z" />
      <path d="M830.37,151.13c-1.89-9.14.46-7.86-2-8.74-.38,13.23,6.32,25.16,14.41,35.66,6.11,7.93,5.62,7,7.22,7.09C841.51,174.9,833.1,164.32,830.37,151.13Z" />
      <path d="M880.48,114.31c-12.31-1-22.45,6.62-31.87,14-17.1,13.47-18.47,10.41-15.41,11.52,1.74.63,14.64-9.45,16.11-10.61,7.9-6.22,18.45-14.69,30.69-13.83C882.41,115.59,882.41,115.83,880.48,114.31Z" />
      <path d="M885.21,118.13c-.41-.34-14.68,2.59-28.34,11.7-3.16,2.11-6.21,4.43-9.33,6.59-9.38,6.5-10.54,4.81-7.55,5.86,12.38-5.95,21.56-18.41,46.16-23.36Z" />
      <path d="M890.2,122.4c-1.61-1.41-18,3.35-36.26,16.46-1.53,1.09-3.05,2.27-4.57,3.36-3.41,2.44-3.82,2-3.19,2.18,1.76.59.77,1,7-3.56,8.05-5.91,25.25-16.39,35.09-17.42C891.72,123.05,892.41,124.33,890.2,122.4Z" />
      <path d="M872.37,184.78c3.15-.2,2.19.69-3.39-4.11A57.79,57.79,0,0,1,850.41,150l-1.28-.42A58.85,58.85,0,0,0,872.37,184.78Z" />
      <path d="M893.59,125.41c-.61-.54-4.8.28-5.7.49-11,2.64-18.72,11.43-28.15,17-7.53,4.48-9.17,2.91-5.63,4.05,14.1-5.33,24-20.63,39.42-20.63C895,126.36,895.1,126.75,893.59,125.41Z" />
      <path d="M900,131.08c-.44-.39-3.26.34-3.7.46-5.12,1.42-8.71,4.22-18.21,9.23-18.21,9.61-18.88,7.85-16,8.77.75.24,14.28-6.27,24.25-12a53.81,53.81,0,0,1,8.84-4.45C901,131.05,902.07,133,900,131.08Z" />
      <path d="M905.69,136.16c-.43-.38-.9-.07-4.71.92-15.09,3.91-21.56,12.24-33.45,14.19,2.15.67,2,1.11,6.47-.61,11.37-4.33,13.3-8.95,30-13.21C907.07,136.65,907.48,137.74,905.69,136.16Z" />
      <path d="M915.49,144.79c-11.68,0-21.76,7-31.84,11.11,2.26.58,1.41.74,5-.93,7.83-3.65,15.68-8.17,24.58-8.92C917.31,145.7,917.61,146.64,915.49,144.79Z" />
      <path d="M921.47,149.71c-1-.76-7.81.74-10.59,1.56-5,1.49-9.92,3.65-14.94,5.12-5.43,1.59-6.94.87-2.37,1.81,9.36-2.2,18.36-7.54,27.91-7.54C923.08,150.66,923.17,151,921.47,149.71Z" />
      <path d="M925.4,152.72c-1.44-1.09-18.12,5-23.73,6.23-2.62.55-3.2.19,1,1,8-1.82,15.78-5.66,23.87-6.34C926.3,153.4,925.82,153.05,925.4,152.72Z" />
      <path d="M910.7,161.31c4.09.68.82.5,16.51-4.71,2.82-.94,3.08-.49,2.54-.84C928,154.66,930.64,154.12,910.7,161.31Z" />
      <path d="M933.91,158.79c1.55,0,2,.56-1-1.1-2.67.22-4.06.88-14.27,4.82,3,.4,1.75.56,6.49-1.27C928.4,160,931.15,158.78,933.91,158.79Z" />
      <path d="M466.94,58.64s-.13.61-.42,1.92C474,54,478.89,45.27,478.75,44.24c-.46-3.29.53-1.84-4,5.1A51.39,51.39,0,0,1,466.94,58.64Z" />
      <path d="M477.18,34.78c0-.07.08.15-.34-2a54.06,54.06,0,0,1-7.42,12.13c-.06.38-.33,2.16-.34,2.19A54.72,54.72,0,0,0,477.18,34.78Z" />
      <path d="M455.25,13.39c-1.12,2.06-1.11,0,1.4,10.06,2.9,11.58,5.42,23.42,6.1,35.28.86-4,1.16-3.37-.06-11.75C461,35.67,458.16,24.49,455.25,13.39Z" />
      <path d="M513.84,75.6c-9.44-8.29-19.71-14.51-29.1-31.7-3.51-6.42-3.15-7.88-2.15-1.61,7.28,14.3,15.28,21.54,24.72,29.32C523.8,85.2,521,86.43,521.73,83.88,522,83,514.64,76.3,513.84,75.6Z" />
      <path d="M461.83,62.82c-.24.18-.5.37-.37.78S461.58,63.9,461.83,62.82Z" />
      <path d="M518.44,92.32c-7-16.42-21-22.6-30.16-33.59-5-6-4.39-7.78-3.93-3.31,8.53,12.64,19,16.37,28,28.62C518.87,92.89,516.92,95.34,518.44,92.32Z" />
      <path d="M466.07,71.71c-3.28,2.2-2.58,1-3.22,3.24,11.21-5.79,17.56-16.6,17.43-17.89,0-.47-.19-1.81-.19-1.83A39.85,39.85,0,0,1,466.07,71.71Z" />
      <path d="M458.1,89.08l-.47,1.23A74.36,74.36,0,0,0,481.2,75.09c0-.58,0-1.22.05-1.65C469.26,85.27,458.41,88.26,458.1,89.08Z" />
      <path d="M472.62,89.13a47,47,0,0,1-17.38,7c-.06.16-.54,1.25-.55,1.27a48.47,48.47,0,0,0,26-13.26C481,80.34,481.48,83.34,472.62,89.13Z" />
      <path d="M449.73,107.56c-.76,1.43-.92,1.17,1,1.17a47.3,47.3,0,0,0,28.32-9.51s.17-1.17.23-1.64A46.06,46.06,0,0,1,449.73,107.56Z" />
      <path d="M445.35,115.35c-.23.43-.33.66-.45.92A44.69,44.69,0,0,0,478,106.16c.51-3,1.09-1.83-4.24,1.75C459.41,117.57,445.61,114.87,445.35,115.35Z" />
      <path d="M442,127c-.58,3-1.71-.08,14.33-4.22,7-1.81,14.51-3.58,20.19-8.65.68-3.37.92-1.79-3.07.94-7.57,5.19-16.95,5.87-25.55,9C447.13,124.38,442.11,126.32,442,127Z" />
      <path d="M481.09,112c-.06.36,0-.06-.24,1.3-.34,1.78,9.66,7.42,12.38,9.82,3.08,2.72,1.95,3.34,3.14,1.68C492.23,119.19,484.73,117.52,481.09,112Z" />
      <path d="M479,122.25c3.27,4.47,8.56,6.36,12.2,10.1l.53-.81c.54-.82-7.94-5.62-10.88-8.83C478.92,120.64,479.49,119.93,479,122.25Z" />
      <path d="M440.83,135.66c-.16,2.42-.68.81,5-1.85,8.33-3.87,18-4.5,26.11-9.77,3.33-2.15,2.89-2.26,3.31-4.15-8.69,8.92-23.74,8.48-33.34,14.73C440.69,135.44,440.85,135.39,440.83,135.66Z" />
      <path d="M475.69,136.41c-.25-.32-.11-.56-.54,1.14,2.58,2.9,6.73,3.44,9,6.41,0,0,.38-.69.51-.91.3-.54-2.42-2.28-3-2.62C479.55,139.24,477.16,138.32,475.69,136.41Z" />
      <path d="M452.48,144.12c6.43-1.78,13.28-3,18.7-7.18.15-.61.33-1.3.49-1.91-8.26,7.6-21.38,6.22-30.68,12.82C441.3,151.11,439.81,147.63,452.48,144.12Z" />
      <path d="M473.65,143.56c8.13,7.81,6.84,7.12,7.59,5.68C472.86,141.19,474.08,141.81,473.65,143.56Z" />
      <path d="M529.88,137.71c-12.09,5.79-26.22,4-38.87,8.64-.71.26-4.53,1.71-4.9,2.4-2.33,4.32-.88-1.31,16.51-4,10.89-1.7,25-2,34.61-10.44C537.14,130.89,538,133.81,529.88,137.71Z" />
      <path d="M453.5,149.79c4.56-1.26,9.34-2.26,13.61-4.49,2.66-1.38,2.22-1.19,2.71-3.11-8,6.28-19.85,5.29-28,11.53C442.34,156.91,440.5,153.39,453.5,149.79Z" />
      <path d="M472.1,150.11c-.18.8,3.82,4.48,5.16,5.52.71.56.45.72,1.09-.62C471.42,149.78,472.78,147.16,472.1,150.11Z" />
      <path d="M456.78,155.47c3.8-1.28,7.71-2.6,11-4.89.81-3.56,1.28-.86-7.44,2.43-4.22,1.6-8.72,2.74-12.75,4.93-.62.33-4.31,2.52-4.14,3.14C444.2,163.76,441.72,160.56,456.78,155.47Z" />
      <path d="M470.45,157.75a13.57,13.57,0,0,0,4.74,4.21c.07-.17.38-.87.39-.89.22-.52-2.53-1-4.8-4.93C470.55,157.24,470.46,157.73,470.45,157.75Z" />
      <path d="M445.21,167.21c.15.49-.41,0,3-1.24,6-2.24,12.6-3.69,18-7.55.67-3.42,1.16-1.06-6.34,2.2-5.07,2.19-12.84,4.14-14.81,5.56C444.91,166.22,444.89,166.09,445.21,167.21Z" />
      <path d="M469,165.34a12.8,12.8,0,0,0,3.26,3.75l.37-.94c.2-.52-1.79-1.15-3.29-4.73C469.3,163.82,469.15,164.64,469,165.34Z" />
      <path d="M447,173.17c.13.42-.43.22,2.83-1.44,5.87-3,10.65-3.22,15-6.07.49-3,1.21-.79-7.11,1.83-1.94.61-7.85,2.33-11,4.7C446.68,172.21,446.84,172.73,447,173.17Z" />
      <path d="M453.72,175.88c2.93-1.38,6-2.43,8.93-4,1.66-.88,1.34-.53,1.58-2.29-4.87,3.29-11.11,4.39-15.89,8.09C449,179.81,447.88,178.63,453.72,175.88Z" />
      <path d="M454.45,181.33a42.23,42.23,0,0,0,8.91-4.36c.26-2.64.94-1.33-5.18,1.59-2.53,1.2-8.64,3.14-8.33,4.17C450.47,184.74,449.24,183.25,454.45,181.33Z" />
      <path d="M462.82,183.63c.19-2.72.86-1.23-3.86.73-2.46,1-5,1.88-6.93,3.85-.49.52-.45.19.05,1.8C454.35,186.45,459.36,186.06,462.82,183.63Z" />
      <path d="M461.75,205.59c0-.27,0-1.25,0-1.27-1.46,1.26-3.13,1.94-4.49,4,.56,2.38.09,1.71,1.24.24S461.73,206.15,461.75,205.59Z" />
      <path d="M459.93,212.37c-1.8,1.47-1.54.91-1.29,2.33.07.38-.25.37,1.45-1s1.45-1.22,1.46-1.51C461.63,209.69,462.05,210.64,459.93,212.37Z" />
      <path d="M478.55,22c.23.95.65,2.72,1,4.33.31,1.36,9.44,21.48,21.86,33.42,8,7.69,16.39,11,22.09,18,.06-.24.11-.4.32-1.1.29-1-7.07-6.61-7.93-7.23C501.39,58.88,494.24,54.92,478.55,22Z" />
      <path d="M504.12,94.29c-6.41-6.49-14.5-7.19-19.11-13.24-.09,1.5-.71,1.76,3.09,4.44,6,4.26,13.58,5.37,19,14.61,3.66,6.17,1.87,7.52,3.39,5.33C510.89,104.92,508.7,98.93,504.12,94.29Z" />
      <path d="M483.75,94.47c2.72,3.35,6.45,5.53,10,7.57,14.71,8.52,10.38,12.35,12.35,9.65.41-.57-2.35-4.5-6.7-7.52-4-2.82-8.69-4.79-12.4-8S484.1,91.58,483.75,94.47Z" />
      <path d="M482.57,103.16c-.14,1-.13.9-.27,1.79,3.16,5,10.11,4.08,18.51,13.81l.7-.93C492.54,107.55,485.91,109.38,482.57,103.16Z" />
      <path d="M468.85,179c.79-2.61.52-.51-1.06-5.36C467.45,176.41,467.19,176,468.85,179Z" />
      <path d="M289.54,129.29c4.69-8.08,5.13-7.37,1.31-4.49a281.84,281.84,0,0,0-23.32,48.81C272,167.92,274.57,155.08,289.54,129.29Z" />
      <path d="M258.1,184.61a89.53,89.53,0,0,1,7.4-24.09c11.71-25.62,23.13-33.81,15.71-29.19-11.15,16.56-21.72,34.19-24.39,54.07a.69.69,0,0,0,.09.46Z" />
      <path d="M210.71,95.82c17.63,12.26,31,37,54.67,39.73,6.62-3.35-6.68,5.26-32.41-20.65C218.79,100.62,215.16,96.11,210.71,95.82Z" />
      <path d="M246.13,196c1.72-1.45.91-13.27,5.23-26.88,8.18-25.76,23.32-33.67,15.21-29.62C245.2,164.37,248,186.9,245,196.58,244.75,197.26,244.39,197.47,246.13,196Z" />
      <path d="M233.76,205.16c11.12-15.47,1.57-37.87,24-61.52-2.37,1.05-2.46.41-6.5,6.1C239.62,166.19,240.57,180.09,238,192,235,205.7,227.37,209.27,233.76,205.16Z" />
      <path d="M227.12,162.74c-18.48,32-14.41,48.3-23.22,56.62-1.63,1.54-2.69,1.94.83.5,8-3.27,5-31.18,30.32-67.43C233.35,153,233.44,151.8,227.12,162.74Z" />
      <path d="M215.17,169.18c-9.34,13.39-9.06,15.18-11.69,23.76-1.85,6.05-2.91,9.47-3.57,15.22-1.74,15.2-7,16.17-5.07,15.48l1.71-.62c2.95-4.16,3.86-9.49,4.44-14.35.87-7.17,1.95-9.86,4.2-17.39,5.44-18.17,13.63-22.21,19.51-35.65C221.69,156.5,225.67,154.11,215.17,169.18Z" />
      <path d="M195.72,182c-11.38,23.69-5.34,39.13-16.94,47l2.87-.89c9.39-9.37,4.5-22,14.13-43.46a85.55,85.55,0,0,1,18-26l-1.82.47C211,159.32,202,169,195.72,182Z" />
      <path d="M201.57,161.64c-1,.24-15.22,18.53-23.52,42.28-5.11,14.61-4.87,22.33-10.14,28.26,8.32-2.41,5.32-32.8,33.73-69.09C203.46,160.77,203.45,161.19,201.57,161.64Z" />
      <path d="M168.88,170.44c-2.06.67-6.83,6.6-9.26,10.83-11,19.13-10.1,47.79-28.84,60.46l2.35-.54c1.56-.37,6.08-5.49,8.63-9.59,9.1-14.65,9.87-32.49,17.71-47.65C166.59,170.17,176,168.14,168.88,170.44Z" />
      <path d="M152.74,176.34c-.94.39-3.22,34-30.48,67.35l1.52-.34c.55-.13,23.63-26.88,30-67.4C153.78,175.91,153.91,175.86,152.74,176.34Z" />
      <path d="M144.41,180c-15.57,19.34-13.83,48.95-32,65.79-.28.26-.66.29,2-.32,9.74-9.82,13.75-23.71,17.81-36.57,8.77-27.8,14.61-28.23,14.17-29.79C145.05,179.72,144.53,180,144.41,180Z" />
      <path d="M111.11,203.35c-7.48,9.66-22.92,23-23.33,48.64,1.34-.37,1.43-.31,1.17-.65.85-29.25,20.08-39.78,28.21-55.06C113.24,199.2,116,197,111.11,203.35Z" />
      <path d="M93,221.83c10.51-15.07,9.52-13.12,4.44-8.07C96.26,215,88,227.09,87.83,227.28c-5.86,8.55-11.66,18-12.12,28.37l1.19-.39C77.62,243.12,85.88,232,93,221.83Z" />
      <path d="M288.55,121.54c2.39-1.77,2-1.15-.42-1.15-15.49,0-27.34-14.91-37.5-17.27-4-.94-1.1-1.19,11.71,7.55C270.16,116,278.8,121.73,288.55,121.54Z" />
      <path d="M276.56,129.45c2.26-1.34,2-1.12,1.61-1.12-23.84,0-33.09-26.66-50.31-30.14C245.6,105.76,254.58,128.29,276.56,129.45Z" />
      <path d="M200.24,95.52c-6.94,0-.22-1.42,6.67,7.43,3.39,4.35,8.47,13.5,20.87,23.29a87.21,87.21,0,0,0,26.71,14.43l1.33-.58c1.14-.51-19.08-4.13-37.41-24.12C209.88,106.67,207.51,99.25,200.24,95.52Z" />
      <path d="M239.33,159.07c5.67-11.35,7.51-10.86,4.41-9.7-.61.23-10.59,15.26-14.31,35.86-1.6,8.85-2.51,22-11.61,28.52-1.47,1.05,2.51-1,3.37-1.43C232.46,200.51,226.25,185.27,239.33,159.07Z" />
      <path d="M186,96.24c-4.08.39-.19-.56,8.15,8.6,10.63,11.69,15.7,25.21,34.39,35,6.16,3.22,14.06,5.68,15,5.34l1.64-.63C209.59,137.27,204.9,108.61,186,96.24Z" />
      <path d="M174.55,97.76c-3.24.56-.57-.64,8,9.34,10.92,12.7,18.28,29.28,33.3,37.91,5.89,3.39,13.27,5.1,15.37,4.42,6.71-2.15-2.29,1.15-13.39-4.65C198.45,134.65,191.78,111.06,174.55,97.76Z" />
      <path d="M171.7,109c10.79,12.74,18.11,28.23,32.27,38.47,4.79,3.46,11.59,6.5,12.93,6.14l1.94-.52c-25.94-5.62-35.55-37.22-54.54-53.21C161.56,100.58,163.44,99.26,171.7,109Z" />
      <path d="M158.32,114.43C170.58,125.06,178,139.92,191,150c4.91,3.81,11.8,7.16,13.18,6.82l1.92-.48c-25.62-6.21-34.56-39.83-58.54-50.24C144.25,107.91,147.83,105.35,158.32,114.43Z" />
      <path d="M139.13,111.13c-3.77,2.43,3.47-2.83,20.22,19.18,7.84,10.3,16.47,24.71,30.33,30.17l2-.5c-18.43-6.1-26.79-27.66-40.44-40.75C145.8,114,139.67,110.78,139.13,111.13Z" />
      <path d="M190,164.46c-.67.17-15.42,15.59-20.83,39.71-2.36,10.49-2.26,22.39-8.77,30.15l1.54-.43c1-.28,3.7-5.64,5.06-11.27,2.51-10.41,2.42-34.85,24.7-58.59Z" />
      <path d="M128.71,118.65c-2.82,2.28,1.12-.83,8.61,7,8.58,9,13.19,29.24,39.41,38.18l1.7-.46c1.48-.4-14-2.65-26.46-18.33C144.69,135.93,140.61,122.39,128.71,118.65Z" />
      <path d="M176.25,168.18c-.76.22-8,11.31-13.34,32.13-9.38,36.77-20.51,38.69-14.84,37.26,1.06-.26,6.07-9.6,6.58-10.59,11-21.65,9.29-39.19,23-59.22Z" />
      <path d="M116.89,129.65l-.76.82c20.37,4,26.43,32.58,47.12,37.56,4.9-1.7,1.1.56-6.72-4-12.07-7.08-17.56-20.91-28.89-29.38C122.68,130.92,117.31,129.21,116.89,129.65Z" />
      <path d="M105.82,142.52c-2.58,3.31-1.25.76,3.09.76,8.51,0,13.75,7.79,20.12,13.59,10.71,9.74,23.62,15.14,24.39,14.83l1.32-.53C124.22,159.88,121.24,138.22,105.82,142.52Z" />
      <path d="M98.34,152.53c-1.62,2.23-1.39,1,2.72,1,17,0,26.95,18.85,43.41,22.05l1.91-.87C128.71,172.58,117.36,150,98.34,152.53Z" />
      <path d="M106.68,251.44c-4.1,1-2-.7,5.77,4.5,3.57,2.39,7,5.08,11.17,6.52,3.29,1.15,3.2.72,4.8-.24C120.33,262.05,114.12,254.35,106.68,251.44Z" />
      <path d="M92.57,160.71c-1.19,1.75-1.3,1.1,1.69,1.1,16.07,0,26.06,15,40.57,18.52,3.11-1.66,1.59.14-5.47-3.17C117.26,171.49,107.78,159.79,92.57,160.71Z" />
      <path d="M136.94,183.78c-.9.48-19.81,28.42-33.31,64.2l1.13-.27c.69-.16,5.22-15.31,18.17-39C137.67,181.72,141.51,181.35,136.94,183.78Z" />
      <path d="M85.12,172.31c-.29.47.08-.14-.66,1.11.74.88,1.76-1.38,7.41-1.38,12.54,0,21.15,12,33.85,13.49,4.19-2.57.84,2.15-15.54-7.76C104.85,174.54,94.43,167.93,85.12,172.31Z" />
      <path d="M113,209.18c-6.35,8.25-12.14,16.58-15.45,26.44-2.4,7.16-2.7,14.5-2.18,14.37l1-.24c-.18-24.47,22.26-42.61,33.56-62C126.27,189.93,131.29,185.43,113,209.18Z" />
      <path d="M79.62,182.34c-2,4-.3-.92,8-.92,10.78,0,19.91,8.9,31.24,8.59,4.41-3,1,1.86-15.66-5.36a65,65,0,0,0-9.61-3.59C86.21,179.17,80.14,181.29,79.62,182.34Z" />
      <path d="M74.37,194.37c-2.15,5.55-.89,0,5.27-1.71s11.76.48,17.76,2.24c6.28,1.84,12.16,2.12,13.41,1.12,4-3.21,1.92-.59-3.75-.59-7.92,0-14.91-4.53-22.82-4.53C79.77,190.9,75,192.8,74.37,194.37Z" />
      <path d="M67,216.94c-2.24,7.54-.62-1.68,10.21-5.18,6.54-2.11,16.72-.15,18.34-1.74,2.84-2.77,1.78-.42-5.77-.42C83.1,209.6,74.69,208.68,67,216.94Z" />
      <path d="M65.18,223.07c-1.22,4.06-1.07.55,6.29-1.65,4.5-1.34,8.64-1.47,16.46-3.8,3.3-3.34,2.75-1.21-9.68,1.14C73.64,219.62,69,220.4,65.18,223.07Z" />
      <path d="M77.29,228.76c3.44-3.73,2.09-.59-9,1.47-1.59.29-5.61,1.09-6.09,2.64s-.9,2.9-1.36,4.34c1.71,0-1.83-4.1,7.9-5.9C69.64,231.15,76.2,230,77.29,228.76Z" />
      <path d="M58.75,243.72c-1.22,3.73-1.35.58,8.68-3.65.21-.09-.08.18,1.6-1.86C64.71,239.8,59,242.89,58.75,243.72Z" />
      <path d="M162.2,238a49.6,49.6,0,0,0,11.59-.7c7.4-4,2.74-.63-7.68-.39Z" />
      <path d="M158.18,242.75a83.86,83.86,0,0,1-8.48-1.44c-3.46.88-4.06.45,3.33,1.83a43.09,43.09,0,0,0,8.38.92C165.67,241.74,164,243.32,158.18,242.75Z" />
      <path d="M139.27,243.89l-1.7.41a44.06,44.06,0,0,0,14.53,4.82C156.4,246.79,151.15,249.85,139.27,243.89Z" />
      <path d="M126.3,246.94l-1.35.31c6.71,6.61,16.22,7.78,17.27,7.21,3.42-1.84,1.8-.25-4-1.46A25,25,0,0,1,126.3,246.94Z" />
      <path d="M116.74,249.12l-2.1.48c6.86,2,11.67,8.88,19.22,9.45,2.83-1.61,2.34-1.1.95-1.1C127.93,258,123.06,251.74,116.74,249.12Z" />
      <path d="M92.21,255c-4.32,1.15-3.7.94-3,.94,11.32,0,20.76,9.84,32.16,10.64,2.07-1.36,1.84-1.1,1.42-1.1C112.12,265.52,103.11,256.56,92.21,255Z" />
      <path d="M79.69,258.81c-.69,0-.29-.13-3.52.95.34,1.14,2.19-.73,9.31.9,10.46,2.38,20,9.82,31.06,9.28,4.73-3.34.62,1.48-13.26-3.78C96.06,263.42,88.1,258.81,79.69,258.81Z" />
      <path d="M64,260.11c2.32-1,.42.28,2.57-5.51,3.18-8.55,9.94-15.24,14.77-22.83,3.38-5.31,2.65-4.43-.52-1,0,0-9.15,12.3-10.59,14.45C67.28,249.72,64.71,254.72,64,260.11Z" />
      <path d="M63,264.81c18.17-4.42,29.41,5.36,36.69,8.67,3.8,1.72,8.43,2.77,9.13,2.21,2.92-2.34.56.68-7.85-2.9-8-3.4-14.76-10.21-29-10.21C67.85,262.58,68.05,262.6,63,264.81Z" />
      <path d="M50.47,273.45a13.17,13.17,0,0,0-.86,1.67c-.65,1.52.9-2.38,8.34-2.9C76.13,271,89,281,103.88,279.86c1.22-1.07,1.39-1.21,1.53-1.32-14.83,2-27.26-8.31-46.69-7.51C56.13,271.13,51.37,272,50.47,273.45Z" />
      <path d="M44.38,289c-.21.66-.35,1.17-.5,1.67A36.19,36.19,0,0,1,63.69,282c12.4-1.33,22.52,4.9,34.11,3.54,0,0,.77-.76,1.17-1.15,1.13-1.09-1.18,2-17.86-1.76-5.43-1.21-10.68-2.34-16.48-1.87C53.37,281.66,44.68,288,44.38,289Z" />
      <path d="M41.76,299.61c-.18.86-.26,1.27-.32,1.55a36.23,36.23,0,0,1,18.77-8.78c13.75-2.09,19.9,4.5,28.83,2.53,2.65-3,1.59-.8-3.45-.8-8.16,0-18.46-7.3-35-.14C46.41,295.76,41.91,298.89,41.76,299.61Z" />
      <path d="M40.67,305.07c-.85,4.57-.06-2.82,12.74-2.82,9.49,0,19.47,4.49,29.05.53,4-5,1.12.48-9,.48-6.56,0-13.22-2.16-20-2.16C46,301.1,40.86,304.08,40.67,305.07Z" />
      <path d="M38.41,318.94c-.63,4.62-.26-.12,6-3.23,8.22-4,17.69-1.7,26.8-3.28.77-.13,4.48-.85,5-1.55,2.9-3.95,1.52.84-13.46,1C55.76,312,44.5,311.47,38.41,318.94Z" />
      <path d="M37.4,327.14c-.14,1.34-.13,1.27-.16,1.56,7.59-8.85,22.07-3.75,32.4-8.37,2.91-4.41,1.52.35-11,1.1-5.65.34-11.48.19-16.76,2.53C40.92,324.4,37.5,326.23,37.4,327.14Z" />
      <path d="M63.69,329.91c2.57-4.4,1.75-.44-8.54,1.48-5,.93-10.42,1.38-15,4.06-.85.5-3.72,2.46-3.78,3.38,0,.59-.11,1.76-.11,1.78C41.78,331.89,54.93,334.44,63.69,329.91Z" />
      <path d="M58.45,339.46c1.09-2.11,2.68-2.13-10.53,3.46-4.65,2-9.41,4-12.1,8.4,0,.07-.06,2.32-.08,2.92,1.71-5.58,7.75-8.18,12.57-10.23C59.4,339.28,58.26,339.83,58.45,339.46Z" />
      <path d="M34,379.59c2.47-3.95,5.45-5,5.77-5.7.21-.44.58-1.2.69-1.41-3,1.33-6.11,4.11-6.22,5.09C34.15,378.77,34.09,379.21,34,379.59Z" />
      <path d="M175.29,246.44a17.08,17.08,0,0,0-.33-5.18l-.88.48c-.51.28.62,1.86-.14,6.09C174.51,247.23,175.26,246.47,175.29,246.44Z" />
      <path d="M167.19,255.26a18.88,18.88,0,0,0-1.59-8.91l-.85.46c-.51.28,2.11,3.73,1.13,10Z" />
      <path d="M159.34,265a24.75,24.75,0,0,0-4.27-12.92l-1,.56C160.64,262.15,156.42,268.84,159.34,265Z" />
      <path d="M191.42,256.87c.14-.32.13-.28.71-1.51-11,4.25-32.26,15.37-38,24.17C152.05,282.66,164.88,267.38,191.42,256.87Z" />
      <path d="M153.18,273.56a24.81,24.81,0,0,0-6.55-16.9l-1,.58C155.78,268.16,149.66,278.72,153.18,273.56Z" />
      <path d="M144.92,286.58c1.06-1.8,1.8-1.73.88-7.79a33.79,33.79,0,0,0-8-17.31l-.86.48C136.36,262.27,146.25,270.86,144.92,286.58Z" />
      <path d="M137.76,285.92c2.55,11,.26,13.24,2.3,9.36.61-1.16-1-17.24-10-29.36l-.83.5C128.7,266.75,134.61,272.33,137.76,285.92Z" />
      <path d="M134.54,304a92.39,92.39,0,0,0-3-21c-1.36-5.11-2.94-9.53-8.34-12.74-2.2,1.46-.84-.36,3.23,4.22,5.4,6.08,7.23,25.32,7,34.51C134.78,306.09,134.62,306.85,134.54,304Z" />
      <path d="M127.55,303.4c2.22,13.88-2.14,19.69,1.2,14.16.67-1.11,1.62-12-2.31-23.32-3.67-10.55-10.83-18.68-11.37-18.27-1.75,1.3-.24-.43,5.23,8.18A50.76,50.76,0,0,1,127.55,303.4Z" />
      <path d="M121.91,327.61a65,65,0,0,0-13-46.71l-.73.62c-.59.51,9,9.67,12.16,28.6C123.15,327.31,118.1,332.65,121.91,327.61Z" />
      <path d="M113.17,338.06c1.25-1.36,1.69-.87,1.94-8.45a74.19,74.19,0,0,0-11.7-42.47c-.66-1-.33-1.05-1.4,0A72.91,72.91,0,0,1,113.17,338.06Z" />
      <path d="M100.23,348.76c.8-.52,2.21-6.86,2.4-7.85,3.08-16.18-1.4-32-6.67-47.63-1.38,1.49-1.08-.47,2.14,10.48C109.32,341.92,94.67,352.36,100.23,348.76Z" />
      <path d="M92.36,353.57c1.41-.83,1.63-.17,2.62-6.88a64.42,64.42,0,0,0-8.31-42.29c-.18-.3,0-.4-.79.56C85.39,305.57,100.05,325.38,92.36,353.57Z" />
      <path d="M70.81,366.21c1.48-.88,2-.45,3-5.36,2.11-10.55-1.28-20.76-1.14-31.25.11-7.74,2.5-10.29-.72-5.3C69.7,338.07,76.88,353.42,70.81,366.21Z" />
      <path d="M66.4,333.32c-2,3.4-1.7,2.79-1.84,4.68-.68,9.45,2,18.77-.19,28.07-1.3,5.64-3.2,6-.06,4.05C69.08,358.43,63.72,345.05,66.4,333.32Z" />
      <path d="M52.59,350c1.78-3,2.41-2.34-4.69,1-4.55,2.17-9.24,4.46-12.36,8.52,0,.41-.1,2-.11,2.22C39.05,355.55,46.86,353,52.59,350Z" />
      <path d="M56.73,350.92c-1.29,2.15-1.6,1.22-1.4,8.52.12,4.63.37,9.39-.71,13.94-1.18,5-3.22,5.76.24,3.1C58,368.21,55.7,359.14,56.73,350.92Z" />
      <path d="M41.41,388.84c.89-.9,1.29-6.6,1-9.83-.12-1.31.13-1.49-1.08,1.05C41.85,389.29,38,392.28,41.41,388.84Z" />
      <path d="M205.89,237c.15,16.06,8.78,25.07,11,28.71-1.3-4.14-1.08-3.59-1.27-3.85a41.33,41.33,0,0,1-8.48-22C206.72,234.17,207.84,233.07,205.89,237Z" />
      <path d="M202.42,231.61c2.38-.76,1.8-.22,2.78-2.07-8.71,2.62-7.78,2.53-10.41,4.69C193.34,235.42,194.34,234.19,202.42,231.61Z" />
      <path d="M178.4,249.14c-9.54,9.69.15-1.38,21.52-9.55.94-1.81.49-.94.8-1.53A117.81,117.81,0,0,0,178.4,249.14Z" />
      <path d="M161.21,283.52c25.69-16.9,24-12.62,25.7-16.56-21.23,11.26-40.31,25-40.87,26C143.83,297,143.19,295.38,161.21,283.52Z" />
      <path d="M228,309.25c-8.48-11.86-21.32-18.51-30.93-29.15-8.43-9.33-6.23-12.26-7.65-9,9.27,18.62,32.81,26.31,41.26,45.05C230.37,312.92,230.5,312.7,228,309.25Z" />
      <path d="M178.27,289.89c-17.85,5.64-28.25,23.64-44.66,31.51-.07.24-.47,1.45-.49,1.49,7.95-3.56,14.38-9.37,20.92-15.3C176.42,287.27,176.91,294.15,178.27,289.89Z" />
      <path d="M175.94,297.85c-40.05,31.41-40.39,32.49-45,32.22-.37,1.32-.45,1.16.37,1.16,5.07,0,6.24-1.83,44.13-31.55C175.78,298.44,175.93,297.88,175.94,297.85Z" />
      <path d="M164.29,352.6C148,355.66,140.37,377,123.86,377c-2.14,0-1.74-.49-1.89.84,0,.49,8.21,1.71,18.42-6.84,7-5.9,13.86-15,23.61-17.12C164.36,352.36,164.22,352.93,164.29,352.6Z" />
      <path d="M161.11,365c-14.1,1.88-22,18.45-36,18.45-2.79,0-3.62-.83-3.75.18-.14,1.44,5.91,1.15,9.42.05,9.19-2.87,15-10.79,23.22-15.11C161.33,364.73,160.34,367.78,161.11,365Z" />
      <path d="M157.7,376.32c-13.19,2.54-23.38,18.06-33.74,18.06-4,0-3.31-2.38-3.52.29,6.21,3.37,15.72-4.06,21-8.17C156.94,374.53,156.56,379.76,157.7,376.32Z" />
      <path d="M154.55,385c-11.27,4.1-20,19.1-31.16,19.1-4.15,0-3.63-2-3.88.38,10.38,4.43,22.49-10.66,30.36-15.81C154.62,385.57,153.53,387.55,154.55,385Z" />
      <path d="M149.87,395.31c-4.68,1.5-10.75,9.53-16.09,14-4.52,3.81-10.8,5.7-15,2.39-.12-.09-.07-.23-.23,1.25a10.16,10.16,0,0,0,6.9,1.51c6.72-.8,10.55-5.57,18.08-13.12C149.76,395.16,148.24,398.51,149.87,395.31Z" />
      <path d="M132.12,415.79c2.1,0,1.43.3,3-1.14,8.28-7.48,5.17-3,7.77-7.11C138.76,409.43,135.4,413,132.12,415.79Z" />
      <path d="M199.36,260.28c-2.4-7.11,0-10.11-2.29-5.44,1,13.46,13.2,22.53,27.73,36C222.82,284.41,204.16,274.5,199.36,260.28Z" />
      <path d="M198.79,271.93c-6.6-8.67-4.13-12.15-5.87-8.45,5.7,17.44,27.18,24.49,35.48,40.2C225.47,291.69,208.38,284.51,198.79,271.93Z" />
      <path d="M200.15,248.57c-.13.25-.43.85-.6,1.2a69.92,69.92,0,0,0,22.12,31s-.47-1.52-.62-2C203.34,263.85,200.74,247.36,200.15,248.57Z" />
      <path d="M202.13,244.57c2.47,12.66,13.12,21.73,18.52,32.92-1.89-6-1.28-5.08-5-10.34-4.89-6.94-11.44-15-12.66-24.34C202.92,243,202.61,243.61,202.13,244.57Z" />
      <path d="M162.49,267.58c-4.7,6.28,7.94-12.43,33.12-19.44l.68-1.38a75,75,0,0,0-29.9,15.82C164.84,264.49,163.7,266,162.49,267.58Z" />
      <path d="M230.5,340.3c.1-.61.18-1.21.3-2.11.17-1.27-2.78-6.41-3.84-8.05-9.21-14.15-24.33-22-35.08-34.69-8.77-10.34-6.59-14.1-7.93-10.18C194,307.62,223.42,316.86,230.5,340.3Z" />
      <path d="M228,351.27c-3.71-26.61-35.45-35.77-46.44-58.33,0-.1,0-.35-.5,1.56,11.8,22.53,44.81,32.57,46,59.47C227.56,352.74,227.84,351.86,228,351.27Z" />
      <path d="M221.94,366l.85-1.67c.63-1.28-1.36-8.08-2.58-11-6.67-16.22-21.07-26.81-32-39-10.68-11.87-8.59-14.26-9.46-10.56C191.48,324.72,220.9,339.73,221.94,366Z" />
      <path d="M172.53,312.59c.06-.3.09-.41.27-1.3-18.4,5.79-26,32.48-45.47,33.55-.15.7-.12.55-.25,1.17C147.32,345.15,154.78,318.75,172.53,312.59Z" />
      <path d="M215.24,377c0-.06.52-.74.88-1.28.48-.7-.64-7.28-1.93-11.63-6.83-23-22.93-30-31.11-37.2-8.59-7.54-6.88-11.67-7.75-7.46,6.75,12.07,19.72,14.26,30.07,29.05A54.65,54.65,0,0,1,215.24,377Z" />
      <path d="M208.63,385.38c.26-.29.31-.34,1-1.13-2.68-15.45-9.34-31.45-22.21-40.64-2.36-1.69-4.39-2.71-10.31-10.16-4.36-5.49-3.58-5.09-3.92-3.41-.11.52,5.81,7.54,6.65,8.47,5.08,5.63,6.18,4.91,10.93,9.3C200.94,357.25,206.37,371.55,208.63,385.38Z" />
      <path d="M169.45,327.81c-19.72,4.31-25.1,24.61-41.39,24.61-2.7,0-2.15-.63-2.43.88.3.27,8.36,2.06,19-7.26,7.43-6.49,12.68-14.2,24.54-17Z" />
      <path d="M166.86,340.83c-16.3,4.22-24.92,25.45-41.7,26-1.54,0-1.75-.23-1.87.64a.56.56,0,0,0,.55.51c9.81.34,17.81-6.8,24.77-13.45C165.79,338.12,166.06,344.72,166.86,340.83Z" />
      <path d="M202.37,392.14c.63-22.32-23.32-37.42-31.82-49.41-.11.52-.19.9-.27,1.26-.16.77,5.24,6.53,6,7.33,6.24,6.38,18.43,16.24,23.18,30.13C203.13,392.08,199.42,395.11,202.37,392.14Z" />
      <path d="M192.15,401.53l.91-.77c.57-.48-.9-7.39-1.3-9-4.13-16-13.1-22.46-19.15-31.17-5.31-7.65-3.6-10.75-4.65-6.2C173.18,368.4,190.49,375.51,192.15,401.53Z" />
      <path d="M182.24,409.14l1-.73c.43-.31,3-21.65-18.57-40.84-.57,2.07-.8.25,4.31,5.89C177.84,383.22,183.48,395.81,182.24,409.14Z" />
      <path d="M162.34,375.72c-.11-.15,0-.26-.53,1.24a70.46,70.46,0,0,1,12.32,30.14c1.19,7.53,0,7.39,1.85,6.23A71.84,71.84,0,0,0,162.34,375.72Z" />
      <path d="M156.71,390.09c-.28.65-.14.32-.47,1.07,7.11,4.25,10.24,12.39,11,20.56.46,4.88-.31,4.07,1.39,4.07C168.4,405.62,165.57,395.27,156.71,390.09Z" />
      <path d="M151.92,400c-1.28,2.42.28-.06,5.24,8.85,4.29,7.68,1.69,6.91,4.2,6.91C158.77,407.35,153.07,400.76,151.92,400Z" />
      <path d="M148,407c-1.36,2.27-.11,0,3,8,.39,1,0,.8,1.53.8A35.39,35.39,0,0,0,148,407Z" />
      <path d="M285.44,283.8c5.86-12.72,5.65-9.55,5.06-12.57-.12-.62.35-.9-2.48,4.76-.66,1.31-1.28,2.51-1.95,3.87C285.11,285,285,284.85,285.44,283.8Z" />
      <path d="M300.12,283.38c.11.14.33.7-1.35-3.28-.85-.88-1.76-1.75-2.83-2.66C296.58,280.74,295.75,278,300.12,283.38Z" />
      <path d="M283.63,291.29c-2.12,8.86-1.53,3.13,4.35-5.22,4.56-6.49,4.48-4.66,4-7.42C290.42,281.41,284.38,288.16,283.63,291.29Z" />
      <path d="M281.34,300.31c-1.61,6-1.21,1.6,5.7-5.09.78-.76,6.76-6.19,6.57-7.34-.35-2.08-.06-1.62-1.08-.13C290.07,291.36,282,297.69,281.34,300.31Z" />
      <path d="M308.54,230.83c6.74-14.22,16.79-26.76,26.78-38.83-.73.4-1.43.8-2.55,1.44-.94.54-10.27,12.78-11.28,14.13-9.51,12.88-17.06,26.08-20.55,41.63-2,9-2.34,19.46-1.46,21.57C304.22,282.13,293.72,262.08,308.54,230.83Z" />
      <path d="M335.58,187.29c1.45-.79,18.44-58.11,18-60-2.2,2.35-1-.13-4.48,13C335.67,190.26,331.92,189.26,335.58,187.29Z" />
      <path d="M301.92,291.57c3.71,3.7,4.22,6,1.79.19a39.35,39.35,0,0,0-6.2-5.39C298,289.28,297.09,286.75,301.92,291.57Z" />
      <path d="M277.14,314.48c-1.39,4.16-1.48,3.42.31,1.38,5.55-6.33,13.67-10.57,17.53-18.43-.62-5.14,1.16-.9-6.5,6.71C285,307.65,280.7,310.61,277.14,314.48Z" />
      <path d="M271.75,328.77c-.4.94-.31.75-.7,1.65,15.49-10.48,24.73-24.81,24.67-25.65-.28-3.42.76-1.26-7.42,8.51C278.82,324.62,272.24,327.59,271.75,328.77Z" />
      <path d="M266.64,339.93l-.65,1.3c20.09-7.89,30.28-24.82,30.26-25.8-.09-4.72.76.15-9.32,10.49C276.52,336.59,267.06,339.08,266.64,339.93Z" />
      <path d="M296.39,326.85c0-4.06.91-.22-10.51,9.87a73.47,73.47,0,0,1-25.4,14.66c-1,1.63-.93,1.52-.69,1.44C283.36,345.38,296.39,327.56,296.39,326.85Z" />
      <path d="M295,349.05c.37-3.28,1.39-.34-15.93,8.9-17.78,9.49-30,10.94-30.59,11.76l-.88,1.19C275,364.69,294.92,349.69,295,349.05Z" />
      <path d="M293.88,357.54c.21-1.37.19-1.28.23-1.54-23.35,22.45-50.59,20.19-51.15,20.88l-.77,1C271.13,378.06,293.6,359.38,293.88,357.54Z" />
      <path d="M291.56,370.27c1.3-6,.23,3.8-16.75,7.7-12.74,2.91-33.92,1.28-41.21,10.11.87.77,2.45-2.58,12.43-5,13.07-3.17,27.32-1.06,39.42-7.89C287.24,374.19,291.32,371.39,291.56,370.27Z" />
      <path d="M288.67,381.78c1.4-4.85.32,3.19-22.09,6.54-12.21,1.83-26.38,1.43-38.46,6.52-3.16,4-2.13,1.11,7.94-1.39,14.15-3.5,29.17-2.07,43.24-6.91C283,385.27,288.43,382.61,288.67,381.78Z" />
      <path d="M299.09,298.18c.08.71,5.92,2.79,11.77,10.62l-1.31-3.12c-.71-1.71-6-6.2-10.59-8.67C299,297.24,299.09,298.16,299.09,298.18Z" />
      <path d="M314.44,319.82c-.37-1.4-6.06-8.9-14.63-14,0,0,.07.91.09,1.18.05.66,3.06,1.61,8.23,6.77C315.34,320.94,316.11,326.23,314.44,319.82Z" />
      <path d="M300.28,313.28s0,.91.06,1.47c17.47,13.23,20.28,31.38,17.29,18.4A60.46,60.46,0,0,0,300.28,313.28Z" />
      <path d="M320.19,347c-.16-1.14-7-12.68-19.63-20.88,0,3.18.28-.1,9.45,9.17C320.85,346.27,321.18,353.92,320.19,347Z" />
      <path d="M296,338c.23-3.23.9-1.46-5.84,4.66-12.35,11.21-23.48,12.34-30.72,15.26-4.65,1.88-4.26,2.17-6.17,5,6.42-5.89,15.27-5.17,27-11.7C288.74,346.42,295.94,339,296,338Z" />
      <path d="M321.6,359.57c-.17-2-7.42-15.53-21.36-22.78-.12,2-.68.51,4.25,3.9C322,352.74,322.45,369.6,321.6,359.57Z" />
      <path d="M311.06,357.64c11.53,10.57,11.65,20.05,11.27,12.83a49,49,0,0,0-23.16-22.05c0,.19,0,.13-.12,1C299,350,304.29,351.45,311.06,357.64Z" />
      <path d="M322.74,382.86A59.22,59.22,0,0,0,297.87,358c0,.21,0,0-.19,1.21C321.35,371.73,322.83,391.43,322.74,382.86Z" />
      <path d="M295.9,369.24s0,.27-.23,1.15A47.87,47.87,0,0,1,318.83,392c3.22,6.14,2.39,7.71,3,3.74A49,49,0,0,0,295.9,369.24Z" />
      <path d="M292.92,381.62s-.23.81-.32,1.11a37.39,37.39,0,0,1,23.34,20.13c3.23,6.93,1.85,9.09,3.09,5A38.58,38.58,0,0,0,292.92,381.62Z" />
      <path d="M260.19,398c-13,1.25-29.23.88-41.51,8.85-1.27,1.61-1.78,2.26-2.67,3.43,18.45-15.78,48.25-5.65,69.19-17.89C287.15,387.06,285.89,395.49,260.19,398Z" />
      <path d="M290.68,388.92c-.05.16,0,.11-.37,1.12a62.54,62.54,0,0,1,21.45,21.17c3.26,5.33,1.85,4.58,3.82,4.58A63.58,63.58,0,0,0,290.68,388.92Z" />
      <path d="M286.92,399.15l-.45,1.09a51.73,51.73,0,0,1,10.6,9.21c6.21,7.05,3.48,6.34,6.16,6.34A53.81,53.81,0,0,0,286.92,399.15Z" />
      <path d="M258.37,409.08c-14.8,1.38-31.34-1.05-45.6,5.65l-.72,1.06c2.54,0,2.93-1.45,10.64-3.16,16.36-3.61,33.6.34,49.69-5.24,1.7-.59,7.91-3.13,8.43-4.29C283.27,397.64,280,407.07,258.37,409.08Z" />
      <path d="M270.23,415.79c2.81,0,2.29.35,5-1.36C277.44,410.29,276.81,412.84,270.23,415.79Z" />
      <path d="M282.35,409.35c-1,2.13-.47-.13,5.07,6.44h1.49A38.44,38.44,0,0,0,282.35,409.35Z" />
      <path d="M332,279c-1.26,3.52-2.13,3.63-2.52,23.36-1,49.39,9.6,78.56,12.91,98.5,2.75,16.55.15,14.9,2.64,14.9C344.39,385.78,325.06,350.51,332,279Z" />
      <path d="M326.73,318.49c-.93-22.82,2.67-33.79-.93-19.88-2.78,39,10,78.06,6.43,117.18h1.14C336.35,383.4,328.05,350.94,326.73,318.49Z" />
      <path d="M210.88,390c1.52-4.26-6.8,6.88-12.34,25.82,1.92,0,.48,1.15,3.73-7.58C206.55,396.69,210.45,391.18,210.88,390Z" />
      <path d="M206.25,393.9c-2.69,2.78-7.19,5.77-13.48,21.89H194A94,94,0,0,1,206.25,393.9Z" />
      <path d="M194.89,404.47c-3,2.5-4.08,2.48-8,11.32h1.26A55.73,55.73,0,0,1,194.89,404.47Z" />
      <path d="M183.61,413.13c-.38.27-.31,0-1.79,2.66,1.71,0,1.09.33,2.27-1.61C186.08,410.92,186.62,411,183.61,413.13Z" />
      <path d="M235.1,371.3c40-60,37.06-76.47,42.53-145.15,2.81-35.3,5.52-35.14,7.59-52-2.87,9.07-6,22.37-8.1,44.54-6.32,67.43-.34,87.29-39.56,146.81-2.35,3.57-26.93,39.87-27.8,44.2C208.63,415.44,208.14,411.71,235.1,371.3Z" />
      <path d="M271.6,212.21c6.36-33.48,13.74-39.1,15-47.58-3.68,6.55-2.68,6.29-9.93,23.43-6.88,16.29-10.62,55.11-12.09,68.75-4.15,38.67-8.6,62.48-29,102.24-16.67,32.57-22.84,37.49-24.8,45.72C275.58,298.31,258.46,281.4,271.6,212.21Z" />
      <path d="M221.35,375.19a20.22,20.22,0,0,0-2.51,5C225.4,368.27,224,371,221.35,375.19Z" />
      <path d="M261.88,218.71l-2.08,3.7c-10.5,47.19.6,70.8-28,130.45-2.75,8.82-8.91,20.75-.64,3.95C263.18,291.73,249.79,265.94,261.88,218.71Z" />
      <path d="M235.37,331.89a319.34,319.34,0,0,0,12.13-81.12c-2.57,7.59-.21,2.13-2.85,27.15C241.12,311.43,235.8,320.82,235.37,331.89Z" />
      <path d="M182,279.15c.18-.48.35-.91.52-1.39-22,12-43.38,29.16-44.07,30.8-.4.93-.52,1.22-.65,1.55a.56.56,0,0,0,.48-.14C162.4,288.91,181.66,280.08,182,279.15Z" />
      <path d="M231.06,321.83A99.05,99.05,0,0,0,217,305.61c-17.08-16.47-22.81-16.23-30.45-27.43l-.41,1.07c-.28.72,4.77,6.23,7.9,8.86,2.2,1.85,26,18,37.13,36C231.14,323.36,231.1,322.6,231.06,321.83Z" />
      <path d="M279,164.92c-5,7.1-.6,1.15-9.87,21.48-15.16,33.24-33.71,59.63-36.37,102.75-.78-49,26.89-78.23,29.34-102.93-3,3.31,0,4.21-7.38,20.71-6.82,15.29-24.52,46.9-23.05,84.95C225,250,248.49,227.42,248.19,199.49c-2.81,2.34.57,2.35-3.93,18.1-4.83,16.93-15.23,35.22-14.94,60.75.1,9.18,2.24,18.5-.87,4.68-2.15-9.53-3.17-19.18-1.37-28.73,2.87-15.21,12.42-30,10.69-45.94-.12-1.15.16-1.11-1.19-.22,2.12,18.26-10.65,34-11.52,53.77-.57,12.83,3.07,25.74,6.84,38,0,.19.42-4.45,1.82-11.09.6-2.89.49-7,2.18-16.64C243.38,229.58,265.93,203.64,279,164.92Z" />
      <path d="M229.8,213.6c.83-2.1,1-1.86-.67-.89-.75.42-9.73,22.64-9.16,49.45C224.1,275.64,215.64,249.51,229.8,213.6Z" />
      <path d="M220.32,223.72c1.77-8.33,2.9-7.83.7-6.77-.64.31-4.14,12.36-4.36,33.73C219.62,262.53,215.68,245.53,220.32,223.72Z" />
      <path d="M776.71,222.09l-1-.81c-2.52,9.68-1.55,19.29-.82,20.62C779.16,249.58,772.45,239.24,776.71,222.09Z" />
      <path d="M749.38,275.33c2.48,1.38-.32,1,3-9,1.57-4.78,3.68-9.6,4-14.65l-1.11-1.61C755.37,258.49,749.51,266.6,749.38,275.33Z" />
      <path d="M773.27,246.91c-.41-.75-4.84-2.32-9.15-2.27a53.66,53.66,0,0,0-7,.93l.61.88c.33.47,3-.66,6.57-.66C773.26,245.79,775.49,251,773.27,246.91Z" />
      <path d="M744,277c-10.36-2-11.31-4.67-8.49-1.13a55.68,55.68,0,0,0,11.42,2.77Z" />
      <path d="M756,285.25c1.86-.4,1.79-.16.1-1.21-11.93,2.76-15.7-2.05-13.12,1.43A33.06,33.06,0,0,0,756,285.25Z" />
      <path d="M748.31,293.26c.34.53,7.58-4.6,18.7-1.74-2.44-1.89-2-1.67-3.83-1.86a27.85,27.85,0,0,0-15.39,2.79Z" />
      <path d="M763.43,295c9.29,0,14.69,5.73,8.54.56-1.63-1.38-11.82-3.75-21.19,1.71l.49.83C751.59,298.61,755.45,295,763.43,295Z" />
      <path d="M758.44,311.74c.22.48,11.71-7.32,26.86-1.3,5,2,6.67,4.37,2.59-.11a33,33,0,0,0-29.86.54C758.32,311.49,758.17,311.18,758.44,311.74Z" />
      <path d="M795.57,319.55c-1.06-1.37-17.34-8.05-34.52-1.94.72,1.72-.32.93,5.38-.35a45.15,45.15,0,0,1,24,1.27C797.24,320.8,798,322.74,795.57,319.55Z" />
      <path d="M800.9,327.12c-.61-1-18-9.46-36.69-1.54.29.78.23.62.4,1.09a42.62,42.62,0,0,1,22.44-2.9C800.62,325.66,803.62,331.55,800.9,327.12Z" />
      <path d="M805.23,335.59c-.56-1.2-18.81-11.32-38.55-3l.36,1.1a41.88,41.88,0,0,1,23.76-2.51C805,333.79,807.39,340.27,805.23,335.59Z" />
      <path d="M809.63,346c-.2-.53-17.1-6-36.46-1l.68,1a66.78,66.78,0,0,1,36.22,1.16S809.73,346.25,809.63,346Z" />
      <path d="M812.87,354.57c-.28-.76-16.06-6.53-32.39.82-.31.13-.24.11.4,1.06A41,41,0,0,1,794.07,353c12.19-1.1,19.44,3.29,19.16,2.52Z" />
      <path d="M807.45,340.6c-.34-.8-18.13-6.71-37.14.12.24.36.43.63.55.82.42.62,12.47-5.6,30.41-1.66C808.72,341.51,808.38,342.78,807.45,340.6Z" />
      <path d="M820.58,350.8c-.71,1.89-2.18,7.14-1.72,8.45,1.94,5.45-.42,1.06,2.6-7.52Z" />
      <path d="M815.25,361.11c-.22-.63-15.13-6.21-31,.25l.67,1a42.16,42.16,0,0,1,30.77,0C815.36,361.42,815.35,361.37,815.25,361.11Z" />
      <path d="M788.67,367.76l.73,1a44.15,44.15,0,0,1,29.65,3.68c-.15-.48-.3-.95-.41-1.27C818.41,370.44,804.62,364,788.67,367.76Z" />
      <path d="M828.91,359.33a53,53,0,0,0-4.37,17.4c2.55,9-1.33-1.29,5.24-16.53Z" />
      <path d="M821.06,379.36a48.92,48.92,0,0,0-26.32-3.18c1.41,1.93-.55.57,7.48.57,12.43,0,19.35,4.55,19.15,3.78C821.36,380.51,821.12,379.6,821.06,379.36Z" />
      <path d="M819.88,387.1c3.78.84,3.43,1.34,3-.45a79,79,0,0,0-22.26-2.56c1.09,1.44.58,1.12,2.15,1.12A78,78,0,0,1,819.88,387.1Z" />
      <path d="M824.69,355.07A28.14,28.14,0,0,0,821.54,367c2.5,7.57-.19,1.71,2.26-6.73C825.37,354.91,826.21,356.63,824.69,355.07Z" />
      <path d="M826.21,403.27a32.75,32.75,0,0,0-14.15-4.35c1.78,2.28,0,.51,6.11,2.21C827.1,403.6,826.78,406.61,826.21,403.27Z" />
      <path d="M827.65,412.37c-.09-.62-6.61-3.35-7.49-3.7s-.77-.37-.46,0c2.25,2.87-.28.26,8.12,4.87C827.82,413.54,827.71,412.83,827.65,412.37Z" />
      <path d="M815.47,409.88a33.78,33.78,0,0,0-1.64,5.91c1.52,0,1.07.3,1.46-1.38C816.29,410.1,816.73,411.51,815.47,409.88Z" />
      <path d="M807.72,399.89a49.58,49.58,0,0,0-1.05,15.9h1.17a48.12,48.12,0,0,1,.8-14.72Z" />
      <path d="M801.16,391.39c-.3-.38-7,10.23-5,24.4h1.16a37.22,37.22,0,0,1,1.53-17.19C801.38,391.21,802.48,393.12,801.16,391.39Z" />
      <path d="M797.74,386.91l-.75-1a43.94,43.94,0,0,0-5.87,29.86h1.16A42.85,42.85,0,0,1,797.74,386.91Z" />
      <path d="M788.28,387.13c2.92-9.75,4.09-7.35,2.54-9.46a57.41,57.41,0,0,0-4.89,37.12c.26,1.26,0,1,1.4,1A56.58,56.58,0,0,1,788.28,387.13Z" />
      <path d="M783.15,382.26c3.56-10.86,4.93-8.34,3.35-10.56-5.87,10.61-7.78,22.89-7.32,28,1,10.71-.39,8.26,1.49,16.07h1.19A61.28,61.28,0,0,1,783.15,382.26Z" />
      <path d="M783,366.76l-.69-1c-4.17,5.54-6.12,11.82-6,13C777.45,386.34,775.28,377.3,783,366.76Z" />
      <path d="M777.49,361.39c1.22-1.62,1.33-.83.42-2.23-1.38,1.38-3.67,4.68-3.46,6C775.28,370.76,773.92,366.17,777.49,361.39Z" />
      <path d="M817.75,347.8c-.5-.55-.32-.34-.91-1a23.43,23.43,0,0,0-.9,4.39C817.83,356.32,816.18,353.47,817.75,347.8Z" />
      <path d="M822.08,395.18c3.45,1.33,3,1.95,2.62-.13a37.8,37.8,0,0,0-18.32-3.44c1.11,1.44.52,1.07,2.36,1.07A36.76,36.76,0,0,1,822.08,395.18Z" />
      <path d="M834.12,364.42l-.86-.83a59.85,59.85,0,0,0-4.87,12.91c-.42,1.64-1.81,8-1.43,9.66C829.29,396.24,824.9,382.57,834.12,364.42Z" />
      <path d="M832.57,402c-1.58-20.58,6.36-33.06,5.8-33.58l-.74-.68a68.73,68.73,0,0,0-3.72,48.07C836.83,415.79,833.72,416.85,832.57,402Z" />
      <path d="M841.91,371.61l-1-.88a89,89,0,0,0,.91,45.06H843A87.84,87.84,0,0,1,841.91,371.61Z" />
      <path d="M845.47,386.22c1.74-10.93,3.07-8.86,1.22-10.44a78.52,78.52,0,0,0-1.38,40c2.16,0,.85,1.28-.27-8.41A78,78,0,0,1,845.47,386.22Z" />
      <path d="M854.08,381.83a69.55,69.55,0,0,0-4.33,34h1.16A68.44,68.44,0,0,1,855,382.57Z" />
      <path d="M860.37,386.6c-.23-.16-1-.72-1-.74a72.08,72.08,0,0,0-1.26,29.93h1.17A70.86,70.86,0,0,1,860.37,386.6Z" />
      <path d="M863.73,396.5c.76-7.8,1.76-6.21,0-7.45a63.27,63.27,0,0,0-.08,26.74h1.18A62.27,62.27,0,0,1,863.73,396.5Z" />
      <path d="M866.68,391.12c2.21,26,1.91,22.85,2.11,24.67h1.15C867.27,390.21,869,392.73,866.68,391.12Z" />
      <path d="M874.15,396.23l-1.12-.75a72.44,72.44,0,0,0,1.79,20.31H876A71.11,71.11,0,0,1,874.15,396.23Z" />
      <path d="M911.05,382.79c-.36-.12-.12-.05-1.95-.63-2.83-.91-19,8.15-27.36,9.9-5.85,1.23-6.77-.22-4.15,1.51,1.23.81,8.47-1.38,11.56-2.59C896.39,388.15,903.35,383.9,911.05,382.79Z" />
      <path d="M879.47,399.77l-1.13-.75a75.41,75.41,0,0,0,1.31,16.77h1.17A73.85,73.85,0,0,1,879.47,399.77Z" />
      <path d="M884.37,403a53.12,53.12,0,0,0-.72,12.8h1.15a52.3,52.3,0,0,1,.62-12.11Z" />
      <path d="M890.54,407a59.57,59.57,0,0,0-.07,8.76c2.17,0,.51,1.22,1.16-8C891.58,407.72,890.6,407.08,890.54,407Z" />
      <path d="M942.23,397.49c-1.72-1.14-1.5-1.55-6-.92-10.48,1.46-19.32,7.68-29.33,10.51-7.27,2.06-8.09.45-5.31,2.28C915.83,407.56,927.92,396.42,942.23,397.49Z" />
      <path d="M895.65,410.38a40.86,40.86,0,0,0-.28,5.41c1.53,0,1.07.47,1.17-2.12C896.66,410.57,897.12,411.34,895.65,410.38Z" />
      <path d="M900.39,415.79c1.48,0,1.1.29,1.31-1.45C900.38,413.47,900.63,413.16,900.39,415.79Z" />
      <path d="M841.29,352.09s-.08,0,.47.26A.56.56,0,0,0,841.29,352.09Z" />
      <path d="M832.42,352.41c-6.75-1.22-9.89-5.34-5-.27A22.79,22.79,0,0,0,836,353.9C832.84,352.18,834.2,352.74,832.42,352.41Z" />
      <path d="M832.88,357.6a19.85,19.85,0,0,0,10.09-.14c-2.87-1.4-.63-.43-5.3-.43C831.34,357,829.29,354.06,832.88,357.6Z" />
      <path d="M836.69,361.29c1.42,1.36,1.09,1.78,6.35,2.23,5.5.47,10.47-1.11,9.77-1.43l-1.24-.56C846.76,363.15,841.56,362.39,836.69,361.29Z" />
      <path d="M844.34,368.36c.69.63,7.7.52,13.84-2.19,2.4-1.05,2.16-.78.48-1.49a27.81,27.81,0,0,1-15.53,2.6Z" />
      <path d="M849.71,373c6.28-.31,11.65-3.76,17.46-5l-1.9-.73c-4.95,1.34-9.48,3.88-14.48,4.45C848,372.13,847.73,371.36,849.71,373Z" />
      <path d="M857.67,375.57c-4.53,1.11-5.26-.26-3.16,1.44,1.8,1.46,14-4.82,18.28-5.95,1.91-.5,2.29-.08-.45-1.07C867.19,371.37,862.74,374.33,857.67,375.57Z" />
      <path d="M885.21,374.88c1.7,0,2.18.27.86-.17-7.71-2.57-16.64,5-26.13,6.57C863.75,384.18,874.85,374.88,885.21,374.88Z" />
      <path d="M867.7,386.93c10.79-.39,20.16-6.7,30.48-8.24-6.8-2.17-19.4,7.43-32.09,7.11Z" />
      <path d="M922,386.71c-3.62-1.49-3.24-1.42-5.24-1.1-8.68,1.38-13.78,7.53-26,10.71-7.68,2-8.16.58-5.56,2.29C904,395.94,910.54,384.81,922,386.71Z" />
      <path d="M931.65,391.21c-2.5-1.31-2.08-1.12-2.73-1.06-10.54,1-18.69,8.09-28.18,11.43-8.23,2.9-10.14.58-6.83,2.75C907.67,403,918,390.78,931.65,391.21Z" />
      <path d="M943.81,404.12c7.34-1.07,10.42,2.21,8.28.52L950,403c-9.66-1.89-22.83,6.14-35.27,9-8.09,1.84-9,.14-6,2.1C921.32,413.09,933.42,405.63,943.81,404.12Z" />
      <path d="M963.12,414.05c-1.11-1-7.21-2.84-13.25-2.84-7.74,0-13.89,2.78-21.33,4.58,5.33,0,4,.15,6.38-.57,11-3.27,19.33-4.55,29.72.57C965.28,415.79,965.41,416.1,963.12,414.05Z" />
      <path d="M936.18,361C891.25,335,823.36,328.57,794.54,280.12a27.05,27.05,0,0,0-3.48-3.83c1,5.35,2.34,9.17,3.71,14.7,1.33,5.34,8.3,18.14,20.13,29.46,42,40.18,100.13,33.33,153.52,83.73,13.68,12.9,10,11.61,13,11.61-14.59-16-31.42-30.23-50.84-40.45-41.79-22-98.06-28.23-126.75-69.51a71.25,71.25,0,0,1-11-24.19,131.88,131.88,0,0,0,51.15,51.21c42.13,23.38,77.53,12.54,126.89,67.39,15.54,17.27,11.47,15.55,14.48,15.55,0,0-4.23-5.44-8.77-10.77-58.71-69-98-45.44-147.83-83.52a131.21,131.21,0,0,1-35-40.51c19.44,32.22,57.46,46.79,90.76,58.94,44.92,16.39,69.09,26.89,100.8,67.76v-1.87C971.36,388,955.83,372.45,936.18,361Z" />
      <path d="M828.64,336.34c-30.6-21.75-33.07-42.72-30.88-31.48.7,3.59,11.55,17.94,25,28.49,46.21,36.34,103.82,34.39,150.59,82.44h1.59C929.41,368.38,874.68,369.06,828.64,336.34Z" />
      <path d="M928.59,268.24c-1.31-1.33-2-1.93-2.29-2.26,4,6.94,2.51,7.55,6,14.3C931.86,276.85,930.15,269.81,928.59,268.24Z" />
      <path d="M937,277.46c-3-3.55-1.24-2.24-.57,3.57,1.33,11.47-.67,6.35,1.74,11.66.55,1.2.09,1.13-.19-5.6A52.78,52.78,0,0,0,937,277.46Z" />
      <path d="M943.82,305.68c-1.09-7,.52-14.07-.87-21.2-3.23-3.9-.79-2.24-.57,5.79C942.76,304.36,941.05,299,943.82,305.68Z" />
      <path d="M958.25,322.17c-3.26,12.18-2.35,12.12-1.16,14.52-.47-9.26,4.64-18.25,4.64-27.56C959.35,305.77,962.24,307.3,958.25,322.17Z" />
      <path d="M981.87,331.67c.87,1.32.41,1.3,3.44,1.78v-1.16C981.56,331.68,981.45,331,981.87,331.67Z" />
      <path d="M972.32,370.43c2.82,6.55,1.1,3.12,1.09-1.94,0-9.78,4.25-18.83,5.45-28.73.65-5.32.19-5-1-6.69C978.85,345.47,971.48,357.63,972.32,370.43Z" />
      <path d="M976.78,379.41c2.07,3.63,1.12,2.67,1.06-2.61-.17-13.41,4.56-20.07,5.8-29.73.69-5.31.08-5.18-1.17-7.07C984.41,352.27,975.65,361,976.78,379.41Z" />
      <path d="M981.83,382.22c0,.82,0,4.95.51,5.69,3.22,4.32-1.07.73,1.54-14,1.65-9.25,1.43-3.47,1.43-11.83C983.81,368.71,982,375.31,981.83,382.22Z" />
      <path d="M932.06,244.18c-2.39-7.63-.9-6.52-2.93-6.89a67.46,67.46,0,0,0,11.47,26.5C947,267.42,937.74,262.31,932.06,244.18Z" />
      <path d="M936.23,266c-6.37-1.09-8.46-4.1-4.66-.29,1,1,5.62,1.65,7.21,1.74Z" />
      <path d="M940.45,246.68c-2.08-8.42-.33-7.35-2.49-7.75,1.64,17,11.3,29.43,12.46,30C957.49,272.34,945.46,267,940.45,246.68Z" />
      <path d="M951.27,273.85c.06,0,.19.08-1.81-.88-3.32.58-6.67,1.59-9.93.8-.62-.16-.79-.51.8,1.32C944.07,275.62,947.62,274.29,951.27,273.85Z" />
      <path d="M951,241.37l-1.18-.22a74.22,74.22,0,0,0,12,33l1.63.69C964.83,275.39,953.54,263.27,951,241.37Z" />
      <path d="M947.28,248.58c-2.07-9.32,0-7.91-2.32-8.35a55.24,55.24,0,0,0,12.75,32.08c3.74,1.68,2.8,1.73,0-1.85A54.61,54.61,0,0,1,947.28,248.58Z" />
      <path d="M959.36,277.49c-1-.45-7.33,3.42-12.78,2.93-2.33-.2-2.66-1.15-.84,1.06,5.32.81,10-2,14.76-3.34C960.83,278.05,961,278.21,959.36,277.49Z" />
      <path d="M971.32,282.32l-2-.77c-2-.77-10.58,4.29-14.92,5.54-4,1.16-4.23-.16-2.78,1.7C958.7,288.1,964.55,282.45,971.32,282.32Z" />
      <path d="M948.51,304.61c2.09-12.18.65-12.4-.42-13.75-.79-1,.89,1.38,0,8.65-.48,4.18-1.52,8.22-1.74,12.49C948.44,317.12,946.64,315.52,948.51,304.61Z" />
      <path d="M982.19,286.27c-2.05-.72-.88-1.34-10.29,3.84-13.93,7.66-17.42,2.33-15,5.52C966.43,296.5,974.08,289.18,982.19,286.27Z" />
      <path d="M971.17,245.19,970,245a55.38,55.38,0,0,0,10.2,36.34l1.62.57C983.8,282.55,969.31,269.51,971.17,245.19Z" />
      <path d="M954.06,311.92c1.87-12.85.9-12.11,0-13.34-.76-1,.66.2-.7,10.31-2.26,16.73-2,15.51.75,21.54C954.38,329.48,951.75,327.75,954.06,311.92Z" />
      <path d="M978.26,292.76c-18.92,10.5-20.34,4.22-17.8,7.68,8.82-.51,16.66-5.92,23.71-9.59,1.44-.75,1.14-.3,1.14-1.86C982.93,290.15,980.61,291.46,978.26,292.76Z" />
      <path d="M964.5,332.82c-3.56,14.69-2.27,14.5-1,17.15-1-10.87,4.73-21.19,4.45-31.94C965.39,314.34,968.48,316.39,964.5,332.82Z" />
      <path d="M969.83,313.69c5.1-.58,9.84-3,14.1-5.94,1.66-1.12,1.38-.63,1.38-2.39C970.81,316,967.66,310.52,969.83,313.69Z" />
      <path d="M973.32,318.83a37.26,37.26,0,0,0,12-4.57v-1.34C973,320.05,971.47,316.08,973.32,318.83Z" />
      <path d="M967.24,358.38c1.71,3.9,1.14,3.42,1.15.19,0-13.24,5-19.75,5.61-29.61.12-2,.24-1.48-1.19-3.63C973.77,336.09,967.32,343.49,967.24,358.38Z" />
      <path d="M976,322.81c.39.6,6.08-.59,9.31-1.7v-1.22C975.35,323.42,974.45,320.49,976,322.81Z" />
      <path d="M978.89,327.16l.63,1c.36.54,5.08-.34,5.79-.49C985.31,325.17,986,327.27,978.89,327.16Z" />
      <path d="M984.7,336c.77,1.18.61,1.21.61.11C984.28,335.55,984.33,335.42,984.7,336Z" />
      <path d="M893.94,229.74a17.83,17.83,0,0,0,1.47,3.06c3.83,2.68,2.19,2,.37-1.68C895.18,229.88,895.74,230.22,893.94,229.74Z" />
      <path d="M902.5,231.9l-1.32-.32c.42,1.28,2.33,6.91,3.43,7.7C908.87,242.31,905.72,241.38,902.5,231.9Z" />
      <path d="M907.05,232.93l-1.31-.29c1.17,3.63,4.62,10.77,6.94,12.49C922,252,911.43,245.78,907.05,232.93Z" />
      <path d="M913.87,211.27c-1,.43-6.13,8-7.84,17.29l.94.22c.59.14.89-8.84,8.75-18.32Z" />
      <path d="M913.84,234.36l-1.26-.26a49.74,49.74,0,0,0,7.57,16.48C928.65,256.65,918.3,250.93,913.84,234.36Z" />
      <path d="M921.49,235.86l-1.24-.24c2.84,12.56,10.76,22.38,13,23.78C944.74,266.61,926.59,257.32,921.49,235.86Z" />
      <path d="M956,242.31l-1-.19c-.44-.08,1.57,17.61,13.08,34.61l1.61.64C971.1,277.92,959.31,265.36,956,242.31Z" />
      <path d="M964.64,251.81c-1.18-9,.4-7.79-1.69-8.19a64.78,64.78,0,0,0,7.88,30.89c2.72,5,2.66,4.33,4.42,5A63.66,63.66,0,0,1,964.64,251.81Z" />
      <path d="M977.59,246.44l-1-.19a61.62,61.62,0,0,0,8.68,31.1c0-3.07.23-1.76-1.12-4.46C976.67,257.86,978.28,246.57,977.59,246.44Z" />
      <path d="M895.68,219.45c-1,.42-2.93,5.77-2.43,5.9l.93.26C895.66,218.81,899.56,217.76,895.68,219.45Z" />
      <path d="M905.15,215.26c-1.2.54-4.44,6.91-5.65,11.72l1.12.28C903.7,215.16,910.23,213,905.15,215.26Z" />
      <path d="M925.85,206.54c-2.09.73-12,9.85-14.51,23.21l1.13.23C916.55,208.33,936.55,202.79,925.85,206.54Z" />
      <path d="M936.28,203.28c-1.7.47-7.7,6.26-11.43,12.6-4.09,7-5.9,15.33-5.28,15.44l.94.18a42.65,42.65,0,0,1,14.8-26.23C939.07,202.13,940.67,202.07,936.28,203.28Z" />
      <path d="M948.52,200.42c-1.27.25-16.54,11.82-23.91,31.83l1,.18c.67.13,2.84-10,13.42-21.91C948.72,199.57,954,199.38,948.52,200.42Z" />
      <path d="M957,199c-1.31.17-17.85,13-21.28,35-.05.3-.24.2,1.1.44a54.16,54.16,0,0,1,16.86-31.45C959,198.13,960.45,198.58,957,199Z" />
      <path d="M960.5,209.82c13.25-11.93,24.53-10.19,16.85-11.12a50.6,50.6,0,0,0-33.29,37.14c2.43.45-.07.58,4-8.75A50.1,50.1,0,0,1,960.5,209.82Z" />
      <path d="M949.75,236.89l1.18.22a67.49,67.49,0,0,1,30.16-30.69c5-2.54,4.22-1.35,4.22-3.21A68.69,68.69,0,0,0,949.75,236.89Z" />
      <path d="M954.8,237.82l1,.18c.83.15,5.27-13.3,22.26-24.33,8.29-5.38,7.27-3,7.27-5.36A65.05,65.05,0,0,0,954.8,237.82Z" />
      <path d="M960.75,238.92l1.21.23A63.41,63.41,0,0,1,975.74,223c10.23-8.58,9.57-4.94,9.57-8A65.67,65.67,0,0,0,960.75,238.92Z" />
      <path d="M966.81,240.05l1,.19c.64.12,5-9,17.47-17.69v-1.4A66.1,66.1,0,0,0,966.81,240.05Z" />
      <path d="M973.88,241.36l1.23.23a60.59,60.59,0,0,1,10.2-11.47V228.6A61.22,61.22,0,0,0,973.88,241.36Z" />
      <path d="M978.11,242.17c2.52.49,0,.63,7-4.86.23-.18.17.1.17-1.58A57,57,0,0,0,978.11,242.17Z" />
      <path d="M983.16,296.74c-18,13.32-19.9,7.54-17.94,10.33.4.56,6.27-1,12.7-5.19,2.52-1.65,4.93-3.49,7.39-5.29C985.31,294.76,985.8,294.8,983.16,296.74Z" />
      <path d="M983.83,247.66a68.29,68.29,0,0,0,1.48,14.57v-7.49C984.54,247,985.73,248,983.83,247.66Z" />
      <path d="M119.49,347.32c-.13.29-.37.77-.55,1.15-.29.6,1.21,2.55,1.89,7.95.08.59-.1,1.09.66-3A32.65,32.65,0,0,0,119.49,347.32Z" />
      <path d="M119.73,363.77a40.44,40.44,0,0,0-3.35-10.15c-.18.35-.34.67-.56,1.08-.32.63,2.52,4.6,3.32,13.05C119.27,366.81,119.53,365.09,119.73,363.77Z" />
      <path d="M112.86,360.25l-.67,1.21c6.82,13.34,4.84,23,6,13.62A61.07,61.07,0,0,0,112.86,360.25Z" />
      <path d="M109.75,365.68l-.63,1.05a36.64,36.64,0,0,1,8,18.36c.42-4.77.44-3.9.29-4.5A37.67,37.67,0,0,0,109.75,365.68Z" />
      <path d="M100.6,372.41c1.52-2.23,1.34-.94-2.92-.94-7.57,0-14.7-3.91-22.45-3.2-5,2.93-2.83,1.06,2,1.06C85,369.33,92.52,373.62,100.6,372.41Z" />
      <path d="M105.77,372.05c-.15.23-.54.83-.66,1,12.94,14.52,10.45,29.38,11.41,18.83A48.35,48.35,0,0,0,105.77,372.05Z" />
      <path d="M82.87,375.8c-6-1.12-12.14-2.39-18.13-1.24-6.23,4-2.43.71,5.09.71,9.14,0,17.77,3.8,26.79,2.75C98.81,375.05,98.39,378.71,82.87,375.8Z" />
      <path d="M101.17,378.69l-.58.77c-.46.62,6.67,5.07,11.34,17.68,3.84,10.36,2.24,17.64,3.23,8.38C115.37,403.51,112.52,389.46,101.17,378.69Z" />
      <path d="M35.08,367.76c0,.4-.13,2-.13,2,1.78-3.43,5.73-5.28,8.87-6.74,2.37-1.1,1.79-.59,3-2.75C44.79,361.38,35.24,365.33,35.08,367.76Z" />
      <path d="M92,384c3.17-4,.71.49-8.12.49s-17.64-4.62-26.45-4.64c-.35,0-.12-.14-1.6,1-.95.75.82-.92,10.76,1.46,6.65,1.6,13.37,4,20.48,3.1C87.94,385.37,91.38,384.81,92,384Z" />
      <path d="M96.28,385l-.72.9a56.51,56.51,0,0,1,16.22,26.91c1,3.56.29,3,1.89,3A57.77,57.77,0,0,0,96.28,385Z" />
      <path d="M48.65,365.36c-.68,6.16.6,12.73-2.12,18.28,1.78-1.7,2.63-1.52,3-8.24.17-3.62-.1-7.3.41-10.88C50.3,361.81,51.25,360.39,48.65,365.36Z" />
      <path d="M90.91,391.57l-.63.74c-.43.5,8.58,7.64,14.14,23.48h1.22A66,66,0,0,0,90.91,391.57Z" />
      <path d="M87.48,395.54l-.79.9A55.47,55.47,0,0,1,94,412.6c1.06,3.83.35,3.19,2,3.19A57.66,57.66,0,0,0,87.48,395.54Z" />
      <path d="M84.26,399.53c-.15-.32,0-.41-1,.67a55.08,55.08,0,0,1,4.6,15.59h1.17A56.42,56.42,0,0,0,84.26,399.53Z" />
      <path d="M81.32,404.22c-.47-1.87-.29-1.58-1.17-.65-.51.54,1.29,3.06,1.67,12.22H83A58.05,58.05,0,0,0,81.32,404.22Z" />
      <path d="M76.58,407.25c-1.85,1.88-.71-.18-1.35,8.54h1.16A82,82,0,0,0,76.58,407.25Z" />
      <path d="M70.76,413c-1.47,1.42-1.12.75-1.16,2.82h1.16C70.78,414.66,70.77,413.85,70.76,413Z" />
      <path d="M116.55,344.25c.51-1.12.59-1.3.66-1.47-10.82,5.92-6.65,3.46-11,6.7C109.14,348.94,116.4,344.56,116.55,344.25Z" />
      <path d="M113,351.59c.09-.19.12-.23.62-1.22-5.26.33-9.45,4.4-14.59,5.2-3.22.51-1-.62-3.71,1C101.7,358.23,107.2,352.36,113,351.59Z" />
      <path d="M109,359c1.77-3.1,1.37-.93-4.93.19-7.41,1.3-11.35-1.1-12-.72l-1.08.63A25.29,25.29,0,0,0,109,359Z" />
      <path d="M104.37,366.66c1.86-3,.68.89-9-1.34-3.7-.85-7.35-2.14-11.2-2.27l-1.7,1c-1.3.76,0-.9,9.77,1.67a35.44,35.44,0,0,0,8.81,1.52C101.58,367.23,104.05,367.17,104.37,366.66Z" />
      <path d="M82.07,359.61c1.46-.85,1.66-.2,2.84-6.59,4-21.46-5.06-31.95-4.22-41.38l-1,1.36C77.16,316.43,90.12,334.2,82.07,359.61Z" />
      <path d="M77.35,391.57c-8.38,0-16-2.81-24.45-2.81-1.18,0-6,.21-6.81,1l-1.31,1.4c12.76-4.44,27.65,4.76,41,.33C89.49,387.24,87.05,391.57,77.35,391.57Z" />
      <path d="M39.4,396.47c-5.38,4.86-1.44-.64,10.69-.64,10.21,0,20.7,3.38,30.7,1,3.15-3.32,1.52-.33-7.84-.33-7.75,0-15-1.86-22.8-1.86C47.19,394.68,40.77,395.24,39.4,396.47Z" />
      <path d="M76.54,401.29c-13.38,5.06-28.67-3-42.95.32-2.86,2.62-2.13,1.45,2,.77,10.32-1.69,20.75,2,31.3,1.77,1.37,0,7.21-.38,8.16-1.35Z" />
      <path d="M70.26,407.63c-13.52,4.35-28.53-3.55-42.62-.24-2.85,2.92-1.87,1,4.54.45,10.11-.83,19.9,2.6,30.1,2.25C69.07,409.86,68.9,409,70.26,407.63Z" />
      <path d="M54.37,414.74c-10.76,0-22.24-3.76-32.83-.8-3.76,4.22-1.15-1.32,16.05.41,8.4.84,17.32,2.63,25.47.55C66.67,411.27,64.67,414.74,54.37,414.74Z" />
      <path d="M36.72,291.53c-.74,1.18-1.37,13.38-1.29,20,.38-2.24,1-5.5,1.24-7.13C37.2,289.25,39.09,287.77,36.72,291.53Z" />
      <path d="M33.85,322.28c-5.08-19.12,1.38-28.38-2.76-21.78a60.17,60.17,0,0,0,2.41,24.71C33.65,323.92,33.84,322.36,33.85,322.28Z" />
      <path d="M32.34,338.16c.25-3.79.65-2.77-1.66-9-1.91-5.14-4-10.35-4.56-15.82-.54-5.14.81-6.24-1.29-2.95C24.79,320.35,30.18,329,32.34,338.16Z" />
      <path d="M31.5,359.63c.42-10.12.33-9,0-10.63-2.33-10.19-7.56-19.58-8.92-30-.67-5.14.36-5.62-1.42-2.89.39,7.66,3.25,15.08,5.66,21.73C33.26,355.79,30.85,358.76,31.5,359.63Z" />
      <path d="M22,307.31s0,.08.21-.31A.53.53,0,0,0,22,307.31Z" />
      <path d="M15.94,324.06c-.55,17.13,11.22,31.81,14.84,47.68.86-10.64-11.59-25.27-13.48-42.57C16.5,321.87,18.28,320.4,15.94,324.06Z" />
      <path d="M9.81,334.14c.8,18.39,15.79,32.81,19.49,50.29.58-3.31.57-2.81-.24-5.27C24.82,366.42,16.23,355.94,12.49,343,9.53,332.79,12.25,329.92,9.81,334.14Z" />
      <path d="M6.13,340.73C6,341,6,341,5.36,342.17c-.47.87,1.48,7,1.95,8.33,4.45,12.55,13,23,17,35.6,3,9.28,1.07,12.86,2.83,7.25C24.7,374.4,8.28,359.32,6.13,340.73Z" />
      <path d="M19.61,395.19c2.75,10.91-.89,15.69,2.22,10.33C23,384.73,4.18,368.39.37,352-2.8,358.54,15.15,377.47,19.61,395.19Z" />
      <path d="M13.71,415.79c.81,0,.53.14,1.22-.7,1.48-15-5-24.58-10.06-32.3-5.79-8.9-4.79-9.06-4.79-5.9C.08,380.77,16.33,394,13.71,415.79Z" />
      <path d="M2.27,401.32c2.92,4.43,5.49,9.27,6.45,14.47H9.9c-1.26-7.21-5.53-13.72-9.82-19.58C.08,398.88-.36,397.34,2.27,401.32Z" />
      <path d="M17.5,298.32,16,299.54a60,60,0,0,1,9-1.15c3.12,0,2.11.75,3.12-.84C24.45,296.74,18,297.87,17.5,298.32Z" />
      <path d="M22.62,306.28l.65-1c-2.82-.83-5.78-.34-8.68.17-7.87,1.39-5.24-.34-7.45,1.57C12.48,308,17.77,305,22.62,306.28Z" />
      <path d="M18.23,313.16c1-1.6.06-.1.85-1.34s-.06,1.48-10.86.87c-8.16-.46-6.73-.72-8.08.49-.08.07-.06,0-.06.19C9,313.89,13.9,314.5,18.23,313.16Z" />
      <path d="M13.59,320.35c2.79-4.35-1.33,1.81-13.51.88,0,1.32,0,1.16.09,1.17A33.58,33.58,0,0,0,13.59,320.35Z" />
      <path d="M9.38,327c2.2-3.56.7-.06-8.31.58-1.25.09-1-.22-1,1.2A31.53,31.53,0,0,0,9.38,327Z" />
      <path d="M4.59,335c1.63-2.84,1.47-1-4.51-.08,0,1.39-.16,1.2.58,1.08A26.63,26.63,0,0,0,4.59,335Z" />
      <path d="M1.92,339.82c-2.29,1.19-1.84.22-1.84,2C1.22,341.47.86,341.78,1.92,339.82Z" />
      <path d="M70.89,204.17c-1.66,5.1-.34-2.47,14.56-2.47,4.6,0,9.28.6,13.87.55,5.24-.05,4.68-.38,6.18-1.72C94.18,202.72,81.26,197.33,70.89,204.17Z" />
      <path d="M71.09,185.11C69.92,186.68,66.6,196,66,206.3c.61-2,.85-2.75,1.68-5.24C70,184.53,78.13,175.67,71.09,185.11Z" />
      <path d="M63.8,194.27c-1.49,1.77-5.71,17.29-3.95,32.4,1.81-6-.07-3.16,1-14.56C62.42,194.47,69,188.16,63.8,194.27Z" />
      <path d="M54,220.41c1.91-19.28,5.35-21,1.64-16.95a131.75,131.75,0,0,0-2.61,44.47c1.23-3.49.86-1.66.54-7.31A131.3,131.3,0,0,1,54,220.41Z" />
      <path d="M47.38,212.28a138.16,138.16,0,0,0-1.76,56c1.46-3.13.62-.2-.39-11.84C42.14,220.69,53,206.45,47.38,212.28Z" />
      <path d="M34.14,279.8c4.76-3.83-.27-31.85,3.33-50.3,2-10.32,5.07-12.19,1-7.91C31.76,240.26,37.2,260.82,34.14,279.8Z" />
      <path d="M29.72,288.6c-2.8,2.17-2.09,1.11,1.05,1.11,2.86,0,1.89.72,2.9-.87A15.69,15.69,0,0,0,29.72,288.6Z" />
      <path d="M29.71,230.74c-.69.73-9.95,26.74-7.81,58.88l.92-.73c.82-.66-3.32-25.91,8.34-59.45C31.34,228.93,31.68,228.64,29.71,230.74Z" />
      <path d="M18.64,243.09c-1,1.17-11.45,25.68-8.8,56.26l.9-.75c.74-.62-2.11-13.64,2.56-35.12C17.8,242.8,24.09,236.57,18.64,243.09Z" />
      <path d="M59.6,180.41c-.21.12-1.31.73-2.28,1.25,6,0,12.84-2.19,13.48-3.14C73.61,174.36,70.88,179.53,59.6,180.41Z" />
      <path d="M51.9,190.09a32.2,32.2,0,0,1-9-1.27l-1.59.72c10.39,3.47,20.7.59,21.43-.28C65.91,185.49,62.89,190.09,51.9,190.09Z" />
      <path d="M33.07,193.1l-1.35.56a33.8,33.8,0,0,0,23.68,4c3-3.3,1.27-.07-8.19-.55A32.36,32.36,0,0,1,33.07,193.1Z" />
      <path d="M19.84,198.27l-1.29.48a38.35,38.35,0,0,0,29.36,7.05c2.69-2.85,1.67-.6-6.41-.6A37.31,37.31,0,0,1,19.84,198.27Z" />
      <path d="M8.2,202.61l-1.26.47a48.82,48.82,0,0,0,33.73,10.29l1.22-1.26A47.77,47.77,0,0,1,8.2,202.61Z" />
      <path d="M32.86,221.42c3.1-3.22.89.16-9.2.16A40.19,40.19,0,0,1,.08,213.85v1.41C15.25,225.93,32,222.29,32.86,221.42Z" />
      <path d="M20.87,234.35c4.67-5.27-1.3,4.14-20.79-3.38v1.24C6.72,234.73,14,236.25,20.87,234.35Z" />
      <path d="M13.67,242.8c2.44-3,1.18-.06-7.41-1.15-7.12-.9-6.18-2.3-6.18-.1A33.56,33.56,0,0,0,13.67,242.8Z" />
      <path d="M8.35,249.55c2.06-2.72,1.09-.17-7.14-.17-1.43,0-1.13-.28-1.13,1.14C1.9,250.57,7.75,250.35,8.35,249.55Z" />
      <path d="M7.21,257.9c-.56.78-6.58,20.6-4.85,47.91.79-.71,1-.86,1.49-1.32a.7.7,0,0,0-.41.18C1.37,268.1,12.44,250.57,7.21,257.9Z" />
      <path d="M4,255.51c-4.67,1.51-3.9.19-3.9,2.11,1-.16,1.92-.36,2.84-.6Z" />
      <path d="M27.34,227.24c4.54-4.86-2.36,4.65-27.26-4.4v1.22C8.8,227.21,18.37,229.41,27.34,227.24Z" />
      <path d="M150.28,99.88c-1.14,1.28-1.32,1,1.81-.25C208.42,38.59,279.21,89,332,55.79c2.24-1.4,1.35-.69,3.64-3.66-1.75-.51-13.75,15.14-58.74,15.14C241,67.27,190.06,55.5,150.28,99.88Z" />
      <path d="M217.1,76.24c30.48-3.13,68.38.61,106.89-6.11.49-.08.25.18,1.12-1.37C261.67,80.21,202.68,62.48,159.65,95c-3.23,2.45-3.23,2.52-3.09,2.89,0,.07-.11,0,2.11-.67,1-.76,0,0,.6-.18l.92-.27c-.24-.17-.35-.28-.56-.28C159.61,96.49,176.91,80.37,217.1,76.24Z" />
      <path d="M294.32,86.16c-14.25-1.37-66.83-16.66-111.9,5.71a.52.52,0,0,0-.23.74c5-.6,20.56-11.66,57-11.66,39.65,0,57.73,12.23,76.89,3.63C319.12,79.31,314.93,88.15,294.32,86.16Z" />
      <path d="M294.6,97.85c6.88,0,14.39-1.22,14.76-1.84l.7-1.18c-33.81,8.81-70.72-17.22-103.73-3.68-1.14.47-1.37.26,2.07.45C235.8,81.63,266,97.85,294.6,97.85Z" />
      <path d="M55,168.84c36.81-30.46,55.58-82,144.51-109.69,16.05-5,13.93-4.35,15.35-4.9-.94-1-24.7-4.24-65.75,2.88C84.28,68.38,67.49,98.66,50.27,128,37.37,149.92,18.9,177.41.08,194.29v1.57C14.57,183,28.48,164.13,39.31,148,55.58,123.76,64.57,99.21,89.1,81.46c28.75-20.81,74.33-27.69,106.22-27.69,7.5,0,3.58-.31-11.67,2.58-5.11,1-10.26,2-15.38,3-4.89.91-9.88,1.59-14.77,2.45C82.69,74.23,61.42,133.51,33.2,166.32a177,177,0,0,1-33.12,30v2.14c52.63-31.9,70.63-68.7,99.62-95.67C127,77.41,171.56,62.34,208,56.39c-33,6.57-62.9,23-88.42,44.2C73.47,138.86,75.46,167,.08,199.41v1.25C19.89,192.17,38.27,182.64,55,168.84ZM159.29,62c17.28-2.74,32.88-6.83,47.72-7.9,18.36,1.09-10.82-1.29-56.8,17.07-75.68,30.2-73.73,76.2-143.75,122C66.66,147.38,68.56,76.35,159.29,62Z" />
      <path d="M284.17,51c-26.1,4.52-48.16,3.63-40.68,4.51,15.87,1.88,47.26.94,74.9-8.68,32.76-11.4,50.79-31.35,76.75-46.68-2.6,0-2.08-.11-2.78.32C357.45,21.8,331.75,55.74,256.84,55.06c69.84-3.41,85.78-29,119.75-54.91-2.55,0,.57-2.28-21.38,16.06C332.92,34.84,316.53,45.4,284.17,51Z" />
      <path d="M83.73,66.91C84.82,67,98.54,56.32,105.66,50,124.74,33.08,135.1,18.81,147.37.15c-1.73,0-1.07-.47-2.91,2.34C131.89,21.69,117,40.91,91.24,60.32c23.06-22,30-42.43,41-60.17-5.62,0-13.57,34.42-45.39,62.6,10.29-12.93,16-26.21,21.92-40.45C119.34-3.21,119.58.15,117.29.15,106.93,21.05,102,41.41,86.78,61,98.1,40.84,91.49,27.89,104.31.15H103c-12.93,28.4-5.91,41.54-18.42,62.31C83.85,67.09,83.07,66.86,83.73,66.91Z" />
      <path d="M85.33,28.37c.29-1.48.27-1.4.31-1.6C71.1,36.37,50.49,65.07.08,78.42v1.2C50.19,66.44,71.88,37.59,85.33,28.37Z" />
      <path d="M83.31,38.81c.23-.08.13.16.38-1.37C61.72,45.57,50.59,73.78,1.12,82c-1.31.22-1-.08-1,1.33C49.34,75.71,63.31,46.66,83.31,38.81Z" />
      <path d="M60.27,64.13c21.42-10.65,20.78-6.39,21.1-9.54C53.63,61.81,36.79,81.39.08,86v1.16C26.64,83.87,42,73.22,60.27,64.13Z" />
      <path d="M79.58,65.87c.09-.27.21-.65.36-1.2A142.58,142.58,0,0,0,24.83,84.05c-.11.06-.13,0,.7-.2C33.89,81.31,49.37,70,79.58,65.87Z" />
      <path d="M17.92,86.22c-1.22-.67-9,2.59-9.32,2.71C-.84,92.77.08,90.73.08,92.44,5.9,90.2,11.93,88.13,17.92,86.22Z" />
      <path d="M91.87.19c0-.06.17,0-.85,0C91.87.38,91.72.41,91.87.19Z" />
      <path d="M87.52,5.94c1.53-1.88,1.88-1-6.61-1C56.12,5,46.57-1.08,42,.24A131.84,131.84,0,0,0,87.52,5.94Z" />
      <path d="M82.48,11.68c2.09-2.21,2.14-1.06-5.33-.67A70.24,70.24,0,0,1,45.92,5.42C36.49,1.35,39.44,1,36.59,1.87A70.85,70.85,0,0,0,82.48,11.68Z" />
      <path d="M74.43,19.28c4.09-3.46-1.5,1.47-20.16-3.22-18-4.51-22.7-12.68-29.38-10.38C34.65,6.58,46.51,20.47,74.43,19.28Z" />
      <path d="M68.54,23.89c3.87-2.79,1.69-.21-8.32-1.26C44.91,21,37,12.45,24.52,8.49,19.73,7,19.94,7.42,17.78,8.21,35.08,10.06,44.72,25.7,68.54,23.89Z" />
      <path d="M53.6,32c1.95,0,1.48.18,4.45-1.48C40.78,33.11,25.6,20.26,15,15.28c-1.5-.7-7.29-3.11-8.4-2.63L5,13.38C19.56,15.09,33.17,32,53.6,32Z" />
      <path d="M61.76,33.07c-1.1,5.74-4.69,7.68-.22,4.37a17.41,17.41,0,0,0,1.52-5.13Z" />
      <path d="M61.73,28.4c3.35-2,2.38-.3-8-2C29.49,22.28,18.47,7.81,11.31,10.69,24.84,13.43,34.54,26.9,61.73,28.4Z" />
      <path d="M45,36.86c4.74-2,2,0-5.57-2.34C25.69,30.27,15.16,17.51.08,16.94V18.1C16.71,18.75,28.18,35.12,45,36.86Z" />
      <path d="M15.48,31.18a86.79,86.79,0,0,0,12.74,7.07c1.17.48,6.57,2.51,7.76,2.1,5.55-2-.1,2.06-16.62-8.1C13.14,28.42,7.12,24.14.08,21.93.08,24.85-1.68,20.34,15.48,31.18Z" />
      <path d="M19,43c5.41,1.32,5.24.94,7.89.2a57.55,57.55,0,0,1-17.4-4.66C-1,33.88.08,31.67.08,34.67A58.94,58.94,0,0,0,19,43Z" />
      <path d="M12.64,46.59c8.62-1.68,4.84-1,2.88-1C10,45.61,4.85,43.46.08,40.82v1.32A36.39,36.39,0,0,0,12.64,46.59Z" />
      <path d="M76.58,22.78c-2,4.15-5.09,7,.63,1.32a28,28,0,0,0,1.34-3C78.11,21.49,77.21,22.26,76.58,22.78Z" />
      <path d="M68.46,28.88c-1,4.53-3.64,6.18.28,2.91A16.63,16.63,0,0,0,69.81,28Z" />
      <path d="M54.32,37.05c-2.76,8.1-7.57,10.37-2.45,7.07,1.41-.91,4.32-8,3.66-7.67Z" />
      <path d="M46.5,40.62c-.54.23-.21,4-3.63,9l1.78-1c1-.56,2.53-4.93,2.87-8.37Z" />
      <path d="M37.32,44.18c-1.82,10.2-7.67,12.49-2.93,10,1.71-.89,4.79-10.7,4-10.4Z" />
      <path d="M27.36,47.32c-2.05,11.69-9.48,14.84-4,12.28A29.17,29.17,0,0,0,28.59,47Z" />
      <path d="M16.53,50c-.81,13.77-10.91,17.35-4.31,14.57a26.23,26.23,0,0,0,5.47-14.82Z" />
      <path d="M6.2,51.82,5,52A35.83,35.83,0,0,1,.08,66.64s0-.13,0,2.14A36.78,36.78,0,0,0,6.2,51.82Z" />
      <path d="M63.33,81.91c6.22-4.71-.44,1.41-16.42,1.41-5.72,0-2.79-.61-7.23.7C50.77,85.42,62,83,63.33,81.91Z" />
      <path d="M21.61,89.38l-1.9.57a51.45,51.45,0,0,0,32,.77C61.51,83.31,45.56,97.08,21.61,89.38Z" />
      <path d="M27.76,98c-13.23,0-20.47-4-21.34-3.68l-1.36.5A61.41,61.41,0,0,0,43,97.28C49.08,92.68,43.23,98,27.76,98Z" />
      <path d="M29.82,107.53c6-4.89-3.39,5.21-29.74-6.59v1.26C9.58,106.41,19.64,109.5,29.82,107.53Z" />
      <path d="M18.2,117.47c3.75-3.41-.26.9-12.53-1.66-6.49-1.36-5.59-2.54-5.59-.39A39.27,39.27,0,0,0,18.2,117.47Z" />
      <path d="M9.83,125.45,11,124.31c-2.58.31-4.93,1.15-8.19,1.36-3.41.22-2.72-.48-2.72,1.19C4.12,127,9.38,125.91,9.83,125.45Z" />
      <path d="M60.34,89.36c-1.52,4.36-3.47,5.52-4.34,10.46-.2,1.19-.43,1.16,1.6-1.73,1-3.33,2.56-4.58,3.89-8.52C62.1,87.78,62.24,87.94,60.34,89.36Z" />
      <path d="M52.14,95.51c-1.69,6.15-6.29,12.49-5.44,19.37,2.21-3.47,0-1.54,2.27-8,1.45-4.24,3.7-8,4.62-12.41Z" />
      <path d="M43.82,101.75l-1.38,1c-1.27,4.64-8.22,12.73-5.16,26.87l.9-1.41C35.66,113.93,42.93,107.82,43.82,101.75Z" />
      <path d="M33.76,109.48c-2.18,1.75-.24-.74-3.19,10.65-1.69,6.53-3.53,14.07-.38,20.69.07-.1.57-.9.74-1.17C27,130.05,33.2,119.41,33.76,109.48Z" />
      <path d="M22.39,119.17c-.71.64-.61.54-1,.88,1.78,8.6-8.61,18.06-1.67,36.93l.79-1.2C14.15,136.86,25,128.51,22.39,119.17Z" />
      <path d="M12.82,128.19c-2.65,2.61,1.24-.9-2.72,15.51-1.49,6.14-4,15.3.31,27,0,0,.57-.82.67-1,.38-.53-2.64-6.43-1.73-16.07C10.16,145.09,14.27,136.79,12.82,128.19Z" />
      <path d="M1.16,154.44c1.29-4.74,2.71-10,.92-15.14-2.4,2.59,1.86.58-2,14.73C.08,160.67-.29,159.79,1.16,154.44Z" />
      <path d="M258.38,38.31c-2,5.6-2.71,12-2.18,11.91l1-.09c.89-19.46,17.14-34.69,26.49-50-2,0-.46-.95-5.38,6C271.08,16.39,262.66,26.07,258.38,38.31Z" />
      <path d="M240,4l2.31-.57C228.71-.45,219.51.15,209.24.15c-19.48,1-46.52,8.17-47,8.61L161,10.06C185.75,3.89,213.37-2.89,240,4Z" />
      <path d="M178,11.45A181.41,181.41,0,0,1,222.09,9l3-.92a182.3,182.3,0,0,0-69.68,7.73C151.34,20,154.3,15.71,178,11.45Z" />
      <path d="M147,24.3c38.41-9,54.47-7.88,62.74-11a431.53,431.53,0,0,0-49.68,7C147.07,22.89,148.68,22.66,147,24.3Z" />
      <path d="M140.76,30.29c-2.11,2-8.72,2.77,44.92-7.11C192.6,20.23,200.11,19.35,140.76,30.29Z" />
      <path d="M167.37,31a138,138,0,0,1-30.68,6.18c-4.59.39-3.36-.16-5.27,1.49C153.88,37.66,161.88,33.37,167.37,31Z" />
      <path d="M161.59,41.49c2.59-2.06,5.47-3.9,7.69-6.43.77-.87,1-.86-2,.44-3.67,3.5-8.67,5.94-11.35,10.3C158.73,45.7,155.81,46.08,161.59,41.49Z" />
      <path d="M126.12,37.69a.57.57,0,0,0-.56.68C126.55,37.54,126.47,37.68,126.12,37.69Z" />
      <path d="M125,44.14l-1.12.92c8.67.42,9.46-.45,14.12-2.18C129.7,44.51,125.54,43.67,125,44.14Z" />
      <path d="M343.91,1.05c.34-1.14.53-.9-.93-.9-1.53,5.1-2.74,10.33-2.54,15.66C343.15,13.67,339.82,15,343.91,1.05Z" />
      <path d="M351.88.15h-1.22A31,31,0,0,0,349,8.56C351.42,6.3,349.2,7.85,351.88.15Z" />
      <path d="M330,23.46c-.09-5.84,1.54-11.34,3.66-16.87,3-7.73,3.22-6.44,1.39-6.44-3.26,7.76-6.54,15.69-6.18,24.07Z" />
      <path d="M313,33.51c2.3-1.18.06.07,1.3-8.29C315.67,16.11,320.14,8,324,.15c-1.62,0-1.08-.4-2.26,2C317.29,11.06,311.65,22.1,313,33.51Z" />
      <path d="M303.36,38.08l1.09-.47C301.6,19.38,310,8.54,313.83.15h-1.27C308.91,8,300.34,19.56,303.36,38.08Z" />
      <path d="M291.34,13.49C287,20.28,282.89,27.05,281.27,35c-1.1,5.41-.49,10.81,0,10.68l.94-.26C279,28.7,294.1,13.76,300.56.15,298.3.15,301.25-1.88,291.34,13.49Z" />
      <path d="M292.84,29.74c-1.32,5.27-1.93,14.12-.09,12.13,1.22-1.32-3.75-5.42,8.75-30.2C303.45,7.8,305.35,4.08,307,.15c-1.91,0-.19-1.53-6,10.06C297.83,16.36,294.57,22.82,292.84,29.74Z" />
      <path d="M276.16,23.8c-5.31,12.41-4.5,23.82-3.32,23.89,1.63-.33-.67-2.2.84-11.53,2.24-13.88,10.59-25.28,19.4-36h-1.49C285.63,7.46,279.87,15.12,276.16,23.8Z" />
      <path d="M252.68,27.36c-7.85,13.13-6.37,23.57-5.73,23.54l1,0c-3-19.9,17-35.63,26.33-49.88-2.57.5-.44-.63-6.51,6.9C262.59,14.26,256.82,20.43,252.68,27.36Z" />
      <path d="M232.15,51l1.15,0c2.1-27.24,22.08-35.21,29.75-47.84-3.18.64,0-.39-6.75,6.62C249.06,17.27,233.91,27.71,232.15,51Z" />
      <path d="M222.44,50.41c2.25.19.16,1,3.1-9.69a71.08,71.08,0,0,1,21.3-34.16c-2.19.48-2-.08-5.66,3.91A72,72,0,0,0,222.44,50.41Z" />
      <path d="M207,49c.59.05,3.87-10.85,14.49-24.47C233.75,8.9,240.05,8,234.31,9.59,233.36,9.85,216,24.64,206,49Z" />
      <path d="M195.32,48a19.62,19.62,0,0,1,2.35-8.76c5.87-11.16,18.46-16.19,25.5-26.34-4.52,1.46,1.52-.71-12.72,10.92-5.74,4.69-15.34,12.3-16.19,22.82C194.13,48.17,194,47.89,195.32,48Z" />
      <path d="M183,47c.49-.49.07-5.5,7.3-13.33,4.58-5,10.09-8.84,14.46-14.11l-2.37.95C195.43,28,183.85,35,182,46.46,181.89,47,182.15,46.93,183,47Z" />
      <path d="M169.81,46c.71,0,.23-2.53,6-8,4.14-3.92,9.16-7,12.65-11.63-4.47,1.94.11-.41-8.62,6.68-4.3,3.49-9.07,7.39-11,12.84Z" />
      <path d="M485.08,20.85c5.18,7.57-1.76-.36.56-16.73.68-4.77,1.32-4-.44-4A48.31,48.31,0,0,0,485.08,20.85Z" />
      <path d="M478.75,11.34c3.24,5.42-.15,1,1.27-11.19-1.38,0-1.14-.28-1.27,1.06A51.86,51.86,0,0,0,478.75,11.34Z" />
      <path d="M474.37.15c-1.27,0-1.09-.07-1,.2C474.3,3.47,474,2.61,474.37.15Z" />
      <path d="M497.29,45.5c.07,0,.11.11-.78-1.11C496.69,45,496.76,45.5,497.29,45.5Z" />
      <path d="M493.5,18.08c1,6.62.86,10.17,1.47,17.05,1.64,2.33,1.36,2,1.3,1.52-.32-3.09-.49-6.24-.67-9.25-.64-10.4-3-17-.91-27.25h-1.17A51.66,51.66,0,0,0,493.5,18.08Z" />
      <path d="M503.67,8.69,502.86,8c-4,15.88,11.87,31.37,7.56,46.8l.92.94c4-12.45-5.31-25.28-7.5-37.36C502.56,11.29,504.22,9.18,503.67,8.69Z" />
      <path d="M513.41,23.65c-3-10-.33-7.61-2.88-9.47.5,14.12,11.07,24.7,8.11,48.25l1,.86C522.27,44.37,515.93,32.17,513.41,23.65Z" />
      <path d="M523.39,28.63c-2.41-8.5-2.27-7.45-3.88-8.44a151.63,151.63,0,0,1,8.09,39.09c1,13.39-.52,8.7,1.54,10.69A152.77,152.77,0,0,0,523.39,28.63Z" />
      <path d="M542.83,38.17c-.52-1.66-2.66-7.37-3.69-7.82-4.74-2.07,2.48-.65,5,20.07,1.26,10.53,1.48,24.21,9.61,33.68,6.21,3,1.26,1.46-2.61-6C544.68,65.82,547,51.39,542.83,38.17Z" />
      <path d="M611.11,67.26c-1.48-3.16-4.87-8.56-7.49-9.81l-2.38-1.11a.57.57,0,0,0,.22.67c17.22,11.9,9.55,38.83,23.42,54.65,6.75,2.51-2.11.81-6.51-15.66C615.81,86.44,615.43,76.47,611.11,67.26Z" />
      <path d="M627,74.54c-.65-1.8-2.79-6.7-4-7.33-6-3.21,2.64-.93,5.45,18.79,1.38,9.76,1.6,21.38,8.39,30.14,4,1.5,1.78,1.35-1.15-3.82C629.18,100.8,631.47,87,627,74.54Z" />
      <path d="M636.18,74.58c-4.52-2.65-1-1.69,2.19,7.53,4.1,11.74,3.9,24.61,10.8,35.6.6,1,2.88,4.33,4.06,4.81,7.43,3-2.17,1.15-7.39-15C642.4,96.91,642.09,84.52,636.18,74.58Z" />
      <path d="M692.74,126.87c-5.62-17.81-7.24-17.81-9.45-19.63-1.8-1.49,1.14.66,4.56,9,3.91,9.56,5.68,19.92,11.13,29C704.42,148.76,699.62,148.61,692.74,126.87Z" />
      <path d="M701,151.54c-.51-.36-6.23,1.24-12.25,4.34-2.45,1.26-5.14,2.65-7.9,2.69l1,.89c1.14,1,8.5-3.34,12.48-4.9C702.45,151.39,703.24,153.08,701,151.54Z" />
      <path d="M502.17,1.72c.77.77,13.05,3.31,31.74,1.73,8.17-.7,15.08-1.76,26.87-3.3-18,0-38.11,5.93-59.88.27C501.15.69,501.36.92,502.17,1.72Z" />
      <path d="M535,13.75c-19.83,0-27.6-6.79-20.7-1.88,1.5,1.07,9.13,2.25,11,2.47,24.79,3,49.08-6.53,72-11.81C591.39.38,562.49,13.75,535,13.75Z" />
      <path d="M535.67,24.29c3.92,1.87,3.23,1.71,6.59,1.83,25.87.94,48.54-15,73.37-16.51-2-.8-2-1.72-13,.79C581,15.37,558.57,28.23,535.67,24.29Z" />
      <path d="M529.66,25.87c-3.55-1.81-1.65-1.9,1.87,6.29,8.73,20.31.52,32.28,7.6,44.36l2.28,1.27C531.85,65.82,543.42,49.78,529.66,25.87Z" />
      <path d="M552.67,31.68c4,1.61,3.07,1.42,5.68,1.42,14.86,0,29.76-5.38,44.67-9.63,27.68-7.89,34.74-3.86,32.2-5.12s-1.88-1.05-3.39-1.05C605.35,17.3,578.93,34.28,552.67,31.68Z" />
      <path d="M560.78,67.3c-1.42-10.71-2.87-22.44-9.29-31.8-4.14-1.7-1.18-1.45,2.46,6.82,5.1,11.62,5,24.93,7.63,37.19.29,1.37,2.14,9.11,3,9.46C567.69,90.3,563.94,91.07,560.78,67.3Z" />
      <path d="M654.91,29.44c-1.32-.83-7.06-1.06-8.77-1.06-20.68,0-42.06,12.69-63.19,12.69-8.83,0-13.26-2.55-4.78.92,26.46,2.59,54.21-17.86,78.63-11.11C657.53,31.09,658.23,31.55,654.91,29.44Z" />
      <path d="M593.26,52.72c-2.4-1.07-1.23-1.6,1.42,9.38,3.07,12.73,5,27.12,13.49,38.56,1.8,2.45,5.88,7,8.26,7.87,7.24,2.67-.71-.13-6.55-7.52C599,87.29,598.52,68.64,593.26,52.72Z" />
      <path d="M670.72,40.65c-1.39-1.1-11.78-1.27-24.57,2.43-11.63,3.36-21.16,7.5-35.32,7.26-15.12-.25-28.05-6.63-15.31-1,3.67,1.61,17,3.53,30.39.75,13-2.71,25.24-8.93,38.73-8.93C672.35,41.14,673.84,43.12,670.72,40.65Z" />
      <path d="M685.86,54.35c-.82-.84-7.94-1.4-10.65-1.43-16.17-.16-32.43,6.49-48.46,6.49-12.07,0-18.2-4.22-9.8.05,1.73.87,7.76,1.1,9.75,1.1,16.26,0,32.08-6.49,47.87-6.49C686.69,54.07,688.85,57.46,685.86,54.35Z" />
      <path d="M697.43,68c-.48-.65-9.19-.84-10-.85-14.2-.11-28.26,2.66-42.32,2.51-11.32-.13-12.63-2-8,.7,20.34,2.15,40.91-4.17,61.18-1.29C698.21,69.06,697.63,68.27,697.43,68Z" />
      <path d="M651.51,93.41c-3.24-12.13-2-10.69-5.17-12.67,3.94,6.12,3.18,21.8,18,42.62,4.66,6.53,4.68,5.91,7.1,7A90.71,90.71,0,0,1,651.51,93.41Z" />
      <path d="M669.88,134.14c-.92-.42-6.42,4.42-12,5.4-1.78.32-2.05-.19-.29,1s11.36-4.84,12.6-5.47C671.17,134.56,671.33,134.81,669.88,134.14Z" />
      <path d="M649.69,78.05c3.2,2,1.95,1.71,7.42,2.53,29.41,4.45,41.16-3.46,49.45,1.15C702,74.08,685.94,85.9,649.69,78.05Z" />
      <path d="M725.34,124.22c-.22-.66-13.51-7.67-29.43-11.42,2.9,2.63.13.79,9.51,3.87C726.31,123.54,726.61,128,725.34,124.22Z" />
      <path d="M702.54,164c-10.9,5.94-12.23,3.19-11.24,4.15l1,.91c7.8-.62,13.83-7.36,21.19-8C712.34,160.17,712.4,158.67,702.54,164Z" />
      <path d="M709.06,131.84c-3.08-3.63-1.21-2.75.79,7.28.36,1.79,2.78,15.9,4.37,17.21,3.22,2.66.94,2.18-1.33-7.69C711.84,144.06,710.17,133.15,709.06,131.84Z" />
      <path d="M694.47,117c-.21.87,2.5,1.85,5.16,8.55,3.85,9.68,3.86,22.65,8.2,25.86,1,.7-.25.22-2.12-6.53-.85-3.06-1.44-6.23-2.08-9.29C700.64,121.31,698.23,120.4,694.47,117Z" />
      <path d="M729.65,138c-.22-.76-5.43-4-5.91-4.33a68.93,68.93,0,0,0-13.13-6.28c2.56,2.91.11.85,6.56,4C730.52,137.91,730.84,142.11,729.65,138Z" />
      <path d="M706.69,155.67c-.68-.52-5.33.62-6.25.87-4.59,1.29-8.81,3.36-14.69,6.11-.29.14-.41-.06.68.95,8-3.79,14.63-7,21.38-7C707.91,156.59,708.1,156.74,706.69,155.67Z" />
      <path d="M734.7,155.47c-.44-1.43-9.29-8.89-11.32-10.48.65,1.11.08.11,1.6,2.77C736,157,736,159.66,734.7,155.47Z" />
      <path d="M704.48,9c2.09,2.52-.65.51,3.78-8.84H707C705.46,3.5,704,8.43,704.48,9Z" />
      <path d="M704.87.15h-1.25c-.72,1.74-1.39,3.51-2,5.28C703,7.14,702,7.08,704.87.15Z" />
      <path d="M700.61,4.15c-1.08-4.61.37-4-1.66-4C699,2.34,698.89,1.92,700.61,4.15Z" />
      <path d="M716,21.52l1.09,1.06A48.33,48.33,0,0,1,719.5.15h-1.22C714.68,10.36,715.37,20.93,716,21.52Z" />
      <path d="M725.79,30.44l1,.85a76.59,76.59,0,0,1,1.75-22.4c2.29-10.07,3.59-8.74,1.33-8.74C724.75,14.76,725.16,29.91,725.79,30.44Z" />
      <path d="M737.65,39.56c4.49,3.16.82,1.48-1-6.25-2.73-11.46,1.12-23.15,3.93-33.16h-1.2C735.85,12.6,731.37,27.05,737.65,39.56Z" />
      <path d="M745.66,39c.41,1.25,2.64,7.26,3.61,7.79l1.62.86c-12.95-21.33-2.35-40.71-.26-47.5-1.56,0-1-.37-1.82,1.82C746.35,8.89,739.81,21.19,745.66,39Z" />
      <path d="M756.74,44.54c1,2.76,3.7,7.5,5.66,8.23,5.29,2-1.12-.21-4.24-7.74s-2.3-13-.54-44.88h-1.16C755,26.93,753.6,36.1,756.74,44.54Z" />
      <path d="M768.93,7.9c8.41,14.92,1.56,35.44,12.37,49.72,3.44.5.85.92-2.2-6.1C772.84,37.08,777.41,14.83,768.93,7.9Z" />
      <path d="M805.71,32.44c-3.84-2-1.51-2.41,2.14,8.19C810,46.9,812.62,54,821.57,59.41c4.16,0,2,.57-2.64-3.25C809.57,48.53,809.89,39.84,805.71,32.44Z" />
      <path d="M813.32,36.37c-2.25-1.15-2.35-2.22,2.58,6.44,3.29,5.8,6.51,11.76,11.76,15.68,1.21.91.68.77,3.44.67-1-.73-.94-.76-1.15-.76-1.19,0-4.54-3.35-5.8-4.84C819.36,47.86,814.36,36.9,813.32,36.37Z" />
      <path d="M836,63.07c-2.52.12-2.48-.77,4.9,5.6a245.31,245.31,0,0,1,34.69,36.91c3.88,2.8,5.35,4.78-2.36-4.9A245.19,245.19,0,0,0,836,63.07Z" />
      <path d="M825.19,43.39c-1.53-2.15-.93-1.59-3.9-3,5.36,5.86,8.2,13.81,15.06,18.6,4.16-.17,1.38.88-3.7-5C829.82,50.74,827.69,46.9,825.19,43.39Z" />
      <path d="M845,52.06c-1.26-1.69-.37-1.1-2.78-2.12,2.58,4.41,6.84,8.44,7.62,8.4C853.35,58.18,850.2,59.05,845,52.06Z" />
      <path d="M805.7.24c1.55-.19,1.92-.09-8.6-.09-8.76,1-17.6,1.94-26.27,0-5.27,0-5-.19-2.78.47C779.85,4.07,792.56,1.86,805.7.24Z" />
      <path d="M771,55.49c3.28.88.95,1-1.78-6.24-6.23-16.59-.75-41.63-6.55-46.67C767.11,19.46,761.19,39.84,771,55.49Z" />
      <path d="M771,4.21c1,.86,1.39,1.16,2.55,2.09,17.13,5,36.46-2.83,53,3.06-1.49-1.55-1.14-2-7.19-2.9C803.22,4.18,786,10.17,771,4.21Z" />
      <path d="M832.26,27.39c-25.17,2.28-34.86-4.05-26.73.44,21.88,3.26,30.6-1.27,38.24,1.05l-1.22-1.48C839.22,26.75,835.66,27.08,832.26,27.39Z" />
      <path d="M796.41,27.26c-.58-.34-.23-.57,1.3,4.27,1,3.25,1.89,6.59,2.9,9.85,4.18,13.52,10.89,18.07,11.79,18.07,5.05,0-1.24,1-7-9.37C800.23,40.79,799.8,29.27,796.41,27.26Z" />
      <path d="M840.77,34.13c8.68.62,9.5,2.75,7.22-.07-8-2-16.27-1-24.55-1-9.68,0-11.4-1.67-6.48.69C819.22,34.8,832.89,33.58,840.77,34.13Z" />
      <path d="M835.16,38.72c-9.07,0-11.66-1.94-6.18.64,2.12,1,11.37.29,13.71.06,9.58-1,10.66,1.37,9.81.3l-1-1.31C846.54,37.24,842.26,38.72,835.16,38.72Z" />
      <path d="M834.32,46.48c-3.26-1.47-2.31-1.7.58,3.09,2.19,3.63,4.07,6.59,8.49,9.07,5.24-.24.8.62-4-4.62C837.36,51.79,836,49,834.32,46.48Z" />
      <path d="M845.44,46.88c3.39,1.46,2.89,1.28,3.54,1.28a49,49,0,0,0,9.43-.91C856.61,44.94,857,47.72,845.44,46.88Z" />
      <path d="M855.24,43.2c-1.7-2.18.83-.31-15.32-.31-4.38,0-4.42-.47-1.31,1C840.31,44.61,855.22,43.2,855.24,43.2Z" />
      <path d="M849.16,62.46a339.81,339.81,0,0,0,31.08,39.66c22.34,24.53,57.66,53.63,57.68,53.64,6.57,3.17,7.17,4.92-14.54-13.94C856.66,83.86,854.67,62.21,849.16,62.46Z" />
      <path d="M858.13,40.31c3,3.79,0,1.76,1.54-9.2,1-6.85,3-13.8,2.13-20.76l-1.25-.64C862.13,19.71,857.14,30.13,858.13,40.31Z" />
      <path d="M884.52,69.84c-1.09-3.73-.79-3.12-2.54-3.9,1.78,4.48,2.41,8.62,3.16,9.62C887.56,78.78,887.6,80.29,884.52,69.84Z" />
      <path d="M895.64,89.19c7.08,8.89-.83.53-4.42-18.68l-1.29-.66A89.12,89.12,0,0,0,895.64,89.19Z" />
      <path d="M900.34,88.35c1.06,8.87,1.18,8.05,3,10.19-2.43-7.81-1.51-16.71-5.07-24.37-3.68-1.93-.47-1.36,1.24,8.29C899.87,84.41,900.11,86.42,900.34,88.35Z" />
      <path d="M906.93,72.08c-1.68,1.77-1.56,1.48-1.29,1.63,1.13.59.5.85,2.76-1.51C920.17,60,925.76,61.48,919.78,61.43,915.42,63.87,910.37,68.44,906.93,72.08Z" />
      <path d="M905.78,89.52c.89,14.1,2.34,14.48,5.43,17.74-5.62-8.34-3.18-19.94-5.85-29.3C902.75,76.53,904.94,76.28,905.78,89.52Z" />
      <path d="M922.48,118.23c3.3,2.94,2.12,2.38.59-1.62-3.76-9.83-2.12-17-3.49-26-.93-6-1.58-5.55-2.91-6.26C921,94.31,917,105.16,922.48,118.23Z" />
      <path d="M927.23,120.52c.68,2.61.76,2.39,1,2.59,3.49,2.82-.19,1.38-1.21-11.33-.6-7.47-.11-16.36-3.24-24-2.77-1.29-1.29-1.43.15,4.13C926.34,101.25,924.74,111,927.23,120.52Z" />
      <path d="M934.33,117.2c-.83-8.34-.29-17.57-3.09-25.85-2.64-1.29-1-1.45.29,5.84,1.85,10.28.34,21.33,4,31.45C939.23,131.24,935.59,130,934.33,117.2Z" />
      <path d="M942.61,133.29c3.22,2,1.69,1.56-.11-3.08-5.29-13.63-1.93-34.23-6.46-36.5C939.28,106.59,936.44,121,942.61,133.29Z" />
      <path d="M952,143.32a.49.49,0,0,0,.52.17C951.74,143.08,951.82,143.07,952,143.32Z" />
      <path d="M946.85,134.39c.76,2,.45,1.64,2.13,2.54,1.49.79-3.1-2.17-4.11-20.4,0-.75-.69-18.64-1.77-19.2-2.15-1.13-.35-2.41.41,14.94C943.84,119.74,944.21,127.31,946.85,134.39Z" />
      <path d="M956.49,140.78c3.74,1.86.13,1.7-2.21-12.51-1.49-9.08-1.64-19.14-5.49-27.93-3.28-1.76-1.08-1.69,1.09,6.82C952.7,118.22,951.92,130.14,956.49,140.78Z" />
      <path d="M956.79,110.42c-.18-.89-1.47-6.5-2.25-6.93-3.48-1.93.6-1.75,2.41,16.08.8,8,1.23,16.36,4.77,23.75,3,1.42,1.44,1.43-.42-4.1C958.17,129.94,958.79,120,956.79,110.42Z" />
      <path d="M961.71,107.5c-2.24-1.25-1-1.51-.27,6.66.76,9.15.06,19.18,3.83,28.19,1.65,4,1.85,3.52,3.69,4.37C961.18,135.64,964.2,120.16,961.71,107.5Z" />
      <path d="M975.56,149.71c3.49,1.54,1.44,1.29-.55-3.71-3.55-8.92-1.91-18.62-3.34-28.1-.95-6.32-1.54-5.79-2.87-6.51C973.52,123.32,968.6,138.11,975.56,149.71Z" />
      <path d="M979.65,141.61c-2.11-8.54-1.35-17.37-3.16-26.12-2.24-1.16-1-1.61-.19,6.69.85,8.66.54,18.29,4.77,26.79.38.76,2.07,3.94,3,4.3C988,154.86,982.37,152.61,979.65,141.61Z" />
      <path d="M983.92,125.72c-1.53-8.16-1.95-7.4-3.37-8.12,3,7.92,3.16,16.68,4.76,24.87C985.31,136.52,985.62,134.81,983.92,125.72Z" />
      <path d="M910.21,76.12l1,.54a77.73,77.73,0,0,1,12.68-12.41c4.26-3.31,5-2.66,1.8-2.74A76,76,0,0,0,910.21,76.12Z" />
      <path d="M922.4,72.47c11.45-11.93,15.17-10.31,13-10.47l-2-.14c-7.86,4-12.4,11.35-18.94,16.54C915.67,79,915,80.18,922.4,72.47Z" />
      <path d="M911.8,95.09c-.12,7.32,2.05,14.91,3,15.82l1.73,1.71c-5.27-12.07-3-20.65-3.27-26.07-.06-.93-.46-4.43-1.22-4.87l-1.46-.84C912.94,85.14,911.87,90.35,911.8,95.09Z" />
      <path d="M946.24,94.39l1.39.73c8.73-3.75,18.69-20.34,32.28-25.9-2.18-.5-1.64-.66-4,.63C964,76.5,953.49,92.37,946.24,94.39Z" />
      <path d="M967.77,97.1c-7.86,6-9.41,3.9-6.71,5.41,8.8-3.92,15.15-11.82,22-17.68,2.76-2.37,2.3-1.4,2.3-3.34C978.86,86.37,974.06,92.3,967.77,97.1Z" />
      <path d="M967.68,106.14c7.06-2.52,12.5-8,17.63-13.54V90.91C968.25,109.24,963.68,104,967.68,106.14Z" />
      <path d="M974.67,109.9c.59.32,5.62-2.18,10.64-6.62v-1.55C975,111.16,971.52,108.22,974.67,109.9Z" />
      <path d="M980.63,113.05a35.72,35.72,0,0,0,4.68-2.42c0-2.71.86-.9-6,1.71Z" />
      <path d="M984.51,115c1,.54.8.76.8-1C984.1,115.1,984.28,114.93,984.51,115Z" />
      <path d="M871.12,63.7a4.41,4.41,0,0,0-2-1.82,164.31,164.31,0,0,0,116.17,99.2V159.9A163.2,163.2,0,0,1,871.12,63.7Z" />
      <path d="M837.68,21.63c-3.95-.1-17.71,3.32-38.39-.38-8-1.43-9.13-2.67-4.4.3,22.64,5,38.64,1.22,42.48,1.22C838.94,22.77,839.12,23.3,837.68,21.63Z" />
      <path d="M841.83,9.85c-.33,11.18-.51,9.79,1.77,12.51-2.06-6.33.54-13.45-1.32-20C839.53,1.52,842.08,1.16,841.83,9.85Z" />
      <path d="M914,1.17c10.4-1.5,8.27-1-3.34-1C916.91.92,916.63.63,914,1.17Z" />
      <path d="M792.68,38.78c-.29-1.52-2.61-15.43-3.93-16.29-2.91-1.91-.68-2.81,2.18,13.13,1.24,6.89,3,17.6,10.35,23.78C807.52,59.58,797,61.15,792.68,38.78Z" />
      <path d="M906.53,2.15c-8.86.6-17.87-.64-26.61-2-9.7,0-9.21-.29-2.61.75C895.83,3.83,895.57,3.47,906.53,2.15Z" />
      <path d="M829,6.06l1.13,1.18a20.58,20.58,0,0,0-2.1-7.09h-1.31C828.72,3.77,828.43,5.44,829,6.06Z" />
      <path d="M826.39,14.72c-6.17,0-11.43,1.54-23.48.76-20.32-1.32-27.22-7.29-20.87-2.65,1.15.84,13.79,4,27.28,4,8,0,11.85-1,17.12-1,7.4,0,8.69,2.76,5.87-.35C831.76,14.92,827.54,14.72,826.39,14.72Z" />
      <path d="M836.41,10c.25,5-.32,3.65,1.22,5.4.44.5.09.69,0-3.59-.09-4-.47-7.47-3.28-11.32C829.88-.33,835.93.49,836.41,10Z" />
      <path d="M848.16,18c-.51,10.7.26,10.13,1.49,11.64,1,1.19-.81-.18-.42-9.8.21-5.2.7-10.62-1-15.58C844.88,3,848.91,2.39,848.16,18Z" />
      <path d="M853.44,17.4c-1.05,17.48-.45,16.38,3,20.79-.11-.81-2.8-3.4-2.39-13.27.2-4.65.92-9.34.52-14,0-.46-.41-4.2-1-4.45C850.76,5.2,854.17,5.16,853.44,17.4Z" />
      <path d="M864.81,44.81c0,.88.18,4.36.76,5.12,3.74,4.89-1,1.16.91-12.88,1-7.34,3.24-15.34,1.85-23.1-2.41-1.41-.8-1.42-.71,4.8C867.73,27.53,864.59,35.91,864.81,44.81Z" />
      <path d="M874.55,33.81c.34-5.44.68-11-.39-16.3-2.78-1.77.35-1.68-.76,16.2-.48,7.6-1,16.46,3.1,23.64C880.76,57.21,873,59,874.55,33.81Z" />
      <path d="M882.06,44.73c-1.34-7.59-.26-15.52-1.94-23.25-2.37-1.64-1.13-1.69-.48,4.29.93,8.57-.37,17.85,3.39,26.35.53,1.2,2.64,5.06,3.45,5.06C890.52,57.15,884.36,57.8,882.06,44.73Z" />
      <path d="M884.52,24.59c3.75,10.06,1,24.59,9.9,32.59,3.88.06.89.69-2.24-4.55C886.3,42.75,889.42,28.15,884.52,24.59Z" />
      <path d="M867.74,8.88c2.55,1.47,11.39,2.36,15.47,2.36,13.18,0,23.52-5,23.14-4.93-5.43.66-3.62.32-5.6,1C875.88,15.48,856.33,2.29,867.74,8.88Z" />
      <path d="M891.38,15.06c-15.6.31-24-6.78-14.61-.67a51.85,51.85,0,0,0,41.13-6c1.9-1.21,1.42-1.12.32-1.36C917.36,6.81,908.18,14.74,891.38,15.06Z" />
      <path d="M902.83,20.8C888.37,25,881,17,888.72,22.69c12.06,2.86,23.16-4.81,33.73-8.3,9.78-3.22,12.46-1.12,12.93-2.14C926.85,8.85,913.35,17.73,902.83,20.8Z" />
      <path d="M907.49,46.7c-1.07-5.83,0-4.55-2.36-6.32,1.47,3.89.3,9.14,5.37,16.95C914,57.35,909.42,57.23,907.49,46.7Z" />
      <path d="M908.59,65.77c6.41-5.29,8.18-4.37,4-4.4a80.87,80.87,0,0,0-8,6.2c-4.51,3.64-4.87,3-4.34,3.31C901.49,71.52,900.53,72.41,908.59,65.77Z" />
      <path d="M899.92,40.65c-1.36-6.2-1.06-5.1-2.53-6.24-.54-.42-.24-1,1.42,6.56,1.27,5.84,2.65,11.82,6.66,16.33h1.58C902.66,52.94,901.2,46.53,899.92,40.65Z" />
      <path d="M892,30.2c-2.17-1.69-1.36-2.62-.11,6.74.76,5.75,1.62,12.5,5.87,17.89,2.27,2.89,2.5,2.43,4.33,2.44C893.42,51.61,893.75,39.1,892,30.2Z" />
      <path d="M912.33,45.84,911.14,45c.12,6.77,3.87,12.41,4.56,12.41h1.24A20,20,0,0,1,912.33,45.84Z" />
      <path d="M899.91,61.31c-1,0-4.7,2.69-7,5.69l1,.55C899.35,60.62,905.48,61.33,899.91,61.31Z" />
      <path d="M941.86,15.06c-.77-.36-3.51.32-4.32.55C928,18.26,912,32.18,894.5,27c2.07,1.58,1.86,1.95,6.2,2.19C919.29,30.24,931.11,16,943,16,944,16,944.48,16.3,941.86,15.06Z" />
      <path d="M905.27,61.33c-1,0-5.76,3.24-8.8,7.54l1,.55C903.76,60.66,911.13,61.36,905.27,61.33Z" />
      <path d="M892,61.29c-.92,0-3.6,2.33-4.26,2.95l1.09.57C893.35,60.71,895.85,61.3,892,61.29Z" />
      <path d="M954.37,21.83c-11.08-1.12-21.06,5.77-29.86,10-15.86,7.56-21.54,1.85-17,5.31,14,.79,24.77-10.08,37.64-13.33C955.27,21.22,960.19,25.39,954.37,21.83Z" />
      <path d="M969.33,27c-.17,0-.23-.09.42.4A.5.5,0,0,0,969.33,27Z" />
      <path d="M957,27.77c-14.9,3.8-28.46,16.81-44.09,13.37l2,1.51c17.8,2.11,31.46-13.77,48.58-14.77C962.25,27,962.57,26.35,957,27.77Z" />
      <path d="M931.54,46.36c-10.16,2.21-11.79-.15-8.85,2,.68.51,5.89-.27,7-.45,15-2.6,33.08-14.1,43.66-12.5-1.83-1.51-1.44-1.29-2.18-1.29C960.71,34.12,944.63,43.52,931.54,46.36Z" />
      <path d="M921.14,57.39h1.23c-3.14-3.79-3.44-6.8-4-7.18l-1.21-.84C918,53.24,920.48,57.39,921.14,57.39Z" />
      <path d="M979.12,40.43c-3.3-3-19.3,11.13-50.29,12,1.86,1.07,1.3,1.33,7.91.64a103,103,0,0,0,31.66-8.54C979.72,39.48,981.41,42.49,979.12,40.43Z" />
      <path d="M976.73,46.39c7.06-2.84,8-.8,5.8-2.83-4.65.66-9.06,2.95-13.32,5.15-25.15,13-36.34,4.93-29.19,9C958.74,57.55,968.76,49.6,976.73,46.39Z" />
      <path d="M951.6,60c11.12,1.69,24.08-6.69,33.71-8.34,0-1.4.1-1.19-.37-1.11C975.88,52.14,968.82,57.68,951.6,60Z" />
      <path d="M926.42,73.68c-8.23,8.33-7.56,7-6.27,7.69.43.22,5.54-5.53,10.8-10.55C941.1,61.14,946.2,63,941,62.5c-.81-.09-3.38,1.52-4,1.93A65.21,65.21,0,0,0,926.42,73.68Z" />
      <path d="M977.81,59.84c-9.72,3.72-11.89,2.35-7.14,3.18,2.13.37,11.69-3.78,14.64-4.93C985.31,56.22,986.45,56.52,977.81,59.84Z" />
      <path d="M981.2,65.24c3.48.87,2.53.85,4.11.52C985.31,63.91,986.08,64.73,981.2,65.24Z" />
      <path d="M965,66.36c-5,2.85-8.91,7.07-12.48,11-14.15,15.49-15.83,12.16-13.06,13.55,10.65-6.49,16.93-19.16,27.59-24.3C964.88,66.19,965.21,66.21,965,66.36Z" />
      <path d="M942.79,66.08a66.47,66.47,0,0,0-9.38,8.13c-10.48,10.31-11.26,8.21-9,9.31,9.26-6,15.11-16.47,25.89-19.86C948.21,63.36,947.83,62.71,942.79,66.08Z" />
      <path d="M953.6,64.18c-1.06-.17-5.5,3.15-6.34,3.82a75.88,75.88,0,0,0-7,6.64c-12.23,12.64-14.23,9.7-11.46,11,10.23-5.28,15.65-16.94,26.56-21.19Z" />
      <path d="M956.11,67.59a67.33,67.33,0,0,0-9.69,8.61C932.9,89.9,930.3,86.39,933.28,87.85c.76.37,5.43-3,6.5-3.86C947.44,77.9,953.11,69,962.2,65.7,960.38,65.37,960.25,64.77,956.11,67.59Z" />
      <path d="M971,67.34c-3.65-.71-20.56,19.68-27,23.53-2.14,1.28-2.94.57-3.3.57-.19,0-.32-.1,2.19,1.18,4.66-1.3,14-12.41,20.42-18.34C972,66.19,974.64,68.06,971,67.34Z" />
      <path d="M965.6,85.64c6.81-7.54,11-13.23,17.16-15.74-2.07-.53-1.69-.61-3.3.37-7.13,4.35-13.2,14.09-20.59,20.46-8,6.91-11,4.51-8.09,6.07C951.47,97.17,957.44,94.65,965.6,85.64Z" />
      <path d="M973.37,82.67c-15.78,18.7-21,15-17.41,17,15.15-8.51,19.82-21.5,29.35-26.17V72.2C980.29,74.49,976.86,78.53,973.37,82.67Z" />
      <path d="M950.38,12c-18-4.39-15.7-4.73-7.31-.82,2.91,1.35,16.81,3.67,28.36,8.11,15,5.76,13.88,9.35,13.88,5.84C974.63,17.93,963.17,15.1,950.38,12Z" />
      <path d="M925.51,4.6c26.67,7.52,35.21,1,59.8,15.86V19.12C961.05,4.63,947.74,10,928.13,4.32,946,5.6,961.48,3.17,985.31,12.93V11.7c-24.95-10-41.29-7.33-56.72-8.5a32,32,0,0,0-4.65.81A7.64,7.64,0,0,0,925.51,4.6Z" />
      <path d="M950.61,15c9.51,5.2,2.95,2.4,12.41,6.13,7.72,3,15.59,6,22.29,10.83V30.53C977.84,25.37,969.8,22.53,950.61,15Z" />
      <path d="M700.5,18.37c11.35,7.71,9.88,8.5,16.18,10.39a202.11,202.11,0,0,0-31-28.61c-3,0-3.12-1.23,5.29,5.55a202.4,202.4,0,0,1,21.61,20.18C691.65,9.31,682.22,6.56,663.75,1.5c-5.93-1.63-3.75-1.35-9-1.35C672.63,5.59,682.82,6.36,700.5,18.37Z" />
      <path d="M696.29,20.43c6.25,2.61,6.82,3.25,9.6,4.25,2.37.84,4.22,1.54,6,2.22-16.69-10-36.21-14.73-54.78-21.14C638.93-.5,644.83.15,639.25.15,663,10.11,678.13,12.84,696.29,20.43Z" />
      <path d="M697.45,47A79.38,79.38,0,0,1,718,88.55c.31,1.92-.11,1.47,1.44,1.92a80.26,80.26,0,0,0-19-41.81c-10.71-12.35-22.9-18.65-37.15-25.9-27.22-13.84-17.53-11.22-24.13-12.53C658.27,23.86,680.56,29.1,697.45,47Z" />
      <path d="M650.76,12.54l-4-.82c10.13,5.54,21,9.08,31.08,14.52A108,108,0,0,1,729.87,91.6c.5,1.79.07,1.51,1.63,1.62A109.2,109.2,0,0,0,681.15,26.8C671,20.91,660.83,17.56,650.76,12.54Z" />
      <path d="M666.72,16.26c-3.9-1.12-7.55-3.42,13.15,9.38,21.1,13,53.87,33,59.89,66.11.24,1.31-.13,1.18,1.32.92-5-30.66-31.65-50.05-57.79-66.27C665.46,15.33,667.7,16.54,666.72,16.26Z" />
      <path d="M730.66,55.46C713.22,35.23,697,33.72,681.64,21L678.83,20c22.83,20.87,43.74,12.15,72.24,70.22L752,90C752.4,89.9,742.69,69.4,730.66,55.46Z" />
      <path d="M711.45,31.11c-4.61-1.85-7.08-4.47,28.24,25.9,8.71,7.49,14.57,12.44,19.42,18,9.32,10.67,5.77,11.51,8.57,10.74C761.57,72.79,752.07,66.17,711.45,31.11Z" />
      <path d="M775.34,78.68c2.67,5.16,1.43,4.68,3.18,4.27a49.92,49.92,0,0,0-9.24-15.1C762.81,64.71,769.35,67.13,775.34,78.68Z" />
      <path d="M866.36,61.77l-1,0c-.59,0,2.71,12.44,9.43,24.35,16.62,29.47,44.91,48.32,72.29,66.45,20.57,13.61,18.42,12.64,19,12.61,2.28-.11,5.77,2.52-24-17.31C909.06,125.94,875.48,100.66,866.36,61.77Z" />
      <path d="M820.24,63.63c-4.23.08-3.22-.87,6.07,4.7a121.53,121.53,0,0,1,29.62,25c.87.47,1.78,1,2.78,1.54A122.3,122.3,0,0,0,820.24,63.63Z" />
      <path d="M805.83,64.66c-3.11-1.32-1.89-1-6.12-1.2a147.87,147.87,0,0,1,35,18.88c5.75,4.18,2.29,2.27,7.71,4.52A148.85,148.85,0,0,0,805.83,64.66Z" />
      <path d="M782.65,23.46c-2.1-7.42-3.37-7.51-6-9.51,10.06,12.05,4.08,31.9,13.92,44.75,3.14.3,1,.79-1.77-5.2C784.49,44.17,785.47,33.4,782.65,23.46Z" />
      <path d="M842.36,91.21c-.88-.37-6.14,2.24-7.35,2.91-11.52,6.37-19.11,17.3-28.63,25.46-9.07,7.76-12.3,5.14-8.41,6.69,14.92-5.74,25.87-29.39,45.78-34.45Z" />
      <path d="M768.66,59.06c-2-.55-3.76-1.11-5.43-1.69,18.48,10.42,44.33,11.89,59,23.23.64.13,1.5.3,2.44.51-8.68-7.57-20.31-10.66-33.51-14.53C770.81,60.59,772.36,60.08,768.66,59.06Z" />
      <path d="M730.56,113c6,11.32,9.14,23.62,12.21,35.79,7.83,31.1,11.26,33.86,17.61,45.12,5.87,10.43,2.56,9.21,5.62,11.36-1.09-7.11-5.5-13.26-9-19.38-14.47-25.4-16.65-75.93-37.81-88.81C720.14,99.28,724.71,102,730.56,113Z" />
      <path d="M781.16,217.69c.1-.62.29-.41-1-.79-.08.51-.16.75,0,1A.55.55,0,0,0,781.16,217.69Z" />
      <path d="M731.7,109.2c-3.05-3.48-.54-.83,3.52,5.57,8.79,13.84,14.67,28.59,25,45.62,5,8.25,10.4,16.56,14.37,25.45,8.08,18.13,5.14,26.74,6.08,27l.94.28c1.18-23.4-14.9-42.94-25.54-62.16C743.75,128.71,742.42,121.41,731.7,109.2Z" />
      <path d="M744.35,125.21c14.18,31.65,54,51.64,54.78,87.82,0,2.12-.36,1.77,1.16,1.74.4-23.58-17.23-42.4-30.64-57C751.81,138.33,754.13,138.71,744.35,125.21Z" />
      <path d="M820,204.28c4.61,6.78,3.58,10.11,4.28,10.12l1,0c-1.07-9.89-9.72-17.56-17-24.25-23.14-21.38-15.36-14.63-23.48-20.12C811.65,194.87,815.89,198.23,820,204.28Z" />
      <path d="M774.25,156.34c.68.65,1.36,1.29,1.69,1.59-4.33-12.27,2.82-27.21-3.65-39.16-4-2-.56-1.1,1,6.13C775.5,135.21,771.61,146,774.25,156.34Z" />
      <path d="M863.32,218.89c.71.18,1.9.49,2.87.76,1.73.49,12-1.34,23.09-5.4,18.8-6.89,46.56-22.83,81.48-22.83,16.17,0,14.55,2.39,14.55-.15C931.64,183.76,897.35,217.56,863.32,218.89Z" />
      <path d="M851,220.67c.29-.14.42-.07-.77-.25A.57.57,0,0,0,851,220.67Z" />
      <path d="M855.51,217.23c.65.11,1.07.19,1.94.36a380.6,380.6,0,0,1,114-32.84c15.95-2,13.86-.46,13.86-2.42C968.75,183.06,914.12,189.53,855.51,217.23Z" />
      <path d="M829.79,229.21c-20.11,12.84-22.7,8.92-19.36,10.92,14.33-3.56,25.35-16.11,39.54-19.75C847.22,220,846.35,218.64,829.79,229.21Z" />
      <path d="M844.87,215.73,847,216a432.22,432.22,0,0,1,138.35-41.5v-1.15C937.81,179.2,888.81,193.47,844.87,215.73Z" />
      <path d="M897.92,185.55c-7.59,1.51,1.62.08-24.82,10.21-11.54,4.42-23.61,9.58-31.17,19.66l1.35.14C856.4,198.79,880.62,197.12,897.92,185.55Z" />
      <path d="M856.05,185.22h1.49c1.45,0-18.37-12.37-22.19-40.35l-1.22-.43A65.14,65.14,0,0,0,856.05,185.22Z" />
      <path d="M860.49,189.35c-1.6,0-1.08-.15-1.51.62-2.33,4.17-14.94,10.31-18.29,18.23-.27.64,0,.38,0-2.28,0-4.43-1-8.85-1-13.26s1.13-3.82-.73-4c-1.81,8.66,3.16,18.86-1.57,26.35l1.29.1c1.65-2.93.51-2.86,1.23.11l1.22.11C837.73,203.66,858.49,195.72,860.49,189.35Z" />
      <path d="M797.42,231.66c12.34,0,22.09-10.24,33.55-13-6.18-.25-3-.46-19.27,7.71-12.28,6.15-17.74,3.34-16.5,4.18C797,231.79,796.64,231.66,797.42,231.66Z" />
      <path d="M827.49,197.61c5.23,5.69,6,9.81,7.66,17.26l1.91.14c.92-8.48-2.82-20-7-27.36-2.87-.42-1.07-1.34,2.57,8.21,2,5.32,3.69,11.93,3.44,17.62-.87-3.87-1.7-7.57-3.55-11-4.69-8.69-19.38-18.72-23.23-20.12C791.21,175.81,813.36,182.21,827.49,197.61Z" />
      <path d="M824.73,182.59c3.62.76,1.65,1.24-3.35-6.21-8.72-13-9.63-24.77-8.72-40l-1.13-.45C810.49,153,811.93,168.11,824.73,182.59Z" />
      <path d="M840.44,184.72c1,.07,1.5.09,2.15.13,1.39.07-18.31-14.71-19.09-44.28l-1-.36c-.46-.18-.75,24.75,18.51,44.45A.55.55,0,0,0,840.44,184.72Z" />
    </svg>
  );
}
