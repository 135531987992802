import styled from "styled-components/macro";

export const Root = styled.div`
  position: absolute;
  /* transform: translate(-50%, 0); */
  left: 40vw;
  bottom: 4%;
  width: 15vh;
  /* width: 15vh; */
  /* border: rgba(255, 153, 148, 0.6) solid 1.5px; */
  /* border-radius: 100%; */
`;
