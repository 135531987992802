import React, { memo, useRef, useMemo, useCallback } from "react";
import { useGLTF } from "@react-three/drei/";
import * as THREE from "three";
import { FIELD_NAME_MAPPER } from "App";
import { DoubleSide } from "three";

const STUDIO_MATERIAL = new THREE.MeshPhongMaterial({
  color: "#56A37D",
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const ONE_BED_MATERIAL = new THREE.MeshLambertMaterial({
  color: "#9C1A0E",
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const TWO_BED_MATERIAL = new THREE.MeshLambertMaterial({
  color: "#8c4942",
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const DUPLEX_MATERIAL = new THREE.MeshLambertMaterial({
  color: "#EBAF49",
  opacity: 0.9,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const COMING_SOON_MATERIAL = new THREE.MeshLambertMaterial({
  color: "#13170E",
  opacity: 0.7,
});

const UNAVAILABLE_MATERIAL = new THREE.MeshLambertMaterial({
  color: "#0F0E0E",
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const SOLD_MATERIAL = new THREE.MeshPhysicalMaterial({
  color: "#000",
  opacity: 0.01,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const MATERIAL = new THREE.MeshLambertMaterial({
  color: "#0B0D0D",
  opacity: 0.9,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

const LABEL = new THREE.MeshLambertMaterial({
  color: "#0b0b0b",
  opacity: 0.9,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  flatShading: true,
  side: DoubleSide,
});

export default function Model({
  availableApartmentsById,
  meshClickedCallback,
  distanceToInView,
  meshID,
  inView,
  floorStartsAt,
  numberOfApt,
  buildingName,
  ...props
}) {
  const floors = useFloors({ buildingName, floorStartsAt, numberOfApt });

  const group = useRef();

  const hovered = useRef();

  const handleMouseOver = useCallback(
    ({ object: { name } }) => {
      if (!!availableApartmentsById[name.toLowerCase()]) {
        hovered.current = name;
        document.body.style.cursor = "pointer";
      }
    },
    [availableApartmentsById]
  );

  const handleMouseOut = useCallback(({ object: { name } }) => {
    if (hovered.current === name) {
      hovered.current = false;
      document.body.style.cursor = "auto";
    }
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      {floors.map((mesh) => (
        <Apartment
          key={mesh.name}
          mesh={mesh}
          availableApartmentsById={availableApartmentsById}
          isSelectable={!!availableApartmentsById[mesh.name.toLowerCase()]}
          onClick={meshClickedCallback}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          apartment={availableApartmentsById[mesh.name.toLowerCase()]}
        />
      ))}
    </group>
  );
}

const Apartment = memo(
  ({ mesh, isSelectable, apartment, onClick, onMouseOver, onMouseOut }) => {
    const handleClick = useCallback(() => {
      if (isSelectable) {
        onClick(apartment);
      }
    }, [isSelectable, apartment, onClick]);

    return (
      <group>
        <mesh
          name={mesh.name}
          onPointerOver={onMouseOver}
          onPointerOut={onMouseOut}
          onClick={handleClick}
          material={
            mesh.name.includes("rest")
              ? mesh.name.includes("label")
                ? LABEL
                : MATERIAL
              : getMaterial(apartment) ?? MATERIAL
          }
          geometry={mesh.geometry}
        />
      </group>
    );
  }
);

const getMaterial = (apartment) => {
  const type = apartment?.[FIELD_NAME_MAPPER.beds];
  const status = apartment?.[FIELD_NAME_MAPPER.status];
  console.log("TYPEE", type, type?.toLowerCase() === "studio");
  if (status === "3") {
    return SOLD_MATERIAL;
  } else if (status === "2") {
    return UNAVAILABLE_MATERIAL;
  } else if (status === "1") {
    return COMING_SOON_MATERIAL;
  } else if (type === "1") {
    return ONE_BED_MATERIAL;
  } else if (type === "2") {
    return TWO_BED_MATERIAL;
  } else if (type === "st" || type?.toLowerCase() === "studio") {
    return STUDIO_MATERIAL;
  } else if (type === "duplex") {
    return DUPLEX_MATERIAL;
  }
  return UNAVAILABLE_MATERIAL;
};

const useFloors = ({ buildingName, floorStartsAt, numberOfApt }) => {
  const { nodes } = useGLTF(
    `${process.env.PUBLIC_URL}/assets/floorplans/${buildingName}.gltf`
  );

  return useMemo(() => {
    const nodeList = Object.values(nodes);

    let floors = [];

    for (let i = floorStartsAt; i < floorStartsAt + numberOfApt; i++) {
      floors.push(nodeList[i]);
    }
    // console.log("floors", floors);
    return floors;
  }, [nodes, floorStartsAt, numberOfApt]);
};
