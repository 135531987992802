import { useState } from "react";

export default function FavouriteButtonIcon(props) {
  return (
    <svg
      style={{
        cursor: "pointer",
      }}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      viewBox="-5 -5 150.44 150.44"
      width={"6vh"}
      height={"6vh"}
      {...props}
    >
      <g strokeWidth={3} id="Layer_2" data-name="Layer 2">
        <circle
          // stroke="#004A3F"
          fill="#FFE9CE"
          cx="63.22"
          cy="63.22"
          r="62.39"
        />
        <path
          stroke="#004A3F"
          fill="none"
          d="M63.22,91.8A3.39,3.39,0,0,1,61,91c-2.36-2.06-4.64-4-6.64-5.71h0c-5.89-5-11-9.34-14.5-13.61-4-4.77-5.79-9.29-5.79-14.23a18.22,18.22,0,0,1,4.63-12.46,15.7,15.7,0,0,1,11.68-5.09A14.67,14.67,0,0,1,59.51,43a18.49,18.49,0,0,1,3.71,3.87A19,19,0,0,1,66.93,43a14.72,14.72,0,0,1,9.18-3.17,15.7,15.7,0,0,1,11.68,5.09,18.22,18.22,0,0,1,4.63,12.46c0,4.94-1.84,9.46-5.79,14.23C83.1,75.89,78,80.21,72.13,85.23c-2,1.71-4.28,3.65-6.65,5.72A3.39,3.39,0,0,1,63.22,91.8Z"
        />
      </g>
    </svg>
  );
}
