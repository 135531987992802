const buildingData = {
  ha: {
    numberOfAprt: [
      10,
      14,
      17,
      17,
      17,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      10,
      9,
      9,
      9,
    ],
    floorStartsAt: [
      0,
      10,
      24,
      41,
      57,
      75,
      88,
      101,
      114,
      127,
      140,
      153,
      166,
      179,
      189,
      198,
      207,
    ],
  },
  hb: {
    numberOfAprt: [9, 7, 7, 7, 7, 11],
    floorStartsAt: [0, 9, 16, 23, 30, 37],
  },
  ib: {
    numberOfAprt: [8, 11, 11, 11, 11, 11, 11, 11],
    floorStartsAt: [0, 8, 19, 30, 41, 52, 63, 74],
  },
  ic: {
    numberOfAprt: [10, 11, 11, 11, 11, 11, 11],
    floorStartsAt: [0, 10, 21, 32, 43, 54, 65],
  },
  id: {
    numberOfAprt: [10, 11, 11, 11, 11, 11, 11],
    floorStartsAt: [0, 10, 21, 32, 43, 54, 65],
  },
  ia: {
    numberOfAprt: [7, 8, 8, 8, 8, 8, 8, 6],
    floorStartsAt: [0, 7, 15, 23, 31, 39, 47, 55],
  },
};
export default buildingData;
