import { useContext } from "react";
import { BasicIcon } from "screens/MainApp/Home/Orbits/MarkerIcon.style";

import { ThemeContext } from "styled-components";

export default function TogglePOIIcon({ isVisible }, props) {
  const theme = useContext(ThemeContext);
  // console.log(isVisible);

  return (
    <BasicIcon
      // style={{ border: "red solid 2px" }}
      viewBox={`25 25 80 80`}
      // viewBox={`20 20 50 50`}
      fill="none"
      strokeWidth={3.3}
      stroke={
        isVisible ? theme.palette.fg.darkGreen : theme.palette.fg.darkPink
      }
    >
      {/* <circle
        // stroke={theme.palette.fg.darkPink}
        stoke="#000"
        fill="#fff"
        strokeWidth="1.5"
        cx={0}
        cy={0}
        r={16}
      ></circle> */}
      {/* <circle cx="64.5" cy="64.6" r="62.9" /> */}
      <line x1="64.1" y1="50.7" x2="64.1" y2="45.6" />
      <polyline points="58.3,58.4 64.1,58.4 64.1,81.9 " />
      <line x1="56.7" y1="81.7" x2="71" y2="81.7" />
      <circle cx="64.5" cy="64.6" r="38" />
    </BasicIcon>
  );
}
