export function copyUrlToClipboard(id) {
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // ridiculous, Safari is the new IE
    var el = document.querySelector(`#${id}`);
    var editable = el.contentEditable;
    var readOnly = el.readOnly;
    el.contentEditable = true;
    el.readOnly = false;
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    el.setSelectionRange(0, 999999);
    el.contentEditable = editable;
    el.readOnly = readOnly;
  } else {
    var emailLink = document.querySelector("#copyable-url");
    emailLink.select();
    try {
      document.execCommand("copy");
    } catch (err) {}
  }
}
export const priceFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
});
