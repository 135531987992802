import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export const Root = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: hidden;
`;

export const FavouritesLink = styled(Link)`
  /* border: white solid 2px; */
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(5)}px;
  top: ${({ theme: { spacing } }) => spacing(3)}px;
  /* border: red 2px solid; */
  display: flex;
  color: grey;

  justify-content: center;
  align-items: center;

  flex-direction: row;
  h1 {
    position: absolute;
    color: grey;
  }
`;

export const NumInFaves = styled.div`
  font-size: ${({ theme: { spacing } }) => spacing(2)}px;
  line-height: ${({ theme: { spacing } }) => spacing(2)}px;
  letter-spacing: 0;
  transform: translate(0, ${({ theme: { spacing } }) => spacing(0.4)}px);
  display: flex;
  /* border: red 2px solid; */

  color: #ff9994;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
`;
