import React from "react";

function SharedFindOutMore() {
  return (
    <div
      style={{
        flex: 1,
        cursor: "pointer",
        padding: "20px",
        height: "100%",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/shared-ownership-peabody-link.png)`,
          backgroundSize: "contain",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          height: "100%",
        }}
      ></div>
    </div>
  );
}

export default SharedFindOutMore;
