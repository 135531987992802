import styled from "styled-components/macro";
import { showOutlines } from "./showOutlines";
import { Link } from "react-router-dom";

export const BackButton = styled(Link)`
  user-select: none;
  cursor: pointer;
  position: absolute;
  left: 0%;
  bottom: 2%;

  z-index: 100;
  height: ${({ theme: { spacing } }) => spacing(4)}px;

  display: flex;

  font-weight: 300;
  font-size: 5vh;

  margin: ${({ theme: { spacing } }) => spacing(2)}px;

  justify-content: center;
  align-items: center;
`;

export const KeyItemCircle = styled.div`
  margin: 10px;
  margin-right: 4px;
  height: 1.7vh;
  width: 1.7vh;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`;

export const Key = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 7%;
  bottom: 4%;
  width: max-content;
`;

export const RowContainer = styled.div`
  display: flex;
  /* border: white solid 2px; */
  justify-content: space-around;
  flex-shrink: 1;
  padding: 1vh;
  margin: 2px;
  max-height: 2.2vh;
  width: max-content;
  flex-basis: 100%;
`;
export const AptTypeKey = styled.div`
  letter-spacing: 0.5vh;
  color: ${(props) => props.color};
  flex-basis: space-around;
  font-size: 1.7vh;
  margin: 10px;
`;

export const ThreeDViewerContainer = styled.div`
  display: flex;
  transform: translate(45px, 0px);
  width: ${(props) => props.divSize};
  height: ${(props) => props.divSize};
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const CircleSliderContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;

  width: 100%;
  height: 100%;

  outline: ${showOutlines ? "#fff solid 1px" : ""};
`;

export const BuildingExplorerFilterContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
`;

export const BuildingName = styled.div`
  font-family: "Tungsten Extra";
  display: flex;
  align-items: center;
  /* border: white solid 2px; */
  font-weight: 200;
  top: 0;
  font-style: normal;
  font-display: swap;
  letter-spacing: 15px;
  position: absolute;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  /* top: -90px; */
  padding: ${({ theme: { spacing } }) => spacing(0.1)}rem;
  padding-bottom: 0;
  padding-top: 0;
  margin: 0px;
  height: max-content;

  h1 {
    /* border: red solid 2px; */
    padding: 0px;
    margin: 0px;
    padding-left: ${({ theme: { spacing } }) => spacing(2)}px;
    font-size: ${({ theme: { spacing } }) => spacing(0.45)}rem;
  }
  h2 {
    padding: 0px;
    margin: 0px;
    font-size: 2rem;
  }
  z-index: 1000;

  background-color: #003028;

  color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
`;

export const ButtonContainer = styled.div`
  bottom: 2%;
  min-height: 40vh;

  flex-basis: 2%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const FloorButtonWr = styled.div`
  h1 {
    padding: 0;
    font-size: 2vh;
    margin: 0 auto;
    margin-left: 20%;
    user-select: none;
  }

  p {
    position: absolute;
    user-select: none;
    padding: 0;
    font-size: 2vh;
    margin: 0 auto;
    margin-left: 3%;
  }

  vertical-align: middle;
  z-index: 1000;
  font-size: 2vh;

  display: flex;
  flex-direction: column-reverse;

  text-align: center;
  justify-content: left;
  justify-items: left;
  align-content: left;
  align-items: left;

  background-color: rgba(252, 235, 204, ${(props) => props.opac});

  transition: background-color 0.2s ease;

  width: 5.5vh;
  height: 3.5vh;
  margin-top: 1vh;
  margin-bottom: 1vh%;
  cursor: pointer;
  color: ${(props) => (props.active ? "#1D4A3D" : "#FCEACB")};

  border-bottom: dashed 1px;
`;

export const BuildingExplorerContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  outline: none;
  background: #003028;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  overflow: hidden !important;
`;
