import { FIELD_NAME_MAPPER } from "App";
import React from "react";
import { priceFormatter } from "util/helpers";

function ShareedSVGTable({ currentApt }) {
  console.log(currentApt);
  return (
    <div
      style={{
        width: "70%",
      }}
    >
      <svg viewBox="0 0 466 118" fill="none">
        <g id="SHARED-OWNERSHIP-PRICING">
          <g id="Group 210">
            <text
              id="Full Value Deposit Required Share Price Share Value Monthly Cost"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="bold"
              letter-spacing="0.05em"
            >
              <tspan x="7" y="42.7578">
                FULL VALUE &#10;
              </tspan>
              <tspan x="7" y="56.2313">
                DEPOSIT REQUIRED &#10;
              </tspan>
              <tspan x="7" y="69.7049">
                SHARE PRICE &#10;
              </tspan>
              <tspan x="7" y="83.1784">
                SHARE VALUE &#10;
              </tspan>
              <tspan x="7" y="96.652">
                MONTHLY COST{" "}
              </tspan>
            </text>
            <text
              id="testvalue"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="112" y="41.6058">
                {priceFormatter.format(
                  Number(currentApt[FIELD_NAME_MAPPER.askingPrice])
                )}
              </tspan>
            </text>
            <text
              id="testvalue_2"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="397" y="41.6058">
                {priceFormatter.format(Number(currentApt["Monthly Mortgage"]))}
              </tspan>
            </text>
            <text
              id="testvalue_3"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="112" y="54.6058">
                {priceFormatter.format(Number(currentApt["Deposit"]))}
              </tspan>
            </text>
            <text
              id="testvalue_4"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="397" y="54.6058">
                {priceFormatter.format(Number(currentApt["Monthly Rent"]))}
              </tspan>
            </text>
            <text
              id="testvalue_5"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="112" y="68.6058">
                {priceFormatter.format(Number(currentApt["Share price"]))}
              </tspan>
            </text>
            <text
              id="testvalue_6"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="397" y="68.6058">
                {priceFormatter.format(Number(currentApt["Service charge"]))}
              </tspan>
            </text>
            <text
              id="testvalue_7"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="112" y="82.6058">
                {currentApt["Share value"]}
              </tspan>
            </text>
            <text
              id="testvalue_8"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="397" y="82.6058">
                {priceFormatter.format(
                  Number(currentApt["Minimum Household Income"])
                )}
              </tspan>
            </text>
            <text
              id="testvalue_9"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="112" y="96.6058">
                {priceFormatter.format(Number(currentApt["Monthly Cost"]))}
              </tspan>
            </text>
            <text
              id="testvalue_10"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="300"
              letter-spacing="0.05em"
            >
              <tspan x="397" y="96.6058">
                {priceFormatter.format(
                  Number(currentApt["Maximum Household Income"])
                )}
              </tspan>
            </text>
            <text
              id="Monthly Mortgage Monthly Rent Monthly Service Charge Minimum Household Income Maximum Household Income"
              fill="#134729"
              fontFamily="Akkurat Pro"
              font-size="8.93765"
              font-weight="bold"
              letter-spacing="0.05em"
            >
              <tspan x="239.57" y="29.2395">
                &#10;
              </tspan>
              <tspan x="239.57" y="42.713">
                MONTHLY MORTGAGE &#10;
              </tspan>
              <tspan x="239.57" y="56.1866">
                MONTHLY RENT &#10;
              </tspan>
              <tspan x="239.57" y="69.6601">
                MONTHLY SERVICE CHARGE &#10;
              </tspan>
              <tspan x="239.57" y="83.1337">
                MINIMUM HOUSEHOLD INCOME &#10;
              </tspan>
              <tspan x="239.57" y="96.6072">
                MAXIMUM HOUSEHOLD INCOME{" "}
              </tspan>
            </text>
          </g>
          <text
            id="SHARED OWNERSHIP MONTHLY PRICING:"
            fill="#E73458"
            fontFamily="Akkurat Pro"
            font-size="9.63933"
            font-weight="bold"
            letter-spacing="0.1em"
          >
            <tspan x="0.36377" y="9.95885">
              SHARED OWNERSHIP MONTHLY PRICING:
            </tspan>
          </text>
          <line
            id="Line 123"
            x1="0.834473"
            y1="22.428"
            x2="432.159"
            y2="22.428"
            stroke="#E73458"
            stroke-width="0.941756"
          />
          <line
            id="Line 124"
            x1="189.657"
            y1="117.074"
            x2="189.657"
            y2="34.1999"
            stroke="#2C4F43"
            stroke-width="0.941756"
          />
        </g>
      </svg>
    </div>
  );
}

export default ShareedSVGTable;
