import React, { useEffect } from "react";
import { noop } from "lodash";
import Cover from "components/Cover";
import Video from "./Video";
import { ArrowLeft, ArrowRight } from "components/Arrow";
import usePairedSegments from "./usePairedSegments";
import { Root, LeftNavButton, RightNavButton, Children } from "./Orbit.style";

const Orbit = ({
  aspectRatio,
  segments,
  index: initialIndex,
  children,
  onIndexChanged = noop,
}) => {
  const {
    index,
    videos,
    current,
    left,
    right,
    playing,
    onPlay,
    onEnd,
  } = usePairedSegments(segments, initialIndex);

  useEffect(() => {
    onIndexChanged(index);
  }, [index, onIndexChanged]);

  return (
    <Root>
      <Cover aspectRatio={aspectRatio}>
        {videos.map((url) => (
          <Video
            isVisible={url === current}
            className={`orbit-vid`}
            key={url}
            url={url}
            playing={playing === url}
            onEnd={onEnd}
          />
        ))}
        <Children isVisible={!playing}>{children}</Children>
      </Cover>
      <LeftNavButton
        onClick={() => {
          onPlay(left);
        }}
      >
        <ArrowLeft />
      </LeftNavButton>
      <RightNavButton
        onClick={() => {
          onPlay(right);
        }}
      >
        <ArrowRight />
      </RightNavButton>
    </Root>
  );
};

export default Orbit;
