import Pattern from "components/Icons/Pattern";
import { useState, useContext, useCallback } from "react";
import { ThemeContext } from "styled-components";
import MarkerIcon from "./MarkerIcon";
import { Root, Content } from "./Marker.style";

const Marker = ({ position: { x, y }, html }) => {
  const [showContent, setShowContent] = useState(false);
  const theme = useContext(ThemeContext);

  const handleMouseOver = useCallback(() => setShowContent(true), []);
  const handleMouseOut = useCallback(() => setShowContent(false), []);

  return (
    <Root left={x} top={y}>
      <MarkerIcon onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
      <Content isVisible={showContent}>
        <div
          style={{
            position: "relative",
            margin: 0,
            padding: 0,
            flexBasis: "200px",
          }}
        >
          <Pattern
            style={{
              position: "absolute",
            }}
            fill={theme.palette.fg.cream}
            strokeOpacity={0}
            fillOpacity={0.6}
            strokeWidth="0.2px"
            viewBox={`0 100 200 200`}
            width="100%"
            height="100%"
          />

          <svg
            style={{
              position: "absolute",
            }}
            width="100%"
            height="100%"
            viewBox="0 0 500 500"
          ></svg>
        </div>
        <div
          style={{
            // flexGrow: 1,
            flexBasis: "450px",
            height: "100%",
          }}
        >
          {/* <h1>THIS IS SOME INFO</h1> */}
          <p
            // style={{ color: "blue" }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Content>
    </Root>
  );
};

export default Marker;
