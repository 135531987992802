import React, { useMemo, memo, useRef } from "react";
import { useGLTF } from "@react-three/drei/core/useGLTF";
import * as THREE from "three";

const SELECTED_MATERIAL = new THREE.MeshPhysicalMaterial({
  color: "#C21129",
  toneMapped: false,

  flatShading: true,
  side: THREE.DoubleSide,
});

const UNSELECTED_MATERIAL = new THREE.MeshPhysicalMaterial({
  color: "#524D4D",
  flatShading: true,
  side: THREE.DoubleSide,
  // wireframe: true
});

const AVAILABLE_MATERIAL = new THREE.MeshPhysicalMaterial({
  color: "#9C7C6B",
  toneMapped: false,
  flatShading: true,
  side: THREE.DoubleSide,
});

const UNAVAILABLE_MATERIAL = new THREE.MeshPhysicalMaterial({
  color: "#594D47",
  toneMapped: false,
  flatShading: true,
  side: THREE.DoubleSide,
});

const WINDOW_MATERIAL = new THREE.MeshPhysicalMaterial({
  reflectivity: 1,
  transmission: 1,
  color: "#fff",
  clearcoatRoughness: 0,
  metalness: 0.7,
  roughness: 0.06,
  toneMapped: true,
  flatShading: true,
});

export default function Model(
  { isActive, buildingName, availableApartmentsById },
  props
) {
  const group = useRef();
  const { nodes } = useGLTF(
    `${process.env.PUBLIC_URL}/assets/buildings/${buildingName}.gltf`
  );

  const uniqueFloors = useMemo(() => {
    const floors = [];
    const available = Object.values(availableApartmentsById);
    for (let i = 0; i < available.length; i++) {
      floors.push(parseInt(available[i].floor));
    }
    return [...new Set(floors)];
  }, [availableApartmentsById]);
  // return null;
  return (
    <group ref={group} {...props} dispose={null}>
      {isActive.map(({ id, active }) => {
        return (
          <BuildingFloor
            key={id}
            floorNumber={id - 1}
            geometry={nodes[`meshID-${id}`].geometry}
            position={nodes[`meshID-${id}`].position}
            isActive={active}
            uniqueFloors={uniqueFloors}
          />
        );
      })}
      {nodes[`meshID-rest`] && (
        <BuildingRest
          position={nodes[`meshID-rest`].position}
          geometry={nodes[`meshID-rest`].geometry}
        />
      )}
      <Windows geometry={nodes[`meshID-windows`].geometry} />
    </group>
  );
}

const BuildingFloor = memo(
  ({ isActive, floorNumber, uniqueFloors, geometry }) => (
    <mesh
      material={
        isActive
          ? SELECTED_MATERIAL
          : uniqueFloors.includes(floorNumber)
          ? AVAILABLE_MATERIAL
          : UNAVAILABLE_MATERIAL
      }
      geometry={geometry}
    />
  )
);

const BuildingRest = memo(({ geometry }) => (
  <mesh material={UNSELECTED_MATERIAL} geometry={geometry} />
));

const Windows = memo(({ geometry }) => (
  <mesh material={WINDOW_MATERIAL} geometry={geometry} />
));
