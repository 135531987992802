import styled from "styled-components/macro";

const Button = styled.button`
  position: absolute;
  margin: 20px;
  right: 0;
  /* top: 0; */
  background-color: #004a3e;
  color: #ffe8cb;
`;

export default Button;
