import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSalesInfo } from "state/apartments";

const SalesInfoPreload = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(fetchSalesInfo());
    dispatch(fetchSalesInfo());
  }, [dispatch]);

  return null;
};

export default SalesInfoPreload;
