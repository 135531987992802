import styled from "styled-components/macro";

const TAB_WIDTH = 40;
const TAB_HEIGHT = 200;

export const Root = styled.div``;

export const Tab = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  height: ${TAB_WIDTH}px;
  width: ${TAB_HEIGHT}px;
  transform-origin: right bottom;
  transform: translate(-${TAB_WIDTH}px, ${TAB_HEIGHT / 2 - TAB_WIDTH}px)
    rotate(90deg);
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  position: absolute;
  right: ${({ isOpen, contentWidth }) => (isOpen ? "0" : `-${contentWidth}px`)};

  transition: right 0.3s ease;
  top: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
