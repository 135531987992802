import styled from "styled-components/macro";
import { TopCentre, CentreLeft, CentreRight } from "components/Absolute";

export const OuterBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
`;

export const Root = styled.div`
  flex: 1;
  display: flex;
`;

const TitleContent = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(1)}px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;
  background-color: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
  color: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
  border: 2px solid
    ${({
      theme: {
        palette: {
          fg: { pink },
        },
      },
    }) => pink};
`;

export const Title = ({ children }) => (
  <TopCentre>
    <TitleContent>{children}</TitleContent>
  </TopCentre>
);

export const Nav = styled.div`
  cursor: pointer;
`;

export const LeftNav = ({ children }) => (
  <CentreLeft>
    <Nav>{children}</Nav>
  </CentreLeft>
);

export const RightNav = ({ children }) => (
  <CentreRight>
    <Nav>{children}</Nav>
  </CentreRight>
);

export const OrbitLink = styled.div`
  position: absolute;
  left: ${({ position: { x = 50 } = { x: 50 } }) => x}%;
  top: ${({ position: { y } = {} }) => (y ? `${y}%` : undefined)};
  bottom: ${({ theme: { spacing }, position: { y } = {} }) =>
    y ? undefined : `${spacing(3)}px`};
  transform: translate(-50%, -50%);

  & svg {
    width: ${({ theme: { spacing } }) => spacing(6)}px;
    height: ${({ theme: { spacing } }) => spacing(6)}px;
  }
`;
