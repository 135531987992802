import styled from "styled-components/macro";

export const Menu = styled.nav`
  z-index: 100;
  position: fixed;
  left: ${({ theme: { spacing } }) => spacing(3)}px;
  top: ${({ theme: { spacing } }) => spacing(3)}px;
  max-height: ${({ isOpen, theme: { spacing } }) =>
    isOpen ? 500 : spacing(6)}px;
  display: flex;
  user-select: none;
  overflow: hidden;
  transition: max-height 0.2s ease;
`;

export const LinkGroup = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LinkItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { spacing } }) => spacing(6)}px;

  border: 2px solid
    ${({
      theme: {
        palette: {
          fg: { pink },
        },
      },
    }) => pink};
  margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;
  background-color: ${({
    theme: {
      palette: {
        bg: { darkGreen },
      },
    },
  }) => darkGreen};

  font-weight: 300;
  color: #ff9994;

  & > a {
    line-height: ${({ theme: { spacing } }) => spacing(3)}px;

    display: flex;
    flex: 1;
    justify-content: center;
    padding: ${({ theme: { spacing } }) =>
      `3px ${spacing(1)}px 0 ${spacing(1)}px`};
    font-size: ${({ theme: { spacing } }) => spacing(3)}px;
    text-transform: uppercase;
    color: ${({
      theme: {
        palette: {
          fg: { pink },
        },
      },
    }) => pink};
  }
`;
