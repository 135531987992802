import styled from "styled-components/macro";

export const Root = styled.div`
  flex: 1;
  display: flex;
`;

export const EyeContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;
