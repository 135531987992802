import styled from "styled-components/macro";

const leftPadding = 2;

export const ElementTitle = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  /* border: blue solid 2px; */
  justify-content: flex-start;
  align-content: left;
  align-items: left;
  flex-basis: 5vh;
  /* max-height: 5vh; */
  /* margin-bottom: 5vh; */
  vertical-align: middle;
  .kflogo {
    display: flex;
    /* height: 8vh; */
    /* margin-top: 2vh; */
    margin-right: 50%;
    margin-bottom: 2vh;
    align-content: left;
    justify-content: left;
    align-items: left;
    /* padding: 2vh; */
    /* padding-left: 0; */
    /* margin: 0; */
    /* flex-basis: 20%; */
    /* width: 60%; */
    /* border: black solid 2px; */
    justify-content: left;
    /* padding-left: 10%; */
  }

  .enquiriesTitle {
    /* transform: translate(0, -20%); */
    /* border: black solid 2px; */
    /* width: 60%; */
    text-align: left;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;

    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
    font-size: 5vh;
    line-height: 100%;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* text-align: center; */
    letter-spacing: 1.5vh;
  }
`;

export const Element = styled.div`
  padding: 2vh;
  padding-left: 5vh;

  justify-content: left;
  align-items: left;
  display: flex;
  flex-direction: column;
  background: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
  flex: 0 1 50%;

  .address {
    cursor: auto;
    /* user-select: contain; */
    /* padding-left: 10%; */
    font-size: 2vh;
    text-align: left;
    letter-spacing: 2px;

    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
  }
`;

export const Enquiries = styled.div`
  flex: 1;
  display: flex;
  flex-basis: 100%;
  /* border: black solid 2px; */
  width: 100%;
  padding: 5vh;
  padding-bottom: 0;

  .render {
    /* flex: 1 0 50%; */
    flex: 5;

    margin: 0;

    padding: 0;

    background-repeat: no-repeat;

    background-size: cover;

    background-image: url("${process.env
      .PUBLIC_URL}/assets/images/about/knightfrank.jpg");
  }
`;

export const SecondaryContainer = styled.div`
  /* border: blue solid 2px; */
  display: flex;
  padding-right: 2vw;
  padding-left: 2vw;
  flex-wrap: nowrap;
  flex: 1;
  flex-basis: 100%;
  /* width: 100%; */
  flex-direction: row;
  /* height: max-content; */

  .logo {
    flex: 1;
  }

  .enquiries {
    flex: 0 1 50%;

    flex-basis: 100%;
    /* width: 100%; */
    border: black solid 2px;
    display: flex;
    user-select: auto;
    /* cursor: auto; */
    /* overflow: hidden; */
    /* margin-top: 5rem; */
    background: ${({
      theme: {
        palette: {
          fg: { pink },
        },
      },
    }) => pink};
    z-index: 20;

    color: black;
  }

  .enquiriesTitle {
    /* transform: translate(0, -20%); */

    padding: 20%;
    padding-top: 10%;
    padding-left: 10%;
    grid-column: 1;
    grid-row: 1;
    z-index: 100;
    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
    font-size: 3rem;
  }

  .peabodyText {
    flex: 1;
    font-family: Tungsten;
    font-family: Akkurat-Pro-Regular;
    font-size: 2vh;
    line-height: 2.4vh;
    padding-left: 5vw;

    letter-spacing: 0.6px;
    margin-bottom: 0%;
    text-align: left;
    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
  }

  .subtitle {
    flex: 1 0 50%;
    font-family: Tungsten;
    color: ${({
      theme: {
        palette: {
          fg: { darkGreen },
        },
      },
    }) => darkGreen};
    font-size: 2rem;
    text-align: left;
    letter-spacing: 1vh;
  }

  .six {
    flex: 0 1 50%;
    /* border: black solid 2px; */
    /* transform: translate(-25%, -20%); */
    p {
      color: ${({
        theme: {
          palette: {
            fg: { darkGreen },
          },
        },
      }) => darkGreen};
      padding: 0;
      margin: 0;
      padding-bottom: 5vh;
      font-family: Akkurat-Pro-Regular;
      font-size: 1.8vh;
      letter-spacing: 0.2vh;
      line-height: 2.1vh;

      text-align: left;
    }
  }

  .quote {
    /* border: red solid 2px; */
    flex: 0 1 50%;
    /* flex: 1; */
    /* flex: 1; */
    font-family: Tungsten;
    /* z-index: 2; */
    /* flex-shrink: 1; */
    /* flex-basis: ; */
    letter-spacing: 1px;
    /* transform: translate(-47vh, 0); */
    font-size: 1.7vw;
    padding-top: 6%;
    padding-right: 5.3vw;
    padding-left: 5.3vw;
    text-align: left;
    /* padding-bottom: 5vh; */
  }

  .headshot {
    /* margin: 5%; */
    flex: 1 0 50%;
    /* flex: 1; */
    /* flex-shrink: 1; */
    /* height: 1; */
    /* width: max-content; */

    /* transform: translate(35vh, -10vh); */
    /* display: inline-block; */
    height: 47vh;
    /* width: 50vh; */
    /* position: relative; */
    margin: 0;
    padding: 0;
    /* margin: 0; */
    /* padding: 0; */
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("${process.env
      .PUBLIC_URL}/assets/images/about/petew.jpg");
  }
`;

export const SubtitlePage = styled.div`
  flex: 1;
  /* border: black solid 2px; */

  z-index: 1;
  flex-basis: 100%;
  padding-top: 20%;
  padding-bottom: 5vh;
  /* padding-bottom: 10%; */
  color: ${(props) => props.textColor};
  font-size: 14vh;
  text-align: left;
  padding-left: ${leftPadding}vw;
`;

export const TitlePage = styled.div`
  height: 100vh;
  color: ${(props) => props.textColor};

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17vh;
`;

export const ContentPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  flex-basis: 100%;
  align-items: center;
  justify-items: center;
  text-align: center;
  justify-content: center;

  .quotation {
    font-family: Akkurat-Pro-Light;
    position: relative;
    transform: translate(-99%, 20%);
    opacity: 0.3;
    font-size: 7rem;
  }

  .disclaimer {
    flex: 1 0 100%;
    font-family: Akkurat-Pro-Light;
    background: #bdbdbd;
    line-height: 1.75vh;
    margin: 5vh;
    margin-top: 0;
    /* padding: 5vh; */
    font-size: 1.2vh;
    letter-spacing: 0.5px;
    color: ${({
      theme: {
        palette: {
          bg: { darkGreen },
        },
      },
    }) => darkGreen};
    text-align: left;

    p {
      /* padding-left: calc(50% + 0.5vh); */
      padding: 1vh;

      bottom: 0;
    }
  }
`;

export const About = styled.div`
  /* border: black solid 2px; */

  overflow-y: scroll;
  font-size: 2rem;
  align-items: center;
  justify-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width}vw;
  height: 100vh;
  left: ${(props) => props.left}%;
  /* user-select: none; */
  letter-spacing: 16px;
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  /* cursor: pointer; */

  .titles {
    font-size: 9rem;
  }
`;
export const LeftPage = styled.div`
  position: absolute;
  flex: 1;
  background-color: #ffffff;
  left: 0;
  width: 55vw;
  height: 100%;
`;

export const RightPage = styled.div`
  left: 55%;
  position: absolute;
  flex: 1;
  background-color: #f2f3f3;
  width: 55vw;
  height: 100%;
`;
