import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import FloorScroller from "./FloorScroller/FloorScroller";
import BuildingShellLoader from "./BuildingModel/BuildingShellLoader";
import buildingData from "./AptData/buildingData";
import Screen from "components/Screen";
import { BLOCK_TO_BUILDING } from "state/apartments";
import { CircleSlider } from "react-circle-slider";
import MiniMap from "./MiniMap";
import {
  BuildingExplorerContainer,
  BuildingName,
  ButtonContainer,
  CircleSliderContainer,
  ThreeDViewerContainer,
  Key,
  AptTypeKey,
  BackButton,
  RowContainer,
  KeyItemCircle,
  FloorButtonWr,
} from "./BuildingExplorer.style";
import params from "./BuildingExplorerParams";
import ApartmentCard from "./ApartmentCard";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { FIELD_NAME_MAPPER } from "App";
import { useAvailableApartments } from "./NewFiltering/NewFilter";

function BuildingExplorer({
  match: {
    url,
    params: { buildingName },
  },
}) {
  const [webFontsLoaded, setWebFontsLoaded] = useState(false);

  useEffect(() => {
    async function areFontsReady() {
      await document.fonts.ready;
      setWebFontsLoaded(true);
    }
    areFontsReady();
  });
  const availableApartments = useAvailableApartments(buildingName);

  const availableApartmentsById = {};

  availableApartments.forEach((apt) => {
    availableApartmentsById[apt[FIELD_NAME_MAPPER.plot]] = apt;
  });

  let allAptArr = Object.values(availableApartments).filter(
    (apt) => apt[FIELD_NAME_MAPPER.pType]
  );

  const divSize = window.innerHeight / 1.5;

  const isAvailable = Object.values(availableApartmentsById).filter(
    (t) => t[FIELD_NAME_MAPPER.status] === "0"
  );

  const availablefloorArr = isAvailable.map(
    (apt) => apt[FIELD_NAME_MAPPER.level]
  );

  const [scrollPosition, setScrollPosition] = useState(0);
  const list = useMemo(() => listGen(buildingName), [buildingName]);
  const [isActive, setIsActive] = useState(list);
  const [rotation, setRotation] = useState(Math.PI / 2);

  const [clicked, setClicked] = useState(0);

  const scrollPositionCallback = (scrollValue) => {
    setScrollPosition(scrollValue);
  };

  const buttonPositionCallback = (buttonClickValue) => {
    setClicked(buttonClickValue);
    setScrollPosition(buttonClickValue);
  };

  const history = useHistory();
  const onChangeFloor = useCallback(
    (value) =>
      setIsActive((isActive) =>
        isActive.map((val) =>
          val.id === value.meshID
            ? {
                ...val,
                active: value.inView,
              }
            : val
        )
      ),
    []
  );

  const [currentApt, setCurrentApt] = useState();

  const meshClickedCallback = useCallback(
    (callbackValue) => {
      setCurrentApt(callbackValue);

      history.push(
        `/finder/${buildingName}/${callbackValue[FIELD_NAME_MAPPER.plot]}`
      );
    },
    [history, buildingName]
  );

  const handleChange = (e) => {
    setRotation(parseFloat(e));
  };

  const handleCloseApartmentModal = useCallback(() => {
    history.replace(url);
  }, [url, history]);

  return (
    webFontsLoaded && (
      <Screen style={{ pointerEvents: "none" }}>
        <Switch>
          <Route
            exact
            path={[`/finder/${buildingName}/:id`]}
            render={(props) => (
              <ApartmentCard
                aptObject={currentApt}
                onClose={handleCloseApartmentModal}
                currentApt={currentApt}
                {...props}
              />
            )}
          />
        </Switch>
        <BuildingExplorerContainer
          style={{ overflow: "hidden", position: "fixed" }}
        >
          <BackButton to={`/finder`}>
            <BsChevronDoubleLeft style={{ color: "#E73358" }} />
          </BackButton>

          <ThreeDViewerContainer>
            <BuildingName>
              <h1 style={{ userSelect: "none" }}>
                {BLOCK_TO_BUILDING[buildingName]}
              </h1>
            </BuildingName>
            <BuildingShellLoader
              style={{
                outline: "red solid 2px",
              }}
              rotation={rotation}
              buildingName={buildingName}
              isActive={isActive}
              availableApartmentsById={availableApartmentsById}
            />

            <CircleSliderContainer divSize={divSize}>
              <CircleSlider
                onChange={(e) => handleChange(e)}
                value={rotation}
                size={divSize}
                stepSize={0.001}
                min="0"
                max={2 * Math.PI}
                progressWidth={10}
                knobRadius={18}
                circleColor="#1d4a3d"
                knobColor="#fceacb"
                progressColor="#f19990"
              />
            </CircleSliderContainer>
          </ThreeDViewerContainer>

          <FloorScroller
            clicked={clicked}
            rotation={rotation}
            availableApartmentsById={availableApartmentsById}
            meshClickedCallback={meshClickedCallback}
            scrollPositionCallback={scrollPositionCallback}
            scrollPosition={scrollPosition}
            buildingName={buildingName}
            isFloorSelected={onChangeFloor}
          />

          <FloorClicker
            style={{
              display: "none !important",
            }}
            availableApartmentsById={availableApartmentsById}
            isActive={isActive}
            availablefloorArr={availablefloorArr}
            buttonPositionCallback={buttonPositionCallback}
            scrollPosition={scrollPosition}
            buildingName={buildingName}
          />
        </BuildingExplorerContainer>
        {/* <FilterOptions /> */}

        <Key>
          <RowContainer>
            {allAptArr.length > 1 ? <KeyItemCircle color="#F5DCB5" /> : null}
            {allAptArr.length > 1 ? (
              <AptTypeKey color="#F5DCB5">DUPLEX</AptTypeKey>
            ) : null}
          </RowContainer>
          <RowContainer>
            <KeyItemCircle color="#CFE5DE" />
            <AptTypeKey color="#CFE5DE">STUDIO</AptTypeKey>
          </RowContainer>

          <RowContainer>
            <KeyItemCircle color="#FC6052" />
            <AptTypeKey color="#FC6052">ONE BED</AptTypeKey>
          </RowContainer>

          <RowContainer>
            <KeyItemCircle color="#F4C7C1" />
            <AptTypeKey color="#F4C7C1">TWO BED</AptTypeKey>
          </RowContainer>

          <RowContainer>
            <KeyItemCircle color="#85807F" />
            <AptTypeKey color="#85807F">NO MATCH</AptTypeKey>
          </RowContainer>

          <RowContainer>
            <KeyItemCircle color="#2C544E" />
            <AptTypeKey color="#2C544E">RESERVED</AptTypeKey>
          </RowContainer>

          <RowContainer>
            <KeyItemCircle color="#87A886" />
            <AptTypeKey color="#87A886">COMING SOON</AptTypeKey>
          </RowContainer>
        </Key>
        <MiniMap theta={rotation} buildingName={buildingName} />
      </Screen>
    )
  );
}

/*
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
-
*/

export default BuildingExplorer;
const FloorClicker = ({
  isActive,
  availablefloorArr,
  buildingName,
  buttonPositionCallback,
}) => {
  const thisBuilding = buildingData[buildingName];
  const allFloors = thisBuilding.floorStartsAt;

  return (
    <ButtonContainer>
      {allFloors.map((_, i) => {
        return (
          <FloorButton
            availablefloorArr={availablefloorArr}
            buildingName={buildingName}
            allFloors={allFloors}
            isActive={isActive}
            buttonPositionCallback={buttonPositionCallback}
            i={i}
          />
        );
      })}
    </ButtonContainer>
  );
};

function FloorButton({
  availablefloorArr,
  buildingName,
  allFloors,
  isActive,
  buttonPositionCallback,
  i,
}) {
  const btnScale = 50;

  const checkFloor = (i) => {
    // console.log(availablefloorArr);
    let values = Object.values(availablefloorArr).filter(
      (t) => parseInt(t) === i
    ).length;

    return values;
  };

  const hasFloor = (i) => {
    return availablefloorArr.map((floor) => parseInt(floor)).includes(i - 1);
  };

  function hasLowerGround(buildingName, i) {
    if (buildingName === "ha" || buildingName === "hb") {
      return true;
    } else {
      return false;
    }
  }

  let totalNumber = allFloors.length + 1;

  const spacing = params.building(buildingName);

  const thisFloor = hasLowerGround(buildingName, i)
    ? totalNumber - i - 3
    : totalNumber - i - 2;

  let reversedIndex = totalNumber - i - 2;

  return (
    <FloorButtonWr
      onClick={() => buttonPositionCallback(spacing.reverse()[i] * 10)}
      key={i}
      active={isActive[reversedIndex].active}
      opac={isActive[reversedIndex].active ? 0.8 : 0}
      diam={isActive[reversedIndex].active ? btnScale : 26}
      position={0}
    >
      <h1>{thisFloor}</h1>
      <svg style={{ position: "absolute" }} width="100%" height="100%">
        <circle cx={0} cy={0} cr={10} />
      </svg>

      <p
        style={{
          color: hasLowerGround(buildingName)
            ? hasFloor(reversedIndex)
              ? "#EB4D62"
              : "grey"
            : hasFloor(reversedIndex + 1)
            ? "#EB4D62"
            : "grey",
        }}
      >
        {hasLowerGround(buildingName, reversedIndex)
          ? checkFloor(reversedIndex - 1)
          : checkFloor(reversedIndex)}
      </p>
    </FloorButtonWr>
  );
}

// const FloorClicker = ({
//   isActive,
//   availablefloorArr,
//   buildingName,
//   buttonPositionCallback,
// }) => {
//   const thisBuilding = buildingData[buildingName];
//   const allFloors = thisBuilding.floorStartsAt;
//   const btnScale = 50;

//   const checkFloor = (i) => {
//     let values = Object.values(availablefloorArr).filter(
//       (t) => parseInt(t) === i
//     ).length;

//     return values;
//   };

//   const hasFloor = (i) => {
//     return availablefloorArr.map((floor) => parseInt(floor)).includes(i - 1);
//   };

//   const spacing = params.building(buildingName);

//   function hasLowerGround(buildingName, i) {
//     if (buildingName === "ha" || buildingName === "hb") {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   return (
//     <ButtonContainer>
//       {allFloors.map((_, i) => (
//         <FloorButton
//           onClick={() => buttonPositionCallback(spacing[i] * 10)}
//           key={i}
//           active={isActive[i].active}
//           opac={isActive[i].active ? 0.8 : 0}
//           diam={isActive[i].active ? btnScale : 26}
//           position={0}
//         >
//           <h1>{hasLowerGround(buildingName, i) ? i - 1 : i}</h1>

//           <p
//             style={{
//               color: hasLowerGround(buildingName)
//                 ? hasFloor(i)
//                   ? "#EB4D62"
//                   : "grey"
//                 : hasFloor(i + 1)
//                 ? "#EB4D62"
//                 : "grey",
//             }}
//           >
//             {hasLowerGround(buildingName, i)
//               ? checkFloor(i - 1)
//               : checkFloor(i)}
//           </p>
//         </FloorButton>
//       ))}
//     </ButtonContainer>
//   );
// };

function listGen(buildingName) {
  const thisBuilding = buildingData[buildingName];
  let Testlist = [];
  for (let i = 0; i < thisBuilding.numberOfAprt.length; i++) {
    Testlist.push({ id: i, active: false });
  }
  return Testlist;
}
