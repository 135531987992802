import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import GridIcon from "components/Icons/GridIcon";
import { ArrowLeft, ArrowRight } from "components/Arrow";
import data from "./data";
import CarouselGalleryImage from "./CarouselGalleryImage";
import ModalImage from "./ModalImage";
import { Top, Bottom, LayoutToggle, RightNav, LeftNav } from "./Gallery.style";
import { CarouselRoot } from "./Carousel.style";

const Carousel = ({
  history,
  match: {
    url,
    params: { index = 1 },
  },
}) => {
  const page = Number(index);
  const { centre, images } = useCarouselImages({
    data,
    index: page - 1,
  });

  const [selected, setSelected] = useState({});

  const prevPage = page > 1 ? `/gallery/carousel/${page - 1}` : undefined;
  const nextPage =
    page < data.length ? `/gallery/carousel/${page + 1}` : undefined;

  const handleCloseSelected = useCallback(() => {
    setSelected({});
  }, []);

  return (
    <>
      <Top>
        <LayoutToggle
          onClick={() => {
            history.replace(`/gallery/grid/${calcPageOnLayoutSwitch(index)}`);
          }}
        >
          <GridIcon />
        </LayoutToggle>
      </Top>
      <CarouselRoot>
        {images.map(({ label, text, thumb, large } = {}, i) => {
          if (thumb) {
            return (
              <CarouselGalleryImage
                style={{
                  left: `calc(-150% + ${i * 80}%)`,
                  // transform: "translate(0, -50%)",
                }}
                key={thumb}
                label={label}
                url={getGalleryImgUrl(thumb)}
                onClick={() => {
                  if (large === centre.large) {
                    setSelected({ label, text, filename: large });
                  } else {
                    history.replace(`/gallery/carousel/${index - 2 + i}`);
                  }
                }}
              />
            );
          }
          return null;
        })}
      </CarouselRoot>
      <Bottom />
      {prevPage && (
        <LeftNav>
          <Link to={prevPage}>
            <ArrowLeft />
          </Link>
        </LeftNav>
      )}
      {nextPage && (
        <RightNav>
          <Link to={nextPage}>
            <ArrowRight />
          </Link>
        </RightNav>
      )}
      <ModalImage
        url={getGalleryImgUrl(selected.filename)}
        label={selected.label}
        text={selected.text}
        onClose={handleCloseSelected}
      />
    </>
  );
};

const calcPageOnLayoutSwitch = (index) => Math.floor(index / 6) + 1;

const getGalleryImgUrl = (filename) =>
  filename && `${process.env.PUBLIC_URL}/assets/images/gallery/${filename}`;

const useCarouselImages = ({ data, index }) => {
  const centre = Math.max(0, Math.min(index, data.length - 1));
  const start = centre - 2;
  return {
    images: Array.from({ length: 5 }).map((_, idx) => {
      const i = start + idx;
      if (i >= 0 && i < data.length) {
        return data[i];
      }
      return undefined;
    }),
    centre: data[centre],
  };
};

export default Carousel;
