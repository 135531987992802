import { Switch, Route, Redirect } from "react-router";
import Screen from "components/Screen";
import Grid from "./Grid";
import Carousel from "./Carousel";
import { Content } from "./Gallery.style";

const Gallery = ({ match: { url, params }, history }) => {
  return (
    <Screen>
      <Content>
        <Switch>
          <Route path={`${url}/carousel/:index`} component={Carousel} />
          <Route path={`${url}/grid/:page`} component={Grid} />
          <Route render={() => <Redirect to={`${url}/carousel/1`} />} />
        </Switch>
      </Content>
    </Screen>
  );
};

export default Gallery;
