import styled from "styled-components/macro";

export const Root = styled.div`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  transform: translate(-50%, -50%);
`;

export const Icon = styled.svg`
  cursor: pointer;
  width: ${({ theme: { spacing } }) => spacing(6)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  /* border: 2px solid
    ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen}; */
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: none;
  top: ${({ theme: { spacing } }) => spacing(8)}px;
  height: max-content;
  /* width: max-content; */
  width: 30vw;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.25s ease;

  background-color: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};

  h1 {
    font-family: Tungsten;
    font-weight: 300;
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: 0.6vw;
    color: ${({
      theme: {
        palette: {
          fg: { cream },
        },
      },
    }) => cream};
    font-size: 1.3vw;
  }

  p {
    font-family: Akkurat-Pro-Light;
    letter-spacing: 1.5px;
    font-size: 0.9rem;
    position: relative;
    margin: 4%;
    /* margin-top: 20px; */
  }
`;
