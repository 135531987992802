import Cover from "components/Cover";
import { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { FilmTitle, FilmSubTitle } from "./Film.style";

// const videoUrl = `${process.env.PUBLIC_URL}/assets/videos/Arden_Film_Edit_02.mp4`;
const redcowvid = `${process.env.PUBLIC_URL}/assets/videos/Arden_Film_Edit_02.mp4`;
const ardenanimation = `${process.env.PUBLIC_URL}/assets/videos/ardenanimation.mp4`;

const redcowthumb = `${process.env.PUBLIC_URL}/assets/videos/redcowthumb.jpg`;
const interiorthumb = `${process.env.PUBLIC_URL}/assets/images/vignettes/vignette_2.jpg`;

const Expandable = ({ isActiveCallback, videoUrl, imageUrl, right }) => {
  const [isActive, setIsActive] = useState(false);

  const video = useRef();
  const player = useRef();

  const handleClick = () => {
    isActiveCallback(isActive);

    setIsActive(!isActive);
  };
  return (
    <div
      onClick={() => handleClick()}
      style={{
        zIndex: isActive ? 100 : 1,
        cursor: "pointer",
        transition: "width 0.5s ease",
        position: "absolute",
        border: "none",
        right: right,
        width: isActive ? "100%" : "50%",
        height: "100%",
      }}
    >
      <div
        style={{
          zIndex: 201,
          right: 0,
          width: "100%",
          opacity: isActive ? 0 : 1,
          transition: "opacity 0.7 ease",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          height: "100%",
          position: "absolute",
        }}
      ></div>
      <ReactPlayer
        ref={player}
        loop={true}
        url={videoUrl}
        width="100%"
        height="100%"
        playing={isActive}
        playsinline={true}
      />
    </div>
  );
};

const Film = ({ match: { url } }) => {
  const [isActive, setIsActive] = useState(false);

  const isActiveCallback = (v) => {
    setIsActive(!isActive);
  };

  // console.log(isActive);

  return (
    <Cover aspectRatio={3874 / 2000}>
      <FilmTitle style={{ opacity: isActive ? 0 : 1 }} left="25">
        LIVE
      </FilmTitle>
      <FilmSubTitle style={{ opacity: isActive ? 0 : 1 }}>LIKE A </FilmSubTitle>
      <FilmTitle style={{ opacity: isActive ? 0 : 1 }} left="75">
        LOCAL
      </FilmTitle>
      <Expandable
        isActiveCallback={isActiveCallback}
        right="0"
        videoUrl={redcowvid}
        imageUrl={redcowthumb}
      />
      <Expandable
        isActiveCallback={isActiveCallback}
        right="50"
        videoUrl={ardenanimation}
        imageUrl={interiorthumb}
      />
    </Cover>
  );
};

export default Film;
