import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "./MarkerIcon.style";

const MarkerIcon = ({ onMouseOver, onMouseOut }) => {
  const theme = useContext(ThemeContext);
  return (
    <Icon
      width={50}
      height={50}
      viewBox={`0 0 130 130`}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <line
        stroke={theme.palette.bg.darkGreen}
        fill={theme.palette.bg.darkGreen}
        strokeWidth={3}
        fillOpacity={0}
        x1="64.08"
        y1="50.7"
        x2="64.08"
        y2="45.61"
      />
      <polyline
        fill={theme.palette.bg.darkGreen}
        stroke={theme.palette.bg.darkGreen}
        strokeWidth={3}
        fillOpacity={0}
        points="58.32 58.38 64.08 58.38 64.08 81.86"
      />
      <line
        stroke={theme.palette.bg.darkGreen}
        strokeWidth={3}
        fillOpacity={0}
        x1="56.73"
        y1="81.66"
        x2="71.03"
        y2="81.66"
      />
      <circle
        stroke={theme.palette.bg.darkGreen}
        strokeWidth={3}
        fillOpacity={0}
        cx="64.5"
        cy="64.56"
        r="33.57"
      />
    </Icon>
  );
};

export default MarkerIcon;
