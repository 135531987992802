import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import CoverImage from "components/CoverImage";
import Eye from "components/Icons/Eye";
import { Root, EyeContainer } from "./Home.style";
import lottie from "lottie-web";

const overviewImage = `${process.env.PUBLIC_URL}/assets/images/londonOverview.jpg`;

const Home = () => {
  const backlayer = useRef();
  const justlogo = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: backlayer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: `${process.env.PUBLIC_URL}/assets/svg/svganimations/backlayer.json`,
    });
  });

  useEffect(() => {
    lottie.loadAnimation({
      container: justlogo.current,
      renderer: "svg",
      loop: false,
      autoplay: true,

      path: `${process.env.PUBLIC_URL}/assets/svg/svganimations/justlogo.json`,
    });
  });

  return (
    <Root>
      <CoverImage imgUrl={overviewImage}></CoverImage>
      <Link to={"/orbits/south/0"}>
        <div
          ref={backlayer}
          style={{
            // cursor: "pointer",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div
          ref={justlogo}
          style={{
            cursor: "pointer",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </Link>
    </Root>
  );
};

export default Home;
