import styled from "styled-components/macro";

export const Root = styled.div`
  cursor: pointer;

  & > div:first-child {
    height: 100%;
    width: 100%;

    background-color: ${({
      theme: {
        palette: {
          bg: { midGrey },
        },
      },
    }) => midGrey};
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Label = styled.div`
  position: absolute;

  bottom: ${({ theme: { spacing } }) => -spacing(1)}px;
  left: ${({ theme: { spacing } }) => -spacing(1)}px;
  background-color: ${({
    theme: {
      palette: {
        bg: { petiteOrchid },
      },
    },
  }) => petiteOrchid};
  padding: ${({ theme: { spacing } }) => `${spacing(1)}px ${spacing(2)}px`};
  text-transform: uppercase;
`;
