const config = {
  mattsEndpoint:
    "https://s3.eu-west-2.amazonaws.com/preview.vmistudio.com/arden-app/testing/arden-dummy.json",
  // HACK ALERT. Pulled the kodime endpoint JSON response down to public folder so I can change the status for two apartments
  // from "Reserved" to "Available". Now Arden web app is using the offline JSON from public folder pulled down on 05/12/2022.
  // There is an email from Sholto Campbell, from Peabody, saying they're not using Kodime nor Google sheets.
  // If you need to restore kodime data, just use the commented kodimeEndpoint below.
  // kodimeEndpoint: "https://cdn.kodi.me/pbwebapp/kodime_872.json",
  kodimeEndpoint: `${process.env.PUBLIC_URL}/kodime_05-12-2022.json`,
  kodimeCredentials: {
    username: "pb872!",
    password: "pb872!",
  },
};

export default config;
