import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { createSelector } from "reselect";
import { flatTypeHolder, aspectHolder, floorHolder } from "./mappers";

export const fetchSalesInfo = createAsyncThunk(
  "apartmentFinder/fetchSalesInfo",
  async (payload, { extra: { salesInfoService } }) => {
    return await salesInfoService.getSalesInfo();
  }
);

const INITIAL_STATE = {
  loading: false,
  apartments: {
    byId: {},
    order: [],
  },
};

const apartments = createSlice({
  name: "aprtment-finder",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [fetchSalesInfo.pending]: (state, action) => {
      state.loading = true;
      state.error = undefined;
    },
    [fetchSalesInfo.fulfilled]: (
      state,
      {
        payload: {
          // Plots field
          Development: { Plots: plots },
        },
      }
    ) => {
      // Organise and Clean Data..
      state.loading = false;
      state.apartments = {
        // Generaate ID field for data
        byId: plots.reduce((r, data) => {
          //Pull(destructure) some fields - Block and Plotnmber - from datasheet
          const { PlotNumber } = data;
          // Asign all data to corresponding ID field
          const id = `${PlotNumber.split("-")[0].toUpperCase()}-${
            PlotNumber.split("-")[1]
          }`;

          r[id] = {
            id,
            ...extractUsefulApartmentInfo(id, data),
          };
          return r;
        }, {}),
        //Create field of Ordered Plots inside apartments
        order: plots.map(
          ({ PlotNumber }) =>
            `${PlotNumber.split("-")[0].toUpperCase()}-${
              PlotNumber.split("-")[1]
            }`
        ),
      };
    },
    [fetchSalesInfo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

const TYPE_MAPPERS = [
  {
    test: ({ bedrooms, receptions }) => Number(bedrooms) === 0,
    type: "ST",
  },
  {
    test: ({ bedrooms, receptions }) => Number(bedrooms) === 1,
    type: "1",
  },
  {
    test: ({ bedrooms }) => {
      return Number(bedrooms) === 2;
    },
    type: "2",
  },
  {
    test: ({ bedrooms }) => Number(bedrooms) === 3,
    type: "3",
  },
  {
    test: ({ bedrooms }) => Number(bedrooms) === 4,
    type: "4",
  },
  {
    test: ({ PropertyType }) => PropertyType === "Duplex",
    type: "DUPLEX",
  },
];

const getApartmentType = (bedrooms, receptions, PropertyType) => {
  // console.log(
  //   TYPE_MAPPERS.find(({ test, type, PropertyType }) => test({ PropertyType }))
  //     ?.type ?? "UNKNOWN"
  // );

  if (PropertyType === "Duplex") {
    return "DUPLEX";
  } else
    return (
      TYPE_MAPPERS.find(({ test, type, PropertyType }) =>
        test({ bedrooms, receptions, PropertyType })
      )?.type ?? "UNKNOWN"
    );
};

const flatTypeMapper = (propnum) => {
  // console.log(flatTypeHolder[propnum.replace("-", "").toUpperCase()]);
  const lower = flatTypeHolder[propnum.replace("-", "").toUpperCase()];
  // console.log(propnum, lower);
  return lower;
};

const aspectMapper = (propnum) => {
  return aspectHolder[propnum.replace("-", "").toUpperCase()];
};

export const BLOCK_TO_BUILDING = {
  hb: "Hb",
  ha: "Ha",
  ib: "iB",
  ic: "iC",
  id: "iD",
  ia: "iA",
};

const floorMapper = (propnum) => {
  return floorHolder[propnum.replace("-", "").toUpperCase()];
};

const extractUsefulApartmentInfo = (
  id,
  {
    FlatType,
    AskingPrice,
    SaleStatus,
    Bedrooms,
    Receptionrooms: receptions,
    PropertyType,
    PriceOfHome,
    PrimaryAspect,
    Level,
    Block,
    PlotNumber,
    SqM,
    Sqft,
    Floor,
    Receptionrooms,
  }
) => ({
  id: PlotNumber.toUpperCase(),
  flatType: flatTypeMapper(PlotNumber),
  plot: PlotNumber.split("-")[1],
  building: BLOCK_TO_BUILDING[PlotNumber.split("-")[0].toLowerCase()]
    .toLowerCase()
    .replace(/\s/g, ""),
  block: PlotNumber.split("-")[0].toUpperCase(),
  floor: floorMapper(PlotNumber),
  isDuplex: PropertyType.toLowerCase().includes("duplex"),
  status: SaleStatus.replace(" ", ""),
  price: PriceOfHome, // or SearchPrice? or FullMarketValue?
  type: getApartmentType(Bedrooms, Receptionrooms, PropertyType),
  aspect: aspectMapper(PlotNumber),
  SqM: SqM,
  Sqft: Sqft,
});

export const apartmentsSelector = (state) =>
  get(state, "apartments.apartments");

export const orderedApartmentsSelector = createSelector(
  apartmentsSelector,
  ({ byId = {}, order = [] } = {}) => order.map((id) => byId[id])
);

export const apartmentsByIdSelector = (state) => apartmentsSelector(state).byId;

export default apartments.reducer;
