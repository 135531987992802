import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CoverImage from "components/CoverImage";

import { Title, TitleAndControls } from "./InnerOrbit.style";
import {
  Root,
  LeftNav,
  RightNav,
  OuterBackground,
  OrbitLink,
} from "./OuterOrbit.style";
import ArrowDown from "components/Icons/ArrowDown";
import { ArrowLeft, ArrowRight } from "components/Arrow";
import ToggleOuter from "components/Icons/ToggleOuter";
import { Back } from "./InnerOrbit.style";

const ORBIT_PATH = "/assets/images/outerOrbit/";

const getBgImageUrl = (viewId) => {
  return `${process.env.PUBLIC_URL}${ORBIT_PATH}${viewId}.jpg`;
};

const OuterOrbit = ({ id: viewId, orbitLinks, prev, next }) => {
  const { t } = useTranslation();
  return (
    <>
      <Root>
        <OuterBackground viewId={viewId}>
          <CoverImage imgUrl={getBgImageUrl(viewId)}> </CoverImage>
          <TitleAndControls>
            <Title>{t("orbits.outer.title", { viewId })}</Title>
          </TitleAndControls>

          {orbitLinks.map(({ id: orbitId, position, link }) => {
            return (
              <OrbitLink key={orbitId} position={position}>
                <Link to={link}>{/* <ArrowDown /> */}</Link>
              </OrbitLink>
            );
          })}

          <Title>{t("orbits.outer.title", { viewId })}</Title>
          <LeftNav>
            <Link to={prev.link}>
              <ArrowLeft />
            </Link>
          </LeftNav>
          <RightNav>
            <Link to={next.link}>
              <ArrowRight />
            </Link>
          </RightNav>
        </OuterBackground>
        {orbitLinks.map(({ id: orbitId, position, link }) => {
          return (
            // <OrbitLink key={orbitId} style={{ position: "absolute" }}>
            <Link to={link}>
              <Back>
                <ToggleOuter />
              </Back>
            </Link>
            // </OrbitLink>
          );
        })}
      </Root>
    </>
  );
};

export default OuterOrbit;
