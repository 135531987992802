import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export const LinkGroup = styled.ul`
  position: absolute;
  bottom: ${({ theme: { spacing } }) => spacing(8)}px;
  left: ${({ theme: { spacing } }) => spacing(4)}px;

  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LinkItem = styled.li`
  margin-top: ${({ theme: { spacing } }) => spacing(12)}px;
`;

export const Link = styled(NavLink)`
  display: flex;
  width: 250px;
  justify-content: center;
  text-transform: uppercase;
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(1)}px;
  font-size: 2em;

  background-color: ${({
    theme: {
      palette: {
        bg: { petiteOrchid },
      },
    },
  }) => petiteOrchid};

  box-shadow: -2px 2px 5px #999;

  &.active {
    background-color: ${({
      theme: {
        palette: {
          bg: { seaMist },
        },
      },
    }) => seaMist};
    box-shadow: -2px 2px 5px
      ${({
        theme: {
          palette: {
            bg: { petiteOrchid },
          },
        },
      }) => petiteOrchid};
  }
`;
