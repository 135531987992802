import { createSelector } from "reselect";

import {
  apartmentsByIdSelector,
  orderedApartmentsSelector,
} from "./apartments";
import { apartmentFiltersSelector } from "./apartmentFinder";
import { favouriteIdsSelector } from "./favourites";

export const filteredApartmentsSelector = createSelector(
  orderedApartmentsSelector,
  apartmentFiltersSelector,
  (apartments, filters) => {
    const { price, aspect, floor, type } = filters;
    const valueFilters = createValueFilters({ aspect, floor, type });
    const priceFilter = createPriceFilter({ price });

    return apartments.filter((apt) => valueFilters(apt) && priceFilter(apt));
  }
);

export const filteredApartmentsForBuildingSelector = createSelector(
  filteredApartmentsSelector,
  (state, buildingId) => buildingId,
  (apartments, buildingId) =>
    apartments.filter(({ building }) => building === buildingId)
);

export const favouritesSelector = createSelector(
  favouriteIdsSelector,
  apartmentsByIdSelector,
  (ids, apartmentsById) => ids.map((id) => apartmentsById[id] || { id })
);

const createValueFilters = (filterDefinitions) => {
  const filters = Object.entries(filterDefinitions).map(
    ([property, options]) => {
      const selectedOptions = options
        .filter(({ selected }) => selected)
        .map(({ values }) => values.map((v) => String(v)));

      return (apt) => {
        if (selectedOptions.length) {
          // some options are selected, filter the apartments ...
          return selectedOptions.find((values) =>
            values.includes(apt[property])
          );
        } else {
          // none of the options are selected, don't filter on this criteria
          return true;
        }
      };
    }
  );

  return (apt) => filters.reduce((r, f) => r && f(apt), true);
};

function createPriceFilter(price) {
  const filters = Object.values(price);

  return (apt) =>
    (apt.price < filters[0].to) & (apt.price > filters[0].from) ? true : false;
}

function createAvailablityFilter(status) {
  const filters = Object.values(status);

  return (apt) => apt.status === "Available";
}
