import { useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isFavouriteSelector,
  addFavourite,
  removeFavourite,
} from "state/favourites";
import Modal from "components/Modal";
import {
  Vignette,
  CardHeader,
  VignetteContainer,
  Content,
  CardBody,
  FloorplanContainer,
  Title,
  Floor,
  Type,
  Price,
  CardFooter,
  TitleHeader,
  ViewContainer,
} from "./ApartmentCard.style";

import FavouriteButtonIcon from "components/Icons/FavouriteButtonIcon";
import IsFavouriteIcon from "components/Icons/IsFavouriteIcon";
import { BLOCK_TO_BUILDING } from "state/apartments";
import Screen from "components/Screen";
import { useLocation } from "react-router-dom";
import { FIELD_NAME_MAPPER } from "App";
import useStore from "state/zustandStore";
import { priceFormatter } from "util/helpers";
import ShareedSVGTable from "components/ShareedSVGTable/ShareedSVGTable";
import SharedFindOutMore from "components/SharedFindOutMore/SharedFindOutMore";

const Floors = {
  "-1": "LOWER GROUND",
  0: "GROUND",
  1: "FIRST",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fith",
  6: "Sith",
  7: "Seventh",
  8: "Eighth",
  9: "Ninth",
  10: "Tenth",
  11: "Eleventh",
  12: "Twelfth",
  13: "Thirteenth",
  14: "Fourteenth",
  15: "Fithteenth",
  16: "Sixteenth",
  17: "Seventeenth",
  18: "Eighteenth",
  19: "Nineteenth",
};

const ReturnFloorplan = ({ id, building }) => {
  console.log(
    "ID",
    `${process.env.PUBLIC_URL}/assets/svg/cards${
      building === "ia" ? "/shared" : ""
    }/${id}.svg`
  );
  if (id === undefined) {
    return (
      <div
        style={{
          width: "200px",
          height: "200px",
        }}
      >
        floorplan
      </div>
    );
  } else {
    return (
      <FloorplanContainer
        id={`${process.env.PUBLIC_URL}/assets/svg/cards/${id}.svg`}
      ></FloorplanContainer>
    );
  }
};

const ApartmentCard = ({ onClose }) => {
  let { pathname } = useLocation();

  const apartments = useStore((s) => s.apartments);

  const sharedOwnership = useStore((s) => s.sharedOwnership);

  let currentApt = apartments.find(
    (apt) => apt[FIELD_NAME_MAPPER.plot] === pathname.split("/")[3]
  );

  const [viewOn, setViewOn] = useState(false);

  const statusMap = {
    1: "COMING SOON",
    2: "RESERVED",
    3: "SOLD",
  };
  function getStatus(status, price) {
    if (status === "0") {
      return priceFormatter.format(Number(price));
    } else {
      return statusMap[status];
    }
  }

  function toggleView() {
    setViewOn(!viewOn);
  }

  const [findOutMore, setFindOutMore] = useState(false);
  const [viewActive, setViewActive] = useState(true);

  if (currentApt) {
    return (
      <Screen>
        {findOutMore && (
          <div
            onClick={() => setFindOutMore(false)}
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/shared-ownership-info.svg`,
              zIndex: 999999,
              width: "100vw",
              position: "absolute",
              height: "100%",
            }}
          ></div>
        )}

        <Modal fullScreen={false} onClose={onClose}>
          <CardHeader>
            <Title>
              <TitleHeader>
                APARTMENT -{" "}
                {
                  BLOCK_TO_BUILDING[
                    currentApt[FIELD_NAME_MAPPER.block].toLowerCase()
                  ]
                }
                {" - "}
                {currentApt[FIELD_NAME_MAPPER.plot].split("-")[1]}
              </TitleHeader>
              <Type>
                {currentApt[FIELD_NAME_MAPPER.beds]
                  .toUpperCase()
                  .includes("DUPLEX")
                  ? currentApt[FIELD_NAME_MAPPER.beds]
                  : currentApt[FIELD_NAME_MAPPER.beds]?.toLowerCase() ===
                    "studio"
                  ? currentApt[FIELD_NAME_MAPPER.beds]
                  : currentApt[FIELD_NAME_MAPPER.beds] + " BED"}
              </Type>
              <Floor>
                {Floors[
                  Number(currentApt[FIELD_NAME_MAPPER.level])
                ].toUpperCase()}{" "}
                FLOOR {"  -  "}
                {currentApt[FIELD_NAME_MAPPER.aspect]} FACING
              </Floor>
            </Title>
            <Price>
              {sharedOwnership ? (
                <>
                  Share Price -{" "}
                  {getStatus(
                    currentApt[FIELD_NAME_MAPPER.status],
                    currentApt[FIELD_NAME_MAPPER.sharePrice]
                  )}
                </>
              ) : (
                <>
                  Asking Price -{" "}
                  {getStatus(
                    currentApt[FIELD_NAME_MAPPER.status],
                    currentApt[FIELD_NAME_MAPPER.askingPrice]
                  )}
                </>
              )}
            </Price>
          </CardHeader>
          <CardBody>
            <ReturnVignette />
            <Content>
              <ReturnFloorplan
                building={currentApt[FIELD_NAME_MAPPER.block].toLowerCase()}
                id={currentApt[FIELD_NAME_MAPPER.type].toLowerCase()}
              />
            </Content>
          </CardBody>

          <ViewButton
            toggleView={toggleView}
            id={currentApt[FIELD_NAME_MAPPER.plot]}
          />

          <FavouriteButton
            currentApt={currentApt}
            id={currentApt[FIELD_NAME_MAPPER.plot]}
          />

          {sharedOwnership && (
            <CardFooter>
              <div
                onClick={() => setFindOutMore(true)}
                style={{
                  flex: 3.5,
                }}
              >
                <SharedFindOutMore />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 5,
                }}
              >
                <ShareedSVGTable currentApt={currentApt} />
              </div>
            </CardFooter>
          )}
          <ViewBox
            viewOn={viewOn}
            toggleView={toggleView}
            id={currentApt[FIELD_NAME_MAPPER.plot]}
          />
        </Modal>
        {viewActive && (
          <div
            style={{
              pointerEvents: "none",
              zIndex: 999,
              width: "100%",
              opacity: 0.2,
              position: "absolute",
              height: "100%",
              // backgroundColor: "#fff",
            }}
          ></div>
        )}
      </Screen>
    );
  } else {
    return null;
  }
};

function ViewBox({ id, viewOn, toggleView }) {
  function ImageExist(url) {
    let img = new Image();
    img.src = url;
    return img.height !== 0;
  }

  return (
    <ViewContainer viewOn={viewOn}>
      <img
        onClick={viewOn ? toggleView : null}
        style={{ pointerEvents: viewOn ? "auto" : "none" }}
        width="100%"
        height="100%"
        src={`${process.env.PUBLIC_URL}/assets/images/views/${id}.jpg`}
        alt="noimg"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = `${process.env.PUBLIC_URL}/assets/images/views/comingsoon.jpg`;
        }}
      />
      {ImageExist(
        `${process.env.PUBLIC_URL}/assets/images/views/${id}_B.jpg`
      ) && (
        <div
          onClick={() => console.log("object")}
          style={{
            pointerEvents: "none",
            cursor: "pointer",
            zIndex: 90232320,
            position: "absolute",
            height: "70px",
            width: "70px",
            backgroundColor: "red",
          }}
        ></div>
      )}
    </ViewContainer>
  );
}

const FavouriteButton = ({ id }) => {
  const isFavourite = useSelector(isFavouriteSelector(id));
  const dispatch = useDispatch();
  if (isFavourite) {
    return (
      <IsFavouriteIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          bottom: 0,
          right: "0",
          zIndex: "100",
        }}
        onClick={() => dispatch(removeFavourite({ id }))}
      />
    );
  } else {
    return (
      <FavouriteButtonIcon
        style={{
          // border: "black solid 2px",
          cursor: "pointer",
          position: "absolute",
          zIndex: "100",
          bottom: 0,
          right: "0",
        }}
        onClick={() => dispatch(addFavourite({ id }))}
      />
    );
  }
};

const ViewButton = ({ toggleView }) => {
  return (
    <svg
      onClick={() => toggleView()}
      style={{
        cursor: "pointer",
        position: "absolute",
        bottom: "0",
        right: "3vw",
        zIndex: "100",
        // border: "black solid 2px",
      }}
      width={"6vh"}
      height={"6vh"}
      viewBox="-4 -4 79 79"
      stroke="#FFE9CE"
      fill="#004A3F"
    >
      {/* <g clip-path="url(#clip0)"> */}
      <path
        d="M31.63 63.7244C49.1623 63.7244 63.375 49.5117 63.375 31.9794C63.375 14.4471 49.1623 0.234375 31.63 0.234375C14.0977 0.234375 -0.11499 14.4471 -0.11499 31.9794C-0.11499 49.5117 14.0977 63.7244 31.63 63.7244Z"
        fill="#004A3F"
        strokeWidth="1.4"
        stroke="#004A3F"
      />
      <path
        d="M31.6301 20.5947C43.1301 20.5947 52.4301 31.9797 52.4301 31.9797C52.4301 31.9797 43.1201 43.3647 31.6301 43.3647C20.1401 43.3647 10.8301 31.9997 10.8301 31.9997C10.8301 31.9997 20.1401 20.5947 31.6301 20.5947Z"
        stroke="#FFE9CE"
        strokeWidth="1.4"
      />
      <path
        d="M31.6301 43.375C37.9234 43.375 43.0251 38.2732 43.0251 31.98C43.0251 25.6867 37.9234 20.585 31.6301 20.585C25.3368 20.585 20.2351 25.6867 20.2351 31.98C20.2351 38.2732 25.3368 43.375 31.6301 43.375Z"
        stroke="#FFE9CE"
        strokeWidth="1.4"
      />
      <path
        d="M31.6302 36.545C34.1514 36.545 36.1952 34.5012 36.1952 31.98C36.1952 29.4589 34.1514 27.415 31.6302 27.415C29.109 27.415 27.0652 29.4589 27.0652 31.98C27.0652 34.5012 29.109 36.545 31.6302 36.545Z"
        stroke="#FFE9CE"
        strokeWidth="1.4"
      />
    </svg>
  );
};

const ReturnVignette = memo(function ReturnVignette() {
  function getRandomImage(num) {
    let d = new Date();
    let n = d.getTime();
    return (n % 6) + num;
  }
  return (
    <VignetteContainer>
      <Vignette vigNum={getRandomImage(0)} />
      <Vignette vigNum={getRandomImage(1)} />
    </VignetteContainer>
  );
});

export default ApartmentCard;
