import useStore from "state/zustandStore";
import { ObjectHolder, Root } from "./Arden.style";

const Arden = ({ match: { url } }) => {
  const sharedOwnership = useStore((s) => s.sharedOwnership);

  return (
    <Root>
      {sharedOwnership ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundSize: "cover",
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/map/arden-ao-sitemap.png)`,
          }}
        ></div>
      ) : (
        <ObjectHolder
          style={{
            position: "absolute",
            backgroundColor: "#F0F2F1",
          }}
          class="kflogo"
          width="100%"
          height="100%"
          data={`${process.env.PUBLIC_URL}/assets/svg/map/sitemap.svg`}
        >
          <img alt="Peabody"></img>
        </ObjectHolder>
      )}
    </Root>
  );
};
export default Arden;
