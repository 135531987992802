import Screen from "components/Screen";
import { BuildingLink, Label, Count } from "./ApartmentFinder.style";
// import FilterOptions from "./BuildingExplorer/FilterOptions";
import CoverImage from "components/CoverImage";
import useStore from "state/zustandStore";
import { useMemo } from "react";
import { FIELD_NAME_MAPPER } from "App";
import { useAvailableApartments } from "./BuildingExplorer/NewFiltering/NewFilter";

const aptFinderBg = `${process.env.PUBLIC_URL}/assets/images/AptFinderBg.jpg`;

const ApartmentFinder = ({ match: { url } }) => {
  const sharedOwnership = useStore((s) => s.sharedOwnership);

  const apartments = useStore((s) => s.apartments);

  const buildingIdNames = useMemo(() => {
    if (sharedOwnership) {
      return Array.from(
        new Set(
          apartments
            .filter((apartment) => apartment[FIELD_NAME_MAPPER.block] === "ia")
            .map((apartment) => apartment[FIELD_NAME_MAPPER.block])
        )
      );
    } else {
      return Array.from(
        new Set(
          apartments
            .filter((apartment) => apartment[FIELD_NAME_MAPPER.block] !== "ia")
            .map((apartment) => apartment[FIELD_NAME_MAPPER.block])
        )
      );
    }
  }, [apartments, sharedOwnership]);

  console.log(buildingIdNames);

  const placement = {
    hb: {
      x: 35,
      y: 20,
      length: 1150,
    },
    ha: {
      x: 45,
      y: 10,
      length: 860,
    },
    ib: {
      x: 82.5,
      y: 20,
      length: 1100,
    },
    ic: {
      x: 71.25,
      y: 10,
      length: 1330,
    },
    id: {
      x: 61,
      y: 20,
      length: 1000,
    },
    ia: {
      x: 61,
      y: 20,
      length: 1000,
    },
  };

  return (
    <Screen style={{ overflow: "hidden" }}>
      <CoverImage
        style={{ overflow: "hidden" }}
        imgUrl={aptFinderBg}
      ></CoverImage>
      {buildingIdNames.map((id, i) => (
        <Building
          key={id}
          id={id}
          length={placement[id]?.length}
          baseUrl={url}
          position={{
            x: placement[id]?.x,
            y: placement[id]?.y,
          }}
          label={id}
        />
      ))}
    </Screen>
  );
};

const Building = ({ length, id, baseUrl, position, label }) => {
  const availableApartments = useAvailableApartments(id);

  return (
    <BuildingLink to={`${baseUrl}/${id}`} position={position}>
      <Label>{label}</Label>
      <Count>Available : {availableApartments.length}</Count>
      <svg stroke="#E8395D" viewBox="-50 -30 100 1900">
        <path strokeWidth={2.5} d={`M 0 0 L 0 ${length}`} />
        <circle
          strokeWidth="2"
          fillOpacity="1"
          fill="#FF1458"
          cx={0}
          cy={length}
          r={2}
        />
      </svg>
    </BuildingLink>
  );
};

export default ApartmentFinder;
