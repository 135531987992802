import React, {
  useRef,
  useEffect,
  Suspense,
  useState,
  useCallback,
} from "react";
import { Canvas } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Block } from "./blocks";
import Model from "./gltfjsx/FloorPlateModel";
import buildingData from "../AptData/buildingData";
import { useInView } from "react-intersection-observer";
import {
  FloorModelContainer,
  FloorplanDOM,
  IntersectionRoot,
  ScrollArea,
} from "./FloorScroller.style";
import params from "../BuildingExplorerParams";

// -_-_-_-_-_-_-_-_-_-_-_-_-_LIGHTS-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
const Lights = () => {
  return (
    <React.Fragment>
      <pointLight position={[-30, -60, 30]} intensity={0.6} />
      <pointLight position={[30, 30, 30]} intensity={2} />
    </React.Fragment>
  );
};

// -_-_-_-_-_-_-_-_-_-_-_-_-_FLOOR PLATE COMPONENT-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
const FloorPlate = ({
  rotation,
  availableApartmentsById,
  meshClickedCallback,
  scrollPosition,
  domContent,
  children,
  initialPositionY,
  meshID,
  buildingName,
  floorStartsAt,
  numberOfApt,
  onChange,
  setFloor,
}) => {
  // -_-_-_-_-_-_-_-_- Identify Current Floor with IO
  const [refItem, inView] = useInView({
    root: document.querySelector(".intersectionRoot"),
    // rootMargin: '-1px',
    threshold: 0,
  });
  const scaleFactor = 3.5;
  const [distanceToInView, setDistanceToInView] = useState(0);
  useEffect(() => {
    onChange({ meshID: meshID, inView: inView });
  }, [inView, meshID, onChange]);

  const currentPositionCallback = useCallback((callbackValue) => {
    setDistanceToInView(callbackValue / 100);
  }, []);

  // return null;
  return (
    <Block
      meshID={meshID}
      currentPositionCallback={currentPositionCallback}
      initialPositionY={initialPositionY}
      scrollPosition={scrollPosition}
      setFloor={setFloor}
      factor={0.5}
      offset={0.1}
    >
      <group
        position={[0, initialPositionY, 0]}
        scale={[
          0.85 - Math.abs(distanceToInView) * scaleFactor,
          0.85 - Math.abs(distanceToInView) * scaleFactor,
          0.85 - Math.abs(distanceToInView) * scaleFactor,
        ]}
        rotation={[-1 * distanceToInView * 3.5 - 0.7, 0, rotation + 0.8]}
      >
        <mesh>
          <Suspense fallback={null}>
            <Model
              availableApartmentsById={availableApartmentsById}
              meshClickedCallback={meshClickedCallback}
              distanceToInView={distanceToInView}
              meshID={meshID}
              inView={inView}
              buildingName={buildingName}
              floorStartsAt={floorStartsAt}
              numberOfApt={numberOfApt}
            />
          </Suspense>
        </mesh>
        <Html portal={domContent} fullscreen>
          <FloorplanDOM ref={refItem}>{children}</FloorplanDOM>
        </Html>
      </group>
    </Block>
  );
};

// -_-_-_-_-_-_-_-_-_-_-_-_-_FLOOR SCROLLER COMPONENT
function FloorScroller({
  rotation,
  availableApartmentsById,
  meshClickedCallback,
  clicked,
  scrollPosition,
  isFloorSelected,
  buildingName,
}) {
  // -_-_-_-_-_-_-_-_-_-_-_-_- PULL BUILDING DATA
  const thisBuilding = buildingData[buildingName];
  const thisFloorStartsAt = thisBuilding.floorStartsAt;
  const thisNumberOfAprt = thisBuilding.numberOfAprt;
  const domContent = useRef();
  const scrollArea = useRef();

  function syncScrollTop() {
    scrollArea.current.scrollTop = scrollPosition;
  }

  //** Investigate */
  useEffect(() => {
    scrollArea.current.scrollTop = clicked;
  }, [clicked]);

  const handleScroll = (e) => {};

  return (
    <React.Fragment>
      <FloorModelContainer meshClickedCallback={meshClickedCallback}>
        <Canvas
          onCreated={(state) => state.events.connect(scrollArea.current)}
          className="floorCanvas"
          camera={{
            position: [0, -5, 100],
            fov: 30,
            near: 80,
            far: 120,
          }}
          colorManagement={true}
          dpr={(Math.min(window.devicePixelRatio), 2)}
        >
          <group>
            {thisFloorStartsAt.map((_, i) => (
              <FloorPlate
                rotation={rotation}
                availableApartmentsById={availableApartmentsById}
                meshClickedCallback={meshClickedCallback}
                scrollPosition={scrollPosition}
                onChange={isFloorSelected}
                meshID={i}
                key={i}
                domContent={domContent}
                initialPositionY={params.building(buildingName)[i]}
                buildingName={buildingName}
                floorStartsAt={thisFloorStartsAt[i]}
                numberOfApt={thisNumberOfAprt[i]}
              >
                <div className={`floorContainer fc${i}`}></div>
              </FloorPlate>
            ))}
          </group>
          <Lights />
        </Canvas>

        <ScrollArea
          onClick={syncScrollTop}
          ref={scrollArea}
          onScroll={(e) => handleScroll(e)}
        >
          <IntersectionRoot className="intersectionRoot" ref={domContent} />
          <div
            style={{
              pointerEvents: "none",
              height: `${1 * 100}vh`,
              transform: "translate(0px, -1000px)",
            }}
          />
        </ScrollArea>
      </FloorModelContainer>
    </React.Fragment>
  );
}

export default FloorScroller;
