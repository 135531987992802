import styled from "styled-components/macro";

export const Root = styled.div`
  display: flex;
  flex: 1;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Action = styled.div`
  z-index: 15;

  position: absolute;

  padding: ${({ theme: { spacing } }) => spacing(1)}px;
  text-transform: uppercase;
  cursor: pointer;

  background-color: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
  color: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
  border: 2px solid
    ${({
      theme: {
        palette: {
          fg: { pink },
        },
      },
    }) => pink};
`;

export const MarkerContainer = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

export const ToggleHolder = styled.div`
  position: absolute;

  padding: ${({ theme: { spacing } }) => spacing(1)}px;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  left: 50%;
  bottom: ${({ theme: { spacing } }) => spacing(3)}px;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 2.5em;
  letter-spacing: ${({ theme: { spacing } }) => spacing(2)}px;

  & > svg {
    margin-bottom: 5px;
    width: ${({ theme: { spacing } }) => spacing(6)}px;
    height: ${({ theme: { spacing } }) => spacing(6)}px;
  }
`;

export const Back = styled(Action)`
  border: none;
  bottom: ${({ theme: { spacing } }) => spacing(3)}px;
  left: ${({ theme: { spacing } }) => spacing(3)}px;

  background: none;
  position: absolute;
  padding: 0;

  width: ${({ theme: { spacing } }) => spacing(6)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;

  margin-right: ${({ theme: { spacing } }) => spacing(1)}px;

  align-items: center;
`;

export const TitleAndControls = styled.div`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(15)}px;
  top: ${({ theme: { spacing } }) => spacing(3)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { spacing } }) => spacing(6)}px;

  margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;

  /* position: absolute; */
  user-select: none;
  padding: ${({ theme: { spacing } }) =>
    `3px ${spacing(1)}px 0 ${spacing(1)}px`};
  line-height: ${({ theme: { spacing } }) => spacing(3)}px;
  font-size: ${({ theme: { spacing } }) => spacing(3)}px;

  border: ${({
      theme: {
        palette: {
          bg: { darkGreen },
        },
      },
    }) => darkGreen}
    solid 2px;

  text-transform: uppercase;
  background-color: ${({
    theme: {
      palette: {
        bg: { smokeyWhite },
      },
    },
  }) => smokeyWhite};

  /* background-color: rgba(1, 1, 1, 0.1); */
`;
export const MarkerToggle = styled.div`
  margin-left: ${({ theme: { spacing } }) => spacing(3)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;
`;
