import { useState, useCallback } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import Toggle from "./Toggle";
import { Menu, LinkGroup, LinkItem } from "./NavigationMenu.style";
import useStore from "state/zustandStore";

const NavigationMenu = ({ routes }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const sharedOwnership = useStore((state) => state.sharedOwnership);
  const setSharedOwnership = useStore((state) => state.setSharedOwnership);

  const handleSwitch = useCallback(() => {
    setSharedOwnership(!sharedOwnership);
    setIsOpen((open) => !open);
  }, [sharedOwnership, setSharedOwnership, pathname]);
  const handleToggleMenu = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const { path: currentLocation } =
    routes.find(({ path }) => pathname.startsWith(path)) || routes[0];

  const dontShow = ["ia", "ha", "hb", "id", "ic", "ib"];

  return (
    <Menu isOpen={isOpen}>
      <Toggle onClick={handleToggleMenu} />
      <LinkGroup>
        {routes.map(({ path, name }, index) => (
          <LinkItem key={path}>
            <Link onClick={handleToggleMenu} to={isOpen ? path : "#"}>
              {isOpen
                ? name
                : index === 0
                ? currentLocation.split("/").length > 2
                  ? currentLocation.split("/")[1]
                  : currentLocation.replaceAll("/", "")
                : name}
            </Link>
          </LinkItem>
        ))}
        {!dontShow.includes(pathname.split("/")[2]) && (
          <LinkItem key={1}>
            <Link onClick={handleSwitch}>
              {sharedOwnership ? "PRIVATE SALES" : "SHARED OWNERSHIP"}
            </Link>
          </LinkItem>
        )}
      </LinkGroup>
    </Menu>
  );
};

export default NavigationMenu;
