import styled from "styled-components";

export const FilmTitle = styled.div`
  position: absolute;
  /* border: white solid 2px; */
  pointer-events: none;

  user-select: none;
  font-family: Tungsten;
  justify-content: center;
  align-items: center;
  color: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
  font-size: 12vw;
  display: flex;
  z-index: 200;
  left: 25%;
  left: ${(props) => props.left}%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 20vh;
  /* background-color: hotpink; */
`;

export const FilmSubTitle = styled.div`
  position: absolute;
  user-select: none;
  pointer-events: none;

  /* border: white solid 2px; */
  font-family: Tungsten;
  justify-content: center;
  align-items: center;
  color: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
  font-size: 3vw;
  display: flex;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 20vh;
  /* background-color: hotpink; */
`;
