import styled from "styled-components";
import { useRanger } from "react-ranger";
import { useEffect, useLayoutEffect, useState } from "react";
import useStore from "state/zustandStore";
import { FIELD_NAME_MAPPER } from "App";
import { useLocation } from "react-router-dom";

function discreteLevelRange(arr) {
  let range = [];

  arr.forEach((v) => {
    range.push(v.split("-").map((e) => (e === "LG" ? -1 : Number(e))));
  });

  return { min: Math.min(...range.flat()), max: Math.max(...range.flat()) };
}

export const useAvailableApartments = (id) => {
  const apartments = useStore((s) => s.apartments);
  const selectedAspects = useStore((s) => s.selectedAspects);
  const bedroomCounts = useStore((s) => s.bedroomCounts);
  const priceRange = useStore((s) => s.priceRange);
  const sharedOwnership = useStore((s) => s.sharedOwnership);
  console.log(priceRange);
  const levelRange = useStore((s) => s.levelRange);

  return apartments.filter((apt) => {
    // console.log("STATUS", apt[FIELD_NAME_MAPPER.status]);
    return (
      apt[FIELD_NAME_MAPPER.status] === "0" &&
      apt[FIELD_NAME_MAPPER.block] === id &&
      (bedroomCounts.length === 0 ||
        bedroomCounts.includes(apt[FIELD_NAME_MAPPER.beds])) &&
      (selectedAspects.length === 0 ||
        apt[FIELD_NAME_MAPPER.aspect]
          .split("")
          .some((e) => selectedAspects.includes(e))) &&
      Number(
        apt[
          sharedOwnership
            ? FIELD_NAME_MAPPER.sharePrice
            : FIELD_NAME_MAPPER.askingPrice
        ]
      ) >= priceRange[0] &&
      Number(
        apt[
          sharedOwnership
            ? FIELD_NAME_MAPPER.sharePrice
            : FIELD_NAME_MAPPER.askingPrice
        ]
      ) <= priceRange[1] &&
      (levelRange.length === 0 ||
        (apt[FIELD_NAME_MAPPER.level] >= discreteLevelRange(levelRange).min &&
          apt[FIELD_NAME_MAPPER.level] <= discreteLevelRange(levelRange).max))
    );
  });
};

function NewFilter() {
  const [show, setShow] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/finder") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location.pathname]);
  const sharedOwnership = useStore((s) => s.sharedOwnership);
  const apartments = useStore((s) => s.apartments);
  const setMinPrice = useStore((s) => s.setMinPrice);
  const setMaxPrice = useStore((s) => s.setMaxPrice);

  const selectedAspects = useStore((s) => s.selectedAspects);
  const toggleAspects = useStore((s) => s.toggleAspects);

  const toggleBedroomCounts = useStore((s) => s.toggleBedroomCounts);
  const bedroomCounts = useStore((s) => s.bedroomCounts);

  const toggleLevelRange = useStore((s) => s.toggleLevelRange);
  const levelRange = useStore((s) => s.levelRange);

  const setPriceRange = useStore((s) => s.setPriceRange);

  const [minmax, setMinmax] = useState([0, 0]);

  useLayoutEffect(() => {
    let priceArray = [];
    apartments.forEach((apt) => {
      let v = Number(
        apt[
          sharedOwnership
            ? FIELD_NAME_MAPPER.sharePrice
            : FIELD_NAME_MAPPER.askingPrice
        ]
      );
      if (
        v !== 0 &&
        v !== null &&
        v !== undefined &&
        apt[FIELD_NAME_MAPPER.block] === "ia"
      )
        priceArray.push(v);
    });

    setMinPrice(Math.min(...priceArray) - 5000);
    setMaxPrice(Math.max(...priceArray) + 5000);

    setMinmax([Math.min(...priceArray) - 5000, Math.max(...priceArray) + 5000]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedOwnership, apartments]);

  /////////////////////////////

  let toggles = {
    ASPECT: selectedAspects,
    TYPE: bedroomCounts,
    FLOOR: levelRange,
  };

  const handleToggle = (e, type, v) => {
    if (type === "ASPECT") toggleAspects(v);
    if (type === "TYPE") toggleBedroomCounts(v);
    if (type === "FLOOR") toggleLevelRange(v);
  };

  const handleClear = () => {
    toggleAspects([]);
    toggleBedroomCounts([]);
    toggleLevelRange([]);

    setPriceRange([minmax[0], minmax[1]]);
    setValues([minmax[0], minmax[1]]);
  };

  const [values, setValues] = useState([null, null]);

  const filterRowsShared = [
    { title: "ASPECT", buttons: ["N", "E", "S", "W"] },
    { title: "TYPE", buttons: ["st", "1", "2"] },
    { title: "FLOOR", buttons: ["LG-1", "2-4", "5-7"] },
  ];
  const filterRowsRest = [
    { title: "ASPECT", buttons: ["N", "E", "S", "W"] },
    { title: "TYPE", buttons: ["st", "1", "2", "duplex"] },
    { title: "FLOOR", buttons: ["LG-1", "2-4", "5-8", "9-11", "12-15"] },
  ];

  let filterRows;

  if (sharedOwnership) {
    filterRows = filterRowsShared;
  } else {
    filterRows = filterRowsRest;
  }

  if (apartments.length !== 0) {
    return (
      <>
        <FilterContaienr
          style={{
            backgroundColor: show ? "rgba(0, 74, 62, 1)" : "",
            border: show ? "2px solid #f19990" : "2px solid #f1999000",
          }}
        >
          {show && (
            <>
              <FilterRow>
                <FilterTitle>PRICE</FilterTitle>
                <FilterAction>
                  <PriceFilter values={values} setValues={setValues} />
                </FilterAction>
              </FilterRow>
              {filterRows.map((cat) => (
                <FilterRow key={cat.title}>
                  <FilterTitle>{cat.title}</FilterTitle>
                  <FilterAction>
                    {cat.buttons.map((v) => (
                      <Btn
                        key={v}
                        className="test"
                        style={{
                          backgroundColor: toggles[cat.title].includes(v)
                            ? "#f19990"
                            : "#f1999000",
                          color: toggles[cat.title].includes(v)
                            ? "#1d4a3d"
                            : "#fff",
                        }}
                        onClick={(e) => handleToggle(e, cat.title, v)}
                      >
                        {v.toUpperCase()}
                      </Btn>
                    ))}
                  </FilterAction>
                </FilterRow>
              ))}
              <FilterRow>
                <FilterTitle style={{ cursor: "pointer" }}>CLEAR</FilterTitle>
                <FilterAction>
                  <Btn
                    onClick={() => handleClear()}
                    style={{ fontSize: "1.2s5em" }}
                  >
                    {"X"}
                  </Btn>
                </FilterAction>
              </FilterRow>
            </>
          )}
        </FilterContaienr>
        <FilterToggle onClick={() => setShow(!show)}>FILTER</FilterToggle>
      </>
    );
  } else return null;
}
const FilterToggle = styled.div`
  user-select: none;
  box-sizing: border-box;
  background-color: #1d4a3d;
  border: 2px solid #f19990;
  pointer-events: all;
  cursor: pointer;
  font-size: 2em;
  letter-spacing: 0.4em;
  left: 0;
  margin: ${({ theme: { spacing } }) => spacing(2)}px;
  bottom: 6%;
  padding: 0.1em 0.2em 0.1em 0.5em;
  transform-origin: 0% 0%;
  transform: rotate(-90deg) translate(0, 0);
  position: absolute;
  color: white;
  z-index: 99999999;
`;

function PriceFilter({ values, setValues }) {
  const minPrice = useStore((s) => s.minPrice);
  const maxPrice = useStore((s) => s.maxPrice);
  const setPriceRange = useStore((s) => s.setPriceRange);

  useLayoutEffect(() => {
    setValues([minPrice, maxPrice]);
    setPriceRange([minPrice, maxPrice]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice, maxPrice]);

  const handleOnChange = (values) => {
    setPriceRange(values);
  };

  const { getTrackProps, handles } = useRanger({
    min: minPrice,
    max: maxPrice,
    stepSize: 5000,
    values,
    onChange: handleOnChange,
    onDrag: setValues,
  });

  return (
    <>
      {/* <PriceValue>£{values[0]?.toFixed(0).slice(0, 3)}K</PriceValue> */}
      <PriceValue>£{values[0]}</PriceValue>
      <PriceSlider>
        <div
          {...getTrackProps({
            style: {
              height: "4px",
              background: "#f19990",
            },
          })}
        >
          {handles.map(({ getHandleProps }) => (
            <button
              {...getHandleProps({
                style: {
                  cursor: "pointer",
                  width: "1.5vh",
                  height: "1.5vh",
                  outline: "none",
                  borderRadius: "100%",
                  background: "#f19990",
                  border: "solid 1px #f19990",
                },
              })}
            />
          ))}
        </div>
      </PriceSlider>
      {/* <PriceValue>£{values[1]?.toFixed(0).slice(0, 3)}K</PriceValue> */}
      <PriceValue>£{values[1]}</PriceValue>
    </>
  );
}

export default NewFilter;

const PriceValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-shrink: 1;
  color: #f19990;
  max-width: 10%;
  min-width: 10%;
`;

const PriceSlider = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
`;

const Btn = styled.div`
  cursor: pointer;
  color: #f19990;

  border: #f19990 solid 1px;
  flex: 0.5;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 18%;

  transition: all 0.2s ease-in-out;
`;

const FilterTitle = styled.div`
  color: #f19990;

  flex: 1;
  flex-basis: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FilterAction = styled.div`
  /* border: orange solid 1px; */
  flex: 1;
  flex-grow: 7;
  pointer-events: all;
  display: flex;

  gap: 2%;
  padding: 0 2%;
`;

const FilterRow = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
`;

const FilterContaienr = styled.div`
  box-sizing: border-box;
  pointer-events: none;
  z-index: 99;
  position: absolute;
  bottom: 0;
  height: 25vh;
  width: 50vh;

  margin: 5em;
  /* background-color: rgba(0, 74, 62, 1); */

  padding: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 1em;

  font-size: 1.5vh;
  letter-spacing: 0.25vh;

  > * {
    user-select: none;
  }
`;
