import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

const INITIAL_STATE = {
  price: {
    from: 340000,
    to: 840000,
  },
  aspect: [
    {
      id: "N",
      values: ["N", "NE", "NW"],
      selected: false,
    },
    {
      id: "S",
      values: ["SE", "S", "SW"],
      selected: false,
    },
    {
      id: "E",
      values: ["E", "SE", "NE"],
      selected: false,
    },
    {
      id: "W",
      values: ["W", "SW", "NW"],
      selected: false,
    },
  ],
  floor: [
    {
      id: "LG-1",
      values: [-1, 0, 1],
      selected: false,
    },
    {
      id: "2-4",
      values: [2, 3, 4],
      selected: false,
    },
    {
      id: "5-8",
      values: [5, 6, 7, 8],
      selected: false,
    },
    {
      id: "9-11",
      values: [9, 10, 11],
      selected: false,
    },
    {
      id: "12-15",
      values: [12, 13, 14, 15],
      selected: false,
    },
  ],
  type: [
    {
      id: "ST",
      values: ["ST"],
      selected: false,
    },
    {
      id: "1",
      values: ["1"],
      selected: false,
    },
    {
      id: "2",
      values: ["2"],
      selected: false,
    },

    {
      id: "DUPLEX",
      values: ["DUPLEX"],
      selected: false,
    },
  ],
};

const apartmentFinder = createSlice({
  name: "aprtment-finder",
  initialState: INITIAL_STATE,
  reducers: {
    setPriceRange: (state, { payload: { value } }) => {
      // console.log(value);
      state.price.from = value[0];
      state.price.to = value[1];
    },
    toggleOption: (state, { payload: { category, id } }) => {
      let current = state[category].find(({ id: _id }) => _id === id);
      // console.log("current:", JSON.stringify(current));
      current.selected = !current.selected;
    },
    clearFilters: (state) => INITIAL_STATE,
  },
  extraReducers: {},
});

export const {
  setPriceRange,
  toggleOption,
  clearFilters,
} = apartmentFinder.actions;

export const apartmentFiltersSelector = (state) =>
  get(state, "aprtment-finder");

export default apartmentFinder.reducer;
