import styled from "styled-components/macro";

const TopCentreOuter = styled.div`
  position: absolute;
  top: ${({ theme: { spacing } }) => spacing(3)}px;
  left: 50%;
`;

const TopCentreInner = styled.div`
  display: inline-block;
  margin-left: -50%;
`;

export const TopCentre = ({ children }) => (
  <TopCentreOuter>
    <TopCentreInner>{children}</TopCentreInner>
  </TopCentreOuter>
);

const CentreLeftOuter = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ theme: { spacing } }) => spacing(3)}px;
`;

const CentreLeftInner = styled.div`
  margin: auto;
  transform: translate(0, -50%);
`;

export const CentreLeft = ({ children }) => (
  <CentreLeftOuter>
    <CentreLeftInner>{children}</CentreLeftInner>
  </CentreLeftOuter>
);

const CentreRightOuter = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme: { spacing } }) => spacing(3)}px;
`;

const CentreRightInner = styled.div`
  margin: auto;
  transform: translate(0, -50%);
`;

export const CentreRight = ({ children }) => (
  <CentreRightOuter>
    <CentreRightInner>{children}</CentreRightInner>
  </CentreRightOuter>
);
