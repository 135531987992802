// create zustand store
import { create } from "zustand";

const useStore = create((set) => ({
  apartmentIndex: {},
  setApartmentIndex: (v) => set({ apartmentIndex: v }),

  apartments: [],
  setApartments: (v) => set({ apartments: v }),

  sharedOwnership: true,
  setSharedOwnership: (v) => set({ sharedOwnership: v }),

  rotation: 0,
  setRotation: (v) => set({ rotation: v }),

  minPrice: 0,
  setMinPrice: (v) => set({ minPrice: v }),
  maxPrice: 0,
  setMaxPrice: (v) => set({ maxPrice: v }),
  priceRange: [0, 0],
  setPriceRange: (v) => set({ priceRange: v }),

  levelRange: [],
  toggleLevelRange: (count) =>
    set((state) => ({
      levelRange: count.length
        ? state.levelRange.includes(count)
          ? state.levelRange.filter((c) => count !== c)
          : [...state.levelRange, count]
        : [],
    })),

  bedroomCounts: [],
  toggleBedroomCounts: (count) =>
    set((state) => ({
      bedroomCounts: count.length
        ? state.bedroomCounts.includes(count)
          ? state.bedroomCounts.filter((c) => count !== c)
          : [...state.bedroomCounts, count]
        : [],
    })),

  selectedAspects: [],
  toggleAspects: (count) =>
    set((state) => ({
      selectedAspects: count.length
        ? state.selectedAspects.includes(count)
          ? state.selectedAspects.filter((c) => count !== c)
          : [...state.selectedAspects, count]
        : [],
    })),
}));

export default useStore;
