import styled from "styled-components/macro";

export const Icon = styled.svg`
  cursor: pointer;
  width: ${({ theme: { spacing } }) => spacing(6)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
`;

export const BasicIcon = styled.svg`
  cursor: pointer;
  width: ${({ theme: { spacing } }) => spacing(6)}px;
  height: ${({ theme: { spacing } }) => spacing(6)}px;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* border-radius: 100%; */
`;
