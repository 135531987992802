import styled from "styled-components/macro";

export const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};

  & video {
    object-fit: cover;
  }
`;
