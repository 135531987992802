import Screen from "components/Screen";
import theme from "theme/theme";
import {
  About,
  ContentPage,
  Element,
  ElementTitle,
  Enquiries,
  SecondaryContainer,
  SubtitlePage,
  TitlePage,
} from "./AboutUs.style";
import { ScrollTextBoxHolder, ScrollTextWrapper } from "./Architects.style";
import LeafGraphic from "./LeafGraphic";
import { Route } from "react-router-dom";
import config from "./config.json";
import { useState, useEffect, useRef } from "react";

const InfoParagraph = ({ textnum, page }) => {
  return (
    <>
      <h5>{config[0][page][textnum].header}</h5>
      <br />
      <p>{config[0][page][textnum].body}</p>
    </>
  );
};

function map_range(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

const AboutUs = ({ match }) => {
  const [archhue, archsetHue] = useState(100);
  const [archlightValue, archsetLigntValue] = useState(58);

  const [devhue, devsetHue] = useState(100);
  const [devlightValue, devsetLigntValue] = useState(79);

  const aboutArcRef = useRef();
  const aboutDevRef = useRef();

  const handleArchScroll = (e) => {
    // console.log(archlightValue);
    archsetHue(map_range(aboutArcRef.current.scrollTop, 1, 1000, 100, 0));
    archsetLigntValue(
      map_range(aboutArcRef.current.scrollTop, 1, 1000, 58, 100)
    );
    // light
  };

  const handleDevScroll = (e) => {
    // console.log(devlightValue);
    devsetHue(map_range(aboutDevRef.current.scrollTop, 1, 1000, 100, 0));
    devsetLigntValue(
      map_range(aboutDevRef.current.scrollTop, 1, 1000, 79, 100)
    );
    // light
  };

  const [isReady, setIsReady] = useState(false);

  // console.log(document.fonts);

  useEffect(() => {
    document.fonts.load("12px Tungsten").then(() => setIsReady(true));
  }, []);

  const id = match.params;
  // console.log(id);

  return (
    isReady && (
      <Screen>
        <Route exact path={["/aboutus/"]}>
          <About
            onScroll={(e) => handleArchScroll(e)}
            ref={aboutArcRef}
            left={0}
            color={`hsl(347,${archhue}%,${archlightValue}%)`}
            textColor={theme.palette.fg.pink}
            width={50}
          >
            <TitlePage textColor={theme.palette.fg.pink}>
              THE <br />
              ARCHITECTS
            </TitlePage>
            <ContentPage>
              <SubtitlePage textColor={theme.palette.fg.pink}>
                THE VISION FOR <br /> ARDEN
              </SubtitlePage>
              <SecondaryContainer>
                <div class="subtitle">
                  PETE WOODFORD DIRECTOR AT BPTW,
                  <br /> ARCHITECTS OF ARDEN
                </div>

                <div class="six">
                  <p>
                    Architect Pete Woodford is a director at BPTW, an
                    award-winning and innovative studio with over 30 years of
                    experience in transforming not just physical spaces, but
                    people’s lives.
                    <br /> <br />
                    For the past 8 years, he’s been a leading director working
                    on the masterplanning and design of Parkside, which
                    dovetails perfectly with his interest in creating
                    sustainable communities.
                  </p>
                </div>
              </SecondaryContainer>
              <SecondaryContainer>
                <div class="quote">
                  “Arden is something unique: the spacious homes are built with
                  a focus on the quality of craftsmanship and a design which
                  cleverly connects them with the outdoors and the wider
                  landscape.”
                </div>
                <div class="headshot"></div>
              </SecondaryContainer>
              <ScrollTextWrapper>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="architects" textnum={0} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="architects" textnum={1} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="architects" textnum={2} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="architects" textnum={3} />
                </ScrollTextBoxHolder>
              </ScrollTextWrapper>
            </ContentPage>
          </About>
          <About
            onScroll={(e) => handleDevScroll(e)}
            ref={aboutDevRef}
            left={50}
            color={`hsl(3,${devhue}%,${devlightValue}%)`}
            textColor={theme.palette.fg.darkPink}
            width={50}
          >
            <TitlePage textColor={theme.palette.fg.darkPnk}>
              THE <br />
              DEVELOPER
            </TitlePage>
            <ContentPage>
              <SecondaryContainer>
                <object
                  class="logo"
                  width="80%"
                  data={`${process.env.PUBLIC_URL}/assets/logo/peabody.svg`}
                >
                  <img alt="Peabody"></img>
                </object>

                <div class="peabodyText">
                  Peabody creates great places where people want to live, adding
                  value through long-term, patient investment. This historic
                  organisation is known for upholding high design standards to
                  create homes in great locations which are safe, easy to
                  maintain and long-lasting.
                </div>
              </SecondaryContainer>
              <ScrollTextWrapper>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={0} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={1} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={2} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={3} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={4} />
                </ScrollTextBoxHolder>
                <ScrollTextBoxHolder>
                  <InfoParagraph page="developers" textnum={5} />
                </ScrollTextBoxHolder>
              </ScrollTextWrapper>
              <Enquiries>
                <Element>
                  <ElementTitle>
                    <div class="enquiriesTitle">ENQUIRIES</div>
                    <object
                      class="kflogo"
                      // width="100%"
                      data={`${process.env.PUBLIC_URL}/assets/logo/knightfrank.svg`}
                    >
                      <img alt="Peabody"></img>
                    </object>
                  </ElementTitle>
                  <div class="address">
                    Arden Marketing Suite <br /> Kestral House <br /> Parkside
                    Avenue <br /> SE10 8FP <br /> London <br /> United Kingdom
                    <br /> <br />
                    +44 (0)20 3369 0817 <br /> <br /> arden@knightfrank.co.uk{" "}
                    <br />
                    arden-parkside.co.uk
                  </div>
                </Element>{" "}
                <div class="render"></div>
              </Enquiries>
              <span class="disclaimer">
                <p>
                  Every care has been taken in the preparation of this online
                  suite. The details contained herein are for guidance only and
                  should not be relied upon as exactly describing any of the
                  particular material illustrated or written by any order under
                  the Consumer Protection from Unfair Trading Regulations 2008.
                  This information does not constitute a contract, part of a
                  contract or warranty. The developer operates a programme of
                  continuous product development. Features, internal and
                  external, may vary from time to time and may differ from those
                  shown in the app. Computer generated images and photography
                  used within this brochure are indicative only.
                </p>
              </span>{" "}
            </ContentPage>
          </About>
        </Route>

        <LeafGraphic />
      </Screen>
    )
  );
};

export default AboutUs;
