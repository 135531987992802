import styled from "styled-components/macro";

export const GridRoot = styled.div`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(6)}px;

  display: grid;
  position: relative;
  gap: ${({ theme: { spacing } }) => spacing(6)}px;

  @media (min-width: 1280px) {
    grid-auto-rows: 50%;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  }

  @media (max-width: 1279px) {
    grid-auto-rows: 30%;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  }
`;

export const SpotsRoot = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Spot = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({
    isActive,
    theme: {
      palette: {
        fg: { pink, darkPink },
      },
    },
  }) => (isActive ? darkPink : pink)};
  margin-left: 10px;
  margin-right: 10px;
`;
