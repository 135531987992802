import styled from "styled-components/macro";

const TAB_WIDTH = 40;
const TAB_HEIGHT = 200;

export const Root = styled.div``;

export const Tab = styled.div`
  position: fixed;
  z-index: 1000000;
  right: 0;
  top: 50%;
  height: ${TAB_WIDTH}px;
  width: ${TAB_HEIGHT}px;
  transform-origin: right bottom;
  transform: translate(-${TAB_WIDTH}px, ${TAB_HEIGHT / 2 - TAB_WIDTH}px)
    rotate(90deg);
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  height: 100%;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: ${({ isOpen }) => (isOpen ? "0px" : "-100%")};

  transition: right 0.3s ease;
`;
