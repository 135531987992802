import { Switch, Route } from "react-router";
import ApartmentFinder from "./ApartmentFinder";
import BuildingExplorer from "./BuildingExplorer";
import NewFilter from "./BuildingExplorer/NewFiltering/NewFilter";

const ApartmentFinderRouting = () => (
  <>
    <NewFilter />
    <Switch>
      <Route path={"/finder/:buildingName"} component={BuildingExplorer} />
      <Route component={ApartmentFinder} />
    </Switch>
  </>
);

export default ApartmentFinderRouting;
