import CoverImage from "components/CoverImage";
import { Root, ImageWrapper } from "./GridGalleryImage.style";

const GalleryImage = ({ style, url, onClick }) => (
  <Root style={style} onClick={onClick}>
    <ImageWrapper>
      <CoverImage imgUrl={url} />
    </ImageWrapper>
  </Root>
);

export default GalleryImage;
