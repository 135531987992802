import { useMemo, useState } from "react";

const usePairedSegments = (segments, initialIndex = 0) => {
  const pairs = useMemo(
    () =>
      segments.map((_, i) => ({
        fw: segments[i][0],
        rv: segments[prevIndex(i, segments)][1],
      })),
    [segments]
  );
  const videos = useMemo(() => segments.flat(), [segments]);

  const [index, setIndex] = useState(initialIndex);
  const [direction, setDirection] = useState(1);
  const [playing, setPlaying] = useState();

  const pair = pairs[index];
  const current = direction === -1 ? pair.rv : pair.fw;

  return {
    index,
    videos,
    current,
    playing,
    left: pair.rv,
    right: pair.fw,
    onPlay: (video) => {
      setDirection(video === pair.rv ? -1 : 1);
      setTimeout(() => {
        setPlaying(video);
      }, 50);
    },
    onEnd: () => {
      setPlaying();
      setIndex(
        direction === -1 ? prevIndex(index, pairs) : nextIndex(index, pairs)
      );
    },
  };
};

const prevIndex = (current, arr) => {
  return current === 0 ? arr.length - 1 : current - 1;
};

const nextIndex = (current, arr) => {
  return (current + 1) % arr.length;
};

export default usePairedSegments;
