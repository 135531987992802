class HttpService {
  async execute({
    url,
    method,
    body,
    credentials,
    contentType = "application/json",
  }) {
    const headers = new Headers({
      "Content-Type": contentType,
    });
    if (credentials) {
      headers.set(
        "Authorization",
        `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
      );
    }
    return await fetch(url, {
      method,
      mode: "cors",
      credentials: credentials ? "include" : "omit",
      headers,
      data: body ? JSON.stringify(body) : undefined,
    }).then((res) => res.json());
  }

  async get({ url, credentials }) {
    return await this.execute({ url, credentials, method: "GET" });
  }
}

export default HttpService;
