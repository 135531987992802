import styled from "styled-components";

export const Info = styled.div`
  align-items: center;
  justify-items: center;
  align-content: center;
  text-align: center;
  font-size: 2rem;
`;

export const Share = styled.div`
  user-select: none;
  z-index: 1000;
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26vw;
  height: 11vh;

  border-radius: none;
  border: ${({
      theme: {
        palette: {
          fg: { darkPink },
        },
      },
    }) => darkPink}
    solid 2px;

  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
`;

export const CopyButton = styled.div`
  cursor: pointer;
  font-size: 2rem;
  flex: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  justify-items: center;
  text-align: center;

  color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
`;

export const FavouriteTitle = styled.div`
  font-weight: 300;
  font-size: 2rem;
  padding-bottom: 200px;
  color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
`;

export const ShareButton = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  text-align: center;
  border-radius: 100%;
  /* position: absolute; */
  height: 100px;
  width: 100px;

  bottom: 20%;
  margin: 60px;
  font-size: 2rem;
  cursor: pointer;

  background: white;
`;

export const ShareUIWrapper = styled.div`
  /* z-index: 1; */
  /* pointer-events: none; */
  overflow: hidden;
  padding-top: 20vh;
  flex: 1;
  background: #003028;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-content: center;
  /* justify-content: space-around; */
  align-items: center;
`;

export const FavouriteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 60px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  flex: 2;
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  background-color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
`;

export const FavouriteCard = styled.div`
  z-index: 100;

  cursor: pointer;
  margin-left: auto;
  width: 600px;
  height: 45vh;
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

export const CenterFaves = styled.div`
  width: max-content;
  display: flex;
`;

export const FavouritesCardsWrapper = styled.div`
  /* pointer-events: none; */
  flex-basis: 79%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: scroll;
  flex-direction: row;
  background-color: ${({
    theme: {
      palette: {
        bg: { darkGreen },
      },
    },
  }) => darkGreen};
`;

export const FavouritesPageWrapper = styled.div`
  /* margin-top: 100px; */
  display: flex;
  width: 100vw;
  height: 100vh;
  /* padding: 10vw; */
  /* background-color: white; */
`;
