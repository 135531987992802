import styled from "styled-components/macro";

export const Root = styled.div`
  flex: 1;
  display: flex;
  outline: none;
  background-color: ${({
    theme: {
      palette: {
        bg: { sageGreen },
      },
    },
  }) => sageGreen};
`;
