import data from "./matt.json";

class SalesInfoService {
  constructor(config, httpService) {
    this.endpoint = config.kodimeEndpoint;
    // this.credentials = config.kodimeCredentials;
    this.httpService = httpService;
  }
  async getSalesInfo() {
    // return this.httpService.get({
    //   url: this.endpoint,
    //   // credentials: this.credentials,
    // });
    // temporarily return a local copy of the data until
    // we get the access worked out with kodime
    return new Promise((resolve) => {
      //   //   // simulate a network delay to make sure we're not
      //   // buggering up the async stuff
      setTimeout(() => {
        resolve(data);
      }, 200);
    });
  }
}

export default SalesInfoService;
