import styled from "styled-components/macro";

export const ObjectHolder = styled.object`
  text {
    font-size: 7rem;
  }
  .kflogo {
    font-size: 7rem;

    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
