export const MenuIconCust = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <svg viewBox="-24 -24 48 48">
        <circle
          stroke="#FF9994"
          strokeWidth="2"
          fillOpacity="1"
          fill="#1d4a3d"
          cx={0}
          cy={0}
          r={22}
        />
        <path
          d="M -11 -7 L 11 -7"
          strokeLinecap="round"
          stroke="#FF9994"
          strokeWidth="2"
          fillOpacity="0"
        />

        <path
          d="M -11 0 L 11 0"
          strokeLinecap="round"
          stroke="#FF9994"
          strokeWidth="2"
          fillOpacity="0"
        />
        <path
          d="M -11 7 L 11 7"
          strokeLinecap="round"
          stroke="#FF9994"
          strokeWidth="2"
          fillOpacity="0"
        />
      </svg>
    </div>
  );
};
