import { Root, GlassPanel, ContentPanel, Content } from "./Modal.style";

const Modal = ({ onClose, fullScreen = false, children }) => (
  <Root style={{ zIndex: 200 }}>
    <GlassPanel onClick={onClose} />
    <ContentPanel fullScreen={fullScreen}>
      <Content>{children}</Content>
    </ContentPanel>
  </Root>
);

export default Modal;
