import ThemeProvider from "./theme";
// import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider as StoreProvider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import createStore from "state/store";
import i18n from "./i18n/i18n-config";
import Landing from "screens/Landing";
import MainApp from "screens/MainApp";
import DependencyProvider from "components/DependencyProvider";
import { primaryRoutes, home } from "./routes";
import SalesInfoPreload from "./SalesInfoPreload";
import dependencies from "./dependencies";
import useStore from "state/zustandStore";

const history = createBrowserHistory();
const store = createStore(dependencies);

const basename = "/";

function formatNumber(num) {
  return num.toString().padStart(3, "0");
}

export const FIELD_NAME_MAPPER = {
  block: "Block",
  plot: "Plot Number",
  number: "Flat Number",
  status: "newStatus",
  blockName: "Block Name",
  level: "Level",
  pType: "Property Type",
  type: "Flat Type",
  beds: "Description",
  askingPrice: "Asking Price",
  sharePrice: "Share price",
  aspect: "Primary Aspect",
};

function cleanAptData(ap) {
  ap["Flat Number"] = ap["Flat or house number"]
    .replaceAll(" ", "")
    .toLowerCase();

  ap.Beds = ap.Beds.charAt(0);
  // console.log(ap.Block);
  ap.Block = ap.Block.replaceAll(" ", "").toLowerCase();

  ap.newStatus = ap.Availability === "Available" ? "0" : "1";

  ap["Asking Price"] = ap["Full Value"];

  ap["Block Name"] = ap.Address.split(".")[0];

  ap.Level = parseInt(ap.Floor.split(" ")[0]);
  // console.log(ap);
  // console.log(ap.Floor);

  ap.Address = ap.Address.replaceAll(".", "");

  const plotNo = formatNumber(ap["Flat or house number"]);

  ap["Plot Number"] = `${ap.Block}-${plotNo}`;

  ap["Flat Type"] = ap["Unit Type"].replaceAll(" ", "").toLowerCase();
  // delete ap["Flat Type"];

  return ap;
}
export const ApartmentSheetURL =
  // "https://docs.google.com/spreadsheets/d/e/2PACX-1vTuqNT7zlR39L1S5YujL_IxiZHjeSkZzIGXKHO606iZrye-WNyDd-UfoIbfryBws-zq_i11XmZ3cZHw/pub?output=csv";
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vSl-Cfhhw0ryfJP4cMTqMKRwxNrUNCocgjjL4bvwKYlLoa6bZQcXYNDLBX-1CwyB15dmzn7kHMsymPr/pub?gid=33736561&single=true&output=csv";
// "https://docs.google.com/spreadsheets/d/e/2PACX-1vQuls1b7_K5cF_78p8tuC1xseY4YSAGusqquaH3zGhiqcTbb8na1PfHaL3CG4AZeQ/pub?output=csv";

function App() {
  const setApartments = useStore((s) => s.setApartments);

  const fetchApartmentsCSV = () => {
    fetch(ApartmentSheetURL)
      .then((response) => {
        if (!response.ok) return;
        processCSV(response.text());
      })
      .catch((err) => {
        console.log("Cannot fetch apartments.", err);
      });
  };

  fetchApartmentsCSV();

  const processCSV = (csvText) => {
    csvText
      .then((text) => {
        // console.log(text);
        const data = text.split("\r\n").map((line) => line.split(","));
        const header = data[0];
        // console.log(header);
        const apartmentsLines = data.slice(1);
        const allApartments = [];
        let avb = [];

        for (let line of apartmentsLines) {
          let apartment = {};
          // console.log(line);
          for (let x = 0; x < line.length; x++) {
            // console.log(header[x], line[x]);
            apartment[header[x]] = line[x];
          }

          if (cleanAptData(apartment).newStatus === "0") {
            avb.push(cleanAptData(apartment)["Flat Number"]);
          }

          allApartments.push(cleanAptData(apartment));

          // if (cleanAptData(apartment)["Flat Number"] === "43")
          //   console.log(cleanAptData(apartment));
        }

        setApartments(allApartments);
      })
      .catch((err) => {
        console.log("Error on processing CSV text: ", err);
      });
  };

  return (
    <ThemeProvider>
      <DependencyProvider dependencies={dependencies}>
        <I18nextProvider i18n={i18n}>
          <StoreProvider store={store}>
            <BrowserRouter history={history} basename={basename}>
              <SalesInfoPreload />
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Landing enterPath={home.path} />}
                />
                <Route render={() => <MainApp routes={primaryRoutes} />} />
              </Switch>
            </BrowserRouter>
          </StoreProvider>
        </I18nextProvider>
      </DependencyProvider>
    </ThemeProvider>
  );
}

export default App;
