import buildingData from "./AptData/buildingData";

const inc = 20;

let windowHeight = window.innerHeight;

const params = {
  inc: inc,
  windowY: windowHeight,
  building: function getBuilding(currentBuilding) {
    // const paramSpace = window.innerHeight / 46;
    let current = -20;

    const spacing = [];
    const floors = buildingData[currentBuilding].numberOfAprt;

    for (let i = 0; i < floors.length; i++) {
      current = current + inc;
      spacing.push(current);
    }
    return spacing;
  },
};

export default params;
