import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Root } from "./Video.style";

const Video = ({ className, url, isVisible, playing, onEnd }) => {
  const player = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isVisible && ready) {
      setTimeout(() => {
        player.current.seekTo(0);
      }, 50);
    }
  }, [ready, isVisible]);
  return (
    <Root isVisible={isVisible}>
      <ReactPlayer
        ref={player}
        url={url}
        width="100%"
        height="100%"
        playing={playing}
        onEnded={onEnd}
        onReady={setReady}
        playsinline={true}
      />
    </Root>
  );
};

export default Video;
