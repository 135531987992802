import { Link } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import { useIntersection } from "react-use";
import gsap from "gsap";
import "./tempStyles/tempLandingStyles.css";
import styled from "styled-components";
import useStore from "state/zustandStore";

const movie = `${process.env.PUBLIC_URL}/assets/videos/arden_splash_opener_video.mp4`;

function Landing({ enterPath }) {
  const setSharedOwnership = useStore((s) => s.setSharedOwnership);
  const isMobile = (function (a) {
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    );
  })(navigator.userAgent || navigator.vendor || window.opera);

  let screen = window.screen;
  let type =
    (screen.orientation || {}).type ||
    screen.mozOrientation ||
    screen.msOrientation;

  const [ori, setOri] = useState(type);

  console.log("window", window.orentation);

  function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      screen.orientation.lock("landscape-secondary");
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }
  window.addEventListener("orientationchange", function () {
    setOri(
      (screen.orientation || {}).type ||
        screen.mozOrientation ||
        screen.msOrientation
    );

    if (ori === "landscape-primary" || ori === "landscape-secondary") {
      screen.orientation.lock(ori);
    }
  });

  const landing = useRef(null);
  const sectionRefOne = useRef(null);
  const sectionRefTwo = useRef(null);

  useEffect(() => {
    fadeInStart(".fadeEnter");
  });

  // All the ref to be observed
  const intersectionOne = useIntersection(sectionRefOne, {
    root: null,
    rootMargin: "100px",
    threshold: 0.5,
  });

  const intersectionTwo = useIntersection(sectionRefTwo, {
    root: null,
    rootMargin: "100px",
    threshold: 0.5,
  });

  const fadeEnter = (element) => {
    gsap.to(element, 2, {
      opacity: 1,
      ease: "Power4.out",
    });
  };

  const fadeInStart = (element) => {
    gsap.to(element, 2, {
      opacity: 1,
      ease: "Power4.out",
    });
  };

  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: -60,
      ease: "Power4.out",
    });
  };

  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: "power4.out",
    });
  };

  intersectionOne && intersectionOne.intersectionRatio < 0.5
    ? fadeOut(".fadeInOne")
    : fadeIn(".fadeInOne");

  intersectionTwo && intersectionTwo.intersectionRatio < 0.5
    ? fadeOut(".fadeInTwo")
    : fadeIn(".fadeInTwo");

  return (
    <div className="container">
      <section className="section First fadeInStart" ref={landing} id="home">
        <div className="arden">
          <object
            style={{
              left: 0,
              height: "50%",
              width: "60%",
            }}
            data={`${process.env.PUBLIC_URL}/assets/logo/title.svg`}
            type="image/svg+xml"
          >
            <img alt="floorplan" />
          </object>
          {/* <h1
            className="fadeIn arden title"
            style={{ fontSize: "17rem", letterSpacing: "6rem" }}
          >
            ARDEN
          </h1> */}
          {/* <h2 className="fadeIn arden subtitle">AT PARKSIDE SE10</h2> */}
        </div>
        <video className="videoTag" autoPlay loop muted>
          <source src={movie} type="video/mp4" />
        </video>

        {ori === "landscape-primary" || ori === "landscape-secondary" ? (
          <EnterHolder>
            <h2
              onClickCapture={() => setSharedOwnership(false)}
              className="fadeEnter quote"
              style={{ userSelect: "none" }}
              onClick={isMobile ? toggleFullScreen : null}
            >
              <Link
                style={{ color: "#FFEACA", fontSize: "4rem" }}
                to={enterPath}
              >
                {ori === "landscape-primary" || ori === "landscape-secondary"
                  ? "Private Sales"
                  : " please rotate phone"}
                {/* {} ENTER */}
              </Link>
            </h2>
            <h2
              onClickCapture={() => setSharedOwnership(true)}
              className="fadeEnter quote"
              style={{ userSelect: "none" }}
              onClick={isMobile ? toggleFullScreen : null}
            >
              <Link
                style={{ color: "#E79F96", fontSize: "4rem" }}
                to={enterPath}
              >
                {ori === "landscape-primary" || ori === "landscape-secondary"
                  ? "Shared Ownership"
                  : " please rotate phone"}
                {/* {} ENTER */}
              </Link>
            </h2>
          </EnterHolder>
        ) : null}
      </section>
    </div>
  );
}
const EnterHolder = styled.div`
  position: absolute;
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  bottom: 10%;
  /* border: red solid 2px; */

  h2 {
    font-size: 2rem;
    letter-spacing: 0.5rem;
  }
`;

export default Landing;
