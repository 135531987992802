import styled from "styled-components";

export const ShowroomCard = styled.img`
  position: absolute;
  /* z-index: 10; */
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh;
  width: 60vw;
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? "1" : "0")};
`;
