import React, { createContext, useRef, useContext } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import lerp from "lerp";
// import state from "./state";
import { Html } from "@react-three/drei";

import { createRef } from "react";

const state = {
  sections: 8,
  pages: 4,
  zoom: 5,
};

// console.log(state.top)

export default state;

const offsetContext = createContext(0);

function Block({
  currentPositionCallback,
  meshID,
  initialPositionY,
  scrollPosition,
  setFloor,
  children,
  offset,
  factor,
  ...props
}) {
  const ref = useRef();
  useFrame(() => {
    const curY = ref.current.position.y;

    const curTop = scrollPosition * -1;

    ref.current.position.y = lerp(curY, (curTop / state.zoom) * factor, 0.1);

    currentPositionCallback(
      initialPositionY - Math.abs(parseFloat(ref.current.position.y))
    );
  });

  return (
    <group {...props} position={[0, 0, 0]}>
      <group ref={ref}>{children}</group>
    </group>
  );
}

function useBlock() {
  const { sections, pages } = state;
  const { size, viewport } = useThree();
  const offset = useContext(offsetContext);
  const viewportWidth = viewport.width;
  const viewportHeight = viewport.height;
  const canvasWidth = viewportWidth;
  const canvasHeight = viewportHeight;
  const mobile = size.width < 700;
  const margin = canvasWidth * (mobile ? 0.2 : 0.1);
  const contentMaxWidth = canvasWidth * (mobile ? 0.8 : 0.6);
  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1));
  return {
    viewport,
    offset,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    mobile,
    margin,
    contentMaxWidth,
    sectionHeight,
  };
}

export { Block, useBlock };
