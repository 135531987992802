import React from "react";

export default function ToggleOuter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      // width={500}
      // height={500}
      fill={"#FFC1BF"}
      opacity={0.9}
      stroke={"#fff"}
      {...props}
    >
      <g strokeWidth={3} data-name="Layer 1">
        <circle strokeWidth={7} cx={200} cy={200} r="179.5" />
        <g fillOpacity={0}>
          <path d="M379.84,209.55c-11.6-2.38-22.68-5-33.2-7.75l-37.87,5.61,8.42,2.8-7,1.4a171.08,171.08,0,0,0,18.25,5.65,174.94,174.94,0,0,0,23.84,4.21l5.61-1.4,9.94,1.4-5.73,1.4c5.42,1.34,11.15,2.61,17.17,3.75" />
          <path d="M338.19,319a171.21,171.21,0,0,1-29.42-14.78V211.65h1.41" />
          <path d="M290,290V96.7l-53.29-3-44.88,1.4v6.27l-11.22.74q.16,23.22.34,46.43l-6-.7-25.21,2v83.53l16.15-2.19,20.32,7v11.22l9.81-2.81,5.61,2.81v68.72Z" />
          <polyline points="290.01 96.7 247.94 99.29 191.84 95.08" />
          <line x1="247.94" y1="99.29" x2="247.94" y2="303.39" />
          <path d="M180.62,102.09l42.07,4.21V154l5.47.63q.06,77.49.14,155" />
          <polyline points="222.69 106.3 242.25 104.67 242.25 305.63" />
          <path d="M228.3,155l-26.65,3.19" />
          <path d="M179.21,148.37,222.69,154" />
          <line x1="191.84" y1="101.35" x2="242.25" y2="104.67" />
          <polyline points="164.71 231.29 93.66 246.55 116.1 253.56 186.23 238.14" />
          <line x1="28.56" y1="258.38" x2="104.88" y2="256.37" />
          <polyline points="116.1 253.56 116.1 264.36 104.88 266.19 104.88 256.37 78.23 247.95 26.85 248.96" />
          <line x1="92.26" y1="246.55" x2="92.26" y2="252.83" />
          <path d="M99,350.64l5.84-.3,96.77-32.26V158.19l-51.89-8.41" />
          <line x1="116.1" y1="264.36" x2="116.1" y2="346.83" />
          <line x1="104.88" y1="266.19" x2="104.88" y2="350.34" />
          <polyline points="21.19 204.01 26.34 198.86 38.96 198.86 99.27 190.45 99.27 185.52 144.15 179.23 149.76 180.81 149.76 196.1 99.27 185.52 99.27 190.45 118.91 194.66 118.91 200.27 127.32 198.86 127.32 239.34" />
          <polyline points="19.33 205.88 43.23 205.88 118.91 194.66" />
        </g>
      </g>
    </svg>
  );
}
