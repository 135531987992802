import styled from "styled-components/macro";

export const Root = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const NavButton = styled.div`
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

export const LeftNavButton = styled(NavButton)`
  left: 3vw;
  top: 50vh;

  transform: translate(0, -50%);
`;

export const RightNavButton = styled(NavButton)`
  right: 3vw;
  top: 49vh;

  transform: translate(0, -50%);
`;

export const Children = styled.div`
  flex: 1;
  z-index: 200;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.25s ease;
`;
