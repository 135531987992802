import {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
} from "styled-components";
import { normalize } from "polished";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: ${theme.palette.fg.darkGreen};
    background-color: ${theme.palette.bg.lightGrey};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;    
    &:visited, &:active, &:link {
      color: inherit;
    }
  }

  #root {
    
  }
`;

const ApplicationThemeProvider = ({ children }) => (
  <StyledComponentsThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </StyledComponentsThemeProvider>
);

export default ApplicationThemeProvider;
