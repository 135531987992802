import styled from "styled-components/macro";

const Icon = styled.svg`
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
`;

const Spot = styled.circle`
  fill: ${({
    theme: {
      palette: {
        fg: { darkGreen },
      },
    },
  }) => darkGreen};
`;

const GridIcon = () => (
  <Icon viewBox="0 0 100 100">
    <Spot cx={25} cy={25} r={23} />
    <Spot cx={75} cy={25} r={23} />
    <Spot cx={25} cy={75} r={23} />
    <Spot cx={75} cy={75} r={23} />
  </Icon>
);

export default GridIcon;
