import React, { Suspense, useRef, useEffect, useMemo, useState } from "react";
import { Canvas, useResource, useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";

import Model from "./gltfjsx/Building";
import { BuildingModelContainer } from "./BuildingShellLoader.style";
import { Html, OrbitControls, useHelper } from "@react-three/drei";
import { PointLightHelper, PMREMGenerator, UnsignedByteType } from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
const hdri = `${process.env.PUBLIC_URL}/assets/hdri/environment.hdr`;

const Spinner = () => {
  return (
    <Html center>
      <h1>...loading</h1>
    </Html>
  );
};

const Enviro = ({ callActive }) => {
  const manager = useMemo(() => {
    return new THREE.LoadingManager();
  }, []);
  const { gl, scene } = useThree();

  const pmremGenerator = useMemo(() => {
    return new PMREMGenerator(gl);
  }, [gl]);

  const loader = useMemo(() => {
    return new RGBELoader(manager);
  }, [manager]);

  loader.setDataType(UnsignedByteType);
  pmremGenerator.compileEquirectangularShader();

  useEffect(() => {
    loader.load(hdri, (texture) => {
      callActive();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      scene.environment = envMap;

      texture.dispose();
      pmremGenerator.dispose();
    });
  }, [scene, loader, pmremGenerator]);

  return null;
};

function Lighting() {
  const pointLight1 = useRef();

  const pointLight2 = useRef();
  const pointLight3 = useRef();
  const group = useRef();

  useHelper(pointLight1, PointLightHelper, 0, "hotpink");
  useHelper(pointLight2, PointLightHelper, 0, "hotpink");
  useHelper(pointLight3, PointLightHelper, 0, "hotpink");
  return (
    <>
      <group ref={group}>
        <pointLight
          ref={pointLight1}
          color="lightBlue"
          position={[390, 90, 50]}
          intensity={0.9}
        />
        <pointLight
          ref={pointLight2}
          color="#FFC8A6"
          position={[-120, 60, 130]}
          intensity={0.4}
        />
      </group>
    </>
  );
}

function BuildingShellLoader({
  rotation,
  isActive,
  buildingName,
  availableApartmentsById,
}) {
  const [active, setActive] = useState(false);

  function callActive() {
    setActive(true);
  }

  return (
    <React.Fragment>
      <BuildingModelContainer>
        <Canvas
          gl={{
            antialias: true,
          }}
          camera={{
            position: [
              0,
              buildingName === "ha" ? 200 : 130,
              buildingName === "ha" ? 1000 : 550,
            ],
            fov: 30,
            far: 10000,
          }}
          colorManagement={true}
          // pixelRatio={(Math.min(window.devicePixelRatio), 2)}
          dpr={(Math.min(window.devicePixelRatio), 2)}
        >
          <Suspense fallback={<Spinner />}>
            <Enviro callActive={callActive} />
            {active && (
              <mesh
                position={[
                  buildingName === "ha" ? 0 : 0,
                  buildingName === "ha" ? -170 : -70,
                  buildingName === "ha" ? 0 : 0,
                ]}
                rotation={[0, rotation + 0.8, 0]}
              >
                <Model
                  isActive={isActive}
                  buildingName={buildingName}
                  availableApartmentsById={availableApartmentsById}
                />
              </mesh>
            )}
          </Suspense>
          <OrbitControls />

          <Lighting />
        </Canvas>
      </BuildingModelContainer>
    </React.Fragment>
  );
}

export default BuildingShellLoader;
