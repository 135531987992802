import styled from "styled-components";
import Button from "components/Button";
const margin = 20;

function randomVignette(num) {
  return `${process.env.PUBLIC_URL}/assets/images/vignettes/vignette_${num}.jpg`;
}

export const Vignette = styled.div`
  background-image: url(" ${(props) => randomVignette(props.vigNum)}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 2;
  overflow: auto;
  margin: ${margin}px;
`;

export const VignetteContainer = styled.div`
  z-index: 100;
  flex-basis: 100%;
  display: flex;
  flex: 3.5;
  flex-direction: column;
`;

export const Content = styled.div`
  /* border: orange solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 5;
  flex-direction: column;
`;

export const CardBody = styled.div`
  /* border: green solid 2px; */
  display: flex;
  flex: 5;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const InfoContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const FloorplanContainer = styled.div`
  /* border: blue solid 2px; */
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url("${(props) => props.id}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex: 3;
  width: 70%;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
`;

export const Indicator = styled.div`
  flex: 1;
`;

export const InfoFooter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-content: flex-start;
`;

export const Buttons = styled.div`
  position: "relative";

  & > ${Button} {
    margin-right: 20px;
  }
`;

export const FloorPlanGraphicContainer = styled.div`
  flex: 1;
`;

export const Price = styled.div`
  vertical-align: middle;
  padding: 1%;
  display: flex;
  /* border: black 2px solid; */
  justify-content: flex-end;
  flex: 1;

  font-size: 8vh;
  font-weight: 400;

  font-weight: 300;
  min-width: max-content;
  color: ${({
    theme: {
      palette: {
        fg: { pink },
      },
    },
  }) => pink};
`;

export const TitleHeader = styled.div`
  vertical-align: middle;

  font-size: 4vh;
  padding-top: 5px;
  flex: 1;
  font-weight: 400;
`;

export const Title = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 2%;
`;

export const Type = styled.li`
  display: inline;
  font-size: 2.5vh;

  font-weight: 400;
  vertical-align: middle;
`;

export const Floor = styled.li`
  font-size: 2.5vh;
  vertical-align: middle;
  display: inline;
  font-weight: 400;
`;

export const CardHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  background-color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
`;
export const CardFooter = styled.div`
  display: flex;

  /* border: red solid 2px; */
  /* padding: 2%; */
  flex-shrink: 1;
  flex-direction: row;
  /* gap: 1.5%; */
`;

export const ViewContainer = styled.div`
  cursor: pointer;
  z-index: 99999999999999;
  position: absolute;
  left: 0;
  top: 0;

  opacity: ${(props) => (props.viewOn ? 1 : 0)};
  transition: opacity 0.1s linear;
  pointer-events: ${(props) => (props.viewOn ? "auto" : "none")};

  padding: 4%;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  height: "100%";
`;
export const GreyedOut = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 15rem;
  z-index: 1000;
  width: 100%;
  height: 100%;

  color: ${(props) =>
    props.status === "ComingSoon"
      ? ({
          theme: {
            palette: {
              fg: { darkPink },
            },
          },
        }) => darkPink
      : "#549180"};

  background-color: rgba(50, 50, 50, 0.7);
  /* color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink}; */
`;

export const Sold = styled.div`
  font-size: 5rem;
`;

export const ComingSoon = styled.div`
  font-size: 5rem;
`;

export const NextButtonsContainer = styled.div`
  display: flex;
  flex-basis: 1;
  margin: 3px;
`;

export const Invisible = styled.div`
  display: flex;
  flex-basis: 1;
`;

export const NextButtons = styled.div`
  display: flex;
  flex-basis: 1;
  margin: 3px;
`;

// export const Compass = styled.div`
//   position: absolute;
//   right: 0;
//   /* transform: translate(0, -150px); */
// `;

// export const Table = styled.table`
//   margin: 0.5%;
//   flex: ${(props) => props.flex};
//   width: 70%;
//   th {
//     /* border: 1px solid #999; */
//     margin-bottom: 5px;
//     font-weight: 400;
//     color: ${({
//       theme: {
//         palette: {
//           fg: { darkPink },
//         },
//       },
//     }) => darkPink};
//     font-size: 1rem;

//     td {
//       /* border: 1px solid #999; */
//       font-weight: 400;
//       color: ${({
//         theme: {
//           palette: {
//             fg: { darkGreen },
//           },
//         },
//       }) => darkGreen};
//       font-size: 1rem;
//     }
//   }
// `;

// export const Root = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   color: ${({
//     theme: {
//       palette: {
//         fg: { pink },
//       },
//     },
//   }) => pink};
// `;
