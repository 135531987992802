import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

const color = "sageGreen";

export const BuildingLink = styled(NavLink)`
  /* border: white 2px solid; */
  position: absolute;
  left: ${({ position: { x } }) => x}%;
  top: ${({ position: { y } }) => y}%;
  /* transform: translate(-50%, -50%); */
  color: ${({
    theme: {
      palette: {
        fg: { color },
      },
    },
  }) => color};
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  justify-items: left;
  width: 160px;
  height: 100%;

  flex-direction: column;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const bg_image = `${process.env.PUBLIC_URL}/assets/images/AptFinderBg.jpg`;

export const AptImgBg = styled.div`
  background-image: url("${bg_image}");
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Label = styled.span`
  text-decoration: 0.5px;
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};

  font-size: ${({ theme: { spacing } }) => spacing(0.45)}rem;
  font-weight: 400;
  display: flex;
  /* letter-spacing: 6px; */
`;

export const Count = styled.span`
  display: flex;
  /* border: red 2px solid; */
  justify-content: center;
  width: 150px;
  text-transform: uppercase;
  top: 20px;
  margin-top: 5px;
  color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  font-size: 1.3rem;
  font-weight: 500;
  font-size: ${({ theme: { spacing } }) => spacing(0.175)}rem;
  letter-spacing: ${({ theme: { spacing } }) => spacing(0.4)}px; ;
`;
