import { useMemo, useState, useEffect, useCallback } from "react";
import { Switch, Route, generatePath } from "react-router";
import Screen from "components/Screen";
import InnerOrbit from "./InnerOrbit";
import OuterOrbit from "./OuterOrbit";
import config from "./config";

const VIDEO_PATH = `${process.env.PUBLIC_URL}/assets/videos/orbits`;

const Orbits = ({
  history,
  match: {
    url,
    path,
    params: { outer: view },
  },
}) => {
  const [showMarkers, setShowMarkers] = useState(true);
  const handleToggleMarkers = useCallback(() => {
    setShowMarkers((show) => !show);
  }, []);

  const { viewIndex, viewId, markers, orbitLinks } = useView(
    view,
    `${path}/:inner?`
  );
  const prev = usePrevView(viewIndex, path);
  const next = useNextView(viewIndex, path);

  useEffect(() => {
    if (view !== viewId) {
      history.replace(generatePath(path, { view: viewId }));
    }
  }, [view, viewId, path, history]);

  return (
    <Screen>
      <OuterOrbit id={viewId} orbitLinks={orbitLinks} prev={prev} next={next} />
      <Switch>
        <Route
          path={`${url}/:inner?`}
          render={({
            history,
            match: {
              path,
              params: { outer, inner },
            },
          }) =>
            inner != null ? (
              <ConfiguredInnerOrbit
                parentUrl={url}
                path={path}
                view={viewId}
                markers={markers}
                outer={outer}
                inner={inner}
                history={history}
                showMarkers={showMarkers}
                onToggleMarkers={handleToggleMarkers}
              />
            ) : null
          }
        />
      </Switch>
    </Screen>
  );
};

const ConfiguredInnerOrbit = ({
  parentUrl,
  outer,
  inner,
  history,
  path,
  view,
  showMarkers,
  onToggleMarkers,
}) => {
  const { aspectRatio, segments } = useSegments();

  const onIndexChanged = useCallback(
    (newInner) => {
      history.replace(generatePath(path, { view, outer, inner: newInner }));
    },
    [history, view, outer, path]
  );

  return (
    <InnerOrbit
      back={parentUrl}
      segments={segments}
      index={Number(inner)}
      aspectRatio={aspectRatio}
      showMarkers={showMarkers}
      onToggleMarkers={onToggleMarkers}
      onIndexChanged={onIndexChanged}
    />
  );
};

const useView = (view, path) => {
  const { outer } = config;
  const viewIndex = Math.max(
    outer.findIndex(({ id }) => id === view),
    0
  );
  const { id: viewId, markers } = outer[viewIndex];
  const orbitLinks = useMemo(
    () =>
      markers.map(({ id, position, segment }) => ({
        id,
        position,
        link: generatePath(path, { outer: viewId, inner: segment }),
      })),
    [markers, viewId, path]
  );
  return { viewId, markers, viewIndex, orbitLinks };
};

const usePrevView = (viewIndex, path) =>
  useMemo(() => {
    const { outer } = config;
    const viewId =
      viewIndex === 0 ? outer[outer.length - 1].id : outer[viewIndex - 1].id;
    return {
      viewId: viewId,
      link: generatePath(path, { outer: viewId }),
    };
  }, [viewIndex, path]);

const useNextView = (viewIndex, path) =>
  useMemo(() => {
    const { outer } = config;
    const viewId =
      viewIndex === outer.length - 1 ? outer[0].id : outer[viewIndex + 1].id;
    return {
      viewId,
      link: generatePath(path, { outer: viewId }),
    };
  }, [viewIndex, path]);

const useSegments = () =>
  useMemo(() => {
    return {
      aspectRatio: config.aspectRatio,
      segments: expandConfig(config),
    };
  }, []);

const expandConfig = ({ segments }) => {
  const path = `${VIDEO_PATH}`;
  return segments.map(({ videos, markers, title }) => ({
    videos: videos.map((v) => `${path}/${v}`),
    markers,
    title,
  }));
};

export default Orbits;
