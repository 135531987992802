import { memo } from "react";
import { Root } from "./MiniMap.style";

const MiniMap = ({ buildingName, theta }) => {
  let offset = Math.PI / 1.2;
  theta = theta + offset;

  let x = Math.cos(theta) * 12 + 12.5;
  let y = Math.sin(theta) * 12 + 12.5;

  let innerX = Math.cos(theta) * 11 + 12.5;
  let innerY = Math.sin(theta) * 11 + 12.5;

  // console.log("y", y);
  // console.log("x", x);
  return (
    <Root>
      <svg
        stroke="#222"
        fill="#fff"
        width="100%"
        height="100%"
        viewBox="0 -5 25 30"
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            stroke="#F39B93"
            fill="#F39B93"
            strokeWidth=".2"
            fillOpacity="1"
            strokeOpacity="0.6"
            d="M3.35,10.2,1,3,1.09,8.7v1.5H.65V1.8h.48L3.42,9.05l0-5.75V1.8h.45v8.4Z"
            transform="scale(0.3) translate(39,-10)"
          />
        </g>
        <polygon
          transform={`rotate(${
            (180 / Math.PI) * (theta - Math.PI / 2)
          }, ${x},${y} )`}
          stroke="#F39B93"
          fill="#F39B93"
          strokeWidth=".15"
          fillOpacity="1"
          strokeOpacity="0.6"
          points={`${x + 1} ${y}, ${x - 1} ${y}, ${x} ${y - 1.5}`}
        />

        <circle
          cy="12.5"
          cx="12.5"
          r="12"
          stroke="#F39B93"
          strokeWidth=".2"
          fillOpacity="0"
          strokeOpacity="0.6"
        />
        <path
          d="M15.84755,7.891309 L17.21535,7.852187 C18.01002,7.943439 18.6613,8.295243 18.6613,8.295243 L19.23443,7.044219 L18.80458,6.887828 L18.85671,6.731536 L17.99701,6.457878 L17.24147,6.379633 L17.24147,5.415275 L16.16023,5.415275 L16.16023,5.780186 L15.88677,5.780186 L15.87367,6.327503 L15.5872,6.392641 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "ia" ? "1" : "0"}
        />
        <path
          d="M15.00903,10.52837 L14.16096,11.4805 L14.42772,11.6706 L14.27566,11.85074 L15.36567,12.51759 L15.45682,12.34819 L15.67825,12.45235 L15.5872,12.63496 L16.45981,12.96065 L16.53796,12.80435 L16.79861,12.89551 L17.17643,11.6706 L16.03006,11.20133 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "id" ? "1" : "0"}
        />
        <path
          d="M18.96087,11.94416 L20.1985,11.8399 L20.40692,13.16926 L20.09414,13.18217 L20.10724,13.36458 L18.8437,13.44283 L18.8437,13.2343 L18.60927,13.2343 L18.59616,13.4038 L17.72335,13.33856 L17.72335,13.10403 L17.43678,13.07791 L17.68433,11.82699 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "ic" ? "1" : "0"}
        />
        <path
          d="M20.94177,10.81099 L22.22955,10.38173 L22.00852,9.874324 L22.12559,9.822292 L21.34501,8.534411 L20.42136,7.662784 L20.29118,7.766847 L19.95307,7.493681 L19.21142,8.638474 C19.21142,8.638474 19.80998,8.989688 20.27827,9.575142 C20.69807,10.09989 20.94177,10.81099 20.94177,10.81099 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "ib" ? "1" : "0"}
        />
        <path
          d="M7.41521,15.5613 L7.528143,15.86107 L7.276262,15.97834 L7.137314,15.6829 L6.189708,16.11689 L5.087881,16.62135 L5.209583,16.92979 L4.862114,17.07307 L4.203639,15.52977 L3.594239,14.10175 L3.889478,13.96281 L3.993738,14.17123 L5.055755,13.69309 L5.730588,13.38937 L5.643672,13.1983 L5.921568,13.07659 L6.030165,13.26777 L6.616309,13.03323 L7.176634,11.93022 L7.693205,10.92211 L7.536815,10.80041 L7.640976,10.57454 L9.464644,11.4781 L10.14204,10.14016 L7.345737,8.732742 L7.154757,9.010737 L6.946433,8.906477 L6.755257,9.288831 L6.946433,9.410336 L6.616309,10.05324 L6.720569,10.12281 L6.616309,10.33143 L6.52949,10.29655 L6.002276,11.32053 L5.643672,12.01674 L5.730588,12.31208 L5.348433,12.45113 L5.226828,12.13845 L4.505383,12.46384 L3.45529,12.93764 L3.594239,13.26777 L3.246967,13.42416 L3.021102,12.93764 L2.617266,13.11148 L2.695412,13.29379 L1.770471,13.68481 L2.708519,15.89142 L2.847368,15.83929 L2.945617,16.07689 L3.05579,16.34325 L2.951628,16.39548 L3.889478,18.58475 L4.896901,18.16761 L4.966276,18.324 L5.365875,18.1546 L5.226828,17.8028 L5.539412,17.64641 L5.613222,17.81581 L6.729044,17.30702 L7.693205,16.8819 L7.645312,16.72117 L7.866841,16.62135 L7.936413,16.77773 L8.670217,16.45004 L8.123983,15.24106 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fillOpacity="0"
        />
        <path
          d="M10.58204,14.46811 L10.88644,13.8459 L10.74749,13.75908 L10.8692,13.49843 L11.06008,13.58534 L11.3901,12.87287 L11.25125,12.80349 L11.51171,12.26475 L11.73747,12.36911 L12.38018,11.08329 L11.56384,10.6836 L11.37276,11.04851 L11.21647,10.97894 L11.3901,10.56209 L10.60854,10.1623 L9.966033,11.43076 L10.13957,11.51758 L9.861674,12.09101 L9.705382,12.02154 L9.340669,12.73402 L10.0701,13.09883 L9.948492,13.34214 L9.722727,13.23778 L9.392109,13.8797 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "ha" ? "1" : "0"}
        />
        <path
          d="M9.39458,13.8749 L8.856821,14.91839 L9.152061,15.07478 L9.030457,15.31809 L8.492058,15.07478 L8.123983,15.24106 L8.670217,16.45004 L9.04041,16.28472 L9.881489,15.90887 L9.881489,15.76982 L9.725197,15.6829 L9.846801,15.43979 L10.07257,15.50927 L10.58451,14.46331 z"
          stroke="#F39B93"
          strokeWidth="0.1"
          fill="#FF9994"
          fillOpacity={buildingName === "hb" ? "1" : "0"}
        />
      </svg>
    </Root>
  );
};

export default memo(MiniMap);
