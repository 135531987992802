import styled from "styled-components/macro";

export const Root = styled.div`
  min-width: 100%;
  min-height: 100%;
  position: relative;
`;

export const ImageAndChildren = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  transition: opacity 0.5s ease;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;
