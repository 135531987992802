import { useState, useRef, useEffect } from "react";
import Cover from "components/Cover";
import { VideoAndMarkers } from "./London.style";
import SlideDrawerTemp, {
  useSlideDrawerTempControls,
} from "components/SlideDrawer/SlideDrawerTemp";
import { BsChevronDoubleRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
// import { Tab } from "components/SlideDrawer/SlideDrawer.style";
import {
  Root,
  Tab,
  PoiRoot,
  ClosePois,
  Pois,
  Poi,
  PoiNumber,
  PoiTitle,
  PoiText,
  Left,
  Right,
  POICircle,
  Time,
} from "./Local.style";
const videoUrl = `${process.env.PUBLIC_URL}/assets/videos/londonmap.mp4`;

const CARDS = {
  riverTaxi: "River_taxi_route_card.png",
  dlr: "DLR_route_card.png",
  southeastern: "Southeastern_route_card.png",
};

const TEMP_MARKERS = [
  {
    x: 55,
    y: 65,
    width: 10,
    height: 10,
    card: CARDS.riverTaxi,
  },
  {
    x: 52,
    y: 50,
    width: 8,
    height: 5,
    card: CARDS.dlr,
  },
  {
    x: 53,
    y: 23,
    width: 7,
    height: 15,
    card: CARDS.dlr,
  },
  {
    x: 33,
    y: 50,
    width: 7,
    height: 15,
    card: CARDS.southeastern,
  },
];

const TravelTimesCard = ({ index, isHighlighted, onSelect }) => {
  return (
    <img
      style={{ position: "absolute", right: 0, marginRight: 0 }}
      // width="100%"
      height="100%"
      src={`${process.env.PUBLIC_URL}/assets/svg/traveltimes/Arden_travel_times_card.svg`}
      alt="traveltimes"
    />
  );
};

const London = () => {
  const [highlightedPOI, setHighlightedPOI] = useState();
  const { onOpen, onClose, isOpen } = useSlideDrawerTempControls(false);

  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <Cover aspectRatio={3874 / 2000}>
        <VideoAndMarkers isVisible={isVisible}>
          <video
            className="videoTag"
            autoPlay
            muted
            onLoadedData={() => {
              setIsVisible(true);
            }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          {TEMP_MARKERS.map((params) => (
            <Marker {...params} />
          ))}
        </VideoAndMarkers>
      </Cover>
      <SlideDrawerTemp
        width="10vh"
        backgroundColor="hotpink"
        isOpen={isOpen}
        onTabClick={isOpen ? onClose : onOpen}
        tab={<Tab>TRAVEL TIMES</Tab>}
      >
        {/* <ClosePois
          style={{
            border: "green 2px solid",
            zIndex: 1000,
            position: "absolute",
            width: " 100vh",
            height: "100vh",
          }}
          onClick={onClose}
        ></ClosePois> */}
        <TravelTimesCard onClick={onClose} />
      </SlideDrawerTemp>
    </>
  );
};

const Marker = ({ x, y, width, height, card }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {show && (
        <img
          style={{
            position: "absolute",
            left: `${x}%`,
            top: `${y}%`,
            transform: "translate(-25%,-25%)",
          }}
          src={imageUrl(card)}
          alt="card"
        />
      )}
      <div
        style={{
          position: "absolute",
          left: `${x}%`,
          top: `${y}%`,
          width: `${width}%`,
          height: `${height}%`,
          // backgroundColor: "rgba(255,0,0,0.5)",
        }}
      ></div>
    </>
  );
};

const imageUrl = (filename) => {
  return `${process.env.PUBLIC_URL}/assets/images/location/london/${filename}`;
};

export default London;
