import Home from "./screens/MainApp/Home";
import Gallery from "./screens/MainApp/Gallery";
import Location from "./screens/MainApp/Location";
import Showroom from "./screens/MainApp/Showroom";
import ApartmentFinder from "./screens/MainApp/ApartmentFinder";
import AboutUs from "./screens/MainApp/AboutUs";
import Film from "./screens/MainApp/Film";
import Orbits from "./screens/MainApp/Home/Orbits/Orbits";

export const primaryRoutes = [
  {
    name: "Home",
    path: "/home",
    component: Home,
  },
  // {
  //   name: "Explore",
  //   path: "/orbits/south/0",
  //   component: Home,
  // },
  {
    name: "Location",
    path: "/location",
    component: Location,
  },
  {
    name: "Apartment Finder",
    path: "/finder",
    component: ApartmentFinder,
  },
  {
    name: "Showroom",
    path: "/showroom",
    component: Showroom,
  },
  {
    name: "Film",
    path: "/film",
    component: Film,
  },

  {
    name: "Gallery",
    path: "/gallery",
    component: Gallery,
  },

  {
    name: "About Us",
    path: "/aboutus",
    component: AboutUs,
  },
  // {
  //   name: "Reset",
  //   path: "/",
  //   component: null,
  // },
];

export const home = primaryRoutes[0];
