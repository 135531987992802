import { useMemo } from "react";
import { Link } from "react-router-dom";
import Orbit from "components/Orbit";
import {
  Root,
  Back,
  TitleAndControls,
  Title,
  MarkerToggle,
  MarkerContainer,
  ToggleHolder,
} from "./InnerOrbit.style";
import Marker from "./Marker";
import ToggleInner from "components/Icons/ToggleInner";
import TogglePOIIcon from "components/Icons/TogglePOIIcon";

const InnerOrbit = ({
  back,
  segments,
  index,
  aspectRatio,
  showMarkers,
  onToggleMarkers,
  onIndexChanged,
}) => {
  const videos = useMemo(() => segments.map(({ videos }) => videos), [
    segments,
  ]);
  const { markers, title } = useMemo(() => segments[index], [segments, index]);
  return (
    <Root>
      <Orbit
        segments={videos}
        index={index}
        aspectRatio={aspectRatio}
        onIndexChanged={onIndexChanged}
      >
        <MarkerContainer isVisible={showMarkers}>
          {markers.map((markerConfig) => (
            <Marker key={markerConfig?.html} {...markerConfig} />
          ))}
        </MarkerContainer>
        <TitleAndControls>
          <Title>{title}</Title>
          <MarkerToggle onClick={onToggleMarkers} showMarkers={showMarkers}>
            <TogglePOIIcon isVisible={showMarkers} />
          </MarkerToggle>
        </TitleAndControls>
      </Orbit>
      <Link to={back}>
        <Back>
          <ToggleInner isVisible={showMarkers} />
          {/* <ToggleOuter width="100%" height="100%" /> */}
        </Back>
      </Link>
    </Root>
  );
};

export default InnerOrbit;
