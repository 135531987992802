import { Switch, Route } from "react-router";
import Home from "screens/MainApp/Home";
import Favourites from "screens/MainApp/Favourites";
import NavigationMenu from "components/NavigationMenu";
import { Root, FavouritesLink, NumInFaves } from "./MainApp.style.js";
import { favouritesSelector } from "state/selectors";
import { useSelector } from "react-redux";
import BigFavouriteIcon from "components/Icons/BigFavouriteIcon.js";

import { ThemeContext } from "styled-components";

const MainApp = ({ routes }) => {
  // const theme = useContext(ThemeContext);

  return (
    <Root>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} component={component} />
        ))}
        <Route component={Favourites} path={"/favourites"} />
        <Route component={Home} />
      </Switch>
      <NavigationMenu routes={routes} />
      <FavouritesButton />
    </Root>
  );
};

const FavouritesButton = () => {
  const favourites = useSelector(favouritesSelector);
  return (
    <FavouritesLink to={"/favourites"}>
      <BigFavouriteIcon />
      {/* <NumInFaves>{favourites.length}</NumInFaves> */}
    </FavouritesLink>
  );
};

export default MainApp;
