import { useRef, useEffect, useState } from "react";
import CoverImage from "components/CoverImage";
import {
  Root,
  Backdrop,
  Content,
  ImageContainer,
  TextContainer,
  Title,
} from "./ModalImage.style";

const GalleryImage = ({ url, label, text, onClose }) => {
  // cache the props in a ref so we still have them available
  // after the parent has removed them (so we can fade out)
  const data = useRef({ url, label, text });

  const [isMinimised, setIsMinimised] = useState(!url);

  useEffect(() => {
    if (url) {
      data.current = { url, label, text };
      setIsMinimised(false);
    } else {
      // size to 0 after fade-out is complete
      setTimeout(() => setIsMinimised(true), 500);
    }
  }, [url, label, text]);

  return (
    <Root isVisible={!!url} isMinimised={isMinimised} onClick={onClose}>
      <Backdrop />
      <Content>
        <ImageContainer>
          <CoverImage imgUrl={data.current.url} />
        </ImageContainer>
        <TextContainer>
          {data.current.text}
          <Title>{data.current.label}</Title>
        </TextContainer>
      </Content>
    </Root>
  );
};

export default GalleryImage;
