import styled from "styled-components/macro";

export const CarouselRoot = styled.div`
  flex: 1;
  position: relative;

  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;

  & > div {
    position: absolute;
    width: 80%;
    height: 110%;
    /* margin-top: 15%; */

    padding-left: ${({ theme: { spacing } }) => spacing(3)}px;
    padding-right: ${({ theme: { spacing } }) => spacing(3)}px;

    transition: left 0.5s ease-in-out;
  }
`;
