import { Switch, Route, Redirect } from "react-router";
import { useTranslation } from "react-i18next";
import Screen from "components/Screen";
import London from "./London";
import Local from "./Local";
import Arden from "./Arden";
import { LinkGroup, LinkItem, Link } from "./Location.style";

const Location = ({ match: { url } }) => (
  <Screen>
    <Switch>
      <Route path={`${url}/london`} component={London} />
      <Route path={`${url}/local`} component={Local} />
      <Route path={`${url}/arden`} component={Arden} />
      <Route render={(props) => <DefaultWithRedirect {...props} />} />
    </Switch>
    <LocationNavigation baseUrl={url} />
  </Screen>
);

const LocationNavigation = ({ baseUrl }) => {
  const { t } = useTranslation();
  return (
    <LinkGroup>
      <LinkItem>
        <Link to={`${baseUrl}/arden`}>{t("location.arden.title")}</Link>
      </LinkItem>
      <LinkItem>
        <Link to={`${baseUrl}/local`}>{t("location.local.title")}</Link>
      </LinkItem>
      <LinkItem>
        <Link to={`${baseUrl}/london`}>{t("location.london.title")}</Link>
      </LinkItem>
    </LinkGroup>
  );
};

const DefaultWithRedirect = ({ match: { url } }) => (
  <>
    <Redirect to={`${url}/london`} />
  </>
);

export default Location;
