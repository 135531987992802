import { Switch, Route } from "react-router";
import Home from "./Home";
import Orbits from "./Orbits";

const HomeRouting = ({ url }) => (
  <Switch>
    <Route path={`/orbits`} component={Orbits} />
    <Route component={Home} />
  </Switch>
);

export default HomeRouting;
