import { Root } from "components/NavigationMenu/Toggle.style";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { favouritesSelector } from "state/selectors";
import { useSelector } from "react-redux";
import { NumInFaves } from "screens/MainApp/MainApp.style";

export default function BigFavouriteIcon(props) {
  const { spacing } = useContext(ThemeContext);

  const favourites = useSelector(favouritesSelector);

  const points =
    "M0,-6.21L0.83,-6.52L1.35,-7.26L1.88,-7.99L2.48,-8.66L3.14,-9.28L3.87,-9.82L4.66,-10.25L5.5,-10.57L6.38,-10.77L7.28,-10.84L8.19,-10.81L9.08,-10.66L9.94,-10.39L10.76,-10.02L11.53,-9.55L12.23,-8.98L12.86,-8.33L13.39,-7.6L13.85,-6.82L14.22,-6L14.5,-5.14L14.69,-4.26L14.79,-3.36L14.8,-2.46L14.72,-1.56L14.56,-0.67L14.31,0.2L13.97,1.03L13.56,1.84L13.1,2.61L12.58,3.35L12.02,4.06L11.44,4.75L10.83,5.42L10.2,6.06L9.55,6.7L8.9,7.32L8.23,7.93L7.56,8.54L6.88,9.13L6.2,9.73L5.52,10.32L4.83,10.91L4.15,11.49L3.46,12.08L2.78,12.67L2.09,13.26L1.41,13.85L0.72,14.44L0.04,15.03L-0.64,14.44L-1.33,13.85L-2.01,13.27L-2.7,12.68L-3.39,12.09L-4.07,11.51L-4.76,10.92L-5.45,10.33L-6.13,9.74L-6.81,9.15L-7.49,8.55L-8.16,7.95L-8.83,7.34L-9.48,6.72L-10.13,6.08L-10.76,5.43L-11.37,4.77L-11.95,4.08L-12.51,3.36L-13.02,2.62L-13.49,1.85L-13.9,1.05L-14.24,0.21L-14.5,-0.65L-14.67,-1.54L-14.74,-2.44L-14.74,-3.34L-14.65,-4.24L-14.47,-5.13L-14.19,-5.99L-13.83,-6.81L-13.38,-7.6L-12.84,-8.33L-12.23,-8.98L-11.52,-9.55L-10.75,-10.02L-9.93,-10.39L-9.07,-10.66L-8.18,-10.81L-7.28,-10.84L-6.38,-10.77L-5.5,-10.57L-4.65,-10.25L-3.86,-9.82L-3.13,-9.28L-2.47,-8.66L-1.87,-7.99L-1.34,-7.26L-0.82,-6.52";

  return (
    <Root
    // style={{ border: "red 2px solid" }}
    >
      <svg viewBox="-24 -24 48 48">
        <circle
          stroke="#FF9994"
          strokeWidth="2"
          fillOpacity="1"
          fill="#1d4a3d"
          cx={0}
          cy={0}
          r={22}
        />
        <g fill="#fff">
          <path
            d={`${points} z`}
            stroke="#FF9994"
            stroke-width="2"
            fill="none"
            // fill="#fff"
          />
        </g>
      </svg>

      <NumInFaves>{favourites.length}</NumInFaves>
    </Root>
  );
}
