import { configureStore } from "@reduxjs/toolkit";

import apartments from "./apartments";
import apartmentFinder from "./apartmentFinder";
import favourites from "./favourites";

const createStore = (dependencies) =>
  configureStore({
    reducer: {
      apartments: apartments,
      "aprtment-finder": apartmentFinder,
      favourites: favourites,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
      }),
  });

export default createStore;
