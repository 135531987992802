export const flatTypeHolder = {
  HA001: "FT01A",
  HA002: "FT02A",
  HA003: "FT03A",
  HA004: "FT01B",
  HA005: "FT02B",
  HA006: "FT03B",
  HA007: "FT12",
  HA008: "FT03BaH",
  HA009: "FT02BaH",
  HA010: "FT01B",
  HA011: "FT02B",
  HA012: "FT03B",
  HA013: "FT29",
  HA014: "FT30A",
  HA015: "FT03BH",
  HA016: "FT02BH",
  HA017: "FT28",
  HA018: "FT01B",
  HA019: "FT02B",
  HA020: "FT03B",
  HA021: "FT29",
  HA022: "FT30A",
  HA023: "FT03BH",
  HA024: "FT02BH",
  HA025: "FT01C",
  HA026: "FT01B",
  HA027: "FT02B",
  HA028: "FT03B",
  HA029: "FT29",
  HA030: "FT30A",
  HA031: "FT03BH",
  HA032: "FT02BH",
  HA033: "FT01C",
  HA034: "FT01B",
  HA035: "FT02B",
  HA036: "FT41",
  HA037: "FT03BH",
  HA038: "FT02BH",
  HA039: "FT01C",
  HA040: "FT01B",
  HA041: "FT02B",
  HA042: "FT03C",
  HA043: "FT03BH",
  HA044: "FT02BH",
  HA045: "FT01C",
  HA046: "FT01B",
  HA047: "FT02B",
  HA048: "FT03C",
  HA049: "FT03BH",
  HA050: "FT02BH",
  HA051: "FT01C",
  HA052: "FT01B",
  HA053: "FT02B",
  HA054: "FT03C",
  HA055: "FT03BH",
  HA056: "FT02BH",
  HA057: "FT01C",
  HA058: "FT01B",
  HA059: "FT02B",
  HA060: "FT03C",
  HA061: "FT03BH",
  HA062: "FT02BH",
  HA063: "FT01C",
  HA064: "FT01B",
  HA065: "FT02B",
  HA066: "FT03C",
  HA067: "FT03BH",
  HA068: "FT02BH",
  HA069: "FT01C",
  HA070: "FT01B",
  HA071: "FT02B",
  HA072: "FT03C",
  HA073: "FT03BH",
  HA074: "FT02BH",
  HA075: "FT01C",
  HA076: "FT01B",
  HA077: "FT02B",
  HA078: "FT03C",
  HA079: "FT03BH",
  HA080: "FT02BH",
  HA081: "FT01C",
  HA082: "FT01B",
  HA083: "FT02Bb",
  HA084: "FT02BbH",
  HA085: "FT01C",
  HA086: "FT01B",
  HA087: "FT02Bc",
  HA088: "FT02BcH",
  HA089: "FT01C",
  HA090: "FT01B",
  HA091: "FT02Bc",
  HA092: "FT02BcH",
  HA093: "FT01C",
  HA094: "FT01B",
  HA095: "FT02Bc",
  HA096: "FT02BcH",
  HA097: "FT01C",
  HB001: "FT04A",
  HB002: "FT13Aa",
  HB003: "FT06Bhb",
  HB004: "FT14hb",
  HB005: "FT13A",
  HB006: "FT06Bhb",
  HB007: "FT14hb",
  HB008: "FT13A",
  HB009: "FT06Bhb",
  HB010: "FT14hb",
  HB011: "FT13A",
  HB012: "FT06Bhb",
  HB013: "FT14hb",
  HB014: "FT42",
  HB015: "FT43",
  HB016: "FT44",
  IB001: "FT15AaH",
  IB002: "FT04AaH",
  IB003: "FT05aH",
  IB004: "FT15AH",
  IB005: "FT04AH",
  IB006: "FT05H",
  IB007: "FT09H",
  IB008: "FT15B",
  IB009: "FT15AH",
  IB010: "FT04AH",
  IB011: "FT05H",
  IB012: "FT04BH",
  IB013: "FT15B",
  IB014: "FT15AH",
  IB015: "FT04AH",
  IB016: "FT05H",
  IB017: "FT04BH",
  IB018: "FT15B",
  IB019: "FT15AH",
  IB020: "FT04AH",
  IB021: "FT05H",
  IB022: "FT04BH",
  IB023: "FT15B",
  IB024: "FT15AH",
  IB025: "FT04AH",
  IB026: "FT05H",
  IB027: "FT04BH",
  IB028: "FT15B",
  IB029: "FT15AH",
  IB030: "FT04AH",
  IB031: "FT05H",
  IB032: "FT04BH",
  IB033: "FT15B",
  IB034: "FT15AH",
  IB035: "FT12AH",
  IB036: "FT11B",
  IB037: "FT12B",
  IB038: "FT15B",
  IC001: "FT06Aa",
  IC002: "FT07Aa",
  IC003: "FT08a",
  IC004: "FT07Ba",
  IC005: "FT06A",
  IC006: "FT07A",
  IC007: "FT08",
  IC008: "FT13",
  IC009: "FT14",
  IC010: "FT06A",
  IC011: "FT07A",
  IC012: "FT08",
  IC013: "FT07B",
  IC014: "FT06Ba",
  IC015: "FT06A",
  IC016: "FT07A",
  IC017: "FT08",
  IC018: "FT07B",
  IC019: "FT06Ba",
  IC020: "FT06A",
  IC021: "FT07A",
  IC022: "FT08",
  IC023: "FT07B",
  IC024: "FT06Ba",
  IC025: "FT06A",
  IC026: "FT07A",
  IC027: "FT08",
  IC028: "FT07B",
  IC029: "FT06Ba",
  IC030: "FT06A",
  IC031: "FT07A",
  IC032: "FT08",
  IC033: "FT07B",
  IC034: "FT06Ba",
  ID001: "FT07BaH",
  ID002: "FT08aH",
  ID003: "FT07AaH",
  ID004: "FT06AaH",
  ID005: "FT14H",
  ID006: "FT13H",
  ID007: "FT08H",
  ID008: "FT07AH",
  ID009: "FT06AH",
  ID010: "FT06BH",
  ID011: "FT07BH",
  ID012: "FT08H",
  ID013: "FT07AH",
  ID014: "FT06AH",
  ID015: "FT06BH",
  ID016: "FT07BH",
  ID017: "FT08H",
  ID018: "FT07AH",
  ID019: "FT06AH",
  ID020: "FT06BH",
  ID021: "FT07BH",
  ID022: "FT08H",
  ID023: "FT07AH",
  ID024: "FT06AH",
  ID025: "FT06BH",
  ID026: "FT07BH",
  ID027: "FT08H",
  ID028: "FT07AH",
  ID029: "FT06AH",
  ID030: "FT06BH",
  ID031: "FT07BH",
  ID032: "FT08H",
  ID033: "FT07AH",
  ID034: "FT06AH",
};

export const aspectHolder = {
  HA001: "SE",
  HA002: "SE",
  HA003: "SE",
  HA004: "SE",
  HA005: "SE",
  HA006: "SE",
  HA007: "SE",
  HA008: "NW",
  HA009: "NW",
  HA010: "SE",
  HA011: "SE",
  HA012: "SE",
  HA013: "SE",
  HA014: "NW",
  HA015: "NW",
  HA016: "NW",
  HA017: "NW",
  HA018: "SE",
  HA019: "SE",
  HA020: "SE",
  HA021: "SE",
  HA022: "NW",
  HA023: "NW",
  HA024: "NW",
  HA025: "NW",
  HA026: "SE",
  HA027: "SE",
  HA028: "SE",
  HA029: "SE",
  HA030: "NW",
  HA031: "NW",
  HA032: "NW",
  HA033: "NW",
  HA034: "SE",
  HA035: "SE",
  HA036: "SE",
  HA037: "NW",
  HA038: "NW",
  HA039: "NW",
  HA040: "SE",
  HA041: "SE",
  HA042: "SE",
  HA043: "NW",
  HA044: "NW",
  HA045: "NW",
  HA046: "SE",
  HA047: "SE",
  HA048: "SE",
  HA049: "NW",
  HA050: "NW",
  HA051: "NW",
  HA052: "SE",
  HA053: "SE",
  HA054: "SE",
  HA055: "NW",
  HA056: "NW",
  HA057: "NW",
  HA058: "SE",
  HA059: "SE",
  HA060: "SE",
  HA061: "NW",
  HA062: "NW",
  HA063: "NW",
  HA064: "SE",
  HA065: "SE",
  HA066: "SE",
  HA067: "NW",
  HA068: "NW",
  HA069: "NW",
  HA070: "SE",
  HA071: "SE",
  HA072: "SE",
  HA073: "NW",
  HA074: "NW",
  HA075: "NW",
  HA076: "SE",
  HA077: "SE",
  HA078: "SE",
  HA079: "NW",
  HA080: "NW",
  HA081: "NW",
  HA082: "SE",
  HA083: "SW",
  HA084: "SW",
  HA085: "NW",
  HA086: "SE",
  HA087: "SW",
  HA088: "SW",
  HA089: "NW",
  HA090: "SE",
  HA091: "SW",
  HA092: "SW",
  HA093: "NW",
  HA094: "SE",
  HA095: "SW",
  HA096: "SW",
  HA097: "NW",
  HB001: "SE",
  HB002: "NW",
  HB003: "SE",
  HB004: "SE",
  HB005: "NW",
  HB006: "SE",
  HB007: "S",
  HB008: "NW",
  HB009: "SE",
  HB010: "S",
  HB011: "NW",
  HB012: "SE",
  HB013: "S",
  HB014: "SE",
  HB015: "SE",
  HB016: "S",
  IB001: "NE",
  IB002: "SW",
  IB003: "SW",
  IB004: "NE",
  IB005: "SW",
  IB006: "SW",
  IB007: "S",
  IB008: "NE",
  IB009: "NE",
  IB010: "SW",
  IB011: "SW",
  IB012: "SW",
  IB013: "NE",
  IB014: "NE",
  IB015: "SW",
  IB016: "SW",
  IB017: "SW",
  IB018: "NE",
  IB019: "NE",
  IB020: "SW",
  IB021: "SW",
  IB022: "SW",
  IB023: "NE",
  IB024: "NE",
  IB025: "SW",
  IB026: "SW",
  IB027: "SW",
  IB028: "NE",
  IB029: "NE",
  IB030: "SW",
  IB031: "SW",
  IB032: "SW",
  IB033: "NE",
  IB034: "NE",
  IB035: "SW",
  IB036: "SW",
  IB037: "SW",
  IB038: "NE",
  IC001: "N",
  IC002: "S",
  IC003: "S",
  IC004: "S",
  IC005: "N",
  IC006: "S",
  IC007: "S",
  IC008: "S",
  IC009: "E",
  IC010: "N",
  IC011: "S",
  IC012: "S",
  IC013: "S",
  IC014: "N",
  IC015: "N",
  IC016: "S",
  IC017: "S",
  IC018: "S",
  IC019: "N",
  IC020: "N",
  IC021: "S",
  IC022: "S",
  IC023: "S",
  IC024: "N",
  IC025: "N",
  IC026: "S",
  IC027: "S",
  IC028: "S",
  IC029: "N",
  IC030: "N",
  IC031: "S",
  IC032: "S",
  IC033: "S",
  IC034: "N",
  ID001: "SW",
  ID002: "SW",
  ID003: "SW",
  ID004: "NE",
  ID005: "NW",
  ID006: "SW",
  ID007: "SW",
  ID008: "SW",
  ID009: "NE",
  ID010: "NW",
  ID011: "SW",
  ID012: "SW",
  ID013: "SW",
  ID014: "NE",
  ID015: "NW",
  ID016: "SW",
  ID017: "SW",
  ID018: "SW",
  ID019: "NE",
  ID020: "NW",
  ID021: "SW",
  ID022: "SW",
  ID023: "SW",
  ID024: "NE",
  ID025: "NW",
  ID026: "SW",
  ID027: "SW",
  ID028: "SW",
  ID029: "NE",
  ID030: "NW",
  ID031: "SW",
  ID032: "SW",
  ID033: "SW",
  ID034: "NE",
};

export const floorHolder = {
  HA001: "-1",
  HA002: "-1",
  HA003: "-1",
  HA004: "0",
  HA005: "0",
  HA006: "0",
  HA007: "0",
  HA008: "0",
  HA009: "0",
  HA010: "1",
  HA011: "1",
  HA012: "1",
  HA013: "1",
  HA014: "1",
  HA015: "1",
  HA016: "1",
  HA017: "1",
  HA018: "2",
  HA019: "2",
  HA020: "2",
  HA021: "2",
  HA022: "2",
  HA023: "2",
  HA024: "2",
  HA025: "2",
  HA026: "3",
  HA027: "3",
  HA028: "3",
  HA029: "3",
  HA030: "3",
  HA031: "3",
  HA032: "3",
  HA033: "3",
  HA034: "4",
  HA035: "4",
  HA036: "4",
  HA037: "4",
  HA038: "4",
  HA039: "4",
  HA040: "5",
  HA041: "5",
  HA042: "5",
  HA043: "5",
  HA044: "5",
  HA045: "5",
  HA046: "6",
  HA047: "6",
  HA048: "6",
  HA049: "6",
  HA050: "6",
  HA051: "6",
  HA052: "7",
  HA053: "7",
  HA054: "7",
  HA055: "7",
  HA056: "7",
  HA057: "7",
  HA058: "8",
  HA059: "8",
  HA060: "8",
  HA061: "8",
  HA062: "8",
  HA063: "8",
  HA064: "9",
  HA065: "9",
  HA066: "9",
  HA067: "9",
  HA068: "9",
  HA069: "9",
  HA070: "10",
  HA071: "10",
  HA072: "10",
  HA073: "10",
  HA074: "10",
  HA075: "10",
  HA076: "11",
  HA077: "11",
  HA078: "11",
  HA079: "11",
  HA080: "11",
  HA081: "11",
  HA082: "12",
  HA083: "12",
  HA084: "12",
  HA085: "12",
  HA086: "13",
  HA087: "13",
  HA088: "13",
  HA089: "13",
  HA090: "14",
  HA091: "14",
  HA092: "14",
  HA093: "14",
  HA094: "15",
  HA095: "15",
  HA096: "15",
  HA097: "15",
  HB001: "-1",
  HB002: "0",
  HB003: "0",
  HB004: "0",
  HB005: "1",
  HB006: "1",
  HB007: "1",
  HB008: "2",
  HB009: "2",
  HB010: "2",
  HB011: "3",
  HB012: "3",
  HB013: "3",
  HB014: "4",
  HB015: "4",
  HB016: "4",
  IB001: "0",
  IB002: "0",
  IB003: "0",
  IB004: "1",
  IB005: "1",
  IB006: "1",
  IB007: "1",
  IB008: "1",
  IB009: "2",
  IB010: "2",
  IB011: "2",
  IB012: "2",
  IB013: "2",
  IB014: "3",
  IB015: "3",
  IB016: "3",
  IB017: "3",
  IB018: "3",
  IB019: "4",
  IB020: "4",
  IB021: "4",
  IB022: "4",
  IB023: "4",
  IB024: "5",
  IB025: "5",
  IB026: "5",
  IB027: "5",
  IB028: "5",
  IB029: "6",
  IB030: "6",
  IB031: "6",
  IB032: "6",
  IB033: "6",
  IB034: "7",
  IB035: "7",
  IB036: "7",
  IB037: "7",
  IB038: "7",
  IC001: "0",
  IC002: "0",
  IC003: "0",
  IC004: "0",
  IC005: "1",
  IC006: "1",
  IC007: "1",
  IC008: "1",
  IC009: "1",
  IC010: "2",
  IC011: "2",
  IC012: "2",
  IC013: "2",
  IC014: "2",
  IC015: "3",
  IC016: "3",
  IC017: "3",
  IC018: "3",
  IC019: "3",
  IC020: "4",
  IC021: "4",
  IC022: "4",
  IC023: "4",
  IC024: "4",
  IC025: "5",
  IC026: "5",
  IC027: "5",
  IC028: "5",
  IC029: "5",
  IC030: "6",
  IC031: "6",
  IC032: "6",
  IC033: "6",
  IC034: "6",
  ID001: "0",
  ID002: "0",
  ID003: "0",
  ID004: "0",
  ID005: "1",
  ID006: "1",
  ID007: "1",
  ID008: "1",
  ID009: "1",
  ID010: "2",
  ID011: "2",
  ID012: "2",
  ID013: "2",
  ID014: "2",
  ID015: "3",
  ID016: "3",
  ID017: "3",
  ID018: "3",
  ID019: "3",
  ID020: "4",
  ID021: "4",
  ID022: "4",
  ID023: "4",
  ID024: "4",
  ID025: "5",
  ID026: "5",
  ID027: "5",
  ID028: "5",
  ID029: "5",
  ID030: "6",
  ID031: "6",
  ID032: "6",
  ID033: "6",
  ID034: "6",
};
