import styled, { css } from "styled-components/macro";

export const AbsoluteFill = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const Root = styled(AbsoluteFill)`
  z-index: 10000000;
  display: flex;
  justify-content: center;

  align-items: center;
`;

export const GlassPanel = styled(AbsoluteFill)`
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ContentPanel = styled.div`
  z-index: 10000000;
  position: absolute;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 57vw; */
  width: 70vw;

  /* border: red solid 2px; */
  height: 85vh;

  ${({ fullScreen }) =>
    fullScreen
      ? css`
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: 2vw;
        `
      : css`
          /* transform: translate(-50%, -50%); */
        `}
`;

export const Content = styled.div`
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.95);
  border-radius: ${({ theme: { spacing } }) => spacing(0)}px;
  flex: 1;
`;
