import styled from "styled-components/macro";
import { showOutlines } from "../showOutlines";
import params from "../BuildingExplorerParams";

// const rootHeight = params.windowY / (params.inc * 0.3);

export const FloorplanDOM = styled.div`
  display: block;
  outline: ${showOutlines ? "#E73358 solid 1px" : ""};

  background-color: ${showOutlines ? " rgba(200, 200, 200, 0.2)" : ""};

  height: 10vh;
  margin: auto;
  pointer-events: none;
`;

export const IntersectionRoot = styled.div`
  position: sticky;
  background-color: ${showOutlines ? "rgba(200,0,200,0.2)" : ""};
  outline: ${showOutlines ? "#fff solid 1px" : ""};
  top: 50%;
  transform: translate(0px, -50%);

  height: 27.6vh;

  pointer-events: none;
`;

export const ScrollArea = styled.div`
  position: absolute;
  outline: ${showOutlines ? "#FEE9C9 solid 5px" : ""};

  top: 0;

  right: 0;

  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
`;

export const FloorModelContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45%;

  height: 100vh;
  position: relative;
  outline: ${showOutlines ? "#4CAF50 solid 1px" : ""};
`;
