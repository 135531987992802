import { useState } from "react";

import Screen from "components/Screen";
import { ShowroomCard } from "./Showroom.style";
import { AbsoluteFill } from "components/Modal/Modal.style";
// https://biganto.com/tour/29297/

const Showroom = () => {
  const [cardFade, setCardFade] = useState(true);
  const [card, setCard] = useState(true);

  function x() {
    let promise = new Promise(function (resolve, reject) {
      setCardFade(false);
      window.setTimeout(function () {
        resolve(setCard(false));
      }, 500);
    });
    return promise;
  }

  return (
    <Screen>
      {card && (
        <ShowroomCard
          showPopup={cardFade}
          onClick={x}
          src={`${process.env.PUBLIC_URL}/assets/svg/tourcard.svg`}
          alt="card"
        />
      )}
      <iframe
        title="tour"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking; gyroscope; accelerometer"
        scrolling="no"
        src="https://biganto.com/tour/29568/"
        // src="https://biganto.com/tour/29297/"
      ></iframe>
    </Screen>
  );
};

export default Showroom;
